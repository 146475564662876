import './EmptyClusterResult.less';

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as AddCloudClusterIcon } from './icons/add-cloud-cluster.svg';

const { Title } = Typography;

function EmptyClusterResult() {
  return (
    <div className="empty-cluster-result">
      <AddCloudClusterIcon />

      <Title level={2} className="empty-cluster-result__title">
        YOUR CLUSTERS LIST IS EMPTY
      </Title>

      <Link to="/clusters/create">
        <Button type="link">
          <PlusOutlined /> Create New Cluster
        </Button>
      </Link>
    </div>
  );
}

export default EmptyClusterResult;
