import { format } from 'date-fns';
import { groupBy, map, sumBy, uniq } from 'lodash';
import { MeasureType } from 'components/Consumption/ConsumptionBody/ConsumptionBody';

const Measure: {
  [key in MeasureType]: (data: ChartData[]) => { data: any; labels: string[] };
} = {
  daily: getChartDataAsDays,
  hourly: getChartDataAsHours,
  monthly: getCartDataAsMonths,
};

interface ChartData {
  day: Date;
  monthFormat: string;
  dayFormat: string;
  incorta: any;
  spark: any;
  total: any;
  isBeforeNow: boolean;
  hours: {
    hour: number;
    incorta: number;
    spark: number;
  }[];
}

function getChartDataAsDays(data: ChartData[]) {
  return {
    data: data.map(day => ({
      value: day.total,
      incorta: day.incorta,
      spark: day.spark,
    })),
    labels: data.map(day => format(day.day, 'dd MMM')),
  };
}

function getChartDataAsHours(data: ChartData[]) {
  return {
    data: map(
      groupBy(
        data.flatMap(e => e.hours),
        'hour',
      ),
      group => ({
        value: sumBy(group, 'incorta') + sumBy(group, 'spark'),
        incorta: sumBy(group, 'incorta'),
        spark: sumBy(group, 'spark'),
      }),
    ),
    labels: Array.from({ length: 24 }, (_, i) => `${i + 1}:00`),
  };
}

function getCartDataAsMonths(data: ChartData[]) {
  return {
    data: map(groupBy(data, 'monthFormat'), group => ({
      value: sumBy(group, 'incorta') + sumBy(group, 'spark'),
      incorta: sumBy(group, 'incorta'),
      spark: sumBy(group, 'spark'),
    })),
    labels: uniq(data.map(day => format(new Date(day.monthFormat), 'LLLL'))),
  };
}

export function getXAxisData(measure: MeasureType, data: ChartData[]) {
  return Measure[measure](data);
}
