import { Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { azureCreationPipelineStatusMap } from '../azureClusterCreationHelper';

type AzureCreationClusterNextStepProps = {
  nextStep: string;
};
function AzureCreationClusterNextStep({
  nextStep,
}: AzureCreationClusterNextStepProps) {
  const intl = useIntl();

  const nextStepName = azureCreationPipelineStatusMap.has(nextStep)
    ? azureCreationPipelineStatusMap.get(nextStep)
    : nextStep;

  return (
    <div className="azure-creation-cluster-step next-step">
      <p className="azure-creation-cluster-step__title">
        {intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.nextStep',
        })}
      </p>
      <Tooltip title={nextStepName}>
        <p className="azure-creation-cluster-step__name">{nextStepName}</p>
      </Tooltip>
    </div>
  );
}

export default AzureCreationClusterNextStep;
