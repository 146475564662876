import './PageSideWrapper.less';

import React from 'react';

interface PageSideWrapperProps {}
function PageSideWrapper({
  children,
}: React.PropsWithChildren<PageSideWrapperProps>) {
  return <div className="PageSideWrapper">{children}</div>;
}

export default PageSideWrapper;
