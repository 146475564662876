import React from 'react';
import './AzureCreationClusterSteps.less';
import AzureCreationClusterCurrentStep from './AzureCreationClusterCurrentStep';
import AzureCreationClusterNextStep from './AzureCreationClusterNextStep';

type Props = {
  currentStep: string;
  states: string[];
};
function AzureCreationClusterSteps({ currentStep, states }: Props) {
  const nextStepIndex = states.findIndex(state => state === currentStep) + 1;

  return (
    <div className="azure-creation-cluster-steps">
      <AzureCreationClusterCurrentStep
        currentStep={currentStep}
        stepOrder={nextStepIndex}
        stepsCount={states.length}
      />
      {nextStepIndex < states.length ? (
        <AzureCreationClusterNextStep nextStep={states[nextStepIndex]} />
      ) : (
        <div className="azure-creation-cluster-step"></div>
      )}
    </div>
  );
}

export default AzureCreationClusterSteps;
