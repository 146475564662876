import { useEffect, useRef } from 'react';
import useAzurePipeline from 'hooks/useAzurePipeline';
import { AzureClusterPipelineData, InstancesData } from 'types/cluster';

type useShowLoadingClusterListProps = {
  areClustersLoading: boolean;
  clustersData: InstancesData | undefined;
  azureClusterPipelineIsLoading: boolean;
  azureClusterPipelineData: AzureClusterPipelineData | undefined;
};

function useShowLoadingClusterList({
  areClustersLoading,
  clustersData,
  azureClusterPipelineIsLoading,
  azureClusterPipelineData,
}: useShowLoadingClusterListProps) {
  const mounted = useRef(false);
  const { isAzurePipelineDone, isNotConfiguredYet: azureIsNotConfiguredYet } =
    useAzurePipeline({
      azureClusterPipelineData,
    });

  useEffect(() => {
    mounted.current = true;
  }, []);

  const shouldShowLoading =
    !mounted.current ||
    (areClustersLoading &&
      (azureClusterPipelineIsLoading ||
        isAzurePipelineDone ||
        azureIsNotConfiguredYet)) ||
    (azureClusterPipelineIsLoading && clustersData?.instances?.length === 0);
  return { shouldShowLoading };
}

export default useShowLoadingClusterList;
