import './AccessSecurity.less';

import React from 'react';
import { Anchor, Col, Row } from 'antd';
import AuthUserSection from './AuthUserSection/AuthUserSection';
import WhitelistSection from './WhitelistSection/WhitelistSection';
import { Instance } from 'types/cluster';
import { UserData } from 'types/user';

const { Link } = Anchor;

interface AccessSecurityProps {
  instance: Instance;
  user: UserData;
}
function AccessSecurity({ instance, user }: AccessSecurityProps) {
  return (
    <Row gutter={32}>
      <Col xs={20}>
        <div className="AccessSecurity__wrapper" id="cluster-users">
          <AuthUserSection instance={instance} />
        </div>

        <div className="AccessSecurity__wrapper" id="ip-whitelisting">
          <WhitelistSection instance={instance} user={user} />
        </div>
      </Col>
      <Col xs={4}>
        <Anchor style={{ marginTop: 24 }}>
          <Link href="#cluster-users" title="Cluster Administration Users" />
          <Link
            href="#ip-whitelisting"
            title="Configure IP Based Access Restriction"
          />
        </Anchor>
      </Col>
    </Row>
  );
}

export default AccessSecurity;
