import './MainDetails.less';

import React, { useState, useEffect, useContext, useMemo } from 'react';
import Icon, { ClockCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, Col, Row, Tooltip, Spin } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery } from 'react-query';
import MainInfoSection from './MainInfoSection/MainInfoSection';
import BIToolsCard from './BIToolsCard/BIToolsCard';
import IncortaXCard from './IncortaXCard/IncortaXCard';
import ClusterRegionCard from './ClusterRegionCard/ClusterRegionCard';
import ClusterSizeCard from './ClusterSizeCard/ClusterSizeCard';
import ConsumedUnitsCard from './ConsumedUnitsCard/ConsumedUnitsCard';
import SubClusterCard from './SubClusterCard/SubClusterCard';
import NodesConsumptionPopover from './NodesConsumptionPopover/NodesConsumptionPopover';
import { ReactComponent as DiskUsageIcon } from 'images/disk-usage-icon.svg';
import { ReactComponent as IncortaVIcon } from 'images/incorta-v-icon.svg';
import { ReactComponent as UpdateIcon } from 'images/update-icon.svg';
import { ReactComponent as UsersIcon } from 'images/users-icon.svg';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import ReleaseModal from 'components/ClusterDetails/ConfigurCluster/ReleaseModal/ReleaseModal';
import { useClusterListRefetch } from 'hooks/cluster';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { getClusterStatus, isJsonString } from 'utils/cluster';
import {
  getClusterUpdates,
  cancelUpgrade,
  getClusterServicesConsumption,
} from 'services/cluster';
import { showErrorMessage } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';

interface MainDetailsProps {
  instance: Instance;
  services: Service[];
  serverConfiguration: ServerConfiguration[];
  incortaXServerConfiguration: ServerConfiguration[];
}

const GET_CLUSTER_CONSUMPTION_KEY = 'GET_CLUSTER_CONSUMPTION_KEY';
const CONSUMPTION_THRESHOLD = 5;
const antIcon = <LoadingOutlined style={{ fontSize: 18 }} spin />;

function MainDetails({
  instance,
  services,
  serverConfiguration,
  incortaXServerConfiguration,
}: MainDetailsProps) {
  const intl = useIntl();
  const { user } = useContext(SessionContext);
  const [showReleaseModal, setShowReleaseModal] = useState(false);
  const [showCancelUpgrade, setShowCancelUpgrade] = useState<any>(undefined);
  const [showUpgradeButton, setShowUpgrade] = useState(true);
  const [clusterUpdates, setClusterUpdates] = useState<any>([]);
  const requestCluster = useClusterListRefetch();
  const status = getClusterStatus(instance, services[0]);

  const { data, isLoading } = useQuery(
    [GET_CLUSTER_CONSUMPTION_KEY, user.uuid, instance.name],
    () => {
      return getClusterServicesConsumption(user.uuid, instance.name);
    },
  );

  const isConsumptionExceededThreshold = useMemo(() => {
    return (
      data?.data?.nodes?.some(
        node => node.availabeSize <= CONSUMPTION_THRESHOLD,
      ) || instance.requireCleanup
    );
  }, [data, instance.requireCleanup]);

  useEffect(() => {
    getUpdates();
    if (status !== 'sleeping') {
      setShowCancelUpgrade(false);
    }
    //eslint-disable-next-line
  }, []);

  function getUpdates() {
    getClusterUpdates({
      userId: user!.uuid,
      clusterName: instance.name,
    })
      .then((response: any) => {
        if (response.data.updates) {
          response.data.updates = response.data.updates.map((update: any) => ({
            ...update,
            value: isJsonString(update.value) && JSON.parse(update.value),
          }));

          setClusterUpdates(response.data.updates);
          if (response.data.updates.length > 0) {
            toggleUpgrade(false);
          } else {
            setShowCancelUpgrade(false);
          }
        }
      })
      .catch((error: any) => {
        showErrorMessage(
          error?.response?.data?.message || error?.message || error,
        );
      });
  }

  const { isToggleFeatureEnabled: isSubClusterToggleFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.subcluster,
    });

  const { isToggleFeatureEnabled: isIncortaXFeatureEnabled } = useToggleFeature(
    {
      user,
      featureKey: TOGGLE_FEATURE_KEYS.incortaX,
    },
  );

  const showSubCluster =
    instance &&
    instance.subClusters.length > 0 &&
    instance?.clusterPolicy.subClusterPolicy.includes('list') &&
    isSubClusterToggleFeatureEnabled;

  const isLocked = instance?.lockedActions?.includes('Self_Upgrade');
  const showUpgrade =
    instance?.clusterPolicy.managementPolicy.includes('update');

  const { isPlatformActionSupported: isUpgradeSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clusterUpgrade,
    });

  const toggleUpgrade = (show: boolean) => {
    setShowCancelUpgrade(!show);
    setShowUpgrade(show);
  };

  function cancelUpgradeDisconnected() {
    getUpdates();
    cancelUpgrade({
      userId: user!.uuid,
      clusterName: instance.name,
      updateId: clusterUpdates[0].id,
    }).then((res: any) => {
      toggleUpgrade(true);
    });
  }

  function getUpdateTitle() {
    if (!isUpgradeSupported) {
      return (
        <FormattedMessage
          id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
          values={{
            action: intl.formatMessage({
              id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.upgrade',
            }),
          }}
        />
      );
    } else
      return isLocked ? 'Update is currently locked for this cluster.' : '';
  }

  function isUpdateButtonDisabled() {
    if (isLocked) return true;
    return !isUpgradeSupported;
  }

  return (
    <div className="MainDetails">
      <div className="MainDetails__top-section">
        <MainInfoSection
          icon={<Icon component={IncortaVIcon} />}
          title={'Current Version'}
          subTitle={
            instance.customBuild
              ? `${instance.customBuild}`
              : `Release ${instance.image}`
          }
          actions={
            <>
              {showCancelUpgrade === false &&
                showUpgrade &&
                instance.upgradable &&
                showUpgradeButton && (
                  <Tooltip placement="left" title={getUpdateTitle()}>
                    <Button
                      size="small"
                      disabled={isUpdateButtonDisabled()}
                      icon={<Icon component={UpdateIcon} />}
                      onClick={() => {
                        setShowReleaseModal(true);
                      }}
                    >
                      Update
                    </Button>
                  </Tooltip>
                )}
              {showCancelUpgrade && (
                <Button
                  size="small"
                  icon={<Icon component={UpdateIcon} />}
                  onClick={() => {
                    cancelUpgradeDisconnected();
                  }}
                >
                  Cancel
                </Button>
              )}
              {clusterUpdates?.length > 0 && (
                <Tooltip
                  title={
                    clusterUpdates[0]?.value?.schedule ? (
                      <FormattedMessage
                        id="clusterPage.pendingUpdates.title"
                        values={{
                          date:
                            new Date(
                              clusterUpdates[0]?.value?.schedule,
                            ).toDateString() +
                            ' ' +
                            new Date(
                              clusterUpdates[0]?.value?.schedule,
                            ).toLocaleTimeString(),
                        }}
                      />
                    ) : (
                      <FormattedMessage id="clusterPage.pendingUpdates.startupTitle" />
                    )
                  }
                >
                  <ClockCircleOutlined />
                </Tooltip>
              )}
            </>
          }
        />
        <ReleaseModal
          instance={instance}
          show={showReleaseModal}
          setShow={toggleUpgrade}
          requestCluster={requestCluster}
          status={status}
          setShowModal={setShowReleaseModal}
          updateCluster={getUpdates}
        />

        <MainInfoSection
          icon={<Icon component={UsersIcon} />}
          title={'Total Incorta Users'}
          subTitle={<>{instance.tenantUsers} Users</>}
        />

        {instance.remoteStorage ? (
          <MainInfoSection
            icon={<Icon component={DiskUsageIcon} />}
            title={
              <span>Consumed Tenant Data ({instance.consumedData} GB)</span>
            }
            subTitle={
              isLoading ? (
                <Spin indicator={antIcon} />
              ) : (
                data?.data.nodes && (
                  <NodesConsumptionPopover nodes={data.data.nodes} />
                )
              )
            }
          />
        ) : (
          <MainInfoSection
            icon={<Icon component={DiskUsageIcon} />}
            title={'Disk Size'}
            subTitle={
              <>
                {+instance.dsize - +instance.availableDisk} GB of{' '}
                {instance.dsize} GB
              </>
            }
          />
        )}
      </div>

      <div className="MainDetails__cards-section">
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={8}>
            <ClusterSizeCard instance={instance} />
          </Col>
          <Col xs={24} lg={8}>
            {user && (
              <ClusterRegionCard
                instance={instance}
                isTrial={user?.role?.id === 1}
              />
            )}
            <BIToolsCard
              instance={instance}
              serverConfigurations={serverConfiguration}
            />
            {isIncortaXFeatureEnabled && (
              <IncortaXCard
                instance={instance}
                serverConfigurations={incortaXServerConfiguration}
              />
            )}
            {showSubCluster ? (
              <>
                <div className="MainDetails__cards-section-spacer" />
                <SubClusterCard instance={instance} />
              </>
            ) : null}
          </Col>
          <Col xs={24} lg={8}>
            <ConsumedUnitsCard instance={instance} />
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default MainDetails;
