import './LeftSideContent.less';

import React from 'react';
import { Tooltip } from 'antd';
import { Link, useParams } from 'react-router-dom';
import Icon from '@ant-design/icons';
import clsx from 'clsx';
import { useClusterList } from 'hooks/cluster';
import { chunkCluster, getClusterStatus } from 'utils/cluster';
import { ReactComponent as DisconnectedIcon } from 'images/disconnected.svg';
import { ReactComponent as LoadingIcon } from 'images/loading-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import { InstanceInfo } from 'types/cluster';

function LeftSideContent() {
  const { clusterName } = useParams<{ clusterName: string }>();

  let { data } = useClusterList();

  if (!data?.instances) {
    return null;
  }

  const { connectedClusters, disconnectedClusters } = chunkCluster(
    data.instances,
  );

  function renderLink(cluster: InstanceInfo) {
    let { instance, services } = cluster;

    let status = getClusterStatus(instance, services[0]);

    let statusIcon =
      status === 'starting-up' || status === 'creating' ? (
        <Icon component={LoadingIcon} />
      ) : status === 'running' ? (
        <Tooltip title="Connected">
          <Icon component={CheckIcon} />
        </Tooltip>
      ) : status === 'sleeping' ? (
        <Tooltip title="Disconnected">
          <Icon component={DisconnectedIcon} style={{ color: '#B43237' }} />
        </Tooltip>
      ) : null;

    let isActiveCluster = clusterName === instance.name;

    return (
      <Link
        key={instance.id}
        to={`/clusters/${instance.name}`}
        className={clsx('LeftSideContent__link', isActiveCluster && 'active')}
      >
        {statusIcon} {instance.name}
      </Link>
    );
  }

  return (
    <div className="LeftSideContent">
      <h3>Connected Clusters ({connectedClusters.length})</h3>
      <div className="LeftSideContent__list">
        {connectedClusters.length === 0 ? (
          <small>No clusters found</small>
        ) : (
          connectedClusters.map(renderLink)
        )}
      </div>
      <h3>Disconnected Clusters ({disconnectedClusters.length})</h3>
      <div className="LeftSideContent__list">
        {disconnectedClusters.length === 0 ? (
          <small>No clusters found</small>
        ) : (
          disconnectedClusters.map(renderLink)
        )}
      </div>
    </div>
  );
}

export default LeftSideContent;
