import './MainInfoSection.less';
import React from 'react';

interface MainInfoSectionProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  subTitle: React.ReactNode;
  actions?: React.ReactNode;
}

function MainInfoSection({
  icon,
  title,
  subTitle,
  actions,
}: MainInfoSectionProps) {
  return (
    <div className="MainInfoSection">
      <div className="MainInfoSection__left">
        <div className="MainInfoSection__icon">{icon}</div>
        <div className="MainInfoSection__info">
          <div className="MainInfoSection__title">{title}</div>
          <div className="MainInfoSection__sub-title">{subTitle}</div>
        </div>
      </div>
      {actions && <div className="MainInfoSection__actions">{actions}</div>}
    </div>
  );
}

export default MainInfoSection;
