import './ReleaseModal.less';

import React, { useState, useEffect, useContext } from 'react';
import { Modal, Select, Button, Popconfirm, Input, Tabs, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { AxiosResponse } from 'axios';
import { ClusterUpgradeResult, Instance } from 'types/cluster';
import { displaySuccessMessage } from 'utils';
import { showErrorMessage, getErrorMessage } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';
import {
  useUpgradeDisconnectedCluster,
  useUpgradeVersion,
} from 'hooks/cluster';

const tabsKeys = {
  buildNumber: 'Build Number',
  officialRelase: 'Official Release',
} as const;

interface RelaseModalProps {
  instance: Instance;
  show: boolean;
  requestCluster: () => Promise<any>;
  setShow: (value: boolean) => void;
  setShowModal: (value: boolean) => void;
  updateCluster: any;
  status: string;
}

function ReleaseModal({
  instance,
  show,
  setShow,
  requestCluster,
  setShowModal,
  updateCluster,
}: RelaseModalProps) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();

  const {
    mutateAsync: mutateUpgradeVersion,
    isLoading: isUpgradeVersionLoading,
  } = useUpgradeVersion({
    async handleOnSucces(res: AxiosResponse<ClusterUpgradeResult>) {
      if (res.data) {
        await requestCluster();
        handleCancel();
      }
      displaySuccessMessage(res.data.message);
    },
  });
  const { mutateAsync: mutateUpgradeDisconnected } =
    useUpgradeDisconnectedCluster({
      async handleOnSucces(res: AxiosResponse<ClusterUpgradeResult>) {
        if (res) {
          setShow(false);
          updateCluster();
          handleCancel();
        }
      },
      handleOnError(error: any) {
        setShow(true);
        showErrorMessage(getErrorMessage(error));
      },
    });

  const [currentActiveTabKey, setCurrentActiveTabKey] = useState<any>(
    instance.byBuildNumber ? tabsKeys.buildNumber : tabsKeys.officialRelase,
  );
  const [version, setVersion] = useState(
    instance.availableUpgrades?.[instance.availableUpgrades?.length - 1],
  );
  const [buildNumber, setBuildNumber] = useState('');

  useEffect(() => {
    setBuildNumber('');
  }, [show, instance.image]);

  function handleCancel() {
    setShowModal(false);
  }

  async function handleUpgradeDisconnected() {
    try {
      await mutateUpgradeDisconnected({
        userId: user!.uuid,
        clusterName: instance.name,
        version:
          currentActiveTabKey === tabsKeys.buildNumber ? buildNumber : version,
        byBuildNo:
          currentActiveTabKey === tabsKeys.buildNumber ? 'true' : 'false',
      });
    } catch (error) {}
  }

  async function handleUpgrade() {
    await (currentActiveTabKey === tabsKeys.buildNumber
      ? mutateUpgradeVersion({
          userId: user!.uuid,
          clusterName: instance.name,
          version: buildNumber,
          byBuildNo: 'true',
        })
      : mutateUpgradeVersion({
          userId: user!.uuid,
          clusterName: instance.name,
          version: version,
          byBuildNo: 'false',
        }));
  }

  const disableConfirm =
    currentActiveTabKey === tabsKeys.buildNumber
      ? !buildNumber.trim()
      : !instance.availableUpgrades?.length;

  return (
    <Modal
      title={
        <FormattedMessage id="clusterConfiguration.mainTab.update.modalTitle" />
      }
      visible={show}
      centered
      onCancel={handleCancel}
      className="release-modal"
      footer={
        <div>
          <Button onClick={handleCancel}>
            <FormattedMessage id="clusterConfiguration.mainTab.update.cancelButton" />
          </Button>
          <Popconfirm
            placement="top"
            title={
              <FormattedMessage id="clusterConfiguration.mainTab.update.confirmMessage" />
            }
            onConfirm={
              instance.status === 'running'
                ? handleUpgrade
                : handleUpgradeDisconnected
            }
            okText={intl.formatMessage({
              id: 'clusterConfiguration.mainTab.update.confirmModal.ok',
            })}
            cancelText={intl.formatMessage({
              id: 'clusterConfiguration.mainTab.update.confirmModal.cancel',
            })}
            disabled={disableConfirm}
          >
            <Tooltip
              title={
                disableConfirm ? (
                  <FormattedMessage
                    id={`clusterConfiguration.mainTab.update.${
                      currentActiveTabKey === tabsKeys.buildNumber
                        ? 'confirmButtonDisabledNoBuildNumber'
                        : 'confirmButtonDisabledNoOfficialReleases'
                    }`}
                  />
                ) : (
                  ''
                )
              }
            >
              <Button
                disabled={disableConfirm}
                type="primary"
                loading={isUpgradeVersionLoading}
                style={{ marginLeft: 8 }}
              >
                <FormattedMessage id="clusterConfiguration.mainTab.update.confirmButton" />
              </Button>
            </Tooltip>
          </Popconfirm>
        </div>
      }
    >
      <Tabs
        onChange={val => {
          setCurrentActiveTabKey(val);
        }}
      >
        {instance.byBuildNumber && (
          <Tabs.TabPane
            tab={
              <FormattedMessage id="clusterConfiguration.mainTab.update.byBuildNumber" />
            }
            key={tabsKeys.buildNumber}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'clusterConfiguration.mainTab.update.buildNumberPlaceholder',
              })}
              value={buildNumber}
              onChange={e => {
                setBuildNumber(e.target.value);
              }}
            />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane
          tab={
            <FormattedMessage id="clusterConfiguration.mainTab.update.byOfficialRelease" />
          }
          key={tabsKeys.officialRelase}
        >
          <Tooltip
            title={
              !instance.availableUpgrades.length ? (
                <FormattedMessage id="clusterConfiguration.mainTab.update.confirmButtonDisabledNoOfficialReleases" />
              ) : (
                ''
              )
            }
          >
            <Select
              style={{ width: '100%' }}
              placeholder={
                <FormattedMessage id="clusterConfiguration.mainTab.update.officialReleaseSelectPlaceholder" />
              }
              defaultValue={
                instance.availableUpgrades?.includes(instance.image)
                  ? instance.image
                  : instance.availableUpgrades?.[
                      instance.availableUpgrades?.length - 1
                    ]
              }
              onChange={(val: string) => setVersion(val)}
              options={[...instance.availableUpgrades]
                .reverse()
                .map((image, i) => {
                  const isLastRelease = i === 0;
                  return {
                    value: image,
                    label: (
                      <div className="release-modal__image-item-wrapper">
                        {`Release ${image} `}

                        {isLastRelease && (
                          <>
                            {intl.formatMessage({
                              id: 'clusterConfiguration.mainTab.update.releaseLatest',
                            })}
                            <a
                              href={instance.releaseUrl}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="release-modal__image-hint"
                            >
                              <FormattedMessage id="clusterConfiguration.mainTab.update.releaseWhatsNew" />
                              <span className="release-modal__image-badge">
                                {instance.releaseCount}
                              </span>
                            </a>
                          </>
                        )}
                      </div>
                    ),
                  };
                })}
              disabled={!instance.availableUpgrades.length}
            />
          </Tooltip>
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

export default ReleaseModal;
