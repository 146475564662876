import './RolesAndPermissionsModal.less';

import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

const ROLES = [
  {
    name: 'Cluster configurations',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: true },
      { type: 'incorta-admin', manage: true, view: true },
    ],
  },
  {
    name: 'Cluster Administration Users',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: true },
    ],
  },
  {
    name: 'Data Apps',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: true },
      { type: 'incorta-admin', manage: true, view: true },
    ],
  },
  {
    name: 'Analytics Sub-Cluster',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: true },
      { type: 'incorta-admin', manage: false, view: true },
    ],
  },
  {
    name: 'Access Token',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: false },
    ],
  },
  {
    name: 'IP Whitelisting',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: true },
    ],
  },
  {
    name: 'Connect/Disconnect Cluster',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: true, view: true },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: false },
    ],
  },
  {
    name: 'Upgrade Version',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: false, view: false },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: false },
    ],
  },
  {
    name: 'Clone/Delete Cluster',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: false, view: false },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: false },
    ],
  },
  {
    name: 'Billing',
    permissions: [
      { type: 'owner', manage: true, view: true },
      { type: 'admin', manage: false, view: false },
      { type: 'user', manage: false, view: false },
      { type: 'incorta-admin', manage: false, view: true },
    ],
  },
];

function RolesAndPermissionsModal() {
  return (
    <div className="RolesAndPermissionsModal">
      <div className="RolesAndPermissionsModal__header-grid">
        <div />

        <div className="RolesAndPermissionsModal__header-box">
          <div
            className="RolesAndPermissionsModal__header-bar"
            style={{ background: '#7F306C' }}
          ></div>
          <div className="RolesAndPermissionsModal__header-content">
            <h2>
              Owner <span>Master Admin role.</span>
            </h2>
            <p>
              Manages the cluster and all its configurations, users, and
              blueprints.
            </p>
          </div>
        </div>

        <div className="RolesAndPermissionsModal__header-box">
          <div
            className="RolesAndPermissionsModal__header-bar"
            style={{ background: '#98C4CA' }}
          ></div>
          <div className="RolesAndPermissionsModal__header-content">
            <h2>Manager</h2>
            <p>Manages the cluster configurations, users and data apps.</p>

          </div>
        </div>

        <div className="RolesAndPermissionsModal__header-box">
          <div
            className="RolesAndPermissionsModal__header-bar"
            style={{ background: '#37517F' }}
          ></div>
          <div className="RolesAndPermissionsModal__header-content">
            <h2>User</h2>
            <p>
              Views the cluster main page, all configurations and data apps.
            </p>
          </div>
        </div>

        <div className="RolesAndPermissionsModal__header-box">
          <div
            className="RolesAndPermissionsModal__header-bar"
            style={{ background: '#99988E' }}
          ></div>
          <div className="RolesAndPermissionsModal__header-content">
            <h2>Billing User </h2>
            <p>
              Manage cluster configurations and data apps. View users and
              billing.
            </p>
          </div>
        </div>
      </div>

      <div className="RolesAndPermissionsModal__table">
        <table>
          <thead>
            <tr>
              <td></td>
              <td>
                <div>
                  <span>Manage</span>
                  <span>View</span>
                </div>
              </td>
              <td>
                <div>
                  <span>Manage</span>
                  <span>View</span>
                </div>
              </td>
              <td>
                <div>
                  <span>Manage</span>
                  <span>View</span>
                </div>
              </td>
              <td>
                <div>
                  <span>Manage</span>
                  <span>View</span>
                </div>
              </td>
            </tr>
          </thead>

          <tbody>
            {ROLES.map((role, i) => {
              return (
                <tr key={i}>
                  <td>{role.name}</td>
                  {role.permissions.map((permission, i) => {
                    return (
                      <td key={i}>
                        <div>
                          <span>{permission.manage && <CheckOutlined />}</span>
                          <span>{permission.view && <CheckOutlined />}</span>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default RolesAndPermissionsModal;
