import React from 'react';
import './ClusterTypeFieldSliderMark.less';
import { Tooltip } from 'antd';
import { Size } from 'types/cluster';
import { getClusterTypeIcons, getClusterTypeName } from 'utils/cluster';

function ClusterTypeFieldSliderMark({
  size,
  isTooltipOff = true,
  tooltipText,
}: {
  size: Size;
  isTooltipOff?: boolean;
  tooltipText?: string;
}) {
  return isTooltipOff ? (
    <div className="ClusterTypeFieldSlider__label">
      <div className="ClusterTypeFieldSlider__label-icon">
        {React.createElement(getClusterTypeIcons(size.type, 'fill'), {
          className: 'fill-icon',
          style: { fontSize: 14, color: '#37517F' },
        })}
        {React.createElement(getClusterTypeIcons(size.type, 'outline'), {
          className: 'outline-icon',
          style: { fontSize: 14, color: '#99A6B0' },
        })}
      </div>
      <div className="ClusterTypeFieldSlider__label-text">
        {getClusterTypeName(size.type)}
      </div>
    </div>
  ) : (
    <Tooltip title={tooltipText}>
      <div className="ClusterTypeFieldSlider__label is-disabled">
        <div className="ClusterTypeFieldSlider__label-icon">
          {React.createElement(getClusterTypeIcons(size.type, 'fill'), {
            className: 'fill-icon',
            style: { fontSize: 14, color: '#37517F' },
          })}
          {React.createElement(getClusterTypeIcons(size.type, 'outline'), {
            className: 'outline-icon',
            style: { fontSize: 14, color: '#99A6B0' },
          })}
        </div>
        <div className="ClusterTypeFieldSlider__label-text">
          {getClusterTypeName(size.type)}
        </div>
      </div>
    </Tooltip>
  );
}

export default ClusterTypeFieldSliderMark;
