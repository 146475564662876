import React from 'react';
import './IncortaVersionField.less';
import { Form, Select, Tooltip } from 'antd';
import { useIntl, FormattedMessage } from 'react-intl';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
import { Image } from 'types/cluster';

interface IncortaVersionFieldProps {
  isDisabled: boolean;
  isLoading: boolean;
  images: Image[];
  image: Image;
  setImage: ((value: any, option: any) => void) | undefined;
}

function IncortaVersionField({
  isDisabled,
  isLoading,
  images,
  image,
  setImage,
}: IncortaVersionFieldProps) {
  const intl = useIntl();

  return (
    <Tooltip
      title={
        isDisabled ? 'Only Latest Version is Supported in Azure Platform' : ''
      }
    >
      <Form.Item
        name={clusterFormFieldNames.image}
        initialValue={image}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="clusterForm.clusterVersionRequired" />
            ),
          },
        ]}
        label={<FormattedMessage id="clusterForm.clusterVersionLabel" />}
      >
        <Select
          showSearch
          loading={isLoading}
          placeholder={intl.formatMessage({
            id: 'clusterForm.clusterVersionPlaceholder',
          })}
          disabled={isDisabled}
          optionLabelProp="label"
          onChange={setImage}
        >
          {images?.map(image => (
            <Select.Option
              key={image.image}
              value={image.image}
              className="image-options_ddl"
            >
              <span>{image.image}</span>
              <span>
                {image?.tags?.release === 'official' ? 'Official Build' : ''}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  );
}

export default IncortaVersionField;
