import React from 'react';
import './ClusterServicesControl.less';
import Icon from '@ant-design/icons';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import useClusterServicesControlHelper from './ClusterServicesControlHelper/useClusterServicesControlHelper';
import { ReactComponent as AnalyticsIcon } from 'images/analytics.svg';
import {
  Instance,
  ServiceItem,
  ServiceName,
  ServiceStatus,
  ServiceToRestart,
} from 'types/cluster';
import { useGetServicesStatus } from 'hooks/cluster';
import DisconnectedClusterPlaceholder from 'components/DisconnectedClusterPlaceholder/DisconnectedClusterPlaceholder';
import { UserData } from 'types/user';

type ClusterServicesControlProps = {
  instance: Instance;
  user: UserData;
};
function ClusterServicesControl({
  instance,
  user,
}: ClusterServicesControlProps) {
  const isClusterRunning = instance.status === 'running';

  const {
    data,
    isLoading: serviceStatusLoading,
    isError: hasServiceStatusError,
  } = useGetServicesStatus({
    instanceId: instance.id,
    isEnabled: instance.status === 'running',
  });
  const instanceServices = user.instanceServices?.find(
    srvc => srvc.instanceId === instance.id,
  );

  function getServiceDataSource<T extends ServiceName>({
    serviceDataFromStatus,
    nodesCount,
  }: {
    serviceDataFromStatus: ServiceItem<T>[];
    nodesCount: number;
  }) {
    if (serviceDataFromStatus) {
      if (serviceDataFromStatus?.length < nodesCount) {
        // service(s) is/are created but not yet in `/status`
        const servicesCountDifference =
          nodesCount! - serviceDataFromStatus.length;

        const tempServices = new Array(servicesCountDifference)
          .fill(null)
          .map((a, i) => ({
            name: ServiceToRestart.ANALYTICS,
            node_name: `__temp-${i}-${instance.name}-node`,
            status: ServiceStatus.CREATING,
          }));

        return [...serviceDataFromStatus, ...(tempServices && tempServices)];
      } else if (serviceDataFromStatus?.length > nodesCount) {
        // service(s) is/are deleted but not yet in `/status`
        for (let i = nodesCount; i < serviceDataFromStatus.length; i++) {
          serviceDataFromStatus[i].node_name =
            '__toBeDeleted-' + serviceDataFromStatus[i].node_name;
        }
        return serviceDataFromStatus;
      }
    }
    return serviceDataFromStatus;
  }

  const analyticsServices =
    (data?.data?.analyticsServices &&
      instanceServices?.analyticsNodes &&
      getServiceDataSource<'analytics'>({
        serviceDataFromStatus: data?.data?.analyticsServices,
        nodesCount: instanceServices?.analyticsNodes,
      })) ||
    data?.data?.analyticsServices;
  const loaderServices =
    (data?.data?.analyticsServices &&
      instanceServices?.analyticsNodes &&
      getServiceDataSource<'loader'>({
        serviceDataFromStatus: data?.data?.loaderServices,
        nodesCount: instanceServices?.loaderNodes,
      })) ||
    data?.data.loaderServices;
  const sqliService = data?.data?.sqliService;
  const notebookService = data?.data?.notebookService;
  const analyticsSubClusterService = data?.data?.analyticsSubClusterServices;
  const loaderSubClusterService = data?.data?.loaderSubClusterServices;

  const {
    analyticsColumns,
    loaderColumns,
    sqliColumns,
    notebookColumns,
    analyticsSubClusterColumns,
    loaderSubClusterColumns,
  } = useClusterServicesControlHelper({
    instance,
    hasServiceStatusError,
    isClusterConnected: instance.status === 'running',
  });

  return (
    <div className="ClusterEdit cluster-services-control">
      <div className="ClusterEdit__icon">
        <Icon component={AnalyticsIcon} />
      </div>
      <div className="ClusterEdit__content">
        <div className="cluster-services-control__actions">
          <h3 className="ClusterEdit__title">
            <FormattedMessage id="clusterConfiguration.titles.clusterServicesControl" />
          </h3>

          {isClusterRunning ? (
            <>
              <Table
                className="cluster-services-control__analytics-table"
                columns={analyticsColumns as any}
                dataSource={analyticsServices}
                loading={serviceStatusLoading}
                pagination={false}
                rowKey="node_name"
              />
              <Table
                className="cluster-services-control__loader-table"
                columns={loaderColumns as any}
                dataSource={loaderServices}
                loading={serviceStatusLoading}
                pagination={false}
                rowKey="node_name"
              />
              {sqliService?.status && (
                <Table
                  className="cluster-services-control__sqli-table"
                  columns={sqliColumns}
                  dataSource={[sqliService]}
                  loading={serviceStatusLoading}
                  pagination={false}
                  rowKey="node_name"
                />
              )}
              {notebookService?.status && (
                <Table
                  className="cluster-services-control__sqli-table"
                  columns={notebookColumns}
                  dataSource={[notebookService]}
                  loading={serviceStatusLoading}
                  pagination={false}
                  rowKey="node_name"
                />
              )}
              {!!analyticsSubClusterService?.length && (
                <Table
                  className="cluster-services-control__sqli-table"
                  columns={analyticsSubClusterColumns}
                  dataSource={analyticsSubClusterService}
                  loading={serviceStatusLoading}
                  pagination={false}
                  rowKey="node_name"
                />
              )}
              {!!loaderSubClusterService?.length && (
                <Table
                  className="cluster-services-control__sqli-table"
                  columns={loaderSubClusterColumns}
                  dataSource={loaderSubClusterService}
                  loading={serviceStatusLoading}
                  pagination={false}
                  rowKey="node_name"
                />
              )}
            </>
          ) : (
            <DisconnectedClusterPlaceholder
              title={
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.disconnectedComponent.title" />
              }
              description={
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.disconnectedComponent.description" />
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ClusterServicesControl;
