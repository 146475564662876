import React from 'react';
import './ScalingConfigurations.less';
import { Divider, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import AutoScalingControl from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/AutoScalingControl/AutoScalingControl';
import { AutoScaleItem, clusterScalingNode } from 'types/cluster';
import AnalyticsChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/AnalyticsChart/AnalyticsChart';
import LoaderChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/LoaderChart/LoaderChart';

const { Title } = Typography;

function ScalingConfigurations({
  instanceID,
  isInstanceRunning,
  autoScaleStatus,
  analyticsClusterScalingNodes,
  loaderClusterScalingNodes,
}: {
  instanceID: string;
  isInstanceRunning: boolean;
  autoScaleStatus: AutoScaleItem | null;
  analyticsClusterScalingNodes: clusterScalingNode[];
  loaderClusterScalingNodes: clusterScalingNode[];
}) {
  return (
    <div className="scaling-configurations__wrapper">
      <AutoScalingControl
        instanceID={instanceID}
        isInstanceRunning={isInstanceRunning}
        autoScaleStatus={autoScaleStatus}
      />
      <Divider />
      <Title level={3}>
        <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScaling.autoScalingHistory" />
      </Title>

      <AnalyticsChart
        instanceId={instanceID}
        clusterScalingNodes={analyticsClusterScalingNodes}
      />
      <LoaderChart
        instanceId={instanceID}
        clusterScalingNodes={loaderClusterScalingNodes}
      />
    </div>
  );
}

export default ScalingConfigurations;
