import React from 'react';
import './MlflowModal.less';
import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import copy from 'clipboard-copy';
import { Tooltip, notification } from 'antd';
import { useIntl } from 'react-intl';
import mlflowGif from './mlflowGif.gif'; // Import the GIF file
import { Instance } from 'types/cluster';
import deltaSharingCredentials from 'components/ClusterDetails/ClusterDetailsBody/Configurations/DeltaSharingEdit/DeltaSharingModal/deltaSharingCredentials.json';
import { API_URL } from 'utils/http';

interface UseDownloadLinkArg {
  instance: Instance;
}
function useDownloadLink({ instance }: UseDownloadLinkArg) {
  let instanceid = instance.id;
  let link = `${API_URL}/users/instances/downloadmlflowsample?instanceid=${instanceid}`;

  return link;
}

type DeltaSharingProps = {
  instance: Instance;
  url: string;
};
function DeltaSharingModal({ instance, url }: DeltaSharingProps) {
  deltaSharingCredentials.instanceName = instance.name;
  const intl = useIntl();
  const downloadLink = useDownloadLink({ instance });

  function copyToClipboard(from: 'url') {
    copy(url)
      .then(() => {
        notification.success({
          message: 'Copied Successfully',
        });
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.deltaSharing.modal.copyFailure',
          }),
        });
      });
  }

  return (
    <div className="delta-sharing-modal__wrapper">
      <div>
        <h4>Download zpln Sample Business Notebook</h4>
        <p>
          Download the .zpln note and import it inside your business notebook to discover more
          about MLflow experiments, runs, models, metrics and how to connect to MLflow!
        </p>
        <a download href={downloadLink} className="download-link link">
          <DownloadOutlined /> Download zpln note
        </a>{' '}
      </div>
      <br></br>

      <h4>Connect to MLflow</h4>
      <section className="delta-sharing-modal__code-snippet">
        <pre>
          <code>{''}</code>
          {url}
          <code>{''}</code>
          <br />
        </pre>
        <Tooltip title={'Integration Sample'}>
          <CopyOutlined
            onClick={() => copyToClipboard('url')}
            className="delta-sharing-modal__copy-icon"
          />
        </Tooltip>
      </section>
      <br></br>
      <img src={mlflowGif} alt="Your GIF" style={{ width: '100%' }} />
    </div>
  );
}

export default DeltaSharingModal;
