import React from 'react';
import './AzureCreationClusterIcon.less';
import { Spin } from 'antd';
import { clusterCreationStatus } from '../azureClusterCreationHelper';
import { ReactComponent as DisconnectedIcon } from 'images/disconnected.svg';
import { ReactComponent as LoadingIcon } from 'images/loading-icon.svg';

type AzureCreationClusterIconProps = {
  status: clusterCreationStatus;
  forFooter?: boolean;
};
function AzureCreationClusterIcon({
  status,
  forFooter = false,
}: AzureCreationClusterIconProps) {
  if (
    status === clusterCreationStatus.FAILED ||
    status === clusterCreationStatus.CANCELED
  ) {
    return <DisconnectedIcon className="azure-creation-cluster-icon failed" />;
  }

  if (forFooter) {
    return <Spin size="small" />;
  } else {
    return <LoadingIcon className="azure-creation-cluster-icon in-progress" />;
  }
}

export default AzureCreationClusterIcon;
