import './FreePlanConfirmation.less';

import React, { useCallback, useContext, useState } from 'react';
import { Button, Modal } from 'antd';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SafeSecureIcon } from '../../MyAccount/Plan/CreditCardInfoModal/icons/safe-secure-icon.svg';
import useRequest from 'hooks/useRequest';
import { changePlan, getUserPaymentMethod } from 'services/user';
import CreditCardInfoModal from 'components/MyAccount/Plan/CreditCardInfoModal/CreditCardInfoModal';
import StripeElementsWrapper from 'components/StripeElementsWrapper/StripeElementsWrapper';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

function FreePlanConfirmation() {
  const { user, isAuthenticated, reloadUser } = useContext(SessionContext);
  let history = useHistory();

  let [showModal, setShowModal] = useState(false);

  let changePlanRequestCb = useCallback(async () => {
    try {
      await changePlan({ userId: user!.uuid, plan: 'premium' });
      reloadUser();
    } catch (error) {
      if (error.response.status === 304) {
        error.response.data = `You Can't change to premium plan`;
      }
      return Promise.reject(error);
    }
    displaySuccessMessage(
      'You can now enjoy all the benefits of our Premium Plan.',
    );
    history.push('/my-account?tab=plan');
    // eslint-disable-next-line
  }, [history, isAuthenticated]);

  let { isPending: changePlanPending, makeRequest: changePlanRequest } =
    useRequest(changePlanRequestCb, { handleError: true });

  let { isPending: purchasePending, makeRequest: purchaseRequest } = useRequest(
    useCallback(async () => {
      let res = await getUserPaymentMethod({ userId: user!.uuid });
      if (res.data.data) {
        await changePlanRequestCb();
      } else {
        setShowModal(true);
      }
    }, [changePlanRequestCb, user]),
    { handleError: true },
  );

  let buttonLoading = changePlanPending || purchasePending;

  return (
    <div className="FreePlanConfirmation">
      <div className="FreePlanConfirmation__wrapper">
        <div className="FreePlanConfirmation__info-card">
          <h2 className="FreePlanConfirmation__info-card-title">
            Free $500 Credits
          </h2>
          <p className="FreePlanConfirmation__info-card-text">
            Receive $500 worth of free usage. After that, you will be charged
            $0.6/unit, $20/users or $20/TB of storage.
          </p>
          <h3 className="FreePlanConfirmation__info-card-subtitle">
            Premium Plan Includes
          </h3>

          <table className="FreePlanConfirmation__info-card-table">
            <tbody>
              <tr>
                <td>Clusters User Count</td>
                <td>
                  Multi Clusters - Upto 2XL - Unlimited Users $20 per User/Month
                </td>
              </tr>
              <tr>
                <td>Storage Capacity</td>
                <td>Unlimited - $20 per TB/Month</td>
              </tr>
              <tr>
                <td>VPN</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Static IP</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Always Alive</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Data Apps Marketplace</td>
                <td>Basic & Premium Data App</td>
              </tr>
              <tr>
                <td>Backup Time Travel</td>
                <td>14 Days</td>
              </tr>
              <tr>
                <td>Multi Sub-clustering</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Clone Cluster</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Data Agent</td>
                <td>✓</td>
              </tr>
              <tr>
                <td>Support</td>
                <td>Live Chat + Premium Email Support</td>
              </tr>
              <tr>
                <td>Incorta Office Hours</td>
                <td>VIP Access</td>
              </tr>
              <tr>
                <td>High Availability & Disaster Recovery</td>
                <td>✓</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="FreePlanConfirmation__actions">
          <div className="CreditCardInfoModal__safe-secure">
            <SafeSecureIcon style={{ marginRight: 8 }} />
            Safe & secure payment
          </div>

          <Button
            loading={buttonLoading}
            type="primary"
            onClick={purchaseRequest}
          >
            Submit purchase
          </Button>
        </div>
      </div>

      <Modal
        visible={showModal}
        destroyOnClose
        centered
        onCancel={() => setShowModal(false)}
        title={'Credit Card Info'}
        footer={null}
      >
        <StripeElementsWrapper>
          <CreditCardInfoModal
            onCancel={() => setShowModal(false)}
            onDone={() => {
              // notice not return
              // this function is not promise that mean that it will handled in this page
              changePlanRequest();
            }}
          />
        </StripeElementsWrapper>
      </Modal>
    </div>
  );
}

export default FreePlanConfirmation;
