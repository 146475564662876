import './SubClusterList.less';
import { Empty, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React from 'react';
import SubClusterState from './SubClusterState/SubClusterState';
import SelfManagedClusterType from './SelfManagedClusterType/SelfManagedClusterType';
import useSubCluster from './useSubCluster';
import {
  CSize,
  Instance,
  SelfManagedClusterTypes,
  SubCluster,
} from 'types/cluster';
import { getClusterTypeName, getIU } from 'utils/cluster';
import SelfManagedClustersListActions from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/SelfManagedClustersListActions/SelfManagedClustersListActions';
import SelfManagedClustersListLinksActions from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/SelfManagedClustersListLinksActions/SelfManagedClustersListLinksActions';

interface SubClusterListProps {
  instance: Instance;
  createButtonUi?: React.ReactNode;
  minimum?: boolean;
}
function SubClusterList({
  instance,
  createButtonUi = null,
  minimum = false,
}: SubClusterListProps) {
  const { getSubClusterState } = useSubCluster();

  const columns: ColumnsType<SubCluster> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    ...(!minimum
      ? [
          {
            title: 'IU',
            dataIndex: 'iu',
            key: 'iu',
            render(value: any, subCluster: SubCluster) {
              return getIU(subCluster, subCluster.nodes, 'subcluster');
            },
          },
          {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            render: (size: CSize) => getClusterTypeName(size),
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type: SelfManagedClusterTypes) => (
              <SelfManagedClusterType type={type} />
            ),
          },
        ]
      : []),
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render(_: any, subCluster: SubCluster) {
        const state = getSubClusterState(subCluster);
        return <SubClusterState state={state} />;
      },
    },
    ...(minimum
      ? [
          {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
            render: (size: CSize) => getClusterTypeName(size),
          },
        ]
      : []),
    {
      title: !minimum ? 'Endpoint' : 'URL',
      key: !minimum ? 'endpoint' : 'incorta',
      render: (_, subCluster) => {
        return (
          <SelfManagedClustersListLinksActions
            subCluster={subCluster}
            instance={instance}
          />
        );
      },
    },
    ...(!minimum
      ? [
          {
            title: '',
            key: 'actions',
            render: (_: any, subCluster: SubCluster) => {
              return (
                <SelfManagedClustersListActions
                  subCluster={subCluster}
                  instance={instance}
                />
              );
            },
          },
        ]
      : []),
  ];
  return (
    <div className="SubClusterList">
      <Table
        size="small"
        className="SubClusterList__table"
        pagination={false}
        columns={columns}
        dataSource={instance.subClusters}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Self-Managed Clusters created yet."
            >
              {createButtonUi}
            </Empty>
          ),
        }}
      />
    </div>
  );
}

export default SubClusterList;
