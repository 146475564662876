import React, { useEffect, useState } from 'react';
import './ScheduledEventModal.less';
import { Button, Card, Form, Modal, Steps, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ScheduledEvent,
  ScheduledEventEnum,
  isEventExistingType,
  scheduledEventFormValuesKeysType,
} from 'types/cluster';
import ScheduledEventActionStep from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/Steps/ScheduledEventActionStep/ScheduledEventActionStep';
import ScheduledEventScalingInfoStep from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/Steps/ScheduledEventScalingInfoStep/ScheduledEventScalingInfoStep';
import ScheduledEventEndTimeStep from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/Steps/ScheduledEventEndTimeStep/ScheduledEventEndTimeStep';
import ScheduledEventSummaryStep from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/Steps/ScheduledEventSummaryStep/ScheduledEventSummaryStep';
import {
  useCreateScheduledEvent,
  useUpdateScheduledEvent,
} from 'hooks/cluster';

export const scheduledEventFormFieldNames = {
  id: 'id',
  event: 'event',
  startTime: 'startTime',
  endTime: 'endTime',
  eventInformation: 'eventInformation',
  eventInfoAnalyticsReplicas: 'eventInfoAnalyticsReplicas',
  eventInfoLoaderReplicas: 'eventInfoLoaderReplicas',
  status: 'status',
} as const;

const { Step } = Steps;

type ScheduledEventModalProps = {
  isEventExisting: isEventExistingType;
  closeModal: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  instanceID: string;
  scheduledEventFormValues: {} | Record<scheduledEventFormValuesKeysType, any>;
  setScheduledEventFormValues: React.Dispatch<
    React.SetStateAction<{} | Record<scheduledEventFormValuesKeysType, any>>
  >;
};
function ScheduledEventModal({
  isEventExisting,
  closeModal,
  instanceID,
  scheduledEventFormValues,
  setScheduledEventFormValues,
}: ScheduledEventModalProps) {
  const intl = useIntl();
  const [isScaling, setIsScaling] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { mutateAsync: mutateUpdateScheduledEvent } = useUpdateScheduledEvent({
    instanceID,
  });
  const { mutateAsync: mutateCreateScheduledEvent } = useCreateScheduledEvent({
    instanceID,
  });

  const [form] = Form.useForm<ScheduledEvent>();

  useEffect(() => {
    if (isEventExisting && scheduledEventFormValues) {
      if (
        'event' in scheduledEventFormValues &&
        [ScheduledEventEnum.SCALE_UP, ScheduledEventEnum.SCALE_DOWN].includes(
          scheduledEventFormValues?.event,
        )
      ) {
        setIsScaling(true);
      }
      form.setFieldsValue({
        ...('event' in scheduledEventFormValues && {
          [scheduledEventFormFieldNames.event]: scheduledEventFormValues?.event,
        }),
        ...('startTime' in scheduledEventFormValues && {
          [scheduledEventFormFieldNames.startTime]:
            scheduledEventFormValues?.startTime,
        }),
        ...('eventInfoAnalyticsReplicas' in scheduledEventFormValues && {
          [scheduledEventFormFieldNames.eventInfoAnalyticsReplicas]:
            scheduledEventFormValues?.eventInfoAnalyticsReplicas,
        }),
        ...('eventInfoLoaderReplicas' in scheduledEventFormValues && {
          [scheduledEventFormFieldNames.eventInfoLoaderReplicas]:
            scheduledEventFormValues?.eventInfoLoaderReplicas,
        }),
        ...('endTime' in scheduledEventFormValues && {
          [scheduledEventFormFieldNames.endTime]:
            scheduledEventFormValues?.endTime,
        }),
      });
    }
    return () => {
      form.resetFields();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = [
    {
      component: () => (
        <ScheduledEventActionStep
          setIsScaling={setIsScaling}
          isViewOnly={isEventExisting === 'View'}
        />
      ),
    },
    ...(isScaling
      ? [
          {
            component: (currentFormValues: Record<any, any>) => (
              <ScheduledEventScalingInfoStep
                currentFormValues={currentFormValues}
                instanceId={instanceID}
                isEventExisting={isEventExisting}
              />
            ),
          },
        ]
      : []),
    {
      component: (currentFormValues: Record<any, any>) => (
        <ScheduledEventEndTimeStep
          currentFormValues={currentFormValues}
          isViewOnly={isEventExisting === 'View'}
        />
      ),
    },
    {
      component: (currentFormValues: Record<any, any>) => (
        <ScheduledEventSummaryStep currentFormValues={currentFormValues} />
      ),
    },
  ];

  const showPreviousButton = currentStepIndex > 0;
  const showNextButton = currentStepIndex <= steps.length - 1;
  const isAtLastStep = currentStepIndex === steps.length - 2;
  const isAtSummaryStep = currentStepIndex === steps.length - 1;

  function goToPreviousStep() {
    setCurrentStepIndex(prev => prev - 1);
  }
  function goToNextStep(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (!isAtSummaryStep) {
      const values = form.getFieldsValue();
      setScheduledEventFormValues(prev => ({ ...prev, ...values }));
      setCurrentStepIndex(prev => prev + 1);
    } else {
      handleSubmit(e);
    }
  }

  async function handleSubmit(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    if (isEventExisting === 'Edit') {
      // UPDATE EVENT:
      if ('event' in scheduledEventFormValues) {
        const { status, data } = await mutateUpdateScheduledEvent({
          updatedEvent: {
            event: scheduledEventFormValues.event,
            startTime: scheduledEventFormValues.startTime,
            endTime: scheduledEventFormValues.endTime,
            eventResult: null,
            eventInformation: {
              ...(scheduledEventFormValues.eventInfoAnalyticsReplicas && {
                analyticsReplicas:
                  scheduledEventFormValues.eventInfoAnalyticsReplicas,
              }),
              ...(scheduledEventFormValues.eventInfoLoaderReplicas && {
                loaderReplicas:
                  scheduledEventFormValues.eventInfoLoaderReplicas,
              }),
            },
            recordID: scheduledEventFormValues.eventID,
          },
        });
        if (status === 200) {
          notification.success({
            message: intl.formatMessage({
              id: 'clusterConfiguration.schedulingTab.message.updateSuccess',
            }),
            description: data.message,
          });
        }
      }
    } else if (isEventExisting === false) {
      // SCHEDULE  EVENT:
      if ('event' in scheduledEventFormValues) {
        const { status, data } = await mutateCreateScheduledEvent({
          eventToCreate: {
            event: scheduledEventFormValues.event,
            eventResult: null,
            startTime: scheduledEventFormValues.startTime,
            ...(scheduledEventFormValues.endTime && {
              endTime: scheduledEventFormValues.endTime,
            }),
            eventInformation: {
              ...(scheduledEventFormValues.eventInfoAnalyticsReplicas && {
                analyticsReplicas:
                  scheduledEventFormValues.eventInfoAnalyticsReplicas,
              }),
              ...(scheduledEventFormValues.eventInfoLoaderReplicas && {
                loaderReplicas:
                  scheduledEventFormValues.eventInfoLoaderReplicas,
              }),
            },
          },
        });
        if (status === 200) {
          notification.success({
            message: intl.formatMessage({
              id: 'clusterConfiguration.schedulingTab.message.addSuccess',
            }),
            description: data.message,
          });
        }
      }
    }
    closeModal(e);
  }

  function getNextBtnDisableStatus() {
    if (currentStepIndex === 0) {
      const eventValue = form.getFieldValue(scheduledEventFormFieldNames.event);
      const startTimeValue = form.getFieldValue(
        scheduledEventFormFieldNames.startTime,
      );
      if (!eventValue || !startTimeValue) {
        return true;
      }
    } else if (isScaling && currentStepIndex === 1) {
      const eventInfoAnalyticsReplicasValue = form.getFieldValue(
        scheduledEventFormFieldNames.eventInfoAnalyticsReplicas,
      );
      const eventInfoLoaderReplicasValue = form.getFieldValue(
        scheduledEventFormFieldNames.eventInfoLoaderReplicas,
      );
      if (!eventInfoAnalyticsReplicasValue && !eventInfoLoaderReplicasValue) {
        return true;
      }
    } else if (isAtSummaryStep && isEventExisting === 'View') {
      return true;
    }

    return false;
  }
  const [isNextBtnDisabled, setIsNextBtnDisabled] = useState<boolean>(
    getNextBtnDisableStatus(),
  );

  useEffect(() => {
    if (!getNextBtnDisableStatus()) {
      setIsNextBtnDisabled(false);
    } else {
      setIsNextBtnDisabled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStepIndex]);

  function getCurrentStepComponentValue() {
    if (currentStepIndex === 0 || (currentStepIndex === 1 && isScaling)) {
      return 0;
    } else if (isAtSummaryStep) {
      return 2;
    } else {
      return 1;
    }
  }

  function onStepComponentChange(stepComponentValue: number) {
    const stepComponentAtFirstNode = stepComponentValue === 0;
    const stepComponentAtLastNode = stepComponentValue === 2;
    const middleStepStartsAtIndex = isScaling ? 2 : 1;

    setCurrentStepIndex(
      stepComponentAtFirstNode
        ? 0
        : stepComponentAtLastNode
        ? steps.length - 1
        : middleStepStartsAtIndex,
    );
  }

  function handleFormValuesChange(changedValues: any, values: any) {
    setTimeout(() => {
      const fieldsErrors = form.getFieldsError();
      for (let errorItem of fieldsErrors) {
        if (errorItem.errors?.length) {
          setIsNextBtnDisabled(true);
          return;
        }
      }
      if (!getNextBtnDisableStatus()) {
        setIsNextBtnDisabled(false);
      } else {
        setIsNextBtnDisabled(true);
      }
      if (
        'event' in changedValues &&
        'event' in scheduledEventFormValues &&
        (scheduledEventFormValues as any).event !== changedValues?.event
      ) {
        // delete (currentFormValues as any).eventInfoAnalyticsReplicas;
        // delete (currentFormValues as any).eventInfoLoaderReplicas;
        form.resetFields([
          scheduledEventFormFieldNames.eventInfoAnalyticsReplicas,
          scheduledEventFormFieldNames.eventInfoLoaderReplicas,
        ]);
      }
    }, 500);
  }

  function handleClosingModal(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    form.resetFields();
    form.setFieldsValue({});
    setScheduledEventFormValues({});
    closeModal(e);
  }

  return (
    <Modal
      visible
      className="scheduled-event-modal__wrapper"
      title={
        <FormattedMessage
          id="clusterConfiguration.schedulingTab.title"
          values={{
            status: (
              <FormattedMessage
                id={`clusterConfiguration.schedulingTab.${
                  isEventExisting === false
                    ? 'new'
                    : isEventExisting === 'Edit'
                    ? 'edit'
                    : 'view'
                }`}
              />
            ),
          }}
        />
      }
      footer={
        <section className="scheduled-event-modal__control-btns">
          <div>
            {showPreviousButton && (
              <Button onClick={goToPreviousStep}>
                <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.controls.previous" />
              </Button>
            )}
          </div>
          {showNextButton && (
            <Button
              onClick={goToNextStep}
              type={isAtSummaryStep ? 'primary' : 'default'}
              disabled={isNextBtnDisabled}
            >
              <FormattedMessage
                id={`clusterConfiguration.schedulingTab.scheduledEventForm.controls.${
                  isAtSummaryStep
                    ? isEventExisting === 'Edit'
                      ? 'editSchedule'
                      : 'schedule'
                    : isAtLastStep
                    ? 'review'
                    : 'next'
                }`}
              />
            </Button>
          )}
        </section>
      }
      onCancel={handleClosingModal}
    >
      <Steps
        size="small"
        current={getCurrentStepComponentValue()}
        onChange={onStepComponentChange}
        className="scheduled-event-modal__step-wrapper"
      >
        <Step title="Event Main Info" />
        <Step title="Event Additional Info" disabled={!isEventExisting} />
        <Step title="Review" disabled={!isEventExisting} />
      </Steps>
      <Form
        form={form}
        layout="vertical"
        className="scheduled-event-modal__form"
        onValuesChange={handleFormValuesChange}
      >
        <Card className="scheduled-event-modal__body">
          {/* Stepped Current Component */}
          {steps[currentStepIndex].component(scheduledEventFormValues)}
        </Card>
      </Form>
    </Modal>
  );
}

export default ScheduledEventModal;
