import './Plan.less';

import React, { useCallback, useContext, useState } from 'react';
import { Alert, Button, Card, Col, Modal, Row, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as IIcon } from './icons/I.svg';
import { ReactComponent as VisaIcon } from './icons/visa.svg';
import CreditCardInfoModal from './CreditCardInfoModal/CreditCardInfoModal';
import StripeElementsWrapper from 'components/StripeElementsWrapper/StripeElementsWrapper';
import useRequest from 'hooks/useRequest';
import { getUserPaymentMethod, deleteUserPaymentMethod } from 'services/user';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

function Plan() {
  let [showAddModal, setShowAddModal] = useState(false);

  const { user } = useContext(SessionContext);

  let {
    result: userPaymentResult,
    isPending,
    isRejected,
    makeRequest: getUserPaymentMethodRequest,
  } = useRequest(
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useCallback(
      () => getUserPaymentMethod({ userId: user!.uuid }),
      // eslint-disable-next-line
      [user!.uuid],
    ),
    { resolve: true },
  );

  let { makeRequest: deleteUserPaymentMethodRequest } = useRequest(
    useCallback(async () => {
      let { data } = await deleteUserPaymentMethod({ userId: user!.uuid });
      await getUserPaymentMethodRequest();
      return data;
    }, [getUserPaymentMethodRequest, user]),
    { handleError: true },
  );

  if (isPending && !userPaymentResult?.data) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting payment Information" />
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  async function handleDeletePaymentMethod() {
    Modal.confirm({
      title: `Delete Credit Card Info`,
      okText: 'delete',
      cancelText: 'Cancel',
      centered: true,
      width: 620,
      async onOk() {
        let { message } = await deleteUserPaymentMethodRequest();
        displaySuccessMessage(message);
      },
    });
  }

  let paymentMethodInfo = userPaymentResult?.data.data;

  let isBasic = user?.role.name === 'free';
  let isPremium = user?.role.name === 'premium' || user?.role.name === 'basic';
  let isEnterprise = user?.role.name === 'enterprise';

  let currentPlanUi = (
    <div className="Plan__current-card">
      <IIcon style={{ marginRight: 8 }} />
      <h3 className="Plan__card-title">
        Cloud{' '}
        {isBasic
          ? 'Basic'
          : isPremium
          ? 'Premium'
          : isEnterprise
          ? 'Enterprise'
          : null}
      </h3>
      <Link style={{ marginLeft: 'auto' }} to={`/plans`}>
        <Button className="Plan__card-button" type="primary">
          {isBasic ? 'Upgrade' : 'Change'}
        </Button>
      </Link>
    </div>
  );

  let cardInfoUi = paymentMethodInfo ? (
    <div className="Plan__current-card">
      <VisaIcon style={{ marginRight: 8 }} />
      <div className="Plan__card-title">
        <span>Visa ending in {paymentMethodInfo.last4}</span>
        <span style={{ marginLeft: 24 }}>
          Expires <strong>{paymentMethodInfo.expiration}</strong>
        </span>
        <button
          className="link"
          style={{ marginLeft: 24 }}
          onClick={handleDeletePaymentMethod}
        >
          delete
        </button>
      </div>
      <Button
        size="small"
        className="Plan__card-button"
        type="primary"
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        Update
      </Button>
    </div>
  ) : (
    <div className="Plan__current-card">
      <div className="Plan__card-title">You didn’t add your card info yet.</div>
      <Button
        size="small"
        className="Plan__card-button"
        type="primary"
        onClick={() => {
          setShowAddModal(true);
        }}
      >
        Add
      </Button>
    </div>
  );

  return (
    <div className="Plan">
      <Row justify="center">
        <Col xs={24} md={16}>
          {isBasic && (
            <Alert
              closable
              message={
                <>
                  Upgrade to Premium with 30-Day free trial then $1200/year{' '}
                  <small> (Only $100/month). </small>
                  <Link to={`/plans`}>Upgrade</Link>
                </>
              }
              type="info"
              showIcon
            />
          )}

          <Card title="Current Plan" className="Plan__card">
            {currentPlanUi}
          </Card>

          <Card title="Credit Card Info" className="Plan__card">
            {cardInfoUi}
          </Card>
        </Col>
      </Row>

      <Modal
        visible={showAddModal}
        destroyOnClose
        centered
        onCancel={() => setShowAddModal(false)}
        title={'Credit Card Info'}
        footer={null}
      >
        <StripeElementsWrapper>
          <CreditCardInfoModal
            isUpdate={!!paymentMethodInfo}
            onDone={() => getUserPaymentMethodRequest()}
            onCancel={() => setShowAddModal(false)}
          />
        </StripeElementsWrapper>
      </Modal>
    </div>
  );
}

export default Plan;
