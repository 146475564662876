/**
 * Reusable component for ant tag having options
 */
import React from 'react';
// import '../ControllableTags/ControllableTags.less';
import './OptionedTag.less';
import { EllipsisOutlined } from '@ant-design/icons';
import { Dropdown, Tag, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';

function OptionedTag({
  DropdownMenu,
  dropdownProps,
  TagContent,
  tagContentProps,
}: {
  DropdownMenu: (args: any) => JSX.Element;
  TagContent: (args: any) => JSX.Element;
  tagContentProps: Record<string, any>;
  dropdownProps?: Record<string, any>;
}) {
  return (
    <Tag
      className="optioned-tag__item"
      // role="listitem"
    >
      <>
        <TagContent {...tagContentProps} />
        <Tooltip
          title={
            !!dropdownProps?.show ? (
              ''
            ) : (
              <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
            )
          }
        >
          <Dropdown
            trigger={['click']}
            overlay={() => DropdownMenu(dropdownProps)}
            disabled={!dropdownProps?.show}
          >
            <EllipsisOutlined
              rotate={90}
              className={'optioned-tag__item-ellipsis'}
            />
          </Dropdown>
        </Tooltip>
      </>
    </Tag>
  );
}

export default OptionedTag;
