import './Plans.less';

import React, { useContext, useState } from 'react';
import { CloseOutlined, LeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import PlansCards from './PlansCards/PlansCards';
import FreePlanConfirmation from './FreePlanConfirmation/FreePlanConfirmation';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { LocationContext } from 'contexts/LastLocation';

const { Title } = Typography;

type PageState = 'PLANS_CARD' | 'FREE_CONFIRMATION';

function Plans() {
  const history = useHistory();
  const { lastLocation } = useContext(LocationContext);

  const [page, setPage] = useState<PageState>('PLANS_CARD');

  const closeIcon = (
    <CloseOutlined
      className="Plans__header-close"
      onClick={() => {
        if (lastLocation) {
          history.push(lastLocation);
        } else {
          history.push('/my-account?tab=plan');
        }
      }}
    />
  );

  let plansCardUi = (
    <div className="Plans">
      <div className="Plans__header">
        <div className="Plans__header-title">
          <Title level={4}>Plans</Title>
        </div>
        {closeIcon}
      </div>
      <PlansCards onTryForFree={() => setPage('FREE_CONFIRMATION')} />
    </div>
  );

  let freeConfirmationUi = (
    <div className="Plans">
      <div className="Plans__header">
        <div className="Plans__header-title">
          <button
            onClick={() => setPage('PLANS_CARD')}
            className="Plans__page-header-back-btn"
          >
            <LeftOutlined />
          </button>
          <Title level={4}>Purchase Premium Plan</Title>
        </div>
        {closeIcon}
      </div>

      <FreePlanConfirmation />
    </div>
  );

  return (
    <IncortaLayoutContainer>
      {page === 'PLANS_CARD' ? plansCardUi : freeConfirmationUi}
    </IncortaLayoutContainer>
  );
}

export default Plans;
