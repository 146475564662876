import React from 'react';
import './GettingStarted.less';
import Icon from '@ant-design/icons';
import { ReactComponent as YoutubeIcon } from 'images/youtube.svg';
import { ReactComponent as LearnIcon } from 'images/learn.svg';
import { ReactComponent as ResourceCenterIcon } from 'images/resource-center.svg';
import { ReactComponent as DocumentationIcon } from 'images/documentation.svg';
import { ReactComponent as CommunityIcon } from 'images/community.svg';

function GettingStarted() {
  return (
    <div className="GettingStarted">
      <h1 className="GettingStarted__header">Recommended for you</h1>
      <div className="GettingStarted__section">
        <a
          href="https://www.youtube.com/watch?v=8jg_yEDaeos"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <Icon component={YoutubeIcon} /> Incorta Overview Demo
          </h2>
        </a>
        <p>Watching demo will help you to know how to use it.</p>
      </div>

      <div className="GettingStarted__section">
        <a
          href="https://learn.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <Icon component={LearnIcon} /> Learn
          </h2>
        </a>
        <p>
          Get all the facts on modern analytics in self-paced learning paths led
          by our experts. Enjoy courses designed for administrators, developers,
          and analysts.
        </p>
      </div>

      <div className="GettingStarted__section">
        <a
          href="https://docs.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <Icon component={DocumentationIcon} /> Documentation
          </h2>
        </a>
        <p>
          Dive into Incorta with official documentation, how-to’s, tech specs,
          user tips, and more. Get the answers needed to optimize your daily
          user experience here.
        </p>
      </div>

      <div className="GettingStarted__section">
        <a
          href="https://community.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <Icon component={CommunityIcon} /> Community
          </h2>
        </a>
        <p>
          Join others and discuss the platform, register for webinars, explore
          events, learn about new product releases, and get support from the
          Incorta community.{' '}
        </p>
      </div>

      <div className="GettingStarted__section">
        <a
          href="https://www.incorta.com/resources"
          target="_blank"
          rel="noopener noreferrer"
        >
          <h2>
            <Icon component={ResourceCenterIcon} /> Resource Center
          </h2>
        </a>
        <p>
          Stop here for guides, data apps, ebooks, and other resources that
          illustrate modern approaches for accessing, analyzing, and acting on
          data across roles and industries.Stop here for guides, data apps,
          ebooks, and other resources that illustrate modern approaches for
          accessing, analyzing, and acting on data across roles and industries.
        </p>
      </div>
    </div>
  );
}

export default GettingStarted;
