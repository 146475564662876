import './UnitsConsumptionDetailsPopover.less';

import React, { useRef, useLayoutEffect } from 'react';
import { Popover, Tooltip } from 'antd';
import echarts from 'echarts/lib/echarts';
import { ReactComponent as DetailsIcon } from '../icons/details-icon.svg';

interface UnitsConsumptionDetailsPopoverProps {
  day: string;
  hours: {
    hour: number;
    incorta: any;
    spark: any;
  }[];
}

function UnitsConsumptionDetailsPopover({
  day,
  hours,
}: UnitsConsumptionDetailsPopoverProps) {
  return (
    <Popover
      destroyTooltipOnHide
      overlayClassName="UnitsConsumptionDetailsPopover"
      content={
        <div className="UnitsConsumptionDetailsPopover__content">
          <HoursChart hours={hours} />
        </div>
      }
      title={day}
      placement="bottomRight"
      trigger="click"
    >
      <Tooltip title="Details" mouseEnterDelay={0.5}>
        <span className="link UnitsConsumptionDetailsResult__details-icons">
          <DetailsIcon />
        </span>
      </Tooltip>
    </Popover>
  );
}

interface HoursChartProps {
  hours: {
    hour: number;
    incorta: any;
    spark: any;
  }[];
}

function HoursChart({ hours }: HoursChartProps) {
  let chartRef = useRef<HTMLDivElement>(null);
  let eChartInstance = useRef<echarts.ECharts | null>(null);

  useLayoutEffect(() => {
    eChartInstance.current = echarts.init(chartRef.current!);
  }, []);

  useLayoutEffect(() => {
    eChartInstance.current!.setOption({
      legend: {
        bottom: 0,
        itemWidth: 16,
        itemHeight: 16,
        icon: 'circle',
        itemGap: 32,
        textStyle: {
          color: '#848F99',
        },
      },
      tooltip: {},
      dataset: {
        source: [
          ['units', 'Incorta Units', 'Spark Units'],
          ...hours.map(hour => [hour.hour, hour.incorta, hour.spark]),
        ],
      },
      xAxis: { name: 'Hours', type: 'category', ...axisStyles },
      yAxis: {
        name: 'Units',
        splitLine: { lineStyle: { color: '#EEF0F2' } },
        ...axisStyles,
      },
      series: [
        { type: 'bar', itemStyle: { color: '#263959' } },
        { type: 'bar', itemStyle: { color: '#D1481A' } },
      ],
    });
  }, []); // eslint-disable-line

  return (
    <div className="UnitsConsumptionDetailsPopover__chart" ref={chartRef}></div>
  );
}

const axisStyles = {
  axisLine: {
    lineStyle: {
      color: '#848F99',
    },
  },
  axisLabel: {
    color: '#848F99',
  },
  axisTick: {
    lineStyle: {
      color: '#848F99',
    },
  },
};

export default UnitsConsumptionDetailsPopover;
