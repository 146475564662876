import './PageSideSection.less';

import React from 'react';
import { CloseOutlined } from '@ant-design/icons';

interface PageSideSectionProps {
  title: React.ReactNode;
  onClose?(): void;
}

function PageSideSection({
  title,
  onClose,
  children,
}: React.PropsWithChildren<PageSideSectionProps>) {
  return (
    <div className="PageSideSection">
      <div className="PageSideSection__title">
        <div className="PageSideSection__title-header truncate">{title}</div>
        <button className="icon-btn" onClick={onClose}>
          <CloseOutlined />
        </button>
      </div>

      {children}
    </div>
  );
}

export default PageSideSection;
