import './MainCard.less';

import React from 'react';
import Icon from '@ant-design/icons';
import clsx from 'clsx';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';

interface MainCardProps {
  icon: React.ReactNode;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  footerText: React.ReactNode;
  content?: React.ReactNode;
  hideFooter?: boolean;
  onFooterClick?: () => void;
}
function MainCard({
  icon,
  title,
  subtitle,
  footerText,
  content,
  children,
  onFooterClick,
  hideFooter = false,
}: React.PropsWithChildren<MainCardProps>) {
  return (
    <div className="MainCard">
      <div
        className={clsx(
          'MainCard__body',
          hideFooter && 'MainCard__body--no-footer',
        )}
      >
        <div className="MainCard__body-section">
          <div className="MainCard__icon">{icon}</div>
          <div className="MainCard__content">
            <h3 className="MainCard__title">{title}</h3>
            <p className="MainCard__subtitle">{subtitle}</p>
            {content}
          </div>
        </div>
        {children}
      </div>
      {!hideFooter && (
        <button className="MainCard__footer link" onClick={onFooterClick}>
          <Icon component={ArrowRightIcon} /> {footerText}
        </button>
      )}
    </div>
  );
}

export default MainCard;
