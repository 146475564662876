import { useEffect, useCallback } from 'react';
import usePromiseRunner from './usePromiseRunner';
import { getErrorMessage, showErrorMessage } from 'utils/errors';

interface ServiceFunction<TResult> {
  (...args: any[]): Promise<TResult>;
}

interface Options<TResult> {
  resolve?: boolean;
  handleError?: boolean;
  initialResult?: TResult | null;
  useCache?: boolean;
}

type ServiceFunctionData<S extends ServiceFunction<any>> =
  S extends ServiceFunction<infer T> ? T : never;

let cache: { [key: string]: any } = {};

function useRequest<F extends ServiceFunction<any>>(
  service: F,
  {
    useCache = false,
    resolve = false,
    handleError = false,
    initialResult = useCache ? cache[service.name] : null,
  }: Options<ServiceFunctionData<F>> = {},
) {
  const {
    isRest,
    isPending,
    isRejected,
    status,
    setResult,
    result,
    error,
    run,
    reset,
  } = usePromiseRunner<ServiceFunctionData<F>>(initialResult);
  const errorMessage: string | null = error ? getErrorMessage(error) : null;

  const makeRequest: ServiceFunction<ServiceFunctionData<F>> = useCallback(
    (...args) => run(service(...args)),
    [run, service],
  );

  useEffect(() => {
    if (useCache && result) {
      cache[service.name] = result;
    }
  }, [result, service.name, useCache]);

  useEffect(() => {
    if (resolve) {
      makeRequest();
    }
  }, [makeRequest, resolve]);

  useEffect(() => {
    if (handleError && isRejected && errorMessage) {
      showErrorMessage(errorMessage);
    }
  }, [handleError, isRejected, errorMessage]);

  return {
    isRest,
    isPending,
    isRejected,
    status,
    setResult,
    result,
    error,
    errorMessage,
    run,
    makeRequest: makeRequest as F,
    reset,
  };
}

export default useRequest;
