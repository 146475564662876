import './Clusters.less';

import React, { useContext, useEffect, useState } from 'react';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { Button, Typography, Tooltip, Space, Modal } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ClusterList from './ClusterList/ClusterList';
import TrailEnded from './TrailEnded/TrailEnded';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { ReactComponent as BookIcon } from 'images/book.svg';
import { ReactComponent as BookFillIcon } from 'images/book-fill.svg';
import { canUserCreateCluster } from 'utils/cluster';
import PageSideSection from 'components/Sider/PageSideSection';
import PageSideWrapper from 'components/Sider/PageSideWrapper/PageSideWrapper';
import GettingStarted from 'components/GettingStarted/GettingStarted';
import { SessionContext } from 'auth/SessionProvider';
import ProvisionUserModal from 'components/ProvisionUserModal/ProvisionUserModal';
import { useAzureClusterPipelineState } from 'hooks/cluster';
import useAzurePipeline from 'hooks/useAzurePipeline';

const { Title } = Typography;

function Clusters() {
  const { user, isLoading } = useContext(SessionContext);
  const intl = useIntl();
  let { state } = useLocation<{ showGettingStarted?: boolean }>();
  let { showGettingStarted = false } = state ?? {};

  let [showSide, setShowSide] = useState(showGettingStarted);
  let canCreateCluster = canUserCreateCluster({
    user,
    configurations: user.configurations,
    platformActions: user.platformActions,
  });
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [azureClusterButtonTooltipText, setAzureClusterButtonTooltipText] =
    useState('');
  const { isToggleFeatureEnabled: showConfigureAzureClusterButton } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.azureSupport,
    });

  const {
    data: azureClusterPipelineData,
    isLoading: azureClusterPipelineIsLoading,
    isError: azureClusterPipelineIsError,
  } = useAzureClusterPipelineState({
    email: user.email,
    userID: user.uuid,
    enabled: !!(user.pipelineStatus && user.uuid && user.email),
  });
  const {
    isLastStep = false,
    isStatusSuccessful = false,
    isNotConfiguredYet,
  } = useAzurePipeline({
    azureClusterPipelineData,
  });

  useEffect(() => {
    if (
      user.last_password_reset === '' &&
      user.isProvision &&
      user.isDummyPassword &&
      showPasswordModal === false
    ) {
      setShowPasswordModal(true);
      user.isProvision = true;
      user.isDummyPassword = true;
    }
    // eslint-disable-next-line
  }, [user.isProvision, user.isDummyPassword, showPasswordModal]);

  useEffect(() => {
    if (azureClusterPipelineData) {
      const isUserHasNoOffer = !user?.showConsentFlow && isNotConfiguredYet;
      if (isLastStep && isStatusSuccessful) {
        setAzureClusterButtonTooltipText(
          intl.formatMessage({
            id: 'clusterPage.configureAzureClusterButtonTooltips.successful',
          }),
        );
      } else if (isUserHasNoOffer) {
        setAzureClusterButtonTooltipText(
          intl.formatMessage({
            id: 'clusterPage.configureAzureClusterButtonTooltips.requireAzureOffer',
          }),
        );
      } else {
        setAzureClusterButtonTooltipText(
          intl.formatMessage({
            id: 'clusterPage.configureAzureClusterButtonTooltips.inProgress',
          }),
        );
      }
    }
  }, [
    azureClusterPipelineData,
    intl,
    isLastStep,
    isStatusSuccessful,
    isNotConfiguredYet,
    user,
  ]);

  let createClusterButtonUi =
    !user?.trialExpired && canCreateCluster ? (
      <Link to="/clusters/create">
        <Button type="link" className="btn-default" icon={<PlusOutlined />}>
          <FormattedMessage id="clusterPage.createNewCluster" />
        </Button>
      </Link>
    ) : (
      <Tooltip
        placement="left"
        title={
          <>
            To create more clusters,{' '}
            <Link style={{ color: 'inherit' }} to="/support">
              upgrade to a Premium Account.
            </Link>
          </>
        }
      >
        <Button
          type="link"
          className="btn-default"
          disabled
          icon={<PlusOutlined />}
          loading={isLoading}
        >
          <FormattedMessage id="clusterPage.createNewCluster" />
        </Button>
      </Tooltip>
    );

  let consentFlowUi = user?.showConsentFlow ? (
    <Link to="/consentflow">
      <Button type="link" className="btn-default" icon={<PlusOutlined />}>
        <FormattedMessage id="clusterPage.createConsentFlow" />
      </Button>
    </Link>
  ) : (
    <Tooltip
      placement="left"
      title={azureClusterButtonTooltipText}
      overlayClassName="azure-cluster-button-tooltip__overlay"
    >
      <Button
        type="link"
        className="btn-default"
        disabled
        icon={<PlusOutlined />}
      >
        <FormattedMessage id="clusterPage.createConsentFlow" />
      </Button>
    </Tooltip>
  );
  return (
    <IncortaLayoutContainer>
      <PageSideWrapper>
        <div className="clusters-page">
          <div className="clusters-page__page-header">
            <div className="clusters-page__page-header-info">
              <Title level={4} data-testid="clusters-title">
                Clusters
              </Title>
            </div>

            <div className="clusters-page__page-header-actions">
              <Space size="large">
                {createClusterButtonUi}
                {showConfigureAzureClusterButton && consentFlowUi}
                {!showSide && (
                  <Button
                    className="btn-default"
                    onClick={() => setShowSide(true)}
                    icon={
                      <Icon
                        style={{
                          fontSize: 16,
                          position: 'relative',
                          top: 1,
                          marginRight: 0,
                        }}
                        component={BookIcon}
                      />
                    }
                  >
                    Getting Started
                  </Button>
                )}
              </Space>
            </div>
          </div>

          {user?.trialExpired ? (
            <TrailEnded user={user} />
          ) : (
            <ClusterList
              azureClusterPipelineData={azureClusterPipelineData}
              azureClusterPipelineIsLoading={azureClusterPipelineIsLoading}
              azureClusterPipelineIsError={azureClusterPipelineIsError}
            />
          )}
        </div>
        {showSide && (
          <PageSideSection
            title={
              <>
                <Icon
                  component={BookFillIcon}
                  style={{
                    fontSize: 16,
                    position: 'relative',
                    top: 1,
                    marginRight: 4,
                  }}
                />{' '}
                Getting Started
              </>
            }
            onClose={() => setShowSide(false)}
          >
            <GettingStarted />
          </PageSideSection>
        )}
      </PageSideWrapper>
      <Modal
        visible={showPasswordModal}
        footer={null}
        onCancel={() => setShowPasswordModal(false)}
        title={<FormattedMessage id="provisionUserModal.title" />}
        destroyOnClose
        centered
        maskClosable={false}
        closable={false}
      >
        <ProvisionUserModal closeModal={() => setShowPasswordModal(false)} />
      </Modal>
    </IncortaLayoutContainer>
  );
}

export default Clusters;
