import './BlueprintsPage.less';

import React, { useContext } from 'react';
import { Typography } from 'antd';
import BlueprintsBody from './BlueprintsBody/BlueprintsBody';
import TrailEnded from 'components/Clusters/TrailEnded/TrailEnded';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

function BlueprintsPage() {
  const { user } = useContext(SessionContext);
  return (
    <IncortaLayoutContainer>
      <div className="BlueprintsPage">
        <div className="BlueprintsPage__header">
          <div className="BlueprintsPage__header-title">
            <Title data-testid="data-apps-title" level={4}>
              Data Apps
            </Title>
          </div>
        </div>

        {user?.trialExpired ? <TrailEnded /> : <BlueprintsBody />}
      </div>
    </IncortaLayoutContainer>
  );
}

export default BlueprintsPage;
