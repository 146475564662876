import './ClusterDetails.less';

import React, { useCallback, useContext, useMemo, useState } from 'react';
import _ from 'lodash';
import Icon, {
  CopyOutlined,
  DeleteOutlined,
  LeftOutlined,
  MoreOutlined,
  RightOutlined,
} from '@ant-design/icons';
import {
  Typography,
  Dropdown,
  Menu,
  Breadcrumb,
  Space,
  Divider,
  Button,
  Tooltip,
} from 'antd';
import { Link, useParams, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import ClusterDetailsBody from './ClusterDetailsBody/ClusterDetailsBody';
import LeftSideContent from './LeftSideContent/LeftSideContent';
import SupportTokenButton from './SupportTokenButton/SupportTokenButton';
import ClusterSecretsPopover from './ClusterSecretsPopover/ClusterSecretsPopover';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import {
  deleteCluster,
  disconnectCluster,
  connectCluster,
} from 'services/cluster';
import { displaySuccessMessage } from 'utils';
import {
  getClusterStatus,
  canUserCreateCluster,
  deleteModalConfirm,
} from 'utils/cluster';
import CloneModal from 'components/Clusters/ClusterCard/CloneModal/CloneModal';
import { ReactComponent as DisconnectedIcon } from 'images/disconnected.svg';
import { ReactComponent as LoadingIcon } from 'images/loading-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import useRequest from 'hooks/useRequest';
import { useClusterList, useClusterListRefetch } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { getErrorsOfInput } from 'utils/fieldsValidation';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

interface ClusterDetailsParams {
  clusterName: string;
}

function ClusterDetails() {
  const { clusterName } = useParams<ClusterDetailsParams>();
  const intl = useIntl();

  const history = useHistory();
  const { user, reloadUser } = useContext(SessionContext);
  let [showCloneModal, setCloneModal] = useState(false);
  let [showLeftPanel, setShowLeftPanel] = useState(false);

  let { data, isLoading, isError } = useClusterList();

  let clusterData = useMemo(
    () =>
      data?.instances.find(instance => instance.instance.name === clusterName),
    [clusterName, data],
  );

  if (data?.instances && !clusterData) {
    isError = true;
  }

  const instance = clusterData?.instance;
  const services = clusterData?.services;
  const serverConfiguration = clusterData?.serverConfiguration;
  const incortaXServerConfiguration = clusterData?.incortaXServerConfiguration;
  const mlflowServerConfiguration = clusterData?.mlflowServerConfiguration;

  let requestCluster = useClusterListRefetch();

  let canClone = instance?.clusterPolicy.managementPolicy.includes('create');
  let canDelete = instance?.clusterPolicy.clusterPolicy.includes('delete');
  let canDoConnection =
    instance?.clusterPolicy.connectionPolicy.includes('create');
  let showCli = instance?.clusterPolicy.cliPolicy.includes('list');
  let platform = instance?.platform || '';
  const { isPlatformActionSupported: isCloneSupported } =
    usePlatformActionsSupport({
      currentPlatform: platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clone,
    });

  const isClusterOwner = useMemo(() => {
    return (
      user?.email &&
      user.email.toLowerCase() ===
        clusterData?.instance?.owner?.email?.toLowerCase()
    );
  }, [user, clusterData]);

  async function handleDelete(instanceName: string) {
    try {
      await deleteCluster({
        userId: user!.uuid,
        instanceName,
      });
      reloadUser();
      await requestCluster();
      displaySuccessMessage(`${instanceName} is deleted successfully`);
      history.replace('/clusters');
    } catch {
      clusterNameError();
    }
  }

  let displayedClusterName = instance
    ? instance.hostName || instance.name
    : undefined;

  let clusterStatus = useMemo(() => {
    return instance && services && getClusterStatus(instance, services[0]);
  }, [instance, services]);

  const { makeRequest: makeDisconnectCluster, isPending: isDisconnecting } =
    useRequest(
      useCallback(async () => {
        await disconnectCluster({
          userId: user!.uuid,
          clusterName: clusterName,
        });
        await requestCluster();
      }, [clusterName, requestCluster, user]),
      {
        handleError: true,
      },
    );

  const { makeRequest: makeConnectCluster, isPending: isConnecting } =
    useRequest(
      useCallback(async () => {
        await connectCluster({
          userId: user!.uuid,
          clusterName: clusterName,
        });
        await requestCluster();
      }, [clusterName, requestCluster, user]),
      {
        handleError: true,
      },
    );

  async function handleConnect() {
    await makeConnectCluster();
  }

  async function handleDisconnect() {
    await makeDisconnectCluster();
  }

  let statusIcon =
    clusterStatus === 'corrupted' ? (
      <span style={{ color: '#393E41' }}>
        <Icon component={DisconnectedIcon} /> Unavailable
      </span>
    ) : clusterStatus === 'running' ? (
      <span style={{ color: '#469650' }}>
        <Icon component={CheckIcon} /> Connected
      </span>
    ) : clusterStatus === 'sleeping' ? (
      <span style={{ color: '#B43237' }}>
        <Icon component={DisconnectedIcon} /> Disconnected
      </span>
    ) : clusterStatus === 'stopping' ? (
      <span style={{ color: '#B43237' }}>
        <Icon component={DisconnectedIcon} /> Stopping...
      </span>
    ) : (
      <span style={{ color: '#FFA900' }}>
        <Icon component={LoadingIcon} /> Loading...
      </span>
    );

  let actionButtons =
    clusterStatus === 'running' || clusterStatus === 'starting-up' ? (
      <Button size="small" onClick={handleDisconnect} loading={isDisconnecting}>
        Disconnect
      </Button>
    ) : clusterStatus === 'sleeping' ? (
      <Button size="small" onClick={handleConnect} loading={isConnecting}>
        Connect
      </Button>
    ) : null;

  let uiCanClone =
    canClone &&
    canUserCreateCluster({
      user,
      configurations: user?.configurations,
      platformActions: user.platformActions,
    }) &&
    instance?.cloneable;
  let uiCanDelete = canDelete;

  const clusterNameError = (value?: string, clusterName?: string) => {
    getErrorsOfInput(
      'cluster-name',
      value ? () => value === '' || clusterName !== value : true,
      'The input is not valid Name!',
    );
  };

  const clusterNameValidation = useCallback(
    _.debounce((e: any) => {
      clusterNameError(e.target.value, clusterName);
    }, 1500),
    [],
  );

  return (
    <IncortaLayoutContainer>
      <div className="LeftSideWrapper" key={instance?.name}>
        <div className={clsx('LeftSide', showLeftPanel && 'show')}>
          <Tooltip
            placement="right"
            title={showLeftPanel ? 'Collapse' : 'Expand'}
          >
            <button
              className="LeftSide__icon icon-btn"
              onClick={() => setShowLeftPanel(f => !f)}
            >
              {showLeftPanel ? <LeftOutlined /> : <RightOutlined />}
            </button>
          </Tooltip>
          <div className="LeftSide__content">
            <LeftSideContent />
          </div>
        </div>

        <div className="cluster-details">
          <div className="cluster-details__page-header">
            {instance && (
              <>
                <div className="cluster-details__page-header-info">
                  <Breadcrumb className="cluster-details__page-header-breadcrumb">
                    <Breadcrumb.Item>
                      <Link to={`/clusters`}>
                        <FormattedMessage id="clusterPage.title" />
                      </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                      <Link to={`/clusters/${instance.name}`}>
                        {displayedClusterName}
                      </Link>
                    </Breadcrumb.Item>
                  </Breadcrumb>
                  <div className="cluster-details__page-header-info_cluster_secrets">
                    <Title level={4}>{displayedClusterName}</Title>
                    {isClusterOwner && (
                      <ClusterSecretsPopover
                        clusterName={clusterName}
                        userId={user.uuid}
                      />
                    )}
                  </div>
                </div>

                <div className="cluster-details__nav-right">
                  <Space align="center">
                    <div>{statusIcon}</div>
                    <div>{canDoConnection && actionButtons}</div>
                    {(showCli || uiCanClone || uiCanDelete) && (
                      <Divider type="vertical" />
                    )}
                    {showCli && <SupportTokenButton instance={instance} />}
                    {(uiCanClone || uiCanDelete) && (
                      <div className="cluster-details__page-header-actions">
                        <Dropdown
                          trigger={['click']}
                          overlayClassName="cluster-card__menu-overlay"
                          overlay={
                            <Menu>
                              {uiCanClone && (
                                <Menu.Item
                                  key="1"
                                  onClick={() => {
                                    setCloneModal(true);
                                  }}
                                  disabled={
                                    clusterStatus !== 'running' ||
                                    !isCloneSupported
                                  }
                                >
                                  <Tooltip
                                    title={
                                      !isCloneSupported ? (
                                        <FormattedMessage
                                          id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                                          values={{
                                            action: intl.formatMessage({
                                              id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.clone',
                                            }),
                                          }}
                                        />
                                      ) : clusterStatus !== 'running' ? (
                                        'Cluster needs to be connected'
                                      ) : (
                                        ''
                                      )
                                    }
                                    placement="top"
                                  >
                                    <CopyOutlined />
                                    Clone
                                  </Tooltip>
                                </Menu.Item>
                              )}
                              {uiCanDelete && (
                                <Menu.Item
                                  key="2"
                                  onClick={() => {
                                    deleteModalConfirm({
                                      clusterName: instance.name,
                                      handleDelete,
                                      clusterNameValidation,
                                      clusterNameError,
                                    });
                                  }}
                                >
                                  <DeleteOutlined />
                                  Delete
                                </Menu.Item>
                              )}
                            </Menu>
                          }
                        >
                          <MoreOutlined />
                        </Dropdown>
                        <CloneModal
                          instance={instance}
                          refresh={requestCluster}
                          show={showCloneModal}
                          setShow={setCloneModal}
                          onSuccess={() => {
                            history.push('/clusters');
                          }}
                        />
                      </div>
                    )}
                  </Space>
                </div>
              </>
            )}
          </div>

          <ClusterDetailsBody
            instance={instance}
            services={services}
            serverConfiguration={serverConfiguration}
            incortaXServerConfiguration={incortaXServerConfiguration}
            mlflowServerConfiguration={mlflowServerConfiguration}
            isPending={isLoading}
            isRejected={isError}
            requestCluster={requestCluster}
          />
        </div>
      </div>
    </IncortaLayoutContainer>
  );
}

export default ClusterDetails;
