import React, { useState } from 'react';
import './ClusterScheduling.less';
import {
  PlusCircleOutlined,
  ScheduleFilled,
  TableOutlined,
} from '@ant-design/icons';
import { Button, Tabs, Tooltip } from 'antd';
import {
  ScheduledEventsWithInfoParsedItem,
  isEventExistingType,
  scheduledEventFormValuesKeysType,
} from 'types/cluster';
import ScheduledEvents from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEvents/ScheduledEvents';
import ScheduledEventModal from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/ScheduledEventModal';
import { useGetScheduledEvents } from 'hooks/cluster';

type ClusterSchedulingProps = {
  instanceID: string;
};
function ClusterScheduling({ instanceID }: ClusterSchedulingProps) {
  const [showScheduledEventModal, setShowScheduledEventModal] = useState(false);
  const [isEventExisting, setIsEventExisting] =
    useState<isEventExistingType>(false);

  const { data: scheduledEvents, isLoading: isScheduledEventsLoading } =
    useGetScheduledEvents({ instanceID });

  const [scheduledEventFormValues, setScheduledEventFormValues] = useState<
    {} | Record<scheduledEventFormValuesKeysType, any>
  >({});

  function openScheduledEventModal(isNew: isEventExistingType) {
    setIsEventExisting(isNew);
    setShowScheduledEventModal(true);
  }
  function closeScheduledEventModal() {
    setShowScheduledEventModal(false);
  }

  return (
    <div className="cluster-scheduling__wrapper">
      <section className="cluster-scheduling__header">
        <section className="cluster-scheduling__title-wrapper">
          <ScheduleFilled />
          <h3 className="cluster-scheduling__title">Cluster Scheduling</h3>
        </section>
        <section>
          <Tooltip title="Add new scheduled event">
            <Button
              icon={<PlusCircleOutlined />}
              shape="circle"
              type="primary"
              aria-label="Add new scheduled event"
              onClick={() => openScheduledEventModal(false)}
            />
          </Tooltip>
        </section>
      </section>

      <Tabs>
        <Tabs.TabPane
          tab={
            <>
              <TableOutlined />
              Table View
            </>
          }
          key="Table View"
        >
          <ScheduledEvents
            instanceID={instanceID}
            scheduledEvents={
              scheduledEvents as ScheduledEventsWithInfoParsedItem[]
            }
            isScheduledEventsLoading={isScheduledEventsLoading}
            openScheduledEventModal={openScheduledEventModal}
            setScheduledEventFormValues={setScheduledEventFormValues}
          />
        </Tabs.TabPane>
        {/* <Tabs.TabPane
          tab={
            <>
              <CalendarOutlined />
              Calendar View
            </>
          }
          key="Calendar View"
        >
          <ScheduledEventsCalendar />
        </Tabs.TabPane> */}
      </Tabs>

      {showScheduledEventModal && (
        <ScheduledEventModal
          isEventExisting={isEventExisting}
          closeModal={closeScheduledEventModal}
          instanceID={instanceID}
          scheduledEventFormValues={scheduledEventFormValues}
          setScheduledEventFormValues={setScheduledEventFormValues}
        />
      )}
    </div>
  );
}

export default ClusterScheduling;
