import './AcceptingOwnership.less';

import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { acceptUserOwnership } from 'services/user';
import { displaySuccessMessage } from 'utils';
import { showErrorMessage } from 'utils/errors';

interface AcceptingOwnershipProps {
  userID: string;
  instanceID: string;
}

function AcceptingOwnership({ userID, instanceID }: AcceptingOwnershipProps) {
  const history = useHistory();

  useEffect(() => {
    acceptUserOwnership(userID, instanceID)
      .then(() => {
        displaySuccessMessage('Ownsership transfered');
        history.push('/clusters');
      })
      .catch((error: any) => {
        showErrorMessage(error);
        history.push('/signin');
      });
  }, [history, userID, instanceID]);

  return (
    <div className="acceptingOwnership">
      <Spin tip="Accepting Cluster Ownership" />
    </div>
  );
}

export default AcceptingOwnership;
