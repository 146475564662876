import './ClusterList.less';

import React, { useContext } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Spin, Collapse } from 'antd';
import ClusterCard from '../ClusterCard/ClusterCard';
import EmptyClusterResult from '../EmptyClusterResult/EmptyClusterResult';
import AzureCreationClusterCard from '../ClusterCard/AzureCreation/AzureCreationClusterCard/AzureCreationClusterCard';
import useShowLoadingClusterList from './useShowLoadingClusterList';
import { deleteCluster } from 'services/cluster';
import { displaySuccessMessage } from 'utils';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import { chunkCluster } from 'utils/cluster';
import { useClusterList, useClusterListRefetch } from 'hooks/cluster';
import { getErrorsOfInput } from 'utils/fieldsValidation';
import { SessionContext } from 'auth/SessionProvider';
import { AzureClusterPipelineData } from 'types/cluster';

const { Panel } = Collapse;

interface ClusterListProps {
  azureClusterPipelineData: AzureClusterPipelineData | undefined;
  azureClusterPipelineIsLoading: boolean;
  azureClusterPipelineIsError: boolean;
}
function ClusterList({
  azureClusterPipelineData,
  azureClusterPipelineIsLoading,
  azureClusterPipelineIsError,
}: ClusterListProps) {
  const { user, reloadUser } = useContext(SessionContext);

  const { data, isLoading, isError } = useClusterList();

  const { shouldShowLoading } = useShowLoadingClusterList({
    areClustersLoading: isLoading,
    clustersData: data,
    azureClusterPipelineIsLoading,
    azureClusterPipelineData,
  });

  let requestCluster = useClusterListRefetch();

  if (shouldShowLoading) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting your clusters" />
      </div>
    );
  }

  if (
    isError &&
    !data &&
    azureClusterPipelineIsError &&
    !azureClusterPipelineData
  ) {
    return (
      <div className="cluster-list flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  //TODO: handle loading of azure clusters separately from gc clusters
  if (
    // TODO: this was to handle glitching showing connected & disconnected. But it also renders when not having gc clusters but having azure pipeline running
    // user?.instancesCount === 0 ||
    data?.instances.length === 0 &&
    (!azureClusterPipelineData || !azureClusterPipelineData?.pipelineStatus) &&
    !azureClusterPipelineIsLoading
  ) {
    return (
      <div className="cluster-list flex-center">
        <EmptyClusterResult />
      </div>
    );
  }

  const clusterNameError = () => {
    getErrorsOfInput('cluster-name', true, 'The input is not valid Name!');
  };

  async function handleDelete(instanceName: string) {
    try {
      await deleteCluster({
        userId: user!.uuid,
        instanceName,
      });
      reloadUser();
      await requestCluster();
      displaySuccessMessage(`${instanceName} is deleted successfully`);
    } catch {
      clusterNameError();
    }
  }

  const clusters = data?.instances;

  const { connectedClusters, disconnectedClusters } = chunkCluster(clusters);

  return (
    <div className="cluster-list cluster-list--noupper-padding">
      <Collapse
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        defaultActiveKey={[1, 2]}
        className="cluster-list__collapse"
      >
        <Panel
          header={`Connected Clusters (${connectedClusters.length})`}
          key="1"
        >
          {connectedClusters.length === 0 && !azureClusterPipelineData ? (
            <div>You don't have Connected Clusters</div>
          ) : (
            <div className="cluster-list__grid">
              {connectedClusters.map(instance => (
                <ClusterCard
                  key={instance.instance.id}
                  cluster={instance}
                  handleDelete={handleDelete}
                />
              ))}
              {azureClusterPipelineData?.pipelineStatus &&
                !azureClusterPipelineIsLoading &&
                !azureClusterPipelineIsError && (
                  <AzureCreationClusterCard
                    azureClusterPipelineData={azureClusterPipelineData}
                  />
                )}
            </div>
          )}
        </Panel>
        <Panel
          header={`Disconnected Clusters (${disconnectedClusters.length})`}
          key="2"
        >
          {disconnectedClusters.length === 0 ? (
            <div>You don't have Disconnected Clusters</div>
          ) : (
            <div className="cluster-list__grid">
              {disconnectedClusters.map(instance => (
                <ClusterCard
                  key={instance.instance.id}
                  cluster={instance}
                  handleDelete={handleDelete}
                />
              ))}
            </div>
          )}
        </Panel>
      </Collapse>
    </div>
  );
}

export default ClusterList;
