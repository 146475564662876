import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Button, Drawer } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useMutation } from 'react-query';
import {
  clearUserIdentityForAllTrackingTools,
  identifyHeapUser,
  identifyUserForFullStory,
  identifyUserForQualified,
} from 'services/tracking';

import './UserConsentOverlay.less';
import { updatePersonalInformation as updatePersonalInformationApi } from 'services/user';
import { SessionContext } from 'auth/SessionProvider';
import { UserConsent } from 'types/user';

enum UserRole {
  TRIAL = 'free',
  ENTERPRISE = 'enterprise',
}

function UserConsentOverlay({
  setUserConsent,
}: {
  setUserConsent: Dispatch<SetStateAction<boolean>>;
}) {
  const { user } = useContext(SessionContext);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const { mutateAsync: updatePersonalInformation, isLoading } = useMutation(
    updatePersonalInformationApi,
  );

  useEffect(() => {
    if (
      user?.role?.name === UserRole.ENTERPRISE ||
      (user?.role?.name === UserRole.TRIAL &&
        user?.userConsent === UserConsent.ACCEPT)
    ) {
      identifyUser();
      setUserConsent(true);
    } else if (user?.role?.name === UserRole.TRIAL && !user?.userConsent) {
      setOpenDrawer(true);
    }

    // eslint-disable-next-line
  }, [user?.role?.name, user?.userConsent]);

  const identifyUser = () => {
    identifyUserForFullStory(user.email as string, {
      displayName: user.fullName as string,
      email: user.email as string,
    });
    identifyHeapUser(user.email as string, { displayName: user.fullName });
    identifyUserForQualified(user);
  };

  const consent = async (userConsent: UserConsent | false) => {
    try {
      await updatePersonalInformation({
        userId: user.uuid,
        personalInformationEdit: {
          userConsent,
        },
      });
    } finally {
      setUserConsent(true);
      if (userConsent === UserConsent.ACCEPT) {
        identifyUser();
      } else {
        clearUserIdentityForAllTrackingTools();
      }
      setOpenDrawer(false);
    }
  };

  return (
    <Drawer
      placement="bottom"
      closable={false}
      key="user-consent-drawer"
      visible={openDrawer}
      destroyOnClose
      className="user-consent-container"
      height={160}
    >
      <div className="user-consent-container__title">Cookies & Tracking</div>
      <div>
        <FormattedMessage
          id="userConsentCookiesText"
          values={{
            globalPrivacy: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.incorta.com/privacy"
              >
                global privacy policy
              </a>
            ),
            cookiesPolicy: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.incorta.com/cookie-policy"
              >
                cookies notice.
              </a>
            ),
            privacyEmail: (
              <a href="mailto:privacy@incorta.com">privacy@incorta.com</a>
            ),
          }}
        />
      </div>
      <div className="user-consent-container__actions">
        <Button onClick={() => consent(UserConsent.REJECT)}>Opt-out</Button>
        <Button
          loading={isLoading}
          type="primary"
          onClick={() => consent(UserConsent.ACCEPT)}
        >
          Opt-in
        </Button>
      </div>
    </Drawer>
  );
}

export default React.memo(UserConsentOverlay);
