import React, { useState } from 'react';
import './ScheduledEvents.less';
import { Button, Modal, Space, Table, Tooltip, notification } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  scheduledEventDateFormat,
  seachableTablePageSizeOptions,
} from 'utils/constants';
import {
  ScheduledEvent,
  ScheduledEventEnum,
  ScheduledEventStatusEnum,
  ScheduledEventsWithInfoParsedItem,
  isEventExistingType,
  scheduledEventFormValuesKeysType,
} from 'types/cluster';
import { scheduledEventFormFieldNames } from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/ScheduledEventModal';
import useClusterSchedulingHelpers from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/useClusterSchedulingHelpers';
import { useDeleteScheduledEvent } from 'hooks/cluster';

type ScheduledEventsProps = {
  instanceID: string;
  scheduledEvents: ScheduledEventsWithInfoParsedItem[];
  isScheduledEventsLoading: boolean;
  openScheduledEventModal: (isNew: isEventExistingType) => void;
  setScheduledEventFormValues: React.Dispatch<
    React.SetStateAction<{} | Record<scheduledEventFormValuesKeysType, any>>
  >;
};
function ScheduledEvents({
  instanceID,
  scheduledEvents = [],
  isScheduledEventsLoading,
  openScheduledEventModal,
  setScheduledEventFormValues,
}: ScheduledEventsProps) {
  const intl = useIntl();
  const { availableSchedulingActions, scheduledEventStatusMap } =
    useClusterSchedulingHelpers();

  const { mutateAsync: mutateDeleteScheduledEvent } = useDeleteScheduledEvent({
    instanceID,
  });

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(seachableTablePageSizeOptions[0]);
  const pageSizeOptionsString = seachableTablePageSizeOptions.map(options =>
    options.toString(),
  );

  const columns: ColumnsType<ScheduledEventsWithInfoParsedItem> = [
    {
      title: '',
      dataIndex: scheduledEventFormFieldNames.id,
      key: scheduledEventFormFieldNames.id,
      render(value, record, index) {
        return (page - 1) * limit + index + 1;
      },
    },
    {
      title: (
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.columnLabels.event" />
      ),
      dataIndex: scheduledEventFormFieldNames.event,
      key: scheduledEventFormFieldNames.event,
      render(value, record, index) {
        return (
          <section className="scheduled-event-table__event-cell">
            {availableSchedulingActions[value as ScheduledEventEnum].icon}
            {availableSchedulingActions[value as ScheduledEventEnum].label}
          </section>
        );
      },
    },
    {
      title: (
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.columnLabels.startTime" />
      ),
      dataIndex: scheduledEventFormFieldNames.startTime,
      key: scheduledEventFormFieldNames.startTime,
      render(value, record, index) {
        return <>{moment(value).format(scheduledEventDateFormat)}</>;
      },
    },
    {
      title: (
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.columnLabels.endTime" />
      ),
      dataIndex: scheduledEventFormFieldNames.endTime,
      key: scheduledEventFormFieldNames.endTime,
      render(value, record, index) {
        return value ? (
          <>{moment(value).format(scheduledEventDateFormat)}</>
        ) : (
          <></>
        );
      },
    },
    {
      title: (
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.columnLabels.eventDetails" />
      ),
      key: scheduledEventFormFieldNames.eventInformation,
      dataIndex: scheduledEventFormFieldNames.eventInformation,
      render(value, record, index) {
        const { analyticsReplicas, loaderReplicas } = value || {};
        return (
          <section>
            {analyticsReplicas && (
              <article>
                {analyticsReplicas}
                <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.eventDetailsAnalyticsReplicas" />
              </article>
            )}
            {loaderReplicas && (
              <article>
                {loaderReplicas}
                <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.eventDetailsLoaderReplicas" />
              </article>
            )}
          </section>
        );
      },
    },
    {
      title: (
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.columnLabels.status" />
      ),
      key: scheduledEventFormFieldNames.status,
      dataIndex: scheduledEventFormFieldNames.status,
      render(value: ScheduledEventStatusEnum, record, index) {
        return scheduledEventStatusMap.get(value);
      },
    },
    {
      title: '',
      key: 'action',
      render: (value: ScheduledEvent, record, index) => {
        const now = moment();
        const startTimeValue = moment(record.startTime);
        const isEventPassed = now >= startTimeValue;
        return (
          <Space size="middle">
            <Tooltip
              title={
                isEventPassed
                  ? intl.formatMessage({
                      id: 'clusterConfiguration.schedulingTab.view',
                    })
                  : intl.formatMessage({
                      id: 'clusterConfiguration.schedulingTab.edit',
                    })
              }
            >
              <Button
                icon={isEventPassed ? <EyeOutlined /> : <EditOutlined />}
                aria-label={
                  isEventPassed
                    ? intl.formatMessage({
                        id: 'clusterConfiguration.schedulingTab.view',
                      })
                    : intl.formatMessage({
                        id: 'clusterConfiguration.schedulingTab.edit',
                      })
                }
                size="small"
                onClick={() => handleEditEvent(value)}
              />
            </Tooltip>
            {!isEventPassed && (
              <Tooltip
                title={intl.formatMessage({
                  id: 'clusterConfiguration.schedulingTab.scheduledEventTable.deleteEvent',
                })}
              >
                <Button
                  icon={<DeleteOutlined />}
                  aria-label={intl.formatMessage({
                    id: 'clusterConfiguration.schedulingTab.scheduledEventTable.deleteEvent',
                  })}
                  size="small"
                  onClick={() => handleDeleteEvent(value.id)}
                />
              </Tooltip>
            )}
          </Space>
        );
      },
    },
  ];

  function handleEditEvent(value: ScheduledEvent) {
    setScheduledEventFormValues({
      eventID: value.id,
      event: value.event,
      status: value.status,
      eventResult: value.eventResult,
      startTime: moment(value.startTime),
      ...(value.endTime && { endTime: moment(value.endTime) }),
      ...(value.eventInformation?.analyticsReplicas && {
        eventInfoAnalyticsReplicas: value.eventInformation?.analyticsReplicas,
      }),
      ...(value.eventInformation?.loaderReplicas && {
        eventInfoLoaderReplicas: value.eventInformation?.loaderReplicas,
      }),
    });
    const now = moment();
    const startTimeValue = moment(value.startTime);
    const isEventPassed = now >= startTimeValue;
    openScheduledEventModal(isEventPassed ? 'View' : 'Edit');
  }

  async function handleDeleteEvent(eventID: number) {
    const successMessage = intl.formatMessage({
      id: 'clusterConfiguration.schedulingTab.message.deleteSuccess',
    });
    Modal.confirm({
      title: 'Delete Scheduled Event',
      content: `Are you sure you want to delete that scheduled event?`,
      centered: true,
      okText: 'Confirm',
      cancelText: 'Cancel',
      async onOk() {
        const { status, data } = await mutateDeleteScheduledEvent(eventID);
        if (status === 200) {
          notification.success({
            message: successMessage,
            description: data.message,
          });
        }
      },
    });
  }

  return (
    <Table
      className="cluster-services-control__analytics-table"
      title={() => (
        <h4>
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.title" />
        </h4>
      )}
      columns={columns}
      dataSource={scheduledEvents}
      loading={isScheduledEventsLoading}
      rowKey={scheduledEventFormFieldNames.id}
      pagination={{
        pageSize: limit,
        total: scheduledEvents?.length,
        showSizeChanger: true,
        pageSizeOptions: pageSizeOptionsString,
        onShowSizeChange: (_, size) => {
          setLimit(size);
        },
        onChange(newPage) {
          setPage(newPage);
        },
      }}
    />
  );
}

export default ScheduledEvents;
