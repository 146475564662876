import './PlansCards.less';
import React, { useCallback, useContext } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import useRequest from 'hooks/useRequest';
import { canDowngrade, changePlan } from 'services/user';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

const { Paragraph } = Typography;

interface PlansCardsProps {
  onTryForFree(): void;
}

function PlansCards({ onTryForFree }: PlansCardsProps) {
  let history = useHistory();
  const { user, isAuthenticated, reloadUser } = useContext(SessionContext);

  let isBasic = user?.role?.name === 'free';
  let isPremium =
    user?.role?.name === 'premium' || user?.role?.name === 'basic';
  let isEnterprise = user?.role?.name === 'enterprise';

  let { isPending: canDowngradePending, makeRequest: canDowngradeRequest } =
    useRequest(
      useCallback(
        () => canDowngrade({ userId: user!.uuid }),
        // eslint-disable-next-line
        [isAuthenticated],
      ),
      { handleError: true },
    );

  let { makeRequest: changePlanRequest } = useRequest(
    useCallback(async () => {
      try {
        await changePlan({ userId: user!.uuid, plan: 'free' });
        reloadUser();
      } catch (error) {
        if (error.response.status === 304) {
          error.response.data = `You Can't change to premium plan`;
        }
        return Promise.reject(error);
      }
      displaySuccessMessage('You are now a free user ');
      history.push('/my-account?tab=plan');

      // eslint-disable-next-line
    }, [history, user]),
    { handleError: true },
  );

  async function handleChangeToFree() {
    let {
      data: { can },
    } = await canDowngradeRequest();
    if (can) {
      Modal.confirm({
        title: `Are you sure You want to downgrade to Free Account?`,
        okText: 'Yes',
        cancelText: 'No',
        centered: true,
        width: 620,
        async onOk() {
          return changePlanRequest();
        },
      });
    } else {
      Modal.error({
        title: (
          <Paragraph
            style={{
              fontSize: 14,
              color: '#393E41',
              fontWeight: 'normal',
            }}
          >
            In order to downgrade or cancel premium trial, you must have only 1
            single cluster of maximum Small size.
          </Paragraph>
        ),
        icon: <ExclamationCircleOutlined />,
        centered: true,
        width: 620,
        okText: 'Okay',
      });
    }
  }

  let basicCardUi = (
    <div className="PlansCards__card">
      <div className="PlansCards__card-header">Limited</div>
      <div className="PlansCards__card-body">
        <div className="PlansCards__card-upper">
          <h2 className="PlansCards__card-title-type">Basic</h2>

          <h3 className="PlansCards__card-title-price">Free</h3>

          <div className="PlansCards__card-description">
            Description Goes describe about the basic free plan.
          </div>

          <div className="PlansCards__card-action">
            {isBasic ? (
              <span className="PlansCards__card-current-plan-tag">
                Current Plan
              </span>
            ) : (
              <Button
                loading={canDowngradePending}
                onClick={handleChangeToFree}
              >
                Select
              </Button>
            )}
          </div>
        </div>

        <div className="PlansCards__card-pricing-info">
          <h3>Includes</h3>

          <ul>
            <li>Option 1</li>
            <li>Option 2 bla bla</li>
            <li>Option 3 bla</li>
            <li>Option 4 bla bla bla</li>
          </ul>
        </div>
      </div>
    </div>
  );

  let premiumCardUi = (
    <div className="PlansCards__card PlansCards__card--primary">
      <div className="PlansCards__card-header">Recommended</div>
      <div className="PlansCards__card-body">
        <div className="PlansCards__card-upper">
          <h2 className="PlansCards__card-title-type">Premium</h2>

          <h3 className="PlansCards__card-title-price">$1 / Unit</h3>

          <div className="PlansCards__card-description">
            <div>Start Free Trial. Receive $500 worth of free usage.</div>
            <div style={{ marginTop: 8 }}>Then you will pay $1/unit</div>
          </div>

          <div className="PlansCards__card-action">
            {isPremium ? (
              <span className="PlansCards__card-current-plan-tag">
                Current Plan
              </span>
            ) : (
              <Button onClick={() => onTryForFree()} type="primary">
                Try for free
              </Button>
            )}
          </div>
        </div>

        <div className="PlansCards__card-pricing-info">
          <h3>Everything in Basic, Plus</h3>

          <ul>
            <li>Option 1</li>
            <li>Option 2 bla bla</li>
            <li>Option 3 bla</li>
            <li>Option 4 bla bla bla</li>
          </ul>
        </div>
      </div>
    </div>
  );

  let enterpriseCardUi = (
    <div className="PlansCards__card">
      <div className="PlansCards__card-header">Unlimited</div>
      <div className="PlansCards__card-body">
        <div className="PlansCards__card-upper">
          <h2 className="PlansCards__card-title-type">Enterprise</h2>

          <h3 className="PlansCards__card-title-price">Let’s Talk</h3>

          <div className="PlansCards__card-description">
            Unlimited Package to cover all your needs.
          </div>

          <div className="PlansCards__card-action">
            {isEnterprise ? (
              <span className="PlansCards__card-current-plan-tag">
                Current Plan
              </span>
            ) : (
              <Button
                onClick={() => {
                  alert('Not working!!');
                }}
              >
                Contact Sales
              </Button>
            )}
          </div>
        </div>

        <div className="PlansCards__card-pricing-info">
          <h3>Everything in Premium, Plus</h3>

          <ul>
            <li>Option 1</li>
            <li>Option 2 bla bla</li>
            <li>Option 3 bla</li>
            <li>Option 4 bla bla bla</li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <div className="PlansCards">
      {basicCardUi}

      {premiumCardUi}

      {enterpriseCardUi}
    </div>
  );
}

export default PlansCards;
