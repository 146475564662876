import './ClusterServices.less';

import React from 'react';
import { Col, Row } from 'antd';
import ServiceCard from './ServiceCard/ServiceCard';
import { Instance, Service } from 'types/cluster';

interface ClusterServicesProps {
  instance: Instance;
  services: Service[];
}
function ClusterServices({ instance, services }: ClusterServicesProps) {
  instance.services = services;
  return (
    <div className="ClusterServices">
      <Row gutter={[16, 16]}>
        {services.map((service, i) => {
          let incortaCard = i === 0;
          return (
            <Col key={service.name} xs={24} lg={8}>
              <ServiceCard
                instance={instance}
                service={service}
                standOut={incortaCard}
              />
            </Col>
          );
        })}
      </Row>
    </div>
  );
}

export default ClusterServices;
