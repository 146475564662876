import React, { useEffect, useRef, useState, useContext } from 'react';
import './ConsentFlowDetails.less';
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Radio,
  Divider,
  notification,
  Tooltip,
} from 'antd';
import { Rule } from 'antd/lib/form';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import copy from 'clipboard-copy';
import { RadioChangeEvent } from 'antd/lib/radio';
import { Subscription } from 'types/cluster';
import {
  envNameRules,
  resourceGroupNameRules,
  subIDRules,
  commandRules,
  resourceRegionSelectionRules,
  networkNameRules,
  networkSubnetNameRules,
  networkSubnetRules,
  isJsonString,
} from 'utils/cluster';
import {
  azureCloudShellUrl,
  configKeys,
  regionsDefaults,
} from 'components/ConsentFlow/constants';
import SubmittingStatus from 'components/ConsentFlow/SubmittingStatus/SubmittingStatus';
import { useGetResourcesRegions } from 'hooks/cluster';
import { LocationContext } from 'contexts/LastLocation';

export const consentFormFieldNames = {
  environmentName: 'configurationName',
  subscriptionID: 'subscriptionID',
  resourceGroup: 'resourceGroup',
  region: 'region',
  servicePrincipalCredentials: 'servicePrincipalCredentials',
  network: 'network',
  networkName: 'vnName',
  subnet1: 'subnet1',
  subnet2: 'subnet2',
  subnet3: 'subnet3',
  subnet4: 'subnet4',
  subnet5: 'subnet5',
} as const;

interface ConsentFlowDetailsProps {
  onSubmit: (values: Subscription) => void;
  loading: boolean;
  subscription: Subscription;
  result: any;
  isRejected: boolean;
  errorMessage: string | null;
}
function ConsentFlowDetails({
  onSubmit,
  loading,
  result,
  isRejected,
  errorMessage,
}: ConsentFlowDetailsProps) {
  const [form] = Form.useForm();
  const { validateFields, getFieldError } = form;
  const intl = useIntl();
  const { TextArea } = Input;
  const defaultEnvironmentName = '<Environment name>' as const;
  const defaultSubscriptionId = '<subscription_id>' as const;
  const defaultResourceGroupName = '<resourcegp_name>' as const;
  const [environmentName, setEnvironmentName] = useState<string>(
    defaultEnvironmentName,
  );
  const [subscriptionId, setSubscriptionId] = useState<string>(
    defaultSubscriptionId,
  );
  const [resourceGroupName, setResourceGroupName] = useState<string>(
    defaultResourceGroupName,
  );
  const [isCopyButtonDisabled, setIsCopyButtonDisabled] = useState(true);
  const [showExistingNetworkFields, setShowExistingNetworkFields] =
    useState(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const {
    data: regions,
    isLoading: areRegionsLoading,
    isError: hasRegionsErrors,
  } = useGetResourcesRegions({
    configKey: configKeys.azureRegions,
    configType: 'string',
    defaultValue: regionsDefaults[0],
  });

  let history = useHistory();
  const { lastLocation } = useContext(LocationContext);
  const azureCommandRef = useRef<HTMLSpanElement>(null);

  async function handleSubmit(values: Subscription) {
    try {
      await validateFields();
      onSubmit(values);
    } catch {}
  }

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const {
      configurationName,
      subscriptionID,
      resourceGroup,
      region,
      servicePrincipalCredentials,
      network,
      vnName,
      subnet1,
      subnet2,
      subnet3,
      subnet4,
      subnet5,
    } = form.getFieldsValue();
    if (
      configurationName.length < 3 ||
      subscriptionID.length < 3 ||
      resourceGroup.length < 3 ||
      !region ||
      resourceGroup.length < 3 ||
      !servicePrincipalCredentials ||
      !isJsonString(servicePrincipalCredentials) ||
      (network &&
        (!vnName || !subnet1 || !subnet2 || !subnet3 || !subnet4 || !subnet5))
    ) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  function handleEnvironmentNameChange(e: React.ChangeEvent<HTMLInputElement>) {
    setTimeout(() => {
      const configurationNameErrors = getFieldError(
        consentFormFieldNames.environmentName,
      );
      if (configurationNameErrors?.length) {
        setEnvironmentName(defaultEnvironmentName);
      } else {
        setEnvironmentName(e.target.value);
      }
    }, 500);
  }

  function handleChangeSubscriptionID(e: React.ChangeEvent<HTMLInputElement>) {
    setTimeout(() => {
      const subscriptionIDErrors = getFieldError(
        consentFormFieldNames.subscriptionID,
      );
      if (subscriptionIDErrors?.length) {
        setSubscriptionId(defaultSubscriptionId);
      } else {
        setSubscriptionId(e.target.value);
      }
    }, 500);
  }

  function handleChangeResourceGroupName(
    e: React.ChangeEvent<HTMLInputElement>,
  ) {
    if (!e.target.value.length) {
      setResourceGroupName(defaultResourceGroupName);
    } else {
      setResourceGroupName(e.target.value);
    }
  }

  function copyAzureCommandToClipboard(e: React.MouseEvent<HTMLSpanElement>) {
    if (!isCopyButtonDisabled) {
      copy(azureCommandRef?.current?.innerText || '');
      notification.success({
        message: intl.formatMessage({
          id: 'consentFlow.azureCommandCopySuccessful',
        }),
      });
    }
  }

  function handleChangeNetwork(e: RadioChangeEvent) {
    if (e.target.value) {
      setShowExistingNetworkFields(true);
    } else {
      setShowExistingNetworkFields(false);
    }
  }

  useEffect(() => {
    if (
      environmentName === defaultEnvironmentName ||
      subscriptionId === defaultSubscriptionId ||
      resourceGroupName === defaultResourceGroupName
    ) {
      setIsCopyButtonDisabled(true);
    } else {
      setIsCopyButtonDisabled(false);
    }
  }, [
    defaultEnvironmentName,
    defaultResourceGroupName,
    defaultSubscriptionId,
    environmentName,
    resourceGroupName,
    subscriptionId,
  ]);

  return (
    <Form
      form={form}
      layout="vertical"
      className="consent-flow-details"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
    >
      <Row justify="center">
        <Col xs={24} lg={12} xxl={10}>
          <Form.Item
            name={consentFormFieldNames.environmentName}
            initialValue=""
            rules={envNameRules as Rule[]}
            className="cluster-input-with-hint"
            label={
              <>
                <FormattedMessage id="consentFlow.envName" />{' '}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'consentFlow.envNameTooltip',
                  })}
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'consentFlow.envNamePlaceholder',
              })}
              onChange={handleEnvironmentNameChange}
            />
          </Form.Item>
          <Form.Item
            name={consentFormFieldNames.subscriptionID}
            initialValue=""
            rules={subIDRules as Rule[]}
            className="cluster-input-with-hint"
            label={<FormattedMessage id="consentFlow.azureSubID" />}
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'consentFlow.azureSubIDPlaceholder',
              })}
              onChange={handleChangeSubscriptionID}
            />
          </Form.Item>
          <Form.Item
            name={consentFormFieldNames.resourceGroup}
            initialValue=""
            rules={resourceGroupNameRules as Rule[]}
            className="cluster-input-with-hint"
            label={
              <>
                <FormattedMessage id="consentFlow.azureRG" />{' '}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'consentFlow.azureRGTooltip',
                  })}
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Input
              placeholder={intl.formatMessage({
                id: 'consentFlow.azureRGPlaceholder',
              })}
              onChange={handleChangeResourceGroupName}
            />
          </Form.Item>
          <Form.Item
            name={consentFormFieldNames.region}
            rules={resourceRegionSelectionRules as Rule[]}
            className="cluster-input-with-hint"
            label={
              <FormattedMessage id="consentFlow.resourceRegionSelection" />
            }
          >
            <Select
              placeholder={intl.formatMessage({
                id: 'consentFlow.resourceRegionSelectionPlaceholder',
              })}
              loading={areRegionsLoading}
              disabled={areRegionsLoading || hasRegionsErrors}
            >
              {regions?.map((region: string) => (
                <Select.Option key={region} value={region}>
                  {region}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            className="cluster-input-with-hint consent-flow-details__azure-command-subheader"
            label={
              <>
                {intl.formatMessage({ id: 'consentFlow.commandRequiredGoTo' })}
                <a
                  href={azureCloudShellUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage({ id: 'consentFlow.azureCloudShell' })}
                </a>
                {intl.formatMessage({ id: 'consentFlow.commandRequiredRun' })}
                <Tooltip
                  title={
                    <FormattedMessage
                      id="consentFlow.commandRequiredTooltip"
                      values={{
                        azureCloudShell: (
                          <a
                            href={azureCloudShellUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FormattedMessage id="consentFlow.azureCloudShell" />
                          </a>
                        ),
                      }}
                    />
                  }
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
          ></Form.Item>
          <Form.Item
            className="cluster-test"
            label={
              <div className="consent-flow-details__azure-command">
                <span ref={azureCommandRef}>
                  {intl
                    .formatMessage({
                      id: 'consentFlow.spCommand',
                    })
                    .replace('<Environment name>', environmentName)
                    .replace('<subscription_id>', subscriptionId)
                    .replace('<resourcegp_name>', resourceGroupName)}
                </span>
                <Tooltip
                  title={
                    isCopyButtonDisabled
                      ? intl.formatMessage({
                          id: 'consentFlow.azureCommandCantCopyTooltip',
                        })
                      : intl.formatMessage({
                          id: 'consentFlow.azureCommandCopyTooltip',
                        })
                  }
                >
                  <CopyOutlined
                    onClick={copyAzureCommandToClipboard}
                    className="consent-flow-details__copy-azure-command"
                    disabled={isCopyButtonDisabled}
                  />
                </Tooltip>
              </div>
            }
          ></Form.Item>
          <Form.Item
            name={consentFormFieldNames.servicePrincipalCredentials}
            initialValue=""
            rules={commandRules as Rule[]}
            className="cluster-input-with-hint"
            label={<FormattedMessage id="consentFlow.subCredRequired" />}
          >
            <TextArea
              placeholder={intl.formatMessage({
                id: 'consentFlow.azureSubscriptionInfoOutputPlaceholder',
              })}
              rows={6}
            />
          </Form.Item>
          <Form.Item
            name={consentFormFieldNames.network}
            initialValue={false}
            className="cluster-input-with-hint consent-flow-details__network-radio"
            label={
              <>
                <FormattedMessage id="consentFlow.networks" />{' '}
                <Tooltip
                  title={intl.formatMessage({
                    id: 'consentFlow.networksTooltip',
                  })}
                  placement="right"
                >
                  <InfoCircleOutlined />
                </Tooltip>
              </>
            }
          >
            <Radio.Group onChange={handleChangeNetwork}>
              <Radio value={false}>
                <FormattedMessage id="consentFlow.newNetwork" />
              </Radio>
              <Radio value={true}>
                <FormattedMessage id="consentFlow.existingNetwork" />
              </Radio>
            </Radio.Group>
          </Form.Item>
          {showExistingNetworkFields && (
            <div className="consent-flow-details__network-subnets">
              <Form.Item
                name={consentFormFieldNames.networkName}
                initialValue=""
                rules={networkNameRules as Rule[]}
                className="cluster-input-with-hint"
                label={
                  <>
                    <FormattedMessage id="consentFlow.networkName" />{' '}
                    <Tooltip
                      title={intl.formatMessage({
                        id: 'consentFlow.networkNameTooltip',
                      })}
                      placement="right"
                    >
                      <InfoCircleOutlined />
                    </Tooltip>
                  </>
                }
              >
                <Input
                  placeholder={intl.formatMessage({
                    id: 'consentFlow.networkName',
                  })}
                />
              </Form.Item>
              <Form.Item
                rules={networkSubnetNameRules as Rule[]}
                className="cluster-input-with-hint consent-flow-details__add-network-subnets-label"
                label={
                  <FormattedMessage id="consentFlow.addYourNetworkSubnets" />
                }
              ></Form.Item>
              <Form.Item
                name={consentFormFieldNames.subnet1}
                initialValue=""
                rules={networkSubnetRules as Rule[]}
                className="consent-flow-details__subnet-form-item"
              >
                <Input
                  addonBefore={
                    <FormattedMessage id="consentFlow.subnets.incortaSubnet" />
                  }
                  placeholder={`${intl.formatMessage({
                    id: 'consentFlow.subnets.subnetPlaceholderPrefix',
                  })}${intl.formatMessage({
                    id: 'consentFlow.subnets.incortaSubnetPlaceholder',
                  })}`}
                />
              </Form.Item>
              <Form.Item
                name={consentFormFieldNames.subnet2}
                initialValue=""
                rules={networkSubnetRules as Rule[]}
                className="consent-flow-details__subnet-form-item"
              >
                <Input
                  addonBefore={
                    <FormattedMessage id="consentFlow.subnets.sparkSubnet" />
                  }
                  placeholder={`${intl.formatMessage({
                    id: 'consentFlow.subnets.subnetPlaceholderPrefix',
                  })}${intl.formatMessage({
                    id: 'consentFlow.subnets.sparkSubnetPlaceholder',
                  })}`}
                />
              </Form.Item>
              <Form.Item
                name={consentFormFieldNames.subnet3}
                initialValue=""
                rules={networkSubnetRules as Rule[]}
                className="consent-flow-details__subnet-form-item"
              >
                <Input
                  addonBefore={
                    <FormattedMessage id="consentFlow.subnets.publicSubnet" />
                  }
                  placeholder={`${intl.formatMessage({
                    id: 'consentFlow.subnets.subnetPlaceholderPrefix',
                  })}${intl.formatMessage({
                    id: 'consentFlow.subnets.publicSubnetPlaceholder',
                  })}`}
                />
              </Form.Item>
              <Form.Item
                name={consentFormFieldNames.subnet4}
                initialValue=""
                rules={networkSubnetRules as Rule[]}
                className="consent-flow-details__subnet-form-item"
              >
                <Input
                  addonBefore={
                    <FormattedMessage id="consentFlow.subnets.databaseSubnet" />
                  }
                  placeholder={`${intl.formatMessage({
                    id: 'consentFlow.subnets.subnetPlaceholderPrefix',
                  })}${intl.formatMessage({
                    id: 'consentFlow.subnets.databaseSubnetPlaceholder',
                  })}`}
                />
              </Form.Item>
              <Form.Item
                name={consentFormFieldNames.subnet5}
                initialValue=""
                rules={networkSubnetRules as Rule[]}
                className="consent-flow-details__subnet-form-item"
              >
                <Input
                  addonBefore={
                    <FormattedMessage id="consentFlow.subnets.loadbalancerSubnet" />
                  }
                  placeholder={`${intl.formatMessage({
                    id: 'consentFlow.subnets.subnetPlaceholderPrefix',
                  })}${intl.formatMessage({
                    id: 'consentFlow.subnets.loadbalancerSubnetPlaceholder',
                  })}`}
                />
              </Form.Item>
            </div>
          )}
          <Divider />
          <Form.Item className="consent-flow-details__actions">
            <Button
              htmlType="button"
              onClick={() => {
                if (lastLocation) {
                  history.push(lastLocation);
                } else {
                  history.push('/');
                }
              }}
            >
              <FormattedMessage id="consentFlow.cancelButton" />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={
                isSubmitButtonDisabled ||
                form.getFieldsError().some(err => err.errors.length)
              }
              loading={loading}
              className="consent-flow-details__submit-button"
            >
              <FormattedMessage id="consentFlow.submitButton" />
            </Button>
          </Form.Item>
        </Col>
      </Row>
      {(loading || result) && (
        <SubmittingStatus
          loading={loading}
          result={result}
          isRejected={isRejected}
          errorMessage={errorMessage}
        />
      )}
    </Form>
  );
}

export default ConsentFlowDetails;
