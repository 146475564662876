import React from 'react';
import './ScheduledEventSummaryStep.less';
import { Col, Row } from 'antd';
import Title from 'antd/lib/typography/Title';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { scheduledEventDateFormat } from '../../../../../../../utils/constants';
import { ScheduledEventEnum } from 'types/cluster';
import useClusterSchedulingHelpers from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/useClusterSchedulingHelpers';

type ScheduledEventSummaryStepProps = {
  currentFormValues: Record<any, any>;
};
function ScheduledEventSummaryStep({
  currentFormValues,
}: ScheduledEventSummaryStepProps) {
  const {
    event,
    startTime,
    endTime,
    eventInfoAnalyticsReplicas,
    eventInfoLoaderReplicas,
    eventResult
  } = currentFormValues;

  const { availableSchedulingActions } = useClusterSchedulingHelpers();
  const isScaling = [
    ScheduledEventEnum.SCALE_UP,
    ScheduledEventEnum.SCALE_DOWN,
  ].includes(event as ScheduledEventEnum);

  return (
    <>
      <Title level={3} className="scheduled-event-summary-step__title">
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepTitle" />
      </Title>
      <Row>
        <Col span={8}>
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepAction" />
        </Col>
        <Col span={16} className="scheduled-event-summary-step__event">
          {availableSchedulingActions[event as ScheduledEventEnum].icon}
          {availableSchedulingActions[event as ScheduledEventEnum].label}
        </Col>
      </Row>
      <Row>
        <Col span={8}>
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepStartTime" />
        </Col>
        <Col span={16}>
          {moment(startTime).format(scheduledEventDateFormat)}
        </Col>
      </Row>
      {eventResult && <Row>
        <Col span={8}>
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepResult" />
        </Col>
        <Col span={16} className="scheduled-event-summary-step__event">
          {eventResult}
        </Col>
      </Row>}
      {endTime && (
        <Row>
          <Col span={8}>
            <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepEndTime" />
          </Col>
          <Col span={16}>
            {moment(endTime).format(scheduledEventDateFormat)}
          </Col>
        </Row>
      )}
      {isScaling && (
        <section className="scheduled-event-summary-step__scaling-details">
          <Title
            level={4}
            className="scheduled-event-summary-step__scaling-details-header"
          >
            {availableSchedulingActions[event as ScheduledEventEnum].label}{' '}
            <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepDetails" />
          </Title>
          {!!eventInfoAnalyticsReplicas && (
            <Row>
              <Col span={8}>
                <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepAnalyticsReplicas" />
              </Col>
              <Col span={16}>{eventInfoAnalyticsReplicas}</Col>
            </Row>
          )}
          {!!eventInfoLoaderReplicas && (
            <Row>
              <Col span={8}>
                <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.summaryStepLoaderReplicas" />
              </Col>
              <Col span={16}>{eventInfoLoaderReplicas}</Col>
            </Row>
          )}
        </section>
      )}
    </>
  );
}

export default ScheduledEventSummaryStep;
