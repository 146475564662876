import './ConsumptionFilter.less';

import React, { useContext, useMemo } from 'react';
import { DatePicker, Form, Select } from 'antd';
import moment from 'moment';
import { ConsumptionFiltersOptions } from 'components/Consumption/Consumption';
import { getClustersConsumption, getTimeRangeDates } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';

interface ConsumptionFilterProps {
  filters: ConsumptionFiltersOptions;
  setFilters: React.Dispatch<React.SetStateAction<ConsumptionFiltersOptions>>;
  isLoading: boolean;
  clustersFormatted?: ReturnType<typeof getClustersConsumption>;
}

function ConsumptionFilter({
  filters,
  setFilters,
  isLoading,
  clustersFormatted,
}: ConsumptionFilterProps) {
  const { user } = useContext(SessionContext);
  return (
    <div className="ConsumptionFilter">
      <Form layout="vertical">
        <Form.Item>
          <Select
            style={{ width: 150 }}
            value={filters.timeRange.type}
            onChange={timeRange => {
              if (timeRange === 'custom') {
                let { start, end } = getTimeRangeDates(filters.timeRange);
                setFilters(filters => ({
                  ...filters,
                  timeRange: { type: 'custom', from: start, to: end },
                  timeRangeChanged: true,
                }));
              } else {
                setFilters(filters => ({
                  ...filters,
                  timeRange: { type: timeRange },
                  timeRangeChanged: true,
                }));
              }
            }}
          >
            <Select.Option value="month">Current Month</Select.Option>
            <Select.Option
              style={{
                borderBottom: '1px solid #EEF0F2',
              }}
              value="last-month"
            >
              Last Month
            </Select.Option>
            <Select.Option value="year">Current Year</Select.Option>
            <Select.Option
              style={{
                borderBottom: '1px solid #EEF0F2',
              }}
              value="last-year"
            >
              Last Year
            </Select.Option>
            <Select.Option value="custom">Custom Range</Select.Option>
          </Select>
        </Form.Item>

        {filters.timeRange.type === 'custom' && (
          <>
            <Form.Item>
              <DatePicker.RangePicker
                className="ConsumptionFilter__range-picker"
                style={{ width: '100%' }}
                // format="D MMM yyyy"
                value={[
                  moment(filters.timeRange.from),
                  moment(filters.timeRange.to),
                ]}
                onChange={date => {
                  if (!date) {
                    return;
                  }
                  let [from, to] = date;
                  setFilters(f => {
                    if (!(from && to)) {
                      return f;
                    }
                    return {
                      ...f,
                      timeRange: {
                        ...f.timeRange,
                        from: from.toDate(),
                        to: to.toDate(),
                      },
                    };
                  });
                }}
                disabledDate={date => {
                  return (
                    date?.isAfter(moment().toDate()) ||
                    date?.isBefore(moment(user?.createdAt).startOf('month')) ||
                    false
                  );
                }}
              />
            </Form.Item>
          </>
        )}

        <ClusterSelect
          filters={filters}
          setFilters={setFilters}
          isLoading={isLoading}
          clustersFormatted={clustersFormatted}
        />
      </Form>
    </div>
  );
}

interface ClusterSelectProps {
  filters: ConsumptionFiltersOptions;
  setFilters: React.Dispatch<React.SetStateAction<ConsumptionFiltersOptions>>;
  isLoading: boolean;
  clustersFormatted?: ReturnType<typeof getClustersConsumption>;
}
function ClusterSelect({
  filters,
  setFilters,
  isLoading,
  clustersFormatted,
}: ClusterSelectProps) {
  const options = useMemo(() => {
    return clustersFormatted?.map(cluster => ({
      value: cluster.id,
      label: cluster.name,
    }));
  }, [clustersFormatted]);

  return (
    <Form.Item className="ConsumptionFilter__clusters-selection">
      <Select
        key={filters.selectedClusters?.length}
        loading={isLoading}
        placeholder="Select clusters"
        mode="multiple"
        notFoundContent={null}
        defaultValue={filters.selectedClusters}
        labelInValue
        optionFilterProp="label"
        onChange={(values: { label: string; value: string }[]) => {
          setFilters(f => ({ ...f, selectedClusters: values }));
        }}
        options={options}
      ></Select>
    </Form.Item>
  );
}

export default ConsumptionFilter;
