/* eslint-disable no-sequences */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { User } from '@auth0/auth0-react';
import ErrorWrapper from './components/ErrorWrapper/ErrorWrapper';
import ScriptWrapper from './components/ScriptWrapper/ScriptWrapper';
import * as serviceWorker from 'serviceWorker';
import AppRoutes from 'components/AppRoutes/AppRoutes';
import { flattenMessages } from 'utils/locale';
import locale from 'locale';
import { AppStateProvider } from 'contexts/AppState';

import './externalScripts.js';

import 'echarts/lib/echarts';
import 'echarts/lib/component/tooltip';
import 'echarts/lib/component/dataZoom';
import 'echarts/lib/component/legend';
import 'echarts/lib/chart/line';
import 'echarts/lib/chart/bar';

import 'styles/index.less';
import Auth0ProviderWithRedirectCallback from 'auth/auth0Provider';
import LastLocation from 'contexts/LastLocation';
import { SessionProvider } from 'auth/SessionProvider';
import UserConsentOverlay from 'components/UserConsentOverlay/UserConsentOverlay';

type MyWindow = typeof window & {
  drift: any;
  driftt: any;
  qualified: any;
};

export const queryClient = new QueryClient();

function App() {
  const [user, setUser] = useState<User>();
  const [userConsent, setUserConsent] = useState<boolean>(false);
  const [chatIsShown, setChatIsShown] = useState(false);

  useEffect(() => {
    setChatIsShown(!!(user && userConsent));
  }, [user, userConsent]);

  const enableQualifiedAndDriftChat = () => {
    document.addEventListener('QUALIFIED_LOADED', function hideChat(event) {
      if ((window as MyWindow).qualified != null) {
        (window as MyWindow).qualified(
          'handleMessengerEvents',
          function (eventName: any) {
            if (eventName === 'messengerClosed') {
              document.dispatchEvent(new CustomEvent('INC_CHAT_CLOSED'));
            }
          },
        );
      }
    });

    document.addEventListener('SESSION_LOGGED_IN', function (event: any) {
      if (event.detail == null || (window as MyWindow).qualified == null)
        return;
      (window as MyWindow).qualified?.('endExperience');
      (window as MyWindow).qualified?.('identify', {
        email: event.detail.user.email,
      });
    });

    document.addEventListener('SESSION_LOGGED_OUT', function () {
      if ((window as MyWindow).qualified == null) return;

      (window as MyWindow).qualified?.('endExperience');
    });

    (function (w: any, q: any) {
      w['QualifiedObject'] = q;
      w[q] =
        w[q] ||
        function () {
          (w[q].q = w[q].q || []).push(arguments);
        };
    })(window, 'qualified');
    var o: any = document.createElement('script');
    (o.type = 'text/javascript'),
      (o.async = !0),
      (o.crossorigin = 'anonymous'),
      (o.src = 'https://js.qualified.com/qualified.js?token=bkbvMJ6hnmQVJaVn');
    o.onload = function () {
      document.dispatchEvent(new CustomEvent('QUALIFIED_LOADED'));
    };
    document.head.appendChild(o);
    var i = document.getElementsByTagName('script')[0];
    i.parentNode?.insertBefore(o, i);
  };

  const enableChat = () => {
    enableQualifiedAndDriftChat();
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const el = document.querySelector('iframe#q-messenger-frame');
      if (el !== null) {
        const chatIframe = document.getElementsByClassName(
          'show-qualified-chat',
        );

        if (chatIsShown && window.location.pathname !== '/signup') {
          if (chatIframe.length === 0) el.classList.add('show-qualified-chat');
        } else {
          el.classList.remove('show-qualified-chat');
        }
        clearInterval(interval);
      }
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [chatIsShown]);

  const showChat = (value: boolean) => {
    setChatIsShown(value);
    if (
      window.location.pathname === '/signup' ||
      window.location.pathname === '/signin'
    ) {
      (window as MyWindow).qualified(
        'showExperience',
        'experience-1645200217227',
      );
    } else if (window.location.pathname === 'account-created') {
      (window as MyWindow).qualified(
        'showExperience',
        'experience-1645200217227',
      );
    }
  };

  return (
    <ErrorWrapper>
      <ScriptWrapper script={enableChat}>
        <QueryClientProvider client={queryClient}>
          <IntlProvider
            locale="en-US"
            messages={flattenMessages(locale['en-US'])}
          >
            <BrowserRouter>
              <LastLocation>
                <Auth0ProviderWithRedirectCallback>
                  <SessionProvider setUser={setUser}>
                    <UserConsentOverlay setUserConsent={setUserConsent} />
                    <AppStateProvider setChatIsShown={showChat}>
                      <AppRoutes />
                    </AppStateProvider>
                  </SessionProvider>
                </Auth0ProviderWithRedirectCallback>
              </LastLocation>
            </BrowserRouter>
          </IntlProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ScriptWrapper>
    </ErrorWrapper>
  );
}

render(<App />, document.getElementById('root'));

serviceWorker.unregister();
