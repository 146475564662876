export const QueryServerKeys = {
  USER: {
    GET_USER_DATA: 'me',
    AUTH_USERS_KEY: `auth-users-key`,
    CONSUMPTION_INFO_KEY: 'consumption-info-list',
    SUSPEND_TIMES_KEY: 'suspend-times',
    SUPPORT: 'send-support',
  },
  CLUSTER: {
    CLUSTER_LIST_KEY: 'cluster-list',
    CLUSTER_LIST_KEY_PULLING: 'cluster-list-pulling',
    CUSTER_WHITE_LIST: 'custer-white-list-key',
    OPERATORS_KEY: 'operators',
    CREATE_CLUSTER_KEY: 'create-cluster-key',
    GET_INCORTA_IMAGES_KEY: 'get-incorta-images-key',
    CLUSTER_SIZE_TYPES_KEY: 'cluster-size-types',
    SUPPORT_TOKEN_KEY: 'support-token',
    ACCEPT_USER_CLUSTER: 'accept-user-cluster',
    GET_SERVICES_STATUS: 'get-services-status',
    UPGRADE_VERSION: 'upgrade-cluster-version',
    UPGRADE_DISCONNECTED: 'upgrade-cluster-version-disconnected',
    AUTO_SCALE_HISTORY: 'auto-scale-history',
    SYNC_PYTHON_PACKAGES: 'sync-python-packages',
    SCHEDULED_EVENTS: 'scheduled-events',
  },
  TENANT: {
    TENANT_LIST: 'tenant-list',
  },
  BLUEPRINT: {
    BLUEPRINT_LIST: 'blueprint-list',
  },
  AZURE: {
    CREATE_SUBSCRIPTION_KEY: 'create-subscription-key',
    PIPELINE_KEY: 'pipeline-key',
    RESOURCES_REGIONS: 'get-resources-regions',
  },
};
