import React, { useEffect } from 'react';

interface ScriptWrapperProps {
  script: Function;
  children: any;
}
function ScriptWrapper({
  children,
  script,
}: React.PropsWithChildren<ScriptWrapperProps>) {
  useEffect(() => {
    script();
    //eslint-disable-next-line
  }, []);

  return <div className="script-container">{children}</div>;
}

export default ScriptWrapper;
