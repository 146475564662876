import {
  clusterCreationStatus,
  hasPipelineReachedLastStep,
} from 'components/Clusters/ClusterCard/AzureCreation/azureClusterCreationHelper';
import { AzureClusterPipelineData } from 'types/cluster';

interface useAzurePipelineProps {
  azureClusterPipelineData: AzureClusterPipelineData | undefined;
}
function useAzurePipeline({ azureClusterPipelineData }: useAzurePipelineProps) {
  const isNotConfiguredYet =
    azureClusterPipelineData?.pipelineStatus === null ? true : false;
  const isLastStep =
    hasPipelineReachedLastStep({
      currentStep: (azureClusterPipelineData as AzureClusterPipelineData)
        ?.pipelineStatus?.stageName,
      states: (azureClusterPipelineData as AzureClusterPipelineData)?.states,
    }) || false;
  const isStatusSuccessful =
    azureClusterPipelineData?.pipelineStatus?.state ===
      clusterCreationStatus.COMPLETED || false;
  const hasError =
    azureClusterPipelineData?.pipelineStatus?.state ===
      clusterCreationStatus.FAILED || false;
  const isCanceled =
    azureClusterPipelineData?.pipelineStatus?.state ===
      clusterCreationStatus.CANCELED || false;
  const shouldTimerPause =
    ([
      clusterCreationStatus.FAILED,
      clusterCreationStatus.CANCELED,
      clusterCreationStatus.COMPLETED,
    ].includes(
      azureClusterPipelineData?.pipelineStatus?.state as clusterCreationStatus,
    ) &&
      isLastStep) ||
    false;
  const isAzurePipelineDone = (isLastStep && isStatusSuccessful) || false;
  return {
    isNotConfiguredYet,
    isLastStep,
    isStatusSuccessful,
    hasError,
    isCanceled,
    shouldTimerPause,
    isAzurePipelineDone,
  };
}

export default useAzurePipeline;
