export function validatePasswordField(password: string) {
  let isMinCharsMatched = false;
  let hasLowerCase = false;
  let hasUpperCase = false;
  let hasNumbers = false;
  let hasSpecialChars = false;

  if (password.length >= 12) {
    isMinCharsMatched = true;
  }
  if (/(.*[a-z].*)/.test(password)) {
    hasLowerCase = true;
  }
  if (/(.*[A-Z].*)/.test(password)) {
    hasUpperCase = true;
  }
  if (/(.*\d.*)/.test(password)) {
    hasNumbers = true;
  }
  if (/(.*\W.*)/.test(password)) {
    hasSpecialChars = true;
  }

  const isPasswordValid =
    isMinCharsMatched &&
    hasLowerCase &&
    hasUpperCase &&
    hasNumbers &&
    hasSpecialChars;

  return {
    isPasswordValid,
    isMinCharsMatched,
    hasLowerCase,
    hasUpperCase,
    hasNumbers,
    hasSpecialChars,
  };
}
