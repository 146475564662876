import React, { useContext } from 'react';
import cookie from 'js-cookie';
import { Button } from 'antd';
import { SessionContext } from 'auth/SessionProvider';
import { clearUserIdentityForAllTrackingTools } from 'services/tracking';

const LogoutButton = () => {
  const { logout } = useContext(SessionContext);

  function handleLogout() {
    logout({ returnTo: window.location.origin });
    cookie.remove('cloudSSO', { domain: process.env.REACT_APP_DOMAIN });
    localStorage.removeItem('firstLogin');
    // reset identity for tracking tools
    clearUserIdentityForAllTrackingTools();
  }

  return (
    <Button block onClick={handleLogout}>
      Log Out
    </Button>
  );
};

export default LogoutButton;
