import React from 'react';
import './PasswordCriteriaPopover.less';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { validatePasswordField } from 'components/ProvisionUserModal/helper';

type Props = {
  password: string;
};
function PasswordCriteriaPopover(props: Props) {
  const {
    isMinCharsMatched,
    hasLowerCase,
    hasUpperCase,
    hasNumbers,
    hasSpecialChars,
  } = validatePasswordField(props.password);

  return (
    <section className="password-constrains__wrapper">
      <div className="checked-password__label constrain__lower-case">
        <section className="validation-icon">
          {hasLowerCase ? (
            <CheckOutlined width={8} height={8} className="success-icon" />
          ) : (
            <CloseOutlined width={8} height={8} className="failure-icon" />
          )}
        </section>
        <label>
          {' '}
          <FormattedMessage id="provisionUserModal.passwordCriteria.minimumLowercaseLetter" />
        </label>
      </div>
      <div className="checked-password__label constrain__upper-case">
        <section className="validation-icon">
          {hasUpperCase ? (
            <CheckOutlined width={8} height={8} className="success-icon" />
          ) : (
            <CloseOutlined width={8} height={8} className="failure-icon" />
          )}
        </section>
        <label>
          {' '}
          <FormattedMessage id="provisionUserModal.passwordCriteria.minimumUppercaseLetter" />
        </label>
      </div>
      <div className="checked-password__label constrain__numbers">
        <section className="validation-icon">
          {/* <RightOutlined width={9} height={13} className="right-arrow-icon" /> */}
          {hasNumbers ? (
            <CheckOutlined width={8} height={8} className="success-icon" />
          ) : (
            <CloseOutlined width={8} height={8} className="failure-icon" />
          )}
        </section>
        <label>
          {' '}
          <FormattedMessage id="provisionUserModal.passwordCriteria.minimumNumber" />
        </label>
      </div>
      <div className="checked-password__label constrain__special-chars">
        <section className="validation-icon">
          {/* <RightOutlined width={9} height={13} className="right-arrow-icon" /> */}
          {hasSpecialChars ? (
            <CheckOutlined width={8} height={8} className="success-icon" />
          ) : (
            <CloseOutlined width={8} height={8} className="failure-icon" />
          )}
        </section>
        <label>
          {' '}
          <FormattedMessage id="provisionUserModal.passwordCriteria.minimumSpecialCharacter" />
        </label>
      </div>
      <div className="checked-password__label constrain__eight-chars">
        <section className="validation-icon">
          {isMinCharsMatched ? (
            <CheckOutlined width={8} height={8} className="success-icon" />
          ) : (
            <CloseOutlined width={8} height={8} className="failure-icon" />
          )}
        </section>
        <label>
          {' '}
          <FormattedMessage id="provisionUserModal.passwordCriteria.minimumCharacters" />
        </label>
      </div>
    </section>
  );
}

export default PasswordCriteriaPopover;
