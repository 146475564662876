import './IncortaLayoutContainer.less';

import React, { ReactNode, useContext } from 'react';
import { Button, Layout, Space } from 'antd';
import { GiftOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import AppNav from 'components/AppNav/AppNav';
import AppFooter from 'components/AppFooter/AppFooter';
import { useAppState } from 'contexts/AppState';
import { SessionContext } from 'auth/SessionProvider';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';

const { Content } = Layout;

const BannerHight = 48;

interface IncortaLayoutContainerProps {
  children?: ReactNode;
}

function IncortaLayoutContainer({ children }: IncortaLayoutContainerProps) {
  let { showUpgradeToPremiumBanner, setShowUpgradeToPremiumBanner } =
    useAppState();

  let { user } = useContext(SessionContext);

  const { isToggleFeatureEnabled: paymentIsEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.payment,
  });

  let bannerUI = paymentIsEnabled && showUpgradeToPremiumBanner && (
    <div className="incorta-layout-container__banner">
      <div className="truncate" style={{ marginRight: 8 }}>
        <GiftOutlined /> Upgrade To Premium and Receive $500 worth of free
        usage.
      </div>
      <Space>
        <Button
          onClick={() => setShowUpgradeToPremiumBanner(false)}
          className="btn-small"
          size="small"
        >
          Dismiss
        </Button>
        <Link to={`/plans`}>
          <Button className="btn-small" size="small" type="primary">
            Upgrade
          </Button>
        </Link>
      </Space>
    </div>
  );

  return (
    <div>
      <Layout
        className="incorta-layout-container"
        style={{
          // @ts-ignore
          '--hight':
            paymentIsEnabled && showUpgradeToPremiumBanner
              ? `${BannerHight}px`
              : '0px',
        }}
      >
        {bannerUI}
        <div className="incorta-layout-container__page">
          <AppNav />
          <Content className="incorta-layout-container__content">
            {children}
          </Content>
        </div>
        <AppFooter />
      </Layout>
    </div>
  );
}

export default IncortaLayoutContainer;
