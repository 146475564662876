import './ConsumptionChart.less';

import React from 'react';

import { ChartType, MeasureType } from '../ConsumptionBody';
import UnitsChart from 'components/UnitsChart/UnitsChart';
import { getClustersConsumption } from 'utils/cluster';

interface ConsumptionChartProps {
  filteredClusters?: ReturnType<typeof getClustersConsumption>;
  chartType: ChartType;
  measureType?: MeasureType;
}

function ConsumptionChart({
  filteredClusters,
  chartType,
  measureType = 'daily',
}: ConsumptionChartProps) {
  if (!filteredClusters) {
    return null;
  }
  let tableData = filteredClusters?.[0]?.consumption?.flatMap(con => con.days);

  return (
    <div className="ConsumptionChart" style={{}}>
      {tableData ? (
        <UnitsChart
          measureType={measureType}
          filteredClusters={filteredClusters}
          chartType={chartType}
          grid={{
            left: '3%',
            right: '4%',
            top: '30px',
            // bottom: '3%',
            // containLabel: true,
          }}
        />
      ) : null}
    </div>
  );
}

export default ConsumptionChart;
