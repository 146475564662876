import React, { ReactElement } from 'react';
import './DisconnectedClusterPlaceholder.less';
import { MinusCircleOutlined } from '@ant-design/icons';

type DisconnectedClusterPlaceholderProps = {
  title: string | ReactElement;
  description: string | ReactElement;
};
function DisconnectedClusterPlaceholder({
  title,
  description,
}: DisconnectedClusterPlaceholderProps) {
  return (
    <div className="disconnected-cluster-placeholder__wrapper">
      <MinusCircleOutlined className="disconnected-cluster-placeholder__icon" />
      <div className="disconnected-cluster-placeholder__title">{title}</div>
      <div className="disconnected-cluster-placeholder__description">
        {description}
      </div>
    </div>
  );
}

export default DisconnectedClusterPlaceholder;
