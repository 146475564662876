import './BlueprintsBody.less';

import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  SearchOutlined,
  CloseOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { Input, Modal, Spin, Button, Typography } from 'antd';
import { useLocation, useParams } from 'react-router-dom';
import BlueprintList from '../BlueprintList/BlueprintList';
import BlueprintFilter from '../BlueprintFilter/BlueprintFilter';
import BlueprintInstallModal from '../BlueprintInstallModal/BlueprintInstallModal';
import useRequest from 'hooks/useRequest';
import { getBlueprints, getValidBlueprintClusters } from 'services/cluster';
import { BlueprintItem } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';

let { Paragraph } = Typography;
export interface FilterType {
  search: string;
  dataSources: string[];
  types: string[];
  businessFunctions: string[];
}

interface LocationState {
  failedBlueprintsIds?: number[];
}

function BlueprintsBody() {
  const { user } = useContext(SessionContext);
  let [isLoading, setIsLoading] = useState(false);

  let { state } = useLocation<LocationState>();
  const { blueprintName } = useParams<any>();

  let { failedBlueprintsIds } = state ?? {};
  const [blueprintsArray, setBlueprintsArray] = useState([]);
  const [businessFunctions, setBusinessFunctions] = useState([]);
  const [dataSources, setDataSources] = useState([]);
  const [types, setTypes] = useState([]);
  const [slug, setSlug] = useState('');
  let [showModal, setShowModal] = useState(false);
  let [filters, setFilters] = useState<FilterType>({
    search: '',
    dataSources: [],
    types: [],
    businessFunctions: [],
  });
  let [selectedBlueprintNames, setSelectedBlueprintNames] = useState<string[]>(
    [],
  );
  let [requestValidBlueprintLoader, setRequestValidBlueprintLoader] =
    useState(false);

  let { result: blueprintsResult } = useRequest(getBlueprints, {
    resolve: true,
    useCache: true,
  });

  let {
    makeRequest: requestValidBlueprint,
    result: validBlueprintClusterResult,
  } = useRequest(
    useCallback(
      () => getValidBlueprintClusters({ userId: user!.uuid }),
      [user],
    ),
    { handleError: true },
  );

  useEffect(() => {
    if (blueprintsResult?.data?.blueprints) {
      let { businessFunctions, dataSources, types, blueprintsArray }: any =
        blueprintsResult?.data?.blueprints?.reduce(
          (blueprints: any, curBlueprint: any) => {
            blueprints.businessFunctions.add(curBlueprint.businessFunction);
            blueprints.types.add(curBlueprint.type);
            blueprints.dataSources.add(curBlueprint.blueprintCategory?.name);
            blueprints.blueprintsArray.push(curBlueprint);

            return {
              businessFunctions: blueprints.businessFunctions,
              dataSources: blueprints.dataSources,
              types: blueprints.types,
              blueprintsArray: blueprints.blueprintsArray,
            };
          },
          {
            businessFunctions: new Set(),
            dataSources: new Set(),
            types: new Set(),
            blueprintsArray: [],
          },
        );
      setBusinessFunctions(Array.from(businessFunctions));
      setDataSources(Array.from(dataSources));
      setTypes(Array.from(types));
      setBlueprintsArray(
        // businessFunctions filter
        blueprintsArray
          .filter(
            (blueprint: BlueprintItem) =>
              filters.businessFunctions.length === 0 ||
              filters.businessFunctions.includes(blueprint.businessFunction),
          )

          // dataSources filter
          .filter(
            (blueprint: BlueprintItem) =>
              filters.dataSources.length === 0 ||
              filters.dataSources.includes(blueprint.blueprintCategory.name),
          )
          // search filter
          .filter(
            (blueprint: BlueprintItem) =>
              !filters.search ||
              blueprint.name
                .toLocaleLowerCase()
                .includes(filters.search.toLocaleLowerCase()),
          ),
      );
    }
  }, [blueprintsResult, filters]);

  let validClusters = validBlueprintClusterResult?.data.instances;

  useEffect(() => {
    if (failedBlueprintsIds) {
      setRequestValidBlueprintLoader(true);
      requestValidBlueprint().finally(() =>
        setRequestValidBlueprintLoader(false),
      );
    }
  }, [failedBlueprintsIds, requestValidBlueprint]);

  useEffect(() => {
    if (blueprintName) setFilters(f => ({ ...f, search: blueprintName }));
  }, [blueprintName]);

  let failedEffectRef = useRef(false);
  useEffect(() => {
    if (failedEffectRef.current || !(failedBlueprintsIds && validClusters)) {
      return;
    }

    let failedBlueprintsName = blueprintsArray
      ?.filter((blueprintItem: BlueprintItem) =>
        failedBlueprintsIds?.includes(blueprintItem.id),
      )
      .map((blueprintItem: BlueprintItem) => blueprintItem.name);
    if (!failedBlueprintsName || failedBlueprintsName.length === 0) {
      return;
    }

    failedEffectRef.current = true;
    setSelectedBlueprintNames(failedBlueprintsName);
    setShowModal(true);
  }, [blueprintsArray, failedBlueprintsIds, validClusters]);

  if (!blueprintsResult || requestValidBlueprintLoader) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting data apps" />
      </div>
    );
  }

  let selectedIdsLength = selectedBlueprintNames.length;
  let selectedIdsNames = selectedBlueprintNames;

  async function handleExport(blueprintName: string, slug: string) {
    setSelectedBlueprintNames([blueprintName]);
    setSlug(slug);
    setShowModal(true);
  }
  async function handleInstall() {
    setIsLoading(true);
    try {
      let res = await requestValidBlueprint();
      if (res.data.instances.length > 0) {
        handleExport('', '');
      } else {
        Modal.error({
          title: 'Connected cluster not found',
          content: (
            <Paragraph>
              To Export data app, you need at least one connected cluster.
              Please create a new cluster or reconnect an existing one.
            </Paragraph>
          ),
          icon: <CloseCircleOutlined />,
          centered: true,
          width: 620,
          okText: 'Okay',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <div className="BlueprintsBody">
      <div className="BlueprintsBody__filter">
        {businessFunctions && dataSources && types && (
          <BlueprintFilter
            businessFunctions={businessFunctions}
            dataSources={dataSources}
            types={types}
            filters={filters}
            setFilters={setFilters}
          />
        )}
      </div>

      <div className="BlueprintsBody__list">
        <div className="BlueprintsBody__search-wrapper">
          <div className="BlueprintsBody__search">
            <Input
              data-testid="search-input"
              prefix={<SearchOutlined style={{ color: '#CFD5DA' }} />}
              placeholder="Search Data App"
              value={filters.search}
              onChange={e => {
                let value = e.target.value;
                setFilters(f => ({ ...f, search: value }));
              }}
            />
            {user?.isPartner && (
              <Button loading={isLoading} onClick={handleInstall}>
                Export
              </Button>
            )}
          </div>
        </div>
        {blueprintsArray ? (
          <div>
            <div className="BlueprintsBody__filter-tags">
              {filters.businessFunctions.length > 0 &&
                filters.businessFunctions.map((filterName: string) => (
                  <label className="BlueprintsBody__filter-tag">
                    {filterName}
                    <span
                      className="BlueprintsBody__filter-tag__close-icon"
                      onClick={() =>
                        setFilters(f => ({
                          ...f,
                          businessFunctions: f.businessFunctions.filter(
                            name => name !== filterName,
                          ),
                        }))
                      }
                    >
                      <CloseOutlined />
                    </span>
                  </label>
                ))}

              {filters.types.length > 0 &&
                filters.types.map((filterName: string) => (
                  <label className="BlueprintsBody__filter-tag">
                    {filterName}
                    <span
                      className="BlueprintsBody__filter-tag__close-icon"
                      onClick={() =>
                        setFilters(f => ({
                          ...f,
                          types: f.types.filter(name => name !== filterName),
                        }))
                      }
                    >
                      <CloseOutlined />
                    </span>
                  </label>
                ))}

              {filters.dataSources.length > 0 &&
                filters.dataSources.map((filterName: string) => (
                  <label className="BlueprintsBody__filter-tag">
                    {filterName}
                    <span
                      className="BlueprintsBody__filter-tag__close-icon"
                      onClick={() =>
                        setFilters(f => ({
                          ...f,
                          dataSources: f.dataSources.filter(
                            name => name !== filterName,
                          ),
                        }))
                      }
                    >
                      <CloseOutlined />
                    </span>
                  </label>
                ))}
              {filters.businessFunctions.length > 0 ||
              filters.dataSources.length > 0 ||
              filters.types.length > 0 ? (
                <Button
                  type="link"
                  onClick={() =>
                    setFilters(f => ({
                      businessFunctions: [],
                      dataSources: [],
                      types: [],
                      search: '',
                    }))
                  }
                >
                  Clear Filters
                </Button>
              ) : null}
            </div>
            <BlueprintList
              blueprintsArray={blueprintsArray}
              selectedBlueprintNames={selectedBlueprintNames}
              setSelectedBlueprintNames={setSelectedBlueprintNames}
              onInstall={handleExport}
              requestValidBlueprint={requestValidBlueprint}
            />
          </div>
        ) : (
          <span>no data apps available</span>
        )}
      </div>

      <Modal
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={
          selectedIdsLength > 1
            ? `Install ${selectedIdsLength} Data App`
            : selectedBlueprintNames[0] === ''
            ? 'Export Data App'
            : `Install ${selectedIdsNames} Data App`
        }
        destroyOnClose
        centered
      >
        {validClusters && (
          <BlueprintInstallModal
            clusters={validClusters}
            exportOP={selectedBlueprintNames[0] === ''}
            selectedBlueprintNames={selectedBlueprintNames}
            onChancel={() => setShowModal(false)}
            slug={slug}
          />
        )}
      </Modal>
    </div>
  );
}

export default BlueprintsBody;
