import React, { useLayoutEffect, useRef, useState } from 'react';
import './ServiceAutoScalingChart.less';
import echarts from 'echarts/lib/echarts';
import { Empty, Select, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import moment from 'moment';
import { useGetAutoScaleHistory } from 'hooks/cluster';
import {
  AUTOSCALING_CHART_COLORS,
  availableTimeFramesEnum,
  filterDataByTime,
  getInterval,
  queriesLegendFormatter,
  serviceReplicasLegendFormatter,
  startsWithNumber,
  tooltipFormatter,
} from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/scalingChartsUtils';
import {
  chartableServices,
  echartsSeriesData,
  serviceLargestDataKeyIndex,
} from 'utils/types';
import { AutoScaleHistoryRecord, clusterScalingNode } from 'types/cluster';

const { Title } = Typography;

type ServiceAutoScalingChartProps = {
  chartService: chartableServices;
  instanceId: string;
  clusterScalingNodes: clusterScalingNode[];
};
function ServiceAutoScalingChart({
  chartService,
  instanceId,
  clusterScalingNodes,
}: ServiceAutoScalingChartProps) {
  const intl = useIntl();
  const availableCharts = ['line'];
  const [selectedChart, setSelectedChart] = useState(availableCharts[0]);

  const [selectedTimeFrame, setSelectedTimeFrame] = useState(
    availableTimeFramesEnum['last day'],
  );

  const serviceDataFromClusterScalingNode = filterDataByTime({
    service: chartService,
    clusterScalingNodes,
    selectedTimeFrame,
  });

  const { data: autoScalingData, isLoading: isAutoScalingLoading } =
    useGetAutoScaleHistory({
      instanceId,
      ...(selectedTimeFrame === availableTimeFramesEnum['last week'] && {
        hours: 24 * 7,
      }),
      ...(selectedTimeFrame === availableTimeFramesEnum['last hour'] && {
        hours: 1,
      }),
      enabled: chartService === 'analytics',
    });

  const serviceData = autoScalingData?.[chartService];
  const serviceDataKeys = Object.keys(serviceData || {});

  const clusterScalingNodesLargestData = clusterScalingNodes.reduce(
    (max, current) => {
      return Math.max(max, current.entries);
    },
    clusterScalingNodes[0].entries || 1,
  );
  const serviceLargestData =
    Math.max(
      autoScalingData?.[
        `${chartService}LargestData` as serviceLargestDataKeyIndex
      ] || 1,
      clusterScalingNodesLargestData,
    ) || 1;
  const queriesInterval = getInterval(serviceLargestData);

  const serviceChartRef = useRef<HTMLDivElement>(null);
  const serviceEChartInstance = useRef<echarts.ECharts | null>(null);

  const seriesCommonConfigs = {
    type: selectedChart,
    barWidth: 10,
  };

  const legendCommonConfigs = {
    itemWidth: 16,
    itemHeight: 16,
    icon: 'circle',
    itemGap: 16,
    textStyle: {
      color: AUTOSCALING_CHART_COLORS.SLATE_GRAY,
    },
    orient: 'vertical' as 'vertical' | 'horizontal' | undefined,
    right: 10,
    top: 30,
  };

  const initialDataZoom = [
    {
      type: 'slider',
      start: 0,
      end: 100,
      bottom: 30,
    },
    {
      type: 'inside',
      start: 0,
      end: 100,
      bottom: 30,
    },
  ];

  function getAutoScaleSeriesData({
    key,
    serviceData,
    property,
  }: {
    key: string;
    serviceData: Record<string, AutoScaleHistoryRecord[]>;
    property: 'rejected_queries' | 'total_queries';
  }): echartsSeriesData {
    const chartData: echartsSeriesData = [];
    for (let i = 0; i < serviceData[key].length; i++) {
      const scaleDataItem = serviceData[key][i];
      chartData.push([
        scaleDataItem.start_timestamp,
        scaleDataItem[property],
        '',
      ]);
    }
    return chartData;
  }

  useLayoutEffect(() => {
    const serviceChartOptions = serviceEChartInstance.current?.getOption();
    if (!serviceChartOptions) {
      serviceEChartInstance.current = echarts.init(serviceChartRef.current!);
    }
  }, []);

  useLayoutEffect(() => {
    if (serviceData || serviceDataFromClusterScalingNode) {
      const chartOptions = serviceEChartInstance.current?.getOption();
      const dataZoom = chartOptions?.dataZoom ?? initialDataZoom;

      let xAxisMin: number;
      if (selectedTimeFrame === availableTimeFramesEnum['last day']) {
        const millisecondsInDay = moment.duration(1, 'day').asMilliseconds();
        xAxisMin = +new Date() - millisecondsInDay;
      } else if (selectedTimeFrame === availableTimeFramesEnum['last week']) {
        const millisecondsInWeek = moment.duration(7, 'days').asMilliseconds();
        xAxisMin = +new Date() - millisecondsInWeek;
      } else {
        const millisecondsInHour = moment.duration(1, 'hour').asMilliseconds();
        xAxisMin = +new Date() - millisecondsInHour;
      }

      serviceEChartInstance.current!.setOption({
        grid: {
          bottom: 100,
          left: 150,
          right: 250,
          containLabel: true,
        },
        legend: {
          ...legendCommonConfigs,
          formatter: function (name) {
            if (startsWithNumber(name)) {
              return queriesLegendFormatter({
                dataName: intl.formatMessage({
                  id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.legendFormat',
                }),
                replica: +name[0],
                unit: intl.formatMessage({
                  id: `clusterConfiguration.configurationsTab.scaling.autoScaling.${
                    name.split('-')[1]
                  }LegendFormatUnit`,
                }),
              });
            }
            return serviceReplicasLegendFormatter({
              name,
              unit: intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.serviceReplicasLegendFormatUnit',
              }),
            });
          },
        },
        tooltip: {
          trigger: 'axis',
          position: function (pt) {
            return [pt[0], '10%'];
          },
          axisPointer: {
            type: 'cross',
            animation: false,
          },
          formatter: function (params: any) {
            return tooltipFormatter({
              params,
              firstLineSavedText: intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.tooltipFormat.firstLine',
              }),
              totalQueriesText: intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.totalQueriesLegendFormatUnit',
              }),
              rejectedQueriesText: intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.rejectedQueriesLegendFormatUnit',
              }),
              thirdLineSavedText: intl.formatMessage({
                id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.tooltipFormat.thirdLine',
              }),
            });
          },
        },
        xAxis: {
          name: intl.formatMessage({
            id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.xAxis',
          }),
          type: 'time',
          min: xAxisMin,
          max: +new Date(),
          splitLine: {
            show: false,
          },
        },
        yAxis: [
          {
            show: chartService === 'analytics',
            name: intl.formatMessage({
              id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.yAxis.queries',
            }),
            type: 'value',
            position: 'left',
            interval: queriesInterval,
            max: serviceLargestData,
          },
          {
            name: intl.formatMessage({
              id: 'clusterConfiguration.configurationsTab.scaling.autoScaling.yAxis.services',
            }),
            type: 'value',
            interval: 1,
            axisLine: {
              show: true,
              lineStyle: {
                color: AUTOSCALING_CHART_COLORS.INC_BLUE,
              },
            },
            splitLine: {
              lineStyle: {
                color: `${AUTOSCALING_CHART_COLORS.INC_BLUE}90`,
                type: 'dashed',
              },
            },
          },
        ],
        series: [
          ...(chartService === 'analytics' && serviceData
            ? (serviceDataKeys.map(key => ({
                ...seriesCommonConfigs,
                name: `${key}-totalQueries`,
                type: selectedChart,
                data: getAutoScaleSeriesData({
                  key,
                  serviceData: serviceData,
                  property: 'total_queries',
                }),
                yAxisIndex: 0,
              })),
              serviceDataKeys.map(key => ({
                ...seriesCommonConfigs,
                name: `${key}-rejectedQueries`,
                type: selectedChart,
                data: getAutoScaleSeriesData({
                  key,
                  serviceData: serviceData,
                  property: 'rejected_queries',
                }),
                yAxisIndex: 0,
              })))
            : []),
          {
            type: selectedChart,
            barWidth: 10,
            name: intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.scaling.${chartService}`,
            }),
            itemStyle: {
              color: `${AUTOSCALING_CHART_COLORS.INC_BLUE}70`,
            },
            areaStyle: {},
            step: 'end',
            data: serviceDataFromClusterScalingNode,
            symbol: 'rect',
            yAxisIndex: 1,
          },
        ],
        dataZoom,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedChart, selectedTimeFrame, serviceData]);

  // function handleShowHideLegendItems({ textStart }: { textStart: string }) {
  //   if (serviceEChartInstance.current) {
  //     const options = serviceEChartInstance.current.getOption();

  //     if (options) {
  //       options.series!.forEach((seriesItem: echarts.EChartOption.Series) => {
  //         if (seriesItem.name!.startsWith(textStart)) {
  //           serviceEChartInstance.current!.dispatchAction({
  //             type: 'legendToggleSelect',
  //             name: seriesItem.name,
  //           });
  //         }
  //       });
  //     }
  //   }
  // }

  return (
    <section className="service-auto-scaling__wrapper">
      <article className="service-auto-scaling__selections">
        <Select
          placeholder={
            <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.selectChart" />
          }
          value={selectedChart}
          onChange={(val: string) => {
            setSelectedChart(val);
          }}
        >
          {availableCharts.map(representation => (
            <Select.Option key={representation} value={representation}>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.chartType"
                values={{ representation }}
              />
            </Select.Option>
          ))}
        </Select>
        <Select
          placeholder={
            <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.selectDuration" />
          }
          value={selectedTimeFrame}
          onChange={val => {
            setSelectedTimeFrame(val);
          }}
        >
          {[
            availableTimeFramesEnum['last hour'],
            availableTimeFramesEnum['last day'],
            availableTimeFramesEnum['last week'],
          ].map(timeFrame => (
            <Select.Option key={timeFrame} value={timeFrame}>
              {`${timeFrame}`}
            </Select.Option>
          ))}
        </Select>
      </article>
      <br />
      <section>
        <Title level={5}>
          <FormattedMessage
            id={`clusterConfiguration.configurationsTab.scaling.${chartService}`}
          />
        </Title>
        {/* <section className="service-auto-scaling__toggle-buttons">
          {Object.keys(serviceDataKeys).map(serviceKey => (
            <Button
              key={serviceKey}
              onClick={() =>
                handleShowHideLegendItems({ textStart: `${serviceKey}-` })
              }
              type="primary"
            >
              Toggle Replica {+serviceKey + 1}
            </Button>
          ))}
        </section> */}
        <div className="service-auto-scaling__chart-wrapper">
          <div
            className="scaling-configurations__chart"
            ref={serviceChartRef}
          />
          <Empty
            className={clsx('service-auto-scaling__chart-empty', {
              hide: !(
                !isAutoScalingLoading &&
                serviceData &&
                !Object.keys(serviceData).length &&
                !serviceDataFromClusterScalingNode?.length
              ),
            })}
          />
        </div>
      </section>
    </section>
  );
}

export default ServiceAutoScalingChart;
