import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  Ref,
  useRef,
} from 'react';
import { clusterCreationStatus } from '../azureClusterCreationHelper';

export interface TimerRefType {
  pauseTimerExplicitly: () => void;
  resumeTimerExplicitly: () => void;
}
type AzureCreationClusterTimerProps = {
  startTime: string;
  state: string;
  isLastStep: boolean;
  updatedAt: string;
  shouldTimerPause: boolean;
};
function AzureCreationClusterTimer(
  {
    startTime,
    state,
    isLastStep,
    updatedAt,
    shouldTimerPause,
  }: AzureCreationClusterTimerProps,
  ref: Ref<TimerRefType>,
) {
  const mounted = useRef(false);
  const [intervalID, setIntervalID] = useState<NodeJS.Timeout>();
  const [isPaused, setIsPaused] = useState(false);

  function getEndTime() {
    if (
      (state === clusterCreationStatus.COMPLETED && isLastStep) ||
      state === clusterCreationStatus.FAILED ||
      state === clusterCreationStatus.CANCELED
    ) {
      return updatedAt;
    } else {
      return Date.now();
    }
  }

  const createdAtUTCString = new Date(startTime).toUTCString();
  const createdAtTotalTime = new Date(createdAtUTCString).getTime();
  const endTimeUTCString = new Date(getEndTime() as string).toUTCString();
  const endTimeUTCTotalTime = new Date(endTimeUTCString).getTime();

  const [totalSeconds, setTotalSeconds] = useState(
    endTimeUTCTotalTime - createdAtTotalTime,
  );

  const seconds = new Date(
    endTimeUTCTotalTime - createdAtTotalTime,
  ).getUTCSeconds();
  const minutes = new Date(
    endTimeUTCTotalTime - createdAtTotalTime,
  ).getUTCMinutes();
  // const hours = new Date(nowUTCTotalTime - createdAtTotalTime).getUTCHours();
  const hours = Math.floor(totalSeconds / 3.6e6);

  useEffect(() => {
    mounted.current = true;
    const intID = setInterval(() => {
      if (mounted.current) setTotalSeconds(prev => prev + 1);
    }, 1000);
    setIntervalID(intID);

    if (shouldTimerPause) {
      pauseTimer();
    }

    return () => {
      mounted.current = false;
      clearInterval(intID);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useImperativeHandle(ref, () => ({
    pauseTimerExplicitly: () => {
      pauseTimer();
    },
    resumeTimerExplicitly: () => {
      resumeTimer();
    },
  }));

  function resumeTimer() {
    if (isPaused) {
      const intID = setInterval(() => {
        setTotalSeconds(prev => prev + 1);
      }, 1000);
      setIntervalID(intID);
      setIsPaused(false);
    }
  }

  function pauseTimer() {
    if (!isPaused && intervalID) {
      clearInterval(intervalID);
      setIsPaused(true);
    }
  }

  function formatToTwoDigits(value: number) {
    return value.toString().length === 1 ? '0' + value : value;
  }

  return (
    <div className="timer">{`${formatToTwoDigits(hours)}:${formatToTwoDigits(
      minutes,
    )}:${formatToTwoDigits(seconds)}`}</div>
  );
}

export default forwardRef(AzureCreationClusterTimer);
