import React from 'react';
import ServiceAutoScalingChart from 'components/ClusterDetails/ClusterDetailsBody/ScalingConfigurations/ServiceAutoScalingChart/ServiceAutoScalingChart';
import { clusterScalingNode } from 'types/cluster';

type LoaderChartProps = {
  instanceId: string;
  clusterScalingNodes: clusterScalingNode[];
};
function LoaderChart({ instanceId, clusterScalingNodes }: LoaderChartProps) {
  return (
    <ServiceAutoScalingChart
      chartService="loader"
      instanceId={instanceId}
      clusterScalingNodes={clusterScalingNodes}
    />
  );
}

export default LoaderChart;
