import React from 'react';
import { HomeFilled } from '@ant-design/icons';
import { notification, Switch, Tooltip } from 'antd';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
import { useMutateCluster } from 'hooks/cluster';

interface ClusterConnectBiToolsEditProps {
  instance: Instance;
  services: Service[];
  isTrial: boolean;
}

function HomePage({
  instance,
  services,
  isTrial,
}: ClusterConnectBiToolsEditProps) {
  let { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  async function handleChange(enable: boolean) {
    let { data } = await mutateCluster({
      enableOnboarding: enable,
    });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  let canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  let status = getClusterStatus(instance, services[0]);

  let enable = status === 'running';

  return (
    <div className="ClusterEdit ClusterConnectBiToolsEdit">
      <div className="ClusterEdit__icon">
        <HomeFilled />
      </div>
      <div className="ClusterEdit__content ClusterConnectBiToolsEdit__content">
        <div className="ClusterConnectBiToolsEdit__main">
          <h3 className="ClusterEdit__title">Home Page</h3>
          <div className="ClusterConnectBiToolsEdit__body">
            <p className="ClusterConnectBiToolsEdit__hint">
              Display the home page tab inside Incorta Analytics
            </p>
          </div>
        </div>
        <div className="ClusterConnectBiToolsEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                isTrial
                  ? 'Only available for Premium users. Chat with us to upgrade.'
                  : status !== 'running'
                  ? 'Cluster must be connected to apply this change.'
                  : 'This feature might not be compatible with old releases.'
              }
            >
              <Switch
                onChange={handleChange}
                loading={isLoading}
                checked={instance.enableOnboarding}
                disabled={!enable || isTrial}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default HomePage;
