import React, { useEffect, useState } from 'react';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Modal, Space, Tooltip, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import WhiteListList from '../WhiteListList/WhiteListList';
import WhiteListModal from '../WhiteListModal/WhiteListModal';
import { Instance, Whitelist } from 'types/cluster';
import { useGetClusterWhiteList } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { ReactComponent as IpWhitelistIcon } from 'images/ip-whitelist.svg';
import { UserData } from 'types/user';

interface WhitelistSectionProps {
  instance: Instance;
  user: UserData;
}
function WhitelistSection({ instance, user }: WhitelistSectionProps) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const [whitelists, setWhitelists] = useState<Whitelist[]>([]);

  const { isLoading: ipWhiteListDataLoading, data: ipWhiteListData } =
    useGetClusterWhiteList({ clusterName: instance.name });

  const whitelistdata = ipWhiteListData?.data.whitelists;
  useEffect(() => {
    setWhitelists(whitelistdata!);
  }, [whitelistdata]);

  const canAddIpWhitelist =
    instance.clusterPolicy.whitelistPolicy.includes('create');
  const { isPlatformActionSupported: isWhiteListSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.whitelisting,
    });

  const addButtonUi = canAddIpWhitelist && (
    <Tooltip
      placement="left"
      title={
        !isWhiteListSupported ? (
          <FormattedMessage
            id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
            values={{
              action: intl.formatMessage({
                id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.whiteList',
              }),
            }}
          />
        ) : (
          ''
        )
      }
    >
      <Button
        disabled={!isWhiteListSupported}
        size="small"
        icon={<PlusOutlined />}
        onClick={() => {
          setShowModal(true);
        }}
      >
        Add
      </Button>
    </Tooltip>
  );

  function addWhiteList(whitelists: Whitelist[]) {
    setWhitelists([...whitelists]);
  }

  return (
    <div className="AccessSecurity">
      <div className="AccessSecurity__icon-wrapper">
        <Icon component={IpWhitelistIcon} />
      </div>

      <div className="AccessSecurity__content-wrapper">
        <div className="AccessSecurity__content-title-wrapper">
          <div className="AccessSecurity__content-title">
            <h2>Restrict User Access Based On IP Address</h2>

            <p>
              Allow restricting access to Incorta (Analytics, CMC, and Spark UI)
              based on a single IP address or a list of IP addresses range.
            </p>
          </div>

          <div className="AccessSecurity__content-action">
            {whitelists && (
              <Space>
                {canAddIpWhitelist && (
                  <>
                    {addButtonUi}
                    <Divider type="vertical" />
                  </>
                )}
                <Typography.Text className="AccessSecurity__items-count">
                  {whitelists.length} Items
                </Typography.Text>
              </Space>
            )}
          </div>
        </div>

        <WhiteListList
          instance={instance}
          addButtonUi={addButtonUi}
          whitelists={whitelists}
          isLoading={ipWhiteListDataLoading}
          setWhitelists={addWhiteList}
        />
      </div>

      <Modal
        title="Add IP Addresses/Range"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={645}
        destroyOnClose
        centered
      >
        <WhiteListModal
          onChancel={() => setShowModal(false)}
          instance={instance}
          whitelists={whitelists}
          setWhitelists={addWhiteList}
        />
      </Modal>
    </div>
  );
}

export default WhitelistSection;
