import React, { useContext } from 'react';
import {
  MinusCircleOutlined,
  PlayCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import useServiceAction from './useServiceAction';
import { ClusterAction, ServiceStatus, ServiceToRestart } from 'types/cluster';
import {
  canStartServiceList,
  canStopServiceList,
  canRestartServiceList,
  canForceStartServiceList,
} from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterServicesControl/ClusterServicesControlHelper/useClusterServicesControlHelper';
import { SessionContext } from 'auth/SessionProvider';

function ClusterServicesControlActions({
  status,
  instanceId,
  serviceNode,
  nodeName,
  tempService,
  isSubCluster = false,
  isSubClusterConnected,
}: {
  status: ServiceStatus;
  instanceId: string;
  serviceNode: ServiceToRestart;
  nodeName: string;
  tempService: boolean;
  isSubCluster?: boolean;
  isSubClusterConnected?: boolean;
}) {
  const { user } = useContext(SessionContext);
  let isTrialUser = user?.role?.id === 1;
  isTrialUser = false;

  const {
    triggeredAction,
    handleServiceRestartorStop,
    isChangingServiceState,
    isStopOrRestartServiceApiSuccess,
  } = useServiceAction({
    instanceId,
    serviceNode,
  });

  const isSubClusterAndDisconnected = isSubCluster && !isSubClusterConnected;
  const isStopBtnLoading =
    triggeredAction === ClusterAction.STOP && isChangingServiceState;
  const isStopBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.RESTART && isChangingServiceState);
  const isRestartBtnLoading =
    triggeredAction === ClusterAction.RESTART && isChangingServiceState;

  const isForceStartBtnLoading =
    triggeredAction === ClusterAction.FORCE_RESTART && isChangingServiceState;
  const isRestartBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.STOP && isChangingServiceState);
  const isStartBtnLoading =
    triggeredAction === ClusterAction.START && isChangingServiceState;
  const isStartBtnDisabled =
    isTrialUser ||
    tempService ||
    isSubClusterAndDisconnected ||
    (triggeredAction === ClusterAction.START &&
      isStopOrRestartServiceApiSuccess);

  return (
    <div className="cluster-services-control__actions-wrapper">
      {canStopServiceList.includes(status) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <Button
            loading={isStopBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.STOP,
                nodeName,
              })
            }
            icon={<MinusCircleOutlined />}
            disabled={isStopBtnDisabled}
          >
            {ClusterAction.STOP}
          </Button>
        </Tooltip>
      )}

      {canForceStartServiceList.includes(status) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <Button
            loading={isForceStartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.FORCE_RESTART,
                nodeName,
              })
            }
            icon={<PlayCircleOutlined />}
            disabled={isRestartBtnDisabled}
          >
            {ClusterAction.START}
          </Button>
        </Tooltip>
      )}
      {canRestartServiceList.includes(status) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <Button
            loading={isRestartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.RESTART,
                nodeName,
              })
            }
            icon={<RetweetOutlined />}
            disabled={isRestartBtnDisabled}
          >
            {ClusterAction.RESTART}
          </Button>
        </Tooltip>
      )}
      {canStartServiceList.includes(status) && (
        <Tooltip
          title={
            isTrialUser ? (
              <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipCantAddForTrialUser" />
            ) : (
              isSubClusterAndDisconnected && (
                <FormattedMessage id="clusterConfiguration.clusterServicesControl.headerOptions.tooltipSubClusterDisconnected" />
              )
            )
          }
        >
          <Button
            loading={isStartBtnLoading}
            onClick={() =>
              handleServiceRestartorStop({
                action: ClusterAction.START,
                nodeName,
              })
            }
            icon={<PlayCircleOutlined />}
            disabled={isStartBtnDisabled}
          >
            {ClusterAction.START}
          </Button>
        </Tooltip>
      )}
    </div>
  );
}

export default ClusterServicesControlActions;
