import React from 'react';
import './AzureSynapseModal.less';
import { Button, Input, Form } from 'antd';
import { useIntl } from 'react-intl';

interface AzureSynapseModalProps {
  closeModal: () => void;
  setSynapse: any;
}
function AzureSynapseModal({ closeModal, setSynapse }: AzureSynapseModalProps) {
  const intl = useIntl();
  const [form] = Form.useForm();

  const fieldNames = {
    tenantFieldName: 'tenant',
    usernameFieldName: 'username',
    passwordFieldName: 'password',
  } as const;

  const onFinish = async () => {
    setSynapse({
      tenant: form.getFieldValue(fieldNames.tenantFieldName),
      username: form.getFieldValue(fieldNames.usernameFieldName),
      password: form.getFieldValue(fieldNames.passwordFieldName),
    });
    closeModal();
  };

  const onReset = () => {
    closeModal();
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="azure-synapse-modal__form"
      onFinish={onFinish}
    >
      <Form.Item
        label={intl.formatMessage({
          id: 'clusterConfiguration.azureSynapseModal.tenantFieldLabel',
        })}
        name={fieldNames.tenantFieldName}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'clusterConfiguration.azureSynapseModal.requiredTenant',
            }),
          },
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'clusterConfiguration.azureSynapseModal.tenantFieldPlaceholder',
          })}
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({
          id: 'clusterConfiguration.azureSynapseModal.usernameFieldLabel',
        })}
        name={fieldNames.usernameFieldName}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'clusterConfiguration.azureSynapseModal.requiredUsername',
            }),
          },
        ]}
      >
        <Input
          placeholder={intl.formatMessage({
            id: 'clusterConfiguration.azureSynapseModal.usernameFieldPlaceholder',
          })}
        />
      </Form.Item>

      <Form.Item
        label={intl.formatMessage({
          id: 'clusterConfiguration.azureSynapseModal.passwordFieldLabel',
        })}
        hasFeedback
        name={fieldNames.passwordFieldName}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'clusterConfiguration.azureSynapseModal.requiredPassword',
            }),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div className="azure-synapse-modal__footer-bottom">
          <Button htmlType="button" onClick={onReset}>
            {intl.formatMessage({
              id: 'clusterConfiguration.azureSynapseModal.cancelBtn',
            })}
          </Button>
          <Button type="primary" htmlType="submit">
            {intl.formatMessage({
              id: 'clusterConfiguration.azureSynapseModal.submitBtn',
            })}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default AzureSynapseModal;
