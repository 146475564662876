import React, { useCallback, useContext } from 'react';
import { Form, Select } from 'antd';
import { blueprintInstallModalFormFieldNames } from 'components/BlueprintsPage/BlueprintInstallModal/BlueprintInstallModal';
import useRequest from 'hooks/useRequest';
import { getClusterTenants } from 'services/cluster';
import { searchFilterOption } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

interface ClusterTenantsFieldProps {
  instanceName: string;
}

function ClusterTenantsField({ instanceName }: ClusterTenantsFieldProps) {
  const { user } = useContext(SessionContext);

  const { result, isPending } = useRequest(
    useCallback(
      () =>
        getClusterTenants({ userId: user?.uuid!, clusterName: instanceName }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [instanceName, user.uuid],
    ),
    { resolve: true, handleError: true, useCache: true },
  );
  const tenants = result?.data.tenants ?? [];
  const tenantName = tenants[0]?.name;

  return (
    <Form.Item
      name={blueprintInstallModalFormFieldNames.tenant}
      label={'Tenant'}
      initialValue={tenantName}
      rules={[
        {
          required: true,
          message: 'Please select your tenant!',
        },
      ]}
    >
      <Select
        data-testid="tenants-dropdown"
        showSearch
        filterOption={searchFilterOption}
        loading={tenants.length === 0 || isPending}
      >
        {tenants?.map((tenant: any) => (
          <Select.Option
            data-testid="tenant-dropdown-options"
            key={tenant.name}
            value={tenant.name}
          >
            {tenant.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default ClusterTenantsField;
