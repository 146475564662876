import './ClusterServiceNodeEdit.less';

import React, { useEffect, useMemo, useState } from 'react';
import Icon, {
  MinusCircleOutlined,
  PlayCircleOutlined,
  RetweetOutlined,
} from '@ant-design/icons';
import { Button, InputNumber, notification, Tooltip, Spin } from 'antd';
import { useMutation, useQuery } from 'react-query';
import Tag from 'antd/lib/tag';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ClusterAction,
  Instance,
  Service,
  ServiceStatus,
  ServiceToRestart,
  ServiceType,
} from 'types/cluster';
import { ReactComponent as AnalyticsIcon } from 'images/analytics.svg';
import { getClusterStatus } from 'utils/cluster';
import { useMutateClusterScale } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { UserData } from 'types/user';
import {
  getServicesStatus,
  stopOrRestartService as stopOrRestartServiceApi,
} from 'services/cluster';
import { queryClient } from 'index';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import { QueryServerKeys } from 'constants/QueryServerKeys';

interface ClusterServiceNodeEditProps {
  instance: Instance;
  services: Service[];
  isTrial: boolean;
  user: UserData;
  serviceNodesCount: number;
  serviceNode: ServiceToRestart;
  hint?: string;
  subTitle: JSX.Element;
}

const NodeServiceMapper: { [key: string]: string } = {
  [ServiceToRestart.LOADER]: 'loaderReplicas',
  [ServiceToRestart.ANALYTICS]: 'analyticsReplicas',
};

const ServiceStatusColor: Record<ServiceStatus, string> = {
  [ServiceStatus.RUNNING]: 'green',
  [ServiceStatus.NOT_RUNNING]: 'red',
  [ServiceStatus.IS_STARTING]: 'orange',
  [ServiceStatus.PROCESSING]: 'orange',
  [ServiceStatus.STARTING_TENANT]: 'orange',
  [ServiceStatus.STARTING_TENANTS]: 'orange',
  [ServiceStatus.SLEEPING]: 'red',
  [ServiceStatus.TERMINATED_UNEXPECTEDLY]: 'red',
  [ServiceStatus.ERROR]: 'red',
  [ServiceStatus.STARTUP_ERROR]: 'red',
  [ServiceStatus.STOPPING]: 'red',
  [ServiceStatus.STOPPING_TENANT]: 'red',
  [ServiceStatus.STOPPING_TENANTS]: 'red',
  [ServiceStatus.NOT_RESPONDING]: 'default',
  [ServiceStatus.NA]: 'default',
  [ServiceStatus.CREATING]: 'blue',
  [ServiceStatus.DELETING]: 'blue',
};

const MappedServiceStatus: { [key: string]: string } = {
  [ServiceStatus.RUNNING]: 'running',
};

const REFETCH_SERVICE_STATUS_INTERVAL = 5000;
function ClusterServiceNodeEdit({
  instance,
  services,
  isTrial,
  user,
  serviceNodesCount,
  serviceNode,
  hint,
  subTitle,
}: ClusterServiceNodeEditProps) {
  const intl = useIntl();
  const [triggeredAction, setTriggeredAction] = useState<ClusterAction>();
  const [node, setNode] = useState(serviceNodesCount);
  const { mutateAsync: mutateClusterScale, isLoading } = useMutateClusterScale({
    clusterName: instance.name,
  });

  const {
    mutateAsync: stopOrRestartService,
    isLoading: isChangingServiceState,
  } = useMutation(stopOrRestartServiceApi);

  useEffect(() => {
    if (instance.status !== 'running') {
      // prevent displaying obselete service status on starting the cluster
      queryClient.removeQueries([
        QueryServerKeys.CLUSTER.GET_SERVICES_STATUS,
        instance.id,
        serviceNode,
      ]);
    }
    // eslint-disable-next-line
  }, [instance.status]);

  const {
    data,
    isLoading: serviceStatusLoading,
    isFetching: isCheckingStatus,
  } = useQuery(
    [QueryServerKeys.CLUSTER.GET_SERVICES_STATUS, instance.id, serviceNode],
    () => getServicesStatus(instance.id),
    {
      enabled: instance.status === 'running',
      refetchInterval: data => {
        return ![ServiceStatus.RUNNING, ServiceStatus.NOT_RUNNING].includes(
          data?.data?.[
            (serviceNode + 'Status') as ServiceType
          ] as ServiceStatus,
        )
          ? REFETCH_SERVICE_STATUS_INTERVAL
          : false;
      },
      refetchOnReconnect: false,
      refetchOnMount: false,
      refetchIntervalInBackground: false,
      refetchOnWindowFocus: false,
    },
  );

  const serviceStatus = useMemo(
    () =>
      data?.data?.[(serviceNode + 'Status') as ServiceType] as ServiceStatus,

    // eslint-disable-next-line
    [data],
  );
  const serviceStatusColor = useMemo(() => {
    return (
      ServiceStatusColor[serviceStatus] ||
      ServiceStatusColor[ServiceStatus.IS_STARTING]
    );
  }, [serviceStatus]);

  async function handleSubmit(
    e: React.MouseEvent<HTMLFormElement, MouseEvent>,
  ) {
    e.preventDefault();

    // eslint-disable-next-line
    const { data } = await mutateClusterScale({
      [NodeServiceMapper[serviceNode]]: node,
    });
    notification.success({ message: 'Success', description: 'data.message' });
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  const status = getClusterStatus(instance, services[0]);
  const enable = status === 'running' && instance.externalCMC;
  const canSave = enable && node !== null && serviceNodesCount !== node;
  const { isPlatformActionSupported: isScalingSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clusterScaling,
    });

  async function handleServiceRestartorStop(action: ClusterAction) {
    setTriggeredAction(action);
    try {
      const data = await stopOrRestartService({
        action,
        instanceId: instance.id,
        service: serviceNode,
        nodeName: '',
      });
      await queryClient.invalidateQueries([
        QueryServerKeys.CLUSTER.GET_SERVICES_STATUS,
        instance.id,
        serviceNode,
      ]);
      notification.success({
        message: 'Success',
        description: data?.data?.message,
      });
    } catch (error) {
      let errorMessage = (error && getErrorMessage(error)) as string;
      if (errorMessage) {
        showErrorMessage(errorMessage);
      }
    }
  }

  return (
    <div className="ClusterEdit ClusterServiceNodeEdit">
      <div className="ClusterEdit__icon">
        <Icon component={AnalyticsIcon} />
      </div>
      <div className="ClusterEdit__content">
        <div className="ClusterServiceNodeEdit__actions">
          <h3 className="ClusterEdit__title">
            {serviceNode} Node
            {serviceStatus && (
              <Tag color={serviceStatusColor}>
                {MappedServiceStatus[serviceStatus] || serviceStatus}
              </Tag>
            )}
          </h3>
          {instance.status === 'running' && serviceStatusLoading && (
            <Spin spinning />
          )}
          {instance.status === 'running' && !serviceStatusLoading && (
            <div className="ClusterServiceNodeEdit__actions__wrapper">
              {![ServiceStatus.NOT_RUNNING, ServiceStatus.RUNNING].includes(
                serviceStatus,
              ) && <Spin spinning />}

              {serviceStatus === ServiceStatus.RUNNING && (
                <Button
                  loading={
                    triggeredAction === ClusterAction.RESTART &&
                    (isChangingServiceState || isCheckingStatus)
                  }
                  onClick={() =>
                    handleServiceRestartorStop(ClusterAction.RESTART)
                  }
                  icon={<RetweetOutlined />}
                  disabled={
                    triggeredAction === ClusterAction.STOP &&
                    (isChangingServiceState || isCheckingStatus)
                  }
                >
                  {ClusterAction.RESTART}
                </Button>
              )}

              {serviceStatus === ServiceStatus.RUNNING && (
                <Button
                  loading={
                    triggeredAction === ClusterAction.STOP &&
                    (isChangingServiceState || isCheckingStatus)
                  }
                  onClick={() => handleServiceRestartorStop(ClusterAction.STOP)}
                  icon={<MinusCircleOutlined />}
                  disabled={
                    triggeredAction === ClusterAction.RESTART &&
                    (isChangingServiceState || isCheckingStatus)
                  }
                >
                  {ClusterAction.STOP}
                </Button>
              )}

              {[ServiceStatus.NOT_RUNNING, ServiceStatus.SLEEPING].includes(
                serviceStatus,
              ) && (
                <Button
                  loading={
                    triggeredAction === ClusterAction.START &&
                    (isChangingServiceState || isCheckingStatus)
                  }
                  onClick={() =>
                    handleServiceRestartorStop(ClusterAction.START)
                  }
                  icon={<PlayCircleOutlined />}
                >
                  {ClusterAction.START}
                </Button>
              )}
            </div>
          )}
        </div>

        <p className="ClusterServiceNodeEdit__subtitle">{subTitle}</p>
        <div className="ClusterServiceNodeEdit__body">
          <p className="ClusterServiceNodeEdit__hint">{hint}</p>
          {canUpdate && (
            <form
              className="ClusterServiceNodeEdit__form"
              onSubmit={handleSubmit}
            >
              <div>
                <label className="ClusterServiceNodeEdit__form-label">
                  Set the number of {serviceNode} services
                </label>
                <div>
                  <Tooltip
                    title={
                      isTrial
                        ? 'Only available for Premium users. Chat with us to upgrade.'
                        : ''
                    }
                  >
                    <InputNumber
                      className="ClusterServiceNodeEdit__form-input-number"
                      min={1}
                      max={10}
                      width={290}
                      value={node}
                      onChange={(number: any) => setNode(number as number)}
                      disabled={isTrial}
                    />
                  </Tooltip>
                </div>
              </div>

              <div>
                <Tooltip
                  title={
                    status !== 'running' ? (
                      'Your cluster must be connected to apply this change'
                    ) : !isScalingSupported ? (
                      <FormattedMessage
                        id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                        values={{
                          action: intl.formatMessage({
                            id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.scaling',
                          }),
                        }}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <Button
                    loading={isLoading}
                    disabled={!canSave || !isScalingSupported}
                    type="primary"
                    htmlType={'submit'}
                  >
                    Save
                  </Button>
                </Tooltip>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClusterServiceNodeEdit;
