import { Col, Modal, Row, Spin, Table, Timeline } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import StatHistoryDetails from './StatHistoryDetails/StatHistoryDetails';
import useRequest from 'hooks/useRequest';
import { getStats } from 'services/user';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import { Stat } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';

function BillingHistory() {
  const { user } = useContext(SessionContext);

  const { isToggleFeatureEnabled: paymentIsEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.payment,
  });

  let { result, isPending, isRejected } = useRequest(
    useCallback(() => getStats({ userId: user!.uuid }), [user]),
    { resolve: true },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin tip="Getting billing history" />
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  let stats = result?.data.stats;

  return (
    <div className="BillingHistory">
      <Row justify="center">
        <Col xs={24} md={12}>
          <Timeline mode="left">
            {stats?.map(stat => {
              return (
                <StatHistory
                  key={stat.date}
                  stat={stat}
                  paymentEnabled={!!paymentIsEnabled}
                />
              );
            })}
          </Timeline>
        </Col>
      </Row>
    </div>
  );
}

interface StatHistoryProps {
  stat: Stat;
  paymentEnabled: boolean;
}

function StatHistory({ stat, paymentEnabled }: StatHistoryProps) {
  let [showModal, setShowModal] = useState(false);

  return (
    <Timeline.Item>
      <h3>
        {stat.date}{' '}
        {paymentEnabled && (
          <button
            className="link"
            style={{ marginLeft: 8 }}
            onClick={() => setShowModal(true)}
          >
            Details
          </button>
        )}
      </h3>
      <Table
        dataSource={[
          {
            key: 1,
            name: 'Units',
            label: `${stat.units} IPU`,
            amount: `${stat.unitsCost} ICC`,
          },
          {
            key: 2,
            name: 'User count',
            label: `${stat.users} users`,
            amount: `${stat.usersCost} ICC`,
          },
          {
            key: 3,
            name: 'Disk Usage',
            label: `${stat.usage} GB`,
            amount: `${stat.usageCost} ICC`,
          },
        ]}
        bordered
        showHeader={false}
        columns={
          paymentEnabled
            ? [
                {
                  dataIndex: 'name',
                  key: 'name',
                  render: name => <strong>{name}</strong>,
                },
                {
                  dataIndex: 'label',
                  key: 'label',
                },
                {
                  dataIndex: 'amount',
                  key: 'amount',
                  align: 'right',
                },
              ]
            : [
                {
                  dataIndex: 'name',
                  key: 'name',
                  width: 100,
                  render: name => <strong>{name}</strong>,
                },
                {
                  dataIndex: 'label',
                  key: 'label',
                  width: 100,
                },
              ]
        }
        pagination={false}
        size="small"
        footer={() => {
          return (
            paymentEnabled && (
              <div style={{ marginLeft: 8 }}>
                <strong>Total:</strong> {stat.totalCost} ICC
              </div>
            )
          );
        }}
      ></Table>

      <Modal
        visible={showModal}
        title={stat.date}
        onCancel={() => setShowModal(false)}
        width={900}
        footer={null}
        centered
      >
        <StatHistoryDetails stat={stat} />
      </Modal>
    </Timeline.Item>
  );
}

export default BillingHistory;
