import './CreditCardInfoModal.less';

import * as stripeJs from '@stripe/stripe-js';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Button, Spin } from 'antd';
import React, { useCallback, useContext, useState } from 'react';
import FormItem from 'antd/lib/form/FormItem';
import { ReactComponent as SafeSecureIcon } from './icons/safe-secure-icon.svg';
import useRequest from 'hooks/useRequest';
import {
  paymentSetup,
  addPaymentMethod,
  deleteUserPaymentMethod,
} from 'services/user';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';

const stripeUiOptions: stripeJs.StripeCardElementOptions = {
  hidePostalCode: true,
  style: {
    base: {
      color: '#393E41',
      letterSpacing: '0.025em',
      fontFamily: 'Source Code Pro, monospace',
      padding: '12px',
      '::placeholder': {
        color: '#CFD5DA',
      },
    },
    invalid: {
      color: '#e9724c',
    },
  },
};

interface CreditCardInfoModalProps {
  onCancel(): void;
  isUpdate?: boolean;
  onDone?: () => Promise<any> | void;
}

function CreditCardInfoModal({
  isUpdate = false,
  onCancel,
  onDone,
}: CreditCardInfoModalProps) {
  const { user } = useContext(SessionContext);
  let [isComplete, setIsComplete] = useState(false);
  let [isReady, setIsReady] = useState(false);

  let stripe = useStripe();
  let elements = useElements();

  let { makeRequest: addCardRequest, isPending: addCardLoading } = useRequest(
    useCallback(
      async ({ card }: { card: stripeJs.StripeCardElement }) => {
        // if user have an exist payment method
        // - delete it first
        if (isUpdate) {
          await deleteUserPaymentMethod({ userId: user!.uuid });
        }

        // setup payment from the backend
        let {
          data: { customer, secret },
        } = await paymentSetup({ userId: user!.uuid });

        // confirm card number from stripe
        let cardSetupRes = await stripe!.confirmCardSetup(secret, {
          payment_method: {
            card,
          },
        });
        let { payment_method } = cardSetupRes.setupIntent ?? {};
        if (!payment_method) {
          throw Error(`Error`);
        }

        // if done add payment card payment to our database
        let { data } = await addPaymentMethod({
          userId: user!.uuid,
          data: {
            pmid: payment_method,
            customer,
          },
        });

        // refresh payment method info
        await onDone?.();

        return data;
      },
      [isUpdate, onDone, stripe, user],
    ),
    { handleError: true },
  );

  async function handleSubmit(e: React.SyntheticEvent) {
    e.preventDefault();

    let isValid = stripe && elements && isComplete;
    if (!isValid) {
      return;
    }

    let card = elements!.getElement(CardElement)!;
    let { message } = await addCardRequest({
      card,
    });
    displaySuccessMessage(message);

    onCancel();
  }

  let loading = !stripe || !elements || !isReady || addCardLoading;
  let disable = loading || !isComplete;

  return (
    <div className="CreditCardInfoModal">
      <form onSubmit={handleSubmit}>
        <Spin spinning={loading}>
          <FormItem style={{ flexFlow: 'column' }} label="Card details">
            <CardElement
              options={stripeUiOptions}
              onChange={e => setIsComplete(e.complete)}
              onReady={() => setIsReady(true)}
            />
          </FormItem>
        </Spin>

        <div className="CreditCardInfoModal__footer-bottom">
          <div className="CreditCardInfoModal__safe-secure">
            <SafeSecureIcon style={{ marginRight: 8 }} />
            Safe & secure
          </div>
          <Button onClick={onCancel} style={{ marginRight: 16 }}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={loading}
            disabled={disable}
          >
            {isUpdate ? 'Update' : 'Add'}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default CreditCardInfoModal;
