import './Blueprint.less';

import React, { useCallback, useEffect, useContext } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import BlueprintSelect from './BlueprintSelect/BlueprintSelect';
import { Instance, ClusterStatus, Service } from 'types/cluster';
import { checkClusterImportStatus } from 'services/cluster';
import useRequest from 'hooks/useRequest';
import { wait } from 'utils';
import useCheckStatus from 'hooks/useCheckStatus';
import { SessionContext } from 'auth/SessionProvider';

interface BlueprintProps {
  instance: Instance;
  service: Service;
  state: ClusterStatus;
}

interface LocationState {
  openBlueprint?: boolean;
}

function Blueprint({ instance, service, state }: BlueprintProps) {
  let history = useHistory();
  const { user } = useContext(SessionContext);

  let {
    isPending: checkImportStatusPending,
    isRejected: checkImportStatusRejected,
    result: importStateResult,
    makeRequest: importStateRequest,
  } = useRequest(
    useCallback(() => checkClusterImportStatus(instance.id), [instance.id]),
    { handleError: true },
  );

  let showCreate = instance?.clusterPolicy.blueprintPolicy.includes('create');
  let platformTest = user?.platformActions?.find(
    ({ name, platforms }) =>
      name === 'dataapps' && platforms.includes(instance.platform),
  );
  let platform = instance?.platform;

  const callImportStateRequest = useCallback(() => {
    async function importStateRequestRefresh() {
      try {
        let { data } = await importStateRequest();
        if (data.state === 'loading') {
          await wait(10000);
          importStateRequestRefresh();
        }
      } catch {
        await wait(3000);
        importStateRequestRefresh();
      }
    }
    importStateRequestRefresh();
  }, [importStateRequest]);

  useEffect(() => {
    callImportStateRequest();
  }, [callImportStateRequest]);

  function handleTryAgain(failedBlueprintsIds: number[]) {
    history.push(`/data-apps?cluster=${instance.name}`, {
      failedBlueprintsIds,
    });
  }

  let blueprintLoading = !importStateResult?.data.importedBlueprints;

  let importState = importStateResult?.data;
  // set importState to loading if importing is happen or checkStatus is loading or error
  if (
    !blueprintLoading &&
    (checkImportStatusPending || checkImportStatusRejected)
  ) {
    importState = {
      state: 'loading',
      importedBlueprints: importState?.importedBlueprints ?? {},
    };
  }

  let { wasLoading } = useCheckStatus({
    importState,
    onTryAgain: handleTryAgain,
  });

  let isClusterNotRunning = state !== 'running';
  let importLoading = importState?.state === 'loading';

  let disableMessage;
  if (isClusterNotRunning) {
    disableMessage =
      'Your cluster must be connected to be able to import Data Apps.';
  } else if (importLoading) {
    disableMessage = 'Currently importing Data App(s), please try again later.';
  }

  let importedBlueprintItems = importState
    ? Object.keys(importState?.importedBlueprints)
    : null;

  function getBlueprintCount() {
    if (importedBlueprintItems && importState) {
      let count: number = 0,
        blueprintItem: string;
      for (blueprintItem of importedBlueprintItems) {
        count += importState?.importedBlueprints[blueprintItem].length;
      }
      return count;
    }
  }

  let importedBlueprintsLength = getBlueprintCount();

  return (
    <div className="Blueprint DetailsSection">
      <div>
        {importedBlueprintsLength ? (
          <div className="DetailsSection__collapse-action">
            {showCreate &&
              (disableMessage ? (
                <Tooltip title={disableMessage}>
                  <span>
                    <Button
                      size="small"
                      type="default"
                      className="icon-btn"
                      disabled
                      onClick={e => {
                        e.stopPropagation();
                      }}
                    >
                      <PlusOutlined /> Import
                    </Button>
                  </span>
                </Tooltip>
              ) : (
                <Link
                  className="icon-btn"
                  to={`/data-apps?cluster=${instance.name}`}
                >
                  <Button size="small" type="default">
                    <PlusOutlined /> Import
                  </Button>
                </Link>
              ))}
          </div>
        ) : null}
        <div
          className="DetailsSection__collapse-title"
          style={{ color: '#393e41' }}
        >
          Data Apps
        </div>
        <h4>
          {importedBlueprintsLength ? `${importedBlueprintsLength} ` : 'No '}
          Data Apps Installed
        </h4>
        <div
          className="DetailsSection__collapse-desc"
          style={{ color: '#5f6972' }}
        >
          A Data App is a services accelerator to help organizations get up and
          running quickly on complex data sources and use cases.
        </div>
      </div>

      <BlueprintSelect
        service={service}
        importState={importState}
        wasLoading={wasLoading}
        blueprintLoading={blueprintLoading}
        instanceName={instance.name}
        showCreate={showCreate}
        disableMessage={disableMessage}
        platformTest={platformTest}
        instancePlatform={platform}
      />
    </div>
  );
}

export default Blueprint;
