import React, { ComponentType, useContext, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import SignUp from 'components/SignUp/SignUp';
import NotFound from 'components/NotFound/NotFound';
import Clusters from 'components/Clusters/Clusters';
import UserAcceptInvitation from 'components/UserAcceptInvitation/UserAcceptInvitation';
import UserAcceptOwnership from 'components/UserAcceptOwnership/UserAcceptOwnership';
import Support from 'components/Support/Support';
import ClusterCreate from 'components/ClusterCreate/ClusterCreate';
import ClusterDetails from 'components/ClusterDetails/ClusterDetails';
import MyAccount from 'components/MyAccount/MyAccount';
import BlueprintsPage from 'components/BlueprintsPage/BlueprintsPage';
import Plans from 'components/Plans/Plans';
import Consumption from 'components/Consumption/Consumption';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import ConsentFlowForm from 'components/ConsentFlow/ConsentForm/ConsentForm';
import { SessionContext } from 'auth/SessionProvider';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import { ErrorHandler } from 'auth/ErrorHandler';
import { Spinner } from 'components/Spinner/Spinner';
import SomethingWentWrongPage from 'components/ErrorPages/SomethingWentWrongPage/SomethingWentWrongPage';
import { showErrorMessage } from 'utils/errors';
import RedirectUser from 'components/RedirectUser/RedirectUser';

interface LocationState {
  redirectTo?: any;
}

function AppRoutes() {
  const { user, error, isAuthenticated, getUserErrorStatusCode } =
    useContext(SessionContext);
  const { pathname } = useLocation<LocationState>();

  useEffect(() => {
    let script = document.createElement('script');
    if (pathname === '/signup' || pathname === '/signup/') {
      script = document.createElement('script');

      script.src = '//js.hs-scripts.com/7507810.js';
      script.async = true;
      script.defer = true;
      script.id = 'hs-script-loader';

      document.body.appendChild(script);
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'block'));
    } else {
      document
        .querySelectorAll('.hs-cookie-notification-position-bottom')
        .forEach((e: any) => (e.style.display = 'none'));
    }
  });

  const { isToggleFeatureEnabled: paymentIsEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.payment,
  });

  if (error) {
    return <ErrorHandler message={error.message} />;
  }

  if (getUserErrorStatusCode) {
    if (getUserErrorStatusCode?.message?.startsWith('5')) {
      return <SomethingWentWrongPage />;
    } else {
      showErrorMessage(getUserErrorStatusCode?.name);
    }
  }

  if (user?.uuid && !user?.userDataIsSet && !getUserErrorStatusCode) {
    return <Spinner />;
  }

  if (user?.email && isAuthenticated) {
    if (user?.trialExpired) {
      return <ErrorHandler message={'Trial Expired'} />;
    } else if (!user?.confirmed) {
      return <ErrorHandler message={'Verify Your Email'} />;
    }
  }

  return (
    <Switch>
      <Route
        exact
        path={['/', '/sso', '/signin']}
        render={() => {
          if (!isAuthenticated) return <SignUp isSSO={true} />;
          return <RedirectUser />;
        }}
      />
      {/* > REACT_APP_RUN_AUTH0_LOCAL=true react-app-rewired start */}
      <Route
        exact
        path={['/admin']}
        render={() => {
          if (!isAuthenticated) return <SignUp />;
          return <Redirect to="/clusters" />;
        }}
      />
      <Route exact path="/signup">
        {isAuthenticated ? (
          <Redirect to="/clusters" />
        ) : (
          <SignUp isSignUp={true} />
        )}
      </Route>
      <Route exact path="/signin-cloud">
        {isAuthenticated ? (
          <Redirect to="/clusters" />
        ) : (
          <SignUp isSSO={true} />
        )}
      </Route>
      <ProtectedRoute exact path="/clusters" component={Clusters} />
      <ProtectedRoute exact path="/data-apps" component={BlueprintsPage} />
      <ProtectedRoute
        exact
        path="/data-apps/:blueprintName"
        component={BlueprintsPage}
      />
      <ProtectedRoute exact path="/instances" component={Clusters} />
      <ProtectedRoute exact path="/clusters/create" component={ClusterCreate} />
      <ProtectedRoute
        exact
        path="/clusters/:clusterName"
        component={ClusterDetails}
      />
      <ProtectedRoute exact path="/my-account" component={MyAccount} />
      <ProtectedRoute exact path="/consumption" component={Consumption} />
      <ProtectedRoute
        exact
        path="/plans"
        component={paymentIsEnabled ? Plans : NotFound}
      />
      <ProtectedRoute exact path="/support" component={Support} />
      <ProtectedRoute
        exact
        path="/users/acceptclusterinv"
        component={UserAcceptInvitation}
      />
      <ProtectedRoute
        exact
        path="/users/acceptownershiptransfer"
        component={UserAcceptOwnership}
      />
      <ProtectedRoute exact path="/consentflow" component={ConsentFlowForm} />
      <Route exact path="/verify">
        <EmailSentPage />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}

interface ProtectedRouteProps {
  path?: string | string[];
  exact?: boolean;
  component: ComponentType<any>;
}

function ProtectedRoute({
  path,
  exact,
  component,
}: React.PropsWithChildren<ProtectedRouteProps>) {
  let location = useLocation();
  const urlSearchParams: { [key: string]: string } = {};
  const queryParams = new URLSearchParams(location.search);
  for (const [key, value] of queryParams.entries()) {
    urlSearchParams[key] = value;
  }

  return (
    <Route
      exact={exact}
      path={path}
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <FullPageLoader />,
        returnTo: location.pathname || '/',
        loginOptions: {
          redirectUri: window.location.origin,
          appState: {
            returnTo: location.pathname,
            ...urlSearchParams,
          },
        },
      })}
    />
  );
}

export default AppRoutes;
