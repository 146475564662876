import React from 'react';
import './PythonPackageInstallationStatus.less';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';
import {
  InstalledPythonPackage,
  pythonInstallationStateEnum,
} from 'types/cluster';

type PythonPackageInstallationStatusProps = {
  pythonPackage: InstalledPythonPackage;
  reinstallPythonPackage: (packageName: string) => void;
  isClusterRunning: boolean;
};
function PythonPackageInstallationStatus({
  pythonPackage,
  reinstallPythonPackage,
  isClusterRunning,
}: PythonPackageInstallationStatusProps) {
  const intl = useIntl();
  const pythonPackageInstallationStatusLabelMap = new Map<
    pythonInstallationStateEnum,
    string
  >([
    [
      pythonInstallationStateEnum.installing,
      intl.formatMessage({
        id: 'clusterConfiguration.pythonPackages.errorTooltip.status.installing',
      }),
    ],
    [
      pythonInstallationStateEnum.failed,
      intl.formatMessage({
        id: 'clusterConfiguration.pythonPackages.errorTooltip.status.failed',
      }),
    ],
    [
      pythonInstallationStateEnum.succeeded,
      intl.formatMessage({
        id: 'clusterConfiguration.pythonPackages.errorTooltip.status.succeeded',
      }),
    ],
  ]);

  const hasAnalyticsOrLoaderFailed =
    pythonPackage.analyticsState === pythonInstallationStateEnum.failed ||
    pythonPackage.loaderState === pythonInstallationStateEnum.failed;

  function handleReinstall() {
    reinstallPythonPackage(pythonPackage.name);
  }

  return (
    <div className="python-package-installation-status__wrapper">
      <div className="python-package-installation-status__row">
        <label>
          <FormattedMessage id="clusterConfiguration.pythonPackages.errorTooltip.analytics" />
        </label>
        <label className="python-package-installation-status__status-value">
          {pythonPackageInstallationStatusLabelMap.get(
            pythonPackage.analyticsState as pythonInstallationStateEnum,
          )}
        </label>
      </div>
      <div className="python-package-installation-status__row">
        <label>
          <FormattedMessage id="clusterConfiguration.pythonPackages.errorTooltip.loader" />
        </label>
        <label className="python-package-installation-status__status-value">
          {pythonPackageInstallationStatusLabelMap.get(
            pythonPackage.loaderState as pythonInstallationStateEnum,
          )}
        </label>
      </div>
      {hasAnalyticsOrLoaderFailed && isClusterRunning && (
        <div className="python-package-installation-status__row action">
          <label
            className={clsx('python-package-installation-status__action', {
              'disabled-link': !isClusterRunning,
            })}
            onClick={handleReinstall}
          >
            <FormattedMessage id="clusterConfiguration.pythonPackages.errorTooltip.reinstall" />
          </label>
        </div>
      )}
    </div>
  );
}

export default PythonPackageInstallationStatus;
