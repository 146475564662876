import React from 'react';
import { Button, notification } from 'antd';
import { useIntl } from 'react-intl';
import './AzureCreationClusterErrorModal.less';
import TextArea from 'antd/lib/input/TextArea';
import copy from 'clipboard-copy';
import { format } from 'date-fns';

interface AzureCreationClusterErrorModalProps {
  error: string;
  createdAt: string;
  closeModal: () => void;
}
function AzureCreationClusterErrorModal({
  error,
  createdAt,
  closeModal,
}: AzureCreationClusterErrorModalProps) {
  const intl = useIntl();

  const copyToClipboard = () => {
    copy(error);
    notification.success({
      message: intl.formatMessage({
        id: 'clustersPage.azureCreationClusterCard.errorModal.copySuccessful',
      }),
    });
  };

  return (
    <div className="azure-creation-cluster-error-modal__wrapper">
      <section>
        {intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.errorModal.createdAt',
        })}{' '}
        {format(new Date(createdAt), 'dd MMM yyyy HH:mm:ss')}
      </section>
      <section>
        <h3>
          {intl.formatMessage({
            id: 'clustersPage.azureCreationClusterCard.errorModal.error',
          })}
          <TextArea rows={4} value={error} style={{ resize: 'none' }} />
        </h3>
      </section>
      <section className="azure-creation-cluster-error-modal__buttons">
        <Button onClick={copyToClipboard}>
          {intl.formatMessage({
            id: 'clustersPage.azureCreationClusterCard.errorModal.copyToClipboardBtn',
          })}
        </Button>
        <Button type="primary" onClick={closeModal}>
          {intl.formatMessage({
            id: 'clustersPage.azureCreationClusterCard.errorModal.okBtn',
          })}
        </Button>
      </section>
    </div>
  );
}

export default AzureCreationClusterErrorModal;
