import './ServiceCard.less';

import React from 'react';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import clsx from 'clsx';
import { ReactComponent as OpenIcon } from 'images/open-icon.svg';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
import { ReactComponent as DisconnectedIcon } from 'images/disconnected.svg';
import { ReactComponent as LoadingIcon } from 'images/loading-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';

interface ServiceCardProps {
  instance: Instance;
  service: Service;
  standOut: boolean;
}
function ServiceCard({ instance, service, standOut }: ServiceCardProps) {
  let chidori_url,
    temp_service = service;
  if (service.name.includes('Spark') && instance?.InstanceChidori?.isChidori) {
    service = instance.services[1]; //get status of cmc instead os spark in order to login to chidori
    chidori_url = `${process.env.REACT_APP_CHIDORI_FRONTEND_URL}/cluster/${instance.name}`;
  }

  let status = getClusterStatus(instance, service);

  let statusIcon =
    status === 'starting-up' || status === 'creating' ? (
      <Icon component={LoadingIcon} />
    ) : status === 'running' ? (
      <Tooltip title="Connected">
        <Icon component={CheckIcon} />
      </Tooltip>
    ) : status === 'sleeping' ? (
      <Tooltip title="Disconnected">
        <Icon component={DisconnectedIcon} style={{ color: '#B43237' }} />
      </Tooltip>
    ) : null;

  service = temp_service;
  let title: string = service.name;
  let subTitle: string = '';
  if (service.name.includes('Incorta')) {
    title = 'Incorta';
    subTitle = 'Analytics';
  } else if (service.name.includes('CMC')) {
    title = 'CMC';
    subTitle = 'Cluster Management Console ';
  } else if (service.name.includes('Spark')) {
    title = 'Spark';
    subTitle = 'SQL Interface & Materialized View';
  }

  return (
    <a
      className={clsx(
        'ServiceCard__wrapper-link',
        status !== 'running' && 'ServiceCard__wrapper-link--disable',
      )}
      href={chidori_url || service.url}
      target="_blank"
      rel="noopener noreferrer"
      onClick={e => {
        if (status !== 'running') {
          e.preventDefault();
        }
      }}
    >
      <div className={clsx('ServiceCard', standOut && 'ServiceCard--standout')}>
        <div className="ServiceCard__title">
          {statusIcon} {title} <small>{subTitle}</small>
        </div>
        <div className="ServiceCard__link">
          <Icon component={OpenIcon}></Icon>
        </div>
      </div>
    </a>
  );
}

export default ServiceCard;
