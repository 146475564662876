import React from 'react';
import Icon from '@ant-design/icons';
import { useHistory } from 'react-router';
import MainCard from '../MainCard/MainCard';
import { ReactComponent as RegionIcon } from 'images/region.svg';
import { Instance } from 'types/cluster';

interface ClusterRegionCardProps {
  instance: Instance;
  isTrial: boolean;
}

function ClusterRegionCard({ instance, isTrial }: ClusterRegionCardProps) {
  let history = useHistory();

  return (
    <MainCard
      icon={<Icon component={RegionIcon} />}
      title={'Cluster Region'}
      subtitle={instance.regionAlias}
      footerText={'Configure Cluster Region'}
      onFooterClick={() => {
        history.push(
          `/clusters/${instance.name}?tab=configurations#cluster-region`,
        );
      }}
      hideFooter={!isTrial}
    />
  );
}

export default ClusterRegionCard;
