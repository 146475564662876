import React from 'react';
import {
  ApiOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DisconnectOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ScheduledEventEnum, ScheduledEventStatusEnum } from 'types/cluster';

function useClusterSchedulingHelpers() {
  const intl = useIntl();

  const availableSchedulingActions: Record<
    ScheduledEventEnum,
    { label: string; icon: JSX.Element }
  > = {
    [ScheduledEventEnum.CONNECT]: {
      label: intl.formatMessage({
        id: 'clusterConfiguration.schedulingTab.actions.connect',
      }),
      icon: <ApiOutlined />,
    },
    [ScheduledEventEnum.DISCONNECT]: {
      label: intl.formatMessage({
        id: 'clusterConfiguration.schedulingTab.actions.disconnect',
      }),
      icon: <DisconnectOutlined />,
    },
    [ScheduledEventEnum.SCALE_UP]: {
      label: intl.formatMessage({
        id: 'clusterConfiguration.schedulingTab.actions.scaleUp',
      }),
      icon: <CaretUpOutlined />,
    },
    [ScheduledEventEnum.SCALE_DOWN]: {
      label: intl.formatMessage({
        id: 'clusterConfiguration.schedulingTab.actions.scaleDown',
      }),
      icon: <CaretDownOutlined />,
    },
  };

  const scheduledEventStatusMap = new Map([
    [
      ScheduledEventStatusEnum.QUEUED,
      <Tag icon={<SyncOutlined />} color="processing">
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.status.queued" />
      </Tag>,
    ],
    [
      ScheduledEventStatusEnum.SUCCESS,
      <Tag icon={<CheckCircleOutlined />} color="success">
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.status.success" />
      </Tag>,
    ],
    [
      ScheduledEventStatusEnum.FAILURE,
      <Tag icon={<CloseCircleOutlined />} color="error">
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventTable.status.failed" />
      </Tag>,
    ],
  ]);

  return {
    availableSchedulingActions,
    scheduledEventStatusMap,
  };
}

export default useClusterSchedulingHelpers;
