import React from 'react';
import { useIntl } from 'react-intl';
import ErrorPage from 'components/ErrorPages/ErrorPage';

function TrialExpiredErrorPage() {
  const intl = useIntl();

  return (
    <ErrorPage
      headerContent={
        <>
          {intl.formatMessage({
            id: 'auth0.errorPages.trialExpired.headerLine1',
          })}
          <br />
          {intl.formatMessage({
            id: 'auth0.errorPages.trialExpired.headerLine2',
          })}
        </>
      }
      subheaderContent={
        <>
          <a
            className="error-page__contact-support"
            href="https://cloud.incorta.com/support"
            target="_blank"
            rel="noopener noreferrer"
          >
            {intl.formatMessage({
              id: 'auth0.errorPages.trialExpired.contactSupport',
            })}
          </a>
          {intl.formatMessage({
            id: 'auth0.errorPages.trialExpired.subheader',
          })}
        </>
      }
    ></ErrorPage>
  );
}

export default TrialExpiredErrorPage;
