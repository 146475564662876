import './EmptyBlueprint.less';

import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { Button, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as NoTenantIcon } from '../icons/NoTenantIcon.svg';

function EmptyBlueprint({
  instanceName,
  showCreate,
  disableMessage,
  platformTest,
}: any) {
  const intl = useIntl();
  return (
    <div className="EmptyBlueprint">
      <NoTenantIcon />
      <p>You didn’t import any Data Apps to this cluster</p>
      <Link
        className="icon-btn icon-btn-import"
        to={`/data-apps?cluster=${instanceName}`}
      >
        {showCreate &&
          (disableMessage ? (
            <Tooltip title={disableMessage}>
              <span>
                <Button
                  size="small"
                  type="default"
                  className="icon-btn"
                  disabled
                  onClick={e => {
                    e.stopPropagation();
                  }}
                >
                  <PlusOutlined /> Import
                </Button>
              </span>
            </Tooltip>
          ) : (
            <Link
              className="icon-btn"
              to={`/data-apps?cluster=${instanceName}`}
            >
              <Tooltip
                title={
                  !platformTest ? (
                    <FormattedMessage
                      id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                      values={{
                        action: intl.formatMessage({
                          id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.dataApps',
                        }),
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <Button size="small" type="default" disabled={!platformTest}>
                  <PlusOutlined /> Import
                </Button>
              </Tooltip>
            </Link>
          ))}
      </Link>
    </div>
  );
}

export default EmptyBlueprint;
