import './ClusterCreatePage.less';

import React, { useContext } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import ClusterForm from '../ClusterForm/ClusterForm';
import { SessionContext } from 'auth/SessionProvider';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { CSize, ClusterData } from 'types/cluster';
import { displaySuccessMessage } from 'utils';
import { useCreateCluster } from 'hooks/cluster';

const { Title } = Typography;

function ClusterCreatePage() {
  const history = useHistory();
  const { user } = useContext(SessionContext);

  const {
    mutateAsync: mutateCreateCluster,
    isLoading: isCreateClusterPending,
  } = useCreateCluster();

  async function handleCreateCluster(formData: ClusterData) {
    await mutateCreateCluster({
      userId: user.uuid,
      cluster: { ...formData, sleeppable: !formData.alive },
    });
    displaySuccessMessage(`cluster is created successfully`);
    history.push('/clusters');
  }

  const configurations = user?.configurations;
  const defaultCluster = {
    name: '',
    csize: configurations?.showInstanceSize.value ?? CSize.small,
    dsize: configurations?.showDiskSize.value,
    image: configurations?.showLatestRelease.value,
    tenantID: configurations?.showTenants.value,
    installation: configurations?.showInstallation.value,
    alive: configurations?.showAlwaysAlive.value,
    idleTime: 1,
  };

  return (
    <IncortaLayoutContainer>
      <div className="create-cluster">
        <Title level={2} className="create-cluster__title">
          Create New Cluster
        </Title>

        <ClusterForm
          onSubmit={handleCreateCluster}
          loading={isCreateClusterPending}
          cluster={defaultCluster}
        />
      </div>
    </IncortaLayoutContainer>
  );
}

export default ClusterCreatePage;
