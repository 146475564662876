import './Support.less';

import React, { useContext } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import SupportForm from './SupportForm/SupportForm';
import IncBackgroundContainer from 'components/IncBackgroundContainer/IncBackgroundContainer';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { SupportData } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import { useSendSupport } from 'hooks/user';

const { Title } = Typography;

function Support() {
  const { user } = useContext(SessionContext);
  const history = useHistory();

  const { mutateAsync: mutateSendSupport, isLoading } = useSendSupport();

  async function handleSendSupport(formData: SupportData) {
    try {
      await mutateSendSupport({ ...formData, email: user.email });
      const path = user?.uuid ? '/clusters' : '/signin';
      history.push(path);
    } catch {}
  }

  if (user?.uuid) {
    return (
      <IncortaLayoutContainer>
        <div className="support support--auth-page">
          <Title level={3} className="support__title">
            Contact Support
          </Title>

          <SupportForm onSubmit={handleSendSupport} loading={isLoading} />
        </div>
      </IncortaLayoutContainer>
    );
  }

  return (
    <IncBackgroundContainer>
      <div className="support">
        <Title level={3} className="support__title">
          Contact Support
        </Title>

        <SupportForm onSubmit={handleSendSupport} loading={isLoading} />
      </div>
    </IncBackgroundContainer>
  );
}

export default Support;
