import { UseQueryResult, useMutation, useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import {
  me,
  assignClusterToUser,
  sendSupport,
  updatePersonalInformation,
  updateProvisionUserPassword,
  updateAutoScaling,
} from 'services/user';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import {
  AssignCluster,
  PersonalInformationEdit,
  SupportData,
} from 'types/user';
import { queryClient } from 'index';
import { displaySuccessMessage } from 'utils';

function handleError(error: any) {
  const errorMessage = error && getErrorMessage(error);
  if (errorMessage) {
    showErrorMessage(errorMessage);
  }
}

export function useUpdatePersonalInformation() {
  const queryOptions = useMutation(
    async ({
      userId,
      personalInformationEdit,
    }: {
      userId: string;
      personalInformationEdit: PersonalInformationEdit;
    }) => {
      return await updatePersonalInformation({
        userId,
        personalInformationEdit,
      });
    },
    {
      async onSuccess() {
        await queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA);
      },
      onError(error: any) {
        handleError(error);
      },
    },
  );

  return queryOptions;
}

export function useSendSupport() {
  const queryOptions = useMutation(
    async (data: SupportData) => {
      return await sendSupport(data);
    },
    {
      mutationKey: [QueryServerKeys.USER.SUPPORT],
      onSuccess({ data }) {
        displaySuccessMessage(data?.message);
      },
      onError(error: any) {
        handleError(error);
      },
    },
  );

  return queryOptions;
}

export function useUpdateProvisionUserPassword() {
  const queryOptions = useMutation(
    async ({
      userID,
      isSkipped,
      password,
    }: {
      userID: string;
      isSkipped: boolean;
      password?: string;
    }) => {
      return await updateProvisionUserPassword({
        userID,
        isSkipped,
        password,
      });
    },
    {
      onError(error: any) {
        handleError(error);
      },
    },
  );

  return queryOptions;
}
//TO-DO Refresh token

export function useGetUserData({
  userId,
  isAuthenticated,
}: {
  userId: string;
  isAuthenticated: boolean;
}) {
  const queryOptions = useQuery(
    [QueryServerKeys.USER.GET_USER_DATA, userId],
    () => me(userId),
    {
      enabled: !!userId && isAuthenticated,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );
  return queryOptions;
}

export function useActivateUserAccount({
  userID,
  enableActivateUserAccount,
}: {
  userID: string;
  enableActivateUserAccount: boolean;
}) {
  const queryOptions = useQuery([userID], () => assignClusterToUser(userID), {
    enabled: enableActivateUserAccount,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchOnMount: false,
  });

  return queryOptions as UseQueryResult<AxiosResponse<AssignCluster>, any>;
}

export function useUpdateAutoScaling() {
  const queryOptions = useMutation(
    async ({
      userId,
      instanceID,
      isEnabled,
    }: {
      userId: string;
      instanceID: string;
      isEnabled: boolean;
    }) => {
      return await updateAutoScaling({
        userId,
        instanceID,
        isEnabled,
      });
    },
    {
      async onSuccess(data, { userId }) {
        displaySuccessMessage(data.data.message);
        return await queryClient.invalidateQueries([
          QueryServerKeys.CLUSTER.CLUSTER_LIST_KEY,
          userId,
        ]);
      },
      onError(error: any) {
        handleError(error);
      },
    },
  );

  return queryOptions;
}
