import React, { useState } from 'react';
import './BlueprintInstallModal.less';
import {
  ExclamationCircleOutlined,
  CheckCircleOutlined,
} from '@ant-design/icons';
import { Form, Button, Input, Modal, Select, Typography, Switch } from 'antd';
import { useHistory } from 'react-router-dom';
import ClusterTenantsField from './ClusterTenantsField/ClusterTenantsField';
import CopyParquetToggleField from 'components/BlueprintsPage/BlueprintInstallModal/CopyParquetToggleField/CopyParquetToggleField';
import { getErrorMessage } from 'utils/errors';
import {
  importBlueprints,
  exportBlueprints,
  downloadExportedBlueprint,
  getServicesStatus,
} from 'services/cluster';
import { Instance, ServiceStatus } from 'types/cluster';
import { searchFilterOption } from 'utils';
import { useGetSupportedVersions } from 'hooks/cluster';

export const blueprintInstallModalFormFieldNames = {
  instanceId: 'instanceId',
  tenant: 'tenant',
  username: 'username',
  password: 'password',
  folders: 'folders',
  dashboards: 'dashboards',
  enableDynamicJoins: 'enableDynamicJoins',
  includeParquet: 'includeParquet',
  datasource: 'datasource',
} as const;

const { Paragraph } = Typography;
interface BlueprintInstallModalProps {
  onChancel(): void;
  selectedBlueprintNames: string[];
  clusters: Instance[];
  exportOP: boolean;
  slug: string;
}

function BlueprintInstallModal({
  onChancel,
  selectedBlueprintNames,
  clusters,
  exportOP,
  slug,
}: BlueprintInstallModalProps) {
  let history = useHistory();

  let initialCluster: any = undefined;

  const [isCustom, setIsCustom] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [supportedVersions, setSupportedVersions] = useState<any>([]);

  const [form] = Form.useForm();
  const { getFieldValue, validateFields } = form;

  const { mutateAsync: checkIsSupportedVersion } = useGetSupportedVersions();

  const instanceId =
    getFieldValue(blueprintInstallModalFormFieldNames.instanceId) ??
    initialCluster?.id;

  let selectedCluster = clusters.find(cluster => cluster.id === instanceId);

  async function doImport(values: any) {
    setIsLoading(true);
    try {
      const { data } = await getServicesStatus(instanceId);
      if (data?.loaderStatus === ServiceStatus.RUNNING) {
        importBlueprints({
          ...values,
          names: selectedBlueprintNames.join(','),
        })
          .then(() => {
            setIsLoading(false);
            history.push(`/clusters/${selectedCluster?.name}?tab=data-apps`);
          })
          .catch(error => {
            setIsLoading(false);
            setErrorMessage(getErrorMessage(error));
          });
      } else {
        setIsLoading(false);
        setErrorMessage(
          'Loader service is not running on the selected cluster',
        );
      }
    } catch (error) {
      setIsLoading(false);
      setErrorMessage(getErrorMessage(error));
    }
  }

  function doExport(values: any) {
    setIsLoading(true);
    exportBlueprints({
      ...values,
    })
      .then(response => {
        Modal.success({
          title: response?.data?.message,
          content: <Paragraph>{response?.data?.summary}</Paragraph>,
          icon: <CheckCircleOutlined />,
          centered: true,
          width: 620,
          okText: 'DONE',
        });
        downloadExportedBlueprint(response?.data?.id);

        setIsLoading(false);
        onChancel();
      })
      .catch(error => {
        setIsLoading(false);
        setErrorMessage(getErrorMessage(error));
      });
  }

  async function handleSubmit(values: any) {
    try {
      await validateFields();

      if (exportOP) {
        doExport(values);
      } else {
        let overWrittenBlueprint = [];
        for (let blueprintItemName of selectedBlueprintNames) {
          let importedBlueprint = selectedCluster?.importedBlueprints?.filter(
            importedBlueprint =>
              importedBlueprint.blueprint.name === blueprintItemName &&
              importedBlueprint.tenant === values.tenant,
          );
          if (importedBlueprint && importedBlueprint.length > 0) {
            overWrittenBlueprint.push(blueprintItemName);
          }
        }

        if (overWrittenBlueprint.length) {
          let names = overWrittenBlueprint.join(', ');
          Modal.confirm({
            title: 'Data App already imported.',
            content: `Please note that ${names} is already imported to your cluster, do you want to overwrite the currently added data app?`,
            centered: true,
            width: 620,
            okText: 'Confirm',
            cancelText: 'Cancel',
            onOk() {
              doImport({ ...values, overwrite: true });
            },
          });
        } else {
          doImport(values);
        }
      }
    } catch {}
  }

  return (
    <div className="BlueprintInstallModal" data-testid="installation-modal">
      <div
        className="BlueprintInstallModal__error-container"
        data-testid="error-message"
      >
        {errorMessage && <div>{errorMessage}</div>}
      </div>

      <Form
        form={form}
        layout="vertical"
        id="TenantSelectArea"
        onFinish={handleSubmit}
      >
        {isCustom && (
          <p className="BlueprintInstallModal__custom-warning">
            <ExclamationCircleOutlined />
            {`the cluster custom build might not be compatible with the data app which supports a minimal version ${supportedVersions}, if it takes more than five minutes in loading this means that the data app is not applied.`}
          </p>
        )}

        <Form.Item
          name={blueprintInstallModalFormFieldNames.instanceId}
          label={'Cluster'}
          initialValue={initialCluster?.id}
          rules={[
            { required: true },
            {
              async validator(_rule, value, callback) {
                const Cluster: any = exportOP
                  ? { data: { isSupported: true } }
                  : await checkIsSupportedVersion({ slug, instanceId: value });
                setSupportedVersions(Cluster.data.supportedVersions);
                if (!Cluster.data.isSupported) {
                  if (Cluster.data.isCustom) {
                    setIsCustom(true);
                    callback();
                  } else {
                    setIsCustom(false);
                    callback(
                      `This cluster release doesn't support this data app, ` +
                        `This data app is only compatible with incorta release(s) starting from: ${Cluster.data.supportedVersions}`,
                    );
                  }
                } else {
                  setIsCustom(false);
                }
              },
            },
          ]}
        >
          <Select
            showSearch
            filterOption={searchFilterOption}
            placeholder={'Select Cluster'}
            data-testid="cluster-dropdown"
          >
            {clusters?.map(cluster => (
              <Select.Option
                data-testid="cluster-dropdown-options"
                key={cluster.id}
                value={cluster.id}
              >
                {cluster.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <ClusterTenantsField
          instanceName={
            selectedCluster
              ? selectedCluster.name
              : clusters[0]
              ? clusters[0].name
              : ''
          }
        />

        <Form.Item
          name={blueprintInstallModalFormFieldNames.username}
          label="Username"
          initialValue="admin"
          rules={[
            {
              required: true,
              message: 'Please input your username!',
            },
          ]}
        >
          <Input data-testid="username" placeholder="username" />
        </Form.Item>

        <Form.Item
          name={blueprintInstallModalFormFieldNames.password}
          label="Password"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please input your password!',
            },
          ]}
        >
          <Input.Password data-testid="password" />
        </Form.Item>

        {exportOP && (
          <>
            <Form.Item
              name={blueprintInstallModalFormFieldNames.folders}
              label="Export Folder(s)"
            >
              <Input placeholder="Export data app folders (comma-separated)" />
            </Form.Item>
            <Form.Item
              name={blueprintInstallModalFormFieldNames.dashboards}
              label="Export Dashboard(s)"
            >
              <Input placeholder="Export data app dashboards (comma-separated)" />
            </Form.Item>
            <Form.Item
              name={blueprintInstallModalFormFieldNames.enableDynamicJoins}
              className="BlueprintInstallModal__switch-field"
              label="Enable Dynamic Joins"
              initialValue={true}
            >
              <Switch defaultChecked />
            </Form.Item>

            <CopyParquetToggleField />
          </>
        )}

        <div className="BlueprintInstallModal__footer-bottom">
          <Button onClick={onChancel}>Cancel</Button>
          <Button
            htmlType="submit"
            type="primary"
            loading={isLoading}
            data-testid="submit"
          >
            {exportOP ? 'Export' : 'Import'}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default BlueprintInstallModal;
