import './TrailEnded.less';

import React from 'react';
import { Button, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ReactComponent as TrailEndIcon } from './icons/trail-end.svg';

const { Title, Paragraph } = Typography;

const openInNewTab = (url:any) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

function TrailEnded({ user }: any) {
  return (
    <div className="trail-ended">
      <div>
        <TrailEndIcon />

        <Title level={2} className="trail-ended__title">
          YOUR TRIAL OF INCORTA CLOUD IS EXPIRED
        </Title>

        <Paragraph className="trail-ended__text">
          Are you ready to join Incorta and start transformation of your business?
        </Paragraph>

        {
          user && !user.vholParticipation && !user.extendedTrial ? 
          (
            <Button type="primary" onClick={()=>openInNewTab("https://www.incorta.com/virtual-hands-on-lab-series?web_location=homepage")}> REGISTER IN VIRTUAL HANDS ON LAB </Button>

          ):(
            <Link to="/support">
            <Button type="primary"> REQUEST UPGRADE NOW </Button>
            </Link>
          )
        }


      </div>
    </div>
  );
}

export default TrailEnded;
