import React, { useContext, useRef } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import cookie from 'js-cookie';
import { SessionContext } from 'auth/SessionProvider';
import { useActivateUserAccount } from 'hooks/user';
import { Spinner } from 'components/Spinner/Spinner';
import SomethingWentWrongPage from 'components/ErrorPages/SomethingWentWrongPage/SomethingWentWrongPage';
import { showErrorMessage } from 'utils/errors';

function RedirectUser() {
  const willRedirectOut = useRef(false);
  const { pathname, search, hash } = useLocation();
  const queryParams = new URLSearchParams(search);
  const redirectTo = queryParams.get('redirect_to');
  const clusterURLFromQueryParam =
    redirectTo && `${redirectTo}${hash ? hash : ''}`;

  function redirectToClusterUsingSSO(clusterURL: string) {
    willRedirectOut.current = true;
    window.location.href = clusterURL;
    return null;
  }

  const { user, isAuthenticated } = useContext(SessionContext);
  const enableActivateUserAccount = !!(
    user?.uuid &&
    isAuthenticated &&
    user?.newUser &&
    (user?.confirmed || user?.email_verified) &&
    !user?.assignedCluster
  );

  const {
    data: result,
    isLoading: isActivationUserServiceLoading,
    error: getActivationErrorStatusCode,
  } = useActivateUserAccount({
    userID: user?.uuid,
    enableActivateUserAccount,
  });

  const isSignInAndHaveQueryParams = !!(
    pathname === '/signin' && clusterURLFromQueryParam
  );
  const isJustLoggedIn = !localStorage.getItem('firstLogin');
  const isClusterJustAssignedToUser = !!result?.data?.redirectTo;
  const shouldRedirectOut =
    isSignInAndHaveQueryParams || isJustLoggedIn || isClusterJustAssignedToUser;

  if (isSignInAndHaveQueryParams) {
    redirectToClusterUsingSSO(clusterURLFromQueryParam!);
    // else will continue as in case of new user confirming his account
  }

  if (getActivationErrorStatusCode)
    if (getActivationErrorStatusCode?.message?.startsWith('5')) {
      return <SomethingWentWrongPage />;
    } else {
      showErrorMessage(getActivationErrorStatusCode?.name);
    }

  if (
    isActivationUserServiceLoading ||
    isActivationUserServiceLoading === undefined
  )
    return <Spinner />;

  const crypted = result?.data?.crypted;
  if (crypted) {
    cookie.set('cloudSSO', crypted, {
      domain: process.env.REACT_APP_DOMAIN,
      expires: 1000 * 60 * 60 * 24 * 365,
      secure: true,
      sameSite: 'strict',
    });
  }

  if (isClusterJustAssignedToUser) {
    willRedirectOut.current = true;
    window.location.href = result?.data?.redirectTo!;
    return null;
  }

  if (isJustLoggedIn && user?.redirectTo) {
    willRedirectOut.current = true;
    localStorage.setItem('firstLogin', 'true');
    window.location.href = user?.redirectTo;
    return null;
  }

  if (!shouldRedirectOut && !willRedirectOut.current) {
    return <Redirect to="/clusters" />;
  }

  return <Spinner />;
}
export default RedirectUser;
