import './SomethingWentWrong.less';

import React from 'react';
import { Typography } from 'antd';
import { ReactComponent as SomethingWentWrongIcon } from './icons/SomethingWentWrong.svg';

const { Title, Paragraph } = Typography;

function SomethingWentWrong() {
  return (
    <div className="something-went-wrong">
      <SomethingWentWrongIcon />

      <Title level={2} className="something-went-wrong__title">
        OOPS!! SOMETHING WENT WRONG.
      </Title>

      <Paragraph type="secondary">
        We are facing some problems and working hard to solve it.
      </Paragraph>
    </div>
  );
}

export default SomethingWentWrong;
