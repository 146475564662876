import React, { useEffect, useState, useContext } from 'react';
import { Form, Select, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Region } from 'types/cluster';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
import { InstancePlatform } from 'utils/platforms';
import { SessionContext } from 'auth/SessionProvider';

interface IncortaClusterRegionFieldProps {
  isTrial?: boolean;
  regions: Region[];
  currentPlatform: string;
}

function IncortaClusterRegionField({
  isTrial,
  regions,
  currentPlatform,
}: IncortaClusterRegionFieldProps) {
  const { user } = useContext(SessionContext);
  const [currentRegions, setCurrentRegions] = useState<Region[]>([]);

  useEffect(() => {
    const regionsList = regions.filter((region: Region) =>
      currentPlatform === InstancePlatform.AZURE
        ? region.platform === currentPlatform && region.userId === user.uuid
        : isTrial
        ? region.platform === currentPlatform
        : region.platform === currentPlatform && region.isAvailable,
    );
    setCurrentRegions(regionsList);
    // eslint-disable-next-line
  }, [regions, currentPlatform, user.uuid]);

  return (
    <Tooltip
      title={
        isTrial ? (
          <FormattedMessage id="clusterForm.clusterRegionUpgradeToPermium" />
        ) : (
          ''
        )
      }
    >
      <Form.Item
        name={clusterFormFieldNames.region}
        initialValue={currentRegions[0]?.regionAlias}
        rules={[
          {
            required: true,
            message: (
              <FormattedMessage id="clusterForm.clusterRegionRequired" />
            ),
          },
        ]}
        label={<FormattedMessage id="clusterForm.clusterRegionLabel" />}
      >
        {
          <Select disabled={currentPlatform === InstancePlatform.AZURE}>
            {currentRegions?.map(region => (
              <Select.Option
                key={region.cluster_code + ' ' + region.url}
                value={region.regionAlias}
                disabled={isTrial}
              >
                {region.regionAlias}
              </Select.Option>
            ))}
          </Select>
        }
      </Form.Item>
    </Tooltip>
  );
}

export default IncortaClusterRegionField;
