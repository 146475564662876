export function getFunctionColorMap(functionName: string) {
  functionName = functionName.toLowerCase();

  switch (functionName) {
    case 'cross function': {
      return '#99988E';
    }
    case 'finance': {
      return '#BF82B0';
    }
    case 'operations': {
      return '#F2A555';
    }
    case 'sales': {
      return '#4D72B3';
    }
    case 'supply chain': {
      return '#70A0B3';
    }
    case 'use cases': {
      return '#393E41';
    }
    case 'manufacturing': {
      return '#531dab';
    }
    case 'human resources': {
      return '#08979c';
    }
    case 'projects': {
      return '#5b8c00';
    }
    default: {
      return '#37517F';
    }
  }
}
