import './MyAccount.less';

import React, { useContext } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { Typography, Tabs } from 'antd';
import { parse } from 'query-string';
import { useLocation, useHistory, Link } from 'react-router-dom';
import PersonalInformation from './PersonalInformation/PersonalInformation';
import Plan from './Plan/Plan';
import BillingHistory from './UnitsConsumptionDetails/BillingHistory/BillingHistory';
import UserSecurity from './UserSecurity/UserSecurity';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { SessionContext } from 'auth/SessionProvider';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';

const { Title } = Typography;
const { TabPane } = Tabs;

function MyAccount() {
  let history = useHistory();

  let { search } = useLocation();
  let { tab = 'personal-information' } = parse(search);
  const { user, isLoading } = useContext(SessionContext);

  const { isToggleFeatureEnabled: paymentIsEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.payment,
  });

  if (isLoading) return <FullPageLoader />;

  return (
    <IncortaLayoutContainer>
      <div className="my-account-page">
        <div className="my-account-page__page-header">
          <div className="my-account-page__page-header-info">
            <Link
              to="/clusters"
              className="my-account-page__page-header-back-btn"
            >
              <LeftOutlined />
            </Link>
            <Title level={4}>My Account</Title>
          </div>
        </div>

        <div className="my-account-page__container">
          <Tabs
            destroyInactiveTabPane
            activeKey={tab as string}
            onChange={key =>
              history.push({ pathname: '/my-account', search: `?tab=${key}` })
            }
            className="my-account-page__tabs"
          >
            <TabPane tab="Personal Information" key="personal-information">
              <PersonalInformation />
            </TabPane>
            <TabPane tab="Security" key="security">
              <UserSecurity />
            </TabPane>
            {/* <TabPane tab="Usage Consumption" key="units-consumption-details">
              <UnitsConsumptionDetails />
            </TabPane> */}
            {paymentIsEnabled && (
              <TabPane tab="Plan" key="plan">
                <Plan />
              </TabPane>
            )}
            <TabPane tab="Billing History" key="billing-history">
              <BillingHistory />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </IncortaLayoutContainer>
  );
}

export default MyAccount;
