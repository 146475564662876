export const azureCreationPipelineStatusMap = new Map<string, string>([
  ['Basic_Resources', 'Deploying Basic Resources'],
  ['MYSQL_SERVER', 'Creating Database Services'],
  ['CERT_ISTIO', 'Creating Ingress Gateway'],
  ['OFFPOD_UPGRADPOD_BLOCKPOD', 'Deploying Kubernetes'],
  ['OPERATOR_DEPLOYMENT', 'Deploying Incorta'],
  ['DNS_LB_FOR_ISTIO', 'Setting Up DNS'],
  ['SNAPSHOT_CREATION', 'Provisioning Incorta Images'],
  ['TRIGGGER_CLUSTER_CREATION_PIPELINE', 'Finalizing Deployment'],
]);

export enum clusterCreationStatus {
  INPROGRESS = 'inProgress',
  FAILED = 'failed',
  RUNNING = 'running',
  WAITING = 'waiting',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum AzureClusterType {
  prod = 'prod',
  dev = 'dev',
}

type hasPipelineReachedLastStepProps = {
  currentStep: string;
  states: string[];
};
export function hasPipelineReachedLastStep({
  currentStep,
  states,
}: hasPipelineReachedLastStepProps) {
  if (!currentStep || !states?.length) {
    return false;
  }
  const currentStepIndex = states?.findIndex(state => state === currentStep);
  const lastStepIndex = states?.length - 1;

  if (currentStepIndex === lastStepIndex) {
    return true;
  }
  return false;
}
