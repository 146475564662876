import React, { PropsWithChildren } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { TrackingEventsEnum } from 'types/tracking';
import { trackEvent } from 'services/tracking';

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: PropsWithChildren<any>) => {
  const history = useHistory();

  function prepareSearchParams({
    appState,
  }: {
    appState: { returnTo?: string; returnToClusterURL?: string } | undefined;
  }) {
    const urlSearchParams = Object.fromEntries(
      Object.entries(appState ?? {}).filter(([key]) => key !== 'returnTo'),
    );

    const searchParams = new URLSearchParams();
    Object.entries(urlSearchParams).forEach(([key, value]) =>
      searchParams.append(key, value as string),
    );
    const searchParamsString = searchParams.toString();

    const hasReturnTo = !!appState?.returnTo;
    const returnToURL = hasReturnTo
      ? `${appState?.returnTo}${
          searchParamsString ? `?${searchParamsString}` : ''
        }`
      : '';

    const returnToClusterURL = appState?.returnToClusterURL;

    const state = Object.keys(urlSearchParams)?.length
      ? { ...urlSearchParams }
      : {};

    return {
      hasReturnTo,
      returnToURL,
      returnToClusterURL,
      state,
    };
  }

  const onRedirectCallback = (appState: any) => {
    trackEvent(TrackingEventsEnum.SUBMIT_SIGN_IN_WITH_PASSWORD);

    const { hasReturnTo, returnToURL, returnToClusterURL } =
      prepareSearchParams({
        appState,
      });

    if (!!returnToClusterURL) {
      window.location.href = returnToClusterURL;
    }
    history.push((hasReturnTo && returnToURL) || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      useRefreshTokens={true}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      scope={process.env.REACT_APP_AUTH_SCOPE}
      cacheLocation="localstorage"
      onRedirectCallback={onRedirectCallback}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithRedirectCallback;
