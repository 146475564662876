import { CheckCircleOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Modal, Typography } from 'antd';
import React, { useEffect, useRef } from 'react';
import { ImportState } from 'types/cluster';

const { Paragraph } = Typography;

interface CheckStatusHookProps {
  importState?: ImportState;
  onTryAgain: (failedBlueprintsIds: number[]) => void;
}

function useCheckStatus({ importState, onTryAgain }: CheckStatusHookProps) {
  // simple state machine 🤓
  let loadingStateRef = useRef<'rest' | 'loading' | 'was-loading'>('rest');
  if (
    (loadingStateRef.current === 'rest' ||
      loadingStateRef.current === 'loading') &&
    importState?.state === 'loading'
  ) {
    loadingStateRef.current = 'loading';
  } else if (loadingStateRef.current === 'loading') {
    loadingStateRef.current = 'was-loading';
  }

  let wasLoading = loadingStateRef.current === 'was-loading';

  useEffect(() => {
    if (wasLoading) {
      if (importState?.state === 'success') {
        let hasErrors =
          importState?.error &&
          importState?.failedBlueprints &&
          importState?.failedBlueprints.length > 0;

        if (hasErrors) {
          Modal.confirm({
            title: importState?.message?.title,
            content: (
              <>
                <Paragraph>{importState?.message?.body}</Paragraph>
                <br />
                <div className="success-modal-error">
                  <InfoCircleOutlined />
                  <Paragraph>{importState?.error}</Paragraph>
                </div>
              </>
            ),
            icon: <CheckCircleOutlined />,
            centered: true,
            width: 620,
            okText: 'TRY AGAIN',
            onOk() {
              if (
                importState?.failedBlueprints &&
                importState?.failedBlueprints.length > 0
              ) {
                onTryAgain(
                  importState?.failedBlueprints.map(blueprint => blueprint.id),
                );
              }
            },
            cancelText: 'DONE',
          });
        } else {
          Modal.success({
            title: importState?.message?.title,
            content: <Paragraph>{importState?.message?.body}</Paragraph>,
            icon: <CheckCircleOutlined />,
            centered: true,
            width: 620,
            okText: 'DONE',
          });
        }
      } else if (importState?.state === 'failed') {
        Modal.confirm({
          title: 'Importing Failed',
          content: <Paragraph>{importState?.error}</Paragraph>,
          icon: <InfoCircleOutlined />,
          centered: true,
          width: 620,
          okText: 'TRY AGAIN',
          onOk() {
            if (
              importState?.failedBlueprints &&
              importState?.failedBlueprints.length > 0
            ) {
              onTryAgain(
                importState?.failedBlueprints.map(blueprint => blueprint.id),
              );
            }
          },
          cancelText: 'DONE',
        });
      }
    }
  }, [importState?.state, wasLoading]); // eslint-disable-line

  return { wasLoading };
}

export default useCheckStatus;
