import './AppFooter.less';

import React from 'react';
import Icon from '@ant-design/icons';
import { ReactComponent as Documentation } from 'images/documentation.svg';
import { ReactComponent as CommunityIcon } from 'images/community.svg';
import { ReactComponent as Learn } from 'images/learn.svg';

function AppFooter() {
  return (
    <footer className="footer">
      <div className="footer__main">
        <a
          href="https://community.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer__main-section">
            <Icon component={CommunityIcon} />
            <div className="footer__main-section-text">
              <div>Community</div>
            </div>
          </div>
        </a>

        <a
          href="https://docs.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer__main-section">
            <Icon component={Documentation} />
            <div className="footer__main-section-text">
              <div>Documentation</div>
            </div>
          </div>
        </a>

        <a
          href="https://learn.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="footer__main-section">
            <Icon component={Learn} />
            <div className="footer__main-section-text">
              <div>Free Training</div>
            </div>
          </div>
        </a>
      </div>
    </footer>
  );
}

export default AppFooter;
