import React, { useContext, useState } from 'react';
import './DeltaSharingEdit.less';
import { Modal, Switch, Tooltip, notification } from 'antd';
import { FormattedMessage } from 'react-intl';
import semver from 'semver';
import DeltaSharingModal from './DeltaSharingModal/DeltaSharingModal';
import { SessionContext } from 'auth/SessionProvider';
import { Instance, Service } from 'types/cluster';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';
import { ReactComponent as DeltaSharingLogo } from 'images/delta-sharing.svg';
import { getClusterStatus } from 'utils/cluster';
import { useMutateCluster } from 'hooks/cluster';
import { API_URL } from 'utils/http';

const minimalVersion = '2023.4.0';

type DeltaSharingEditProps = {
  instance: Instance;
  services: Service[];
  title: string;
};
function DeltaSharingEdit({
  instance,
  services,
  title,
}: DeltaSharingEditProps) {
  const { user } = useContext(SessionContext);
  const [showModal, setShowModal] = useState(false);
  const wheelFileName = 'Incorta_Delta_Sharing-0.0.1-py3-none-any.whl';
  const wheelFileURL = `${API_URL}/users/instances/downloadDeltaShare?instanceid=${instance.id}&file=${wheelFileName}`;
  const notebookSampleCodeName = 'Delta-Share-Example.ipynb';
  const notebookSampleCodeURL = `${API_URL}/users/instances/downloadDeltaShare?instanceid=${instance.id}&file=${notebookSampleCodeName}`;

  const isVersionCompatible =
    semver.valid(instance.image) && semver.gte(instance.image, minimalVersion);

  const status = getClusterStatus(instance, services[0]);
  const isClusterRunning = status === 'running';
  const { isToggleFeatureEnabled: isDeltaSharingToggleFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.deltashare,
    });

  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  async function enableDeltaSharing() {
    const { data } = await mutateCluster({ enableDeltaShare: true });
    notification.success({
      message: 'Success',
      description: data.message,
    });
    setShowModal(true);
  }
  async function disableDeltaSharing() {
    const { data } = await mutateCluster({ enableDeltaShare: false });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }
  async function handleChange(enable: boolean) {
    if (enable) {
      Modal.confirm({
        title: 'Enable Delta Sharing',
        content:
          'Enabling this feature will allow anyone having the wheel file to access Delta Lake data stored in GCS buckets according to their tokens and permissions?',
        okText: 'Proceed',
        centered: true,
        width: 620,
        async onOk() {
          enableDeltaSharing();
        },
      });
    } else {
      Modal.confirm({
        title: 'Are you sure you want to disable Delta Sharing?',
        content: '',
        okText: 'Disable',
        centered: true,
        width: 620,
        async onOk() {
          disableDeltaSharing();
        },
      });
    }
  }

  return (
    <div className="ClusterEdit delta-sharing">
      <div className="ClusterEdit__icon">
        <span>
          <DeltaSharingLogo style={{ width: '16px', height: '16px' }} />
        </span>
      </div>
      <div className="ClusterEdit__content delta-sharing__content">
        <div className="delta-sharing__main">
          <h3 className="ClusterEdit__title">{title}</h3>
          <div className="delta-sharing__body">
            <div className="delta-sharing__body-enabled">
              <p className="delta-sharing__hint">
                <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.configurationsDescription1" />
              </p>
              {instance.enableDeltaShare && (
                <p className="delta-sharing__hint">
                  <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.configurationsDescription2" />
                  <button
                    className="link"
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.configurationsDescription3" />
                  </button>

                  <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.configurationsDescription4" />
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="delta-sharing__switch-button">
          <Tooltip
            title={
              !isDeltaSharingToggleFeatureEnabled ? (
                <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.disabled" />
              ) : !isClusterRunning ? (
                <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
              ) : !isVersionCompatible ? (
                <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.unsupportedVersion" />
              ) : (
                ''
              )
            }
          >
            <Switch
              disabled={
                !isClusterRunning ||
                !isDeltaSharingToggleFeatureEnabled ||
                !isVersionCompatible
              }
              onChange={handleChange}
              loading={isLoading}
              checked={instance.enableDeltaShare}
            />
          </Tooltip>
        </div>
      </div>

      <Modal
        visible={showModal}
        title={'Delta Sharing Configurations'}
        onCancel={() => setShowModal(false)}
        width={800}
        centered
        footer={null}
      >
        <DeltaSharingModal
          instance={instance}
          wheelFileName={wheelFileName}
          wheelFileURL={wheelFileURL}
          notebookSampleCodeName={notebookSampleCodeName}
          notebookSampleCodeURL={notebookSampleCodeURL}
        />
      </Modal>
    </div>
  );
}

export default DeltaSharingEdit;
