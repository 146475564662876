import React from 'react';
import PropTypes from 'prop-types';

/**
 * ClickOutside detects when the user has clicked outside of the component's tree. Useful when you want to know when to close a dropdown menu (Filters/Bookmarks menu).
 */

export default class ClickOutside extends React.Component {
  static propTypes = {
    handleClickOutside: PropTypes.func.isRequired,
    className: PropTypes.string,
    safeClicks: PropTypes.arrayOf(PropTypes.string),
    handleFocus: PropTypes.bool
  };

  static defaultProps = {
    safeClicks: ['.ant-notification', '.ant-modal-root'],
    handleFocus: true
  };

  target = React.createRef();

  _checkIsOut = event => {
    if (event?.type === 'focus' && !this.props.handleFous) return;
    const e = event?.type === 'focus' ? event : this.downEvent;

    const target = this.target.current;
    const { safeClicks } = this.props;

    if (!target || !e) return false; //IE Edge target null when unmount

    const path = e.path || this.downEventCompositePath;

    if (e.target.closest && safeClicks.some(selector => e.target.closest(selector))) {
      return false;
    }

    //Use contains first, path as fallback. Path is messed up in Safari esp. when using voiceover
    if (target.contains != null) {
      return !target.contains(e.target);
    } else {
      const pathEl = path?.indexOf?.(target);
      const isInPath = pathEl != null && pathEl !== -1;
      return !isInPath;
    }
  };

  downEvent = null;
  downEventCompositePath = null;

  handleMouseDown = e => {
    this.downEvent = e;
    this.downEventCompositePath = e.composedPath && e.composedPath(); // store calculated composedPath
  };

  handleMouseUp = e => {
    const { handleClickOutside } = this.props;
    if (this._checkIsOut(e) && typeof handleClickOutside === 'function') {
      handleClickOutside();
    }
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleMouseDown, false);
    document.addEventListener('mouseup', this.handleMouseUp, false);
    document.addEventListener('focus', this.handleMouseUp, true);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleMouseDown, false);
    document.removeEventListener('mouseup', this.handleMouseUp, false);
    document.removeEventListener('focus', this.handleMouseUp, true);
  }

  render() {
    return (
      <div className={this.props.className} ref={this.target}>
        {this.props.children}
      </div>
    );
  }
}
