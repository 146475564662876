import { CheckCircleOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import './SubmittingStatus.less';
import { AxiosResponse } from 'axios';

function Loading() {
  return (
    <>
      <Spin />
      <FormattedMessage id="consentFlow.submittingStatus.validating" />
    </>
  );
}
function Success() {
  return (
    <>
      <CheckCircleOutlined className="submitting-status__validation-successful-icon" />
      <FormattedMessage id="consentFlow.submittingStatus.validationSuccessful" />
    </>
  );
}
function Error({ errMsg }: { errMsg: string | null }) {
  return <>{errMsg || 'an error happened'}</>;
}

type SubmittingStatusProps = {
  loading: boolean;
  result: AxiosResponse<any> | null;
  isRejected: boolean;
  errorMessage: string | null;
};
function SubmittingStatus({
  loading,
  result,
  isRejected,
  errorMessage,
}: SubmittingStatusProps) {
  let RenderedComponent = () => <></>;

  if (loading) {
    RenderedComponent = () => <Loading />;
  } else if (result) {
    RenderedComponent = () => <Success />;
  } else if (isRejected) {
    RenderedComponent = () => <Error errMsg={errorMessage} />;
  }

  return (
    <div className="submitting-status">
      <RenderedComponent />
    </div>
  );
}

export default SubmittingStatus;
