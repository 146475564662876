import React from 'react';
import './DeltaSharingModal.less';
import {
  CopyOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import copy from 'clipboard-copy';
import { Tooltip, notification } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { Instance } from 'types/cluster';
import deltaSharingCredentials from 'components/ClusterDetails/ClusterDetailsBody/Configurations/DeltaSharingEdit/DeltaSharingModal/deltaSharingCredentials.json';

function JSONVariable({
  line,
  valueToReplace,
}: {
  line: string;
  valueToReplace: string;
}) {
  return (
    <code className="delta-sharing-modal__code-snippet-line">
      {'  '}
      {line.replace(`"${valueToReplace}"`, '"')}
      <span className="delta-sharing-modal__code-snippet-variable">{`${valueToReplace}`}</span>
      {`",`}
    </code>
  );
}

type DeltaSharingProps = {
  instance: Instance;
  wheelFileName: string;
  wheelFileURL: string;
  notebookSampleCodeName: string;
  notebookSampleCodeURL: string;
};
function DeltaSharingModal({
  instance,
  wheelFileName,
  wheelFileURL,
  notebookSampleCodeName,
  notebookSampleCodeURL,
}: DeltaSharingProps) {
  deltaSharingCredentials.instanceName = instance.name;
  const intl = useIntl();

  const installCommand = `pip install ${wheelFileName}`;
  const jsonCommaSplitted = JSON.stringify(deltaSharingCredentials).split(',');
  const jsonString = JSON.stringify(deltaSharingCredentials, null, 2);

  function copyToClipboard(
    from: 'wheel-file-url' | 'install-command' | 'json' | 'sample-code',
  ) {
    let message = '';

    switch (from) {
      case 'wheel-file-url':
        copy(wheelFileURL);
        message = 'wheelFileURLCopySuccessful';
        break;
      case 'install-command':
        copy(installCommand);
        message = 'installCommandWheelFile';
        break;
      case 'json':
        copy(jsonString);
        message = 'jSONCopySuccessful';
        break;
      case 'sample-code':
        copy(notebookSampleCodeURL);
        message = 'sampleCodeCopySuccessful';
        break;
      default:
        break;
    }

    if (message) {
      notification.success({
        message: intl.formatMessage({
          id: `clusterConfiguration.configurationsTab.deltaSharing.modal.${message}`,
        }),
      });
    }
  }

  return (
    <div className="delta-sharing-modal__wrapper">
      <p>
        <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.headerFollowInstructions1" />
        <a
          href="https://docs.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.headerFollowInstructions2" />
        </a>
        <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.headerFollowInstructions3" />
      </p>

      <ol>
        <li>
          <h2>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step1.header`,
            })}
          </h2>
          <p>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step1.description`,
            })}
          </p>
          <a download href={wheelFileURL} className="download-link link">
            <DownloadOutlined />
            {` ${wheelFileName}`}
          </a>{' '}
          <Tooltip
            title={
              <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.copyWheelFileURL" />
            }
          >
            <span
              data-name="wheel-file-url"
              onClick={() => copyToClipboard('wheel-file-url')}
            >
              <CopyOutlined className="delta-sharing-modal__copy-wheel-file-url" />
            </span>
          </Tooltip>
        </li>
        <li>
          <h2>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step2.header`,
            })}
          </h2>
          <p>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step2.description`,
            })}
          </p>
          <div className="delta-sharing-modal__code-snippet">
            <pre>{installCommand}</pre>
            <Tooltip
              title={
                <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.copyInstallCommandWheelFile" />
              }
            >
              <CopyOutlined
                onClick={() => copyToClipboard('install-command')}
                className="delta-sharing-modal__copy-icon"
              />
            </Tooltip>
          </div>
        </li>
        <li>
          <h2>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step3.header`,
            })}
          </h2>
          <p>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step3.description`,
            })}
          </p>
          <section className="delta-sharing-modal__code-snippet">
            <pre>
              <code>{'{'}</code>
              {jsonCommaSplitted.map((line, index) => {
                line = line.replace(':', ': ');
                if (index === 0) {
                  return (
                    <code className="delta-sharing-modal__code-snippet-line">
                      {`  ${line.replace('{', '')}`}
                      {`,`}
                    </code>
                  );
                } else if (index === jsonCommaSplitted.length - 1) {
                  if (line.includes(`<TENANT_NAME>`)) {
                    return (
                      <JSONVariable
                        line={line.replace('}', '')}
                        valueToReplace={`<TENANT_NAME>`}
                      />
                    );
                  }
                  return null;
                } else {
                  if (line.includes(`<PERSONAL_ACCESS_TOKEN>`)) {
                    return (
                      <JSONVariable
                        line={line}
                        valueToReplace={`<PERSONAL_ACCESS_TOKEN>`}
                      />
                    );
                  }
                  return (
                    <code className="delta-sharing-modal__code-snippet-line">
                      {`  ${line}`}
                      {`,`}
                    </code>
                  );
                }
              })}
              <code>{'}'}</code>
            </pre>
            <Tooltip
              title={
                <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.copyWheelJSON" />
              }
            >
              <CopyOutlined
                onClick={() => copyToClipboard('json')}
                className="delta-sharing-modal__copy-icon"
              />
            </Tooltip>
          </section>
          <section className="delta-sharing-modal__json-info">
            <InfoCircleOutlined />{' '}
            <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.step3.replaceJSONVariables" />
          </section>
        </li>
        <li>
          <h2>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step4.header`,
            })}
          </h2>
          <p>
            {intl.formatMessage({
              id: `clusterConfiguration.configurationsTab.deltaSharing.modal.step4.description`,
            })}
          </p>
          <a
            download
            href={notebookSampleCodeURL}
            className="download-link link"
          >
            <DownloadOutlined />
            {` ${notebookSampleCodeName}`}
          </a>{' '}
          <Tooltip
            title={
              <FormattedMessage id="clusterConfiguration.configurationsTab.deltaSharing.modal.copySampleCode" />
            }
          >
            <span
              data-name="wheel-file-url"
              onClick={() => copyToClipboard('sample-code')}
            >
              <CopyOutlined className="delta-sharing-modal__copy-wheel-file-url" />
            </span>
          </Tooltip>
        </li>
      </ol>
    </div>
  );
}

export default DeltaSharingModal;
