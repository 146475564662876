import './BlueprintList.less';

import React, { useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Button, Modal, Typography, Tooltip } from 'antd';
import { AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import { BlueprintItem, ValidBlueprintClusters } from 'types/cluster';
import { getFunctionColorMap } from 'utils/blueprint';

let { Paragraph } = Typography;

interface BlueprintListProps {
  blueprintsArray: BlueprintItem[];
  selectedBlueprintNames: string[];
  setSelectedBlueprintNames: React.Dispatch<React.SetStateAction<string[]>>;
  onInstall(name: string, slug?: string): void;
  requestValidBlueprint(): Promise<AxiosResponse<ValidBlueprintClusters>>;
}

function BlueprintList({
  blueprintsArray,
  selectedBlueprintNames,
  setSelectedBlueprintNames,
  onInstall,
  requestValidBlueprint,
}: BlueprintListProps) {
  return (
    <div className="BlueprintList">
      {blueprintsArray.map(blueprintItem => {
        let isSelected = selectedBlueprintNames.includes(blueprintItem.name);

        return (
          <BlueprintItemComp
            key={blueprintItem.id}
            blueprintItem={blueprintItem}
            requestValidBlueprint={requestValidBlueprint}
            onInstall={onInstall}
            isSelected={isSelected}
            selectedBlueprintNames={selectedBlueprintNames}
            setSelectedBlueprintNames={setSelectedBlueprintNames}
          />
        );
      })}
    </div>
  );
}

interface BlueprintItemProps {
  blueprintItem: BlueprintItem;
  requestValidBlueprint(): Promise<AxiosResponse<ValidBlueprintClusters>>;
  onInstall(name: string, slug?: string): void;
  isSelected: boolean;
  selectedBlueprintNames: string[];
  setSelectedBlueprintNames: React.Dispatch<React.SetStateAction<string[]>>;
}

function BlueprintItemComp({
  blueprintItem,
  requestValidBlueprint,
  onInstall,
  isSelected,
  selectedBlueprintNames,
  setSelectedBlueprintNames,
}: BlueprintItemProps) {
  let [isLoading, setIsLoading] = useState(false);
  let history = useHistory();
  async function handleInstall() {
    setIsLoading(true);
    try {
      let res = await requestValidBlueprint();
      if (res.data.instances.length > 0) {
        onInstall(blueprintItem.name, blueprintItem.slug);
      } else {
        Modal.error({
          title: 'Connected cluster not found',
          content: (
            <Paragraph>
              To install blueprint, you need at least one connected cluster.
              Please create a new cluster or reconnect an existing one.
            </Paragraph>
          ),
          icon: <CloseCircleOutlined />,
          centered: true,
          width: 620,
          okText: 'Okay',
        });
      }
    } finally {
      setIsLoading(false);
    }
  }
  return (
    <a
      data-testid="data-app-card"
      className="BlueprintList__wrapper-link"
      href={blueprintItem.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      <div
        className="BlueprintList__item"
        style={{
          borderTop: `4px solid ${getFunctionColorMap(
            blueprintItem.businessFunction,
          )}`,
        }}
      >
        <div className="BlueprintList__item-upper">
          <div className="BlueprintList__item-info">
            <div
              className="BlueprintList__item-business-function"
              data-testid="business-function-card-label"
            >
              {blueprintItem.businessFunction}
            </div>
            <Tooltip placement="top" title={blueprintItem.name}>
              <div
                className="BlueprintList__item-name"
                data-testid="data-app-name"
              >
                {blueprintItem.name}
              </div>
            </Tooltip>
          </div>
          <div>
            {history.location.pathname === '/data-apps' &&
              blueprintItem.tag &&
              blueprintItem.tag !== 'null' && (
                <div
                  className="BlueprintList__item-tag"
                  style={{
                    backgroundColor: getFunctionColorMap(
                      blueprintItem.businessFunction,
                    ),
                  }}
                >
                  {blueprintItem.tag}
                </div>
              )}
          </div>
        </div>
        {blueprintItem.description && (
          <Tooltip placement="top" title={blueprintItem.description}>
            <div className="BlueprintList__item-description">
              <p>{blueprintItem.description}</p>
            </div>
          </Tooltip>
        )}
        <div className="BlueprintList__item-footer">
          <div className="BlueprintList__item-images">
            <img src={blueprintItem.blueprintCategory.image} alt="finance" />
          </div>
          <div
            className="BlueprintList__item-action"
            onClick={e => e.preventDefault()}
          >
            <Tooltip
              placement="top"
              title={
                blueprintItem.installable ? '' : "Data app isn't available"
              }
            >
              <Button
                data-testid="install-btn"
                loading={isLoading}
                onClick={handleInstall}
                disabled={!blueprintItem.installable}
              >
                Install
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </a>
  );
}

export default BlueprintList;
