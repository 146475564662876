import './BlueprintFilter.less';

import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { FilterType } from '../BlueprintsBody/BlueprintsBody';
import { getFunctionColorMap } from 'utils/blueprint';

interface BlueprintFilterProps {
  businessFunctions: string[];
  dataSources: string[];
  types: string[];
  filters: FilterType;
  setFilters: React.Dispatch<React.SetStateAction<FilterType>>;
}

function BlueprintFilter({
  businessFunctions,
  dataSources,
  types,
  filters,
  setFilters,
}: BlueprintFilterProps) {
  return (
    <div className="BlueprintFilter">
      <div className="BlueprintFilter__section">
        <div
          className="BlueprintFilter__section-title"
          data-testid="business-function"
        >
          Business Function
        </div>
        <ul className="BlueprintFilter__section-list">
          {businessFunctions.map(functionName => {
            let isSelected = filters.businessFunctions.some(
              name => name === functionName,
            );

            return (
              <li key={functionName}>
                <Checkbox
                  data-testid="business-function-checkbox"
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      setFilters(f => ({
                        ...f,
                        businessFunctions: f.businessFunctions.filter(
                          name => name !== functionName,
                        ),
                      }));
                    } else {
                      setFilters(f => ({
                        ...f,
                        businessFunctions: [
                          ...f.businessFunctions,
                          functionName,
                        ],
                      }));
                    }
                  }}
                >
                  <span
                    className="BlueprintFilter__section-list__function-color"
                    style={{
                      backgroundColor: getFunctionColorMap(functionName),
                    }}
                  ></span>
                  <Tooltip title={functionName} placement="right">
                    <span className="BlueprintFilter__section-list__function-name">
                      {functionName}
                    </span>
                  </Tooltip>
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="BlueprintFilter__section">
        <div className="BlueprintFilter__section-title" data-testid="types">
          Types
        </div>
        <ul className="BlueprintFilter__section-list">
          {types.map(functionName => {
            let isSelected = filters.types.some(name => name === functionName);

            return (
              <li key={functionName}>
                <Checkbox
                  data-testid="type-checkbox"
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      setFilters(f => ({
                        ...f,
                        types: f.types.filter(name => name !== functionName),
                      }));
                    } else {
                      setFilters(f => ({
                        ...f,
                        types: [...f.types, functionName],
                      }));
                    }
                  }}
                >
                  {functionName}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>

      <div className="BlueprintFilter__section">
        <div
          className="BlueprintFilter__section-title"
          data-testid="datasource"
        >
          Data Source
        </div>
        <ul className="BlueprintFilter__section-list">
          {dataSources.map(dataSourceName => {
            let isSelected = filters.dataSources.some(
              name => name === dataSourceName,
            );

            return (
              <li key={dataSourceName}>
                <Checkbox
                  data-testid="datasource-checkbox"
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      setFilters(f => ({
                        ...f,
                        dataSources: f.dataSources.filter(
                          name => name !== dataSourceName,
                        ),
                      }));
                    } else {
                      setFilters(f => ({
                        ...f,
                        dataSources: [...f.dataSources, dataSourceName],
                      }));
                    }
                  }}
                >
                  {dataSourceName}
                </Checkbox>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default BlueprintFilter;
