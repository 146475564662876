import './ClusterAutoSuspendEdit.less';

import React, { useState } from 'react';
import Icon from '@ant-design/icons';
import { Button, notification, Select, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as SuspendIcon } from 'images/suspend.svg';
import { Instance, Service } from 'types/cluster';
import { useMutateCluster, useSuspendTimes } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { getClusterStatus } from 'utils/cluster';
import { UserData } from 'types/user';

interface ClusterAutoSuspendEditProps {
  instance: Instance;
  services: Service[];
  isTrial: boolean;
  user: UserData;
}

function ClusterAutoSuspendEdit({
  instance,
  services,
  isTrial,
  user,
}: ClusterAutoSuspendEditProps) {
  const intl = useIntl();
  const [idleTime, setiIdleTime] = useState(instance.idleTime);
  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });
  const { data } = useSuspendTimes();

  const suspendData = data?.data.availableIdleTimes;
  const status = getClusterStatus(instance, services[0]);

  async function handleSubmit(
    e: React.MouseEvent<HTMLFormElement, MouseEvent>,
  ) {
    e.preventDefault();
    const { data } = await mutateCluster({ idleTime: idleTime });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  const canSave = instance.idleTime !== idleTime;
  const { isPlatformActionSupported: isAutoSuspendSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clusterSuspend,
    });

  return (
    <div className="ClusterEdit ClusterAutoSuspendEdit">
      <div className="ClusterEdit__icon">
        <Icon component={SuspendIcon} />
      </div>
      <div className="ClusterEdit__content">
        <h3 className="ClusterEdit__title">Auto Suspend</h3>
        <p className="ClusterEdit__subtitle">
          Suspend after <strong>{instance.displayIdleTime}</strong> of
          inactivity.
        </p>
        <div className="ClusterAutoSuspendEdit__body">
          <p className="ClusterAutoSuspendEdit__hint">
            The maximum inactivity period of time before the cluster will be
            automatically suspended.
          </p>
          {canUpdate && (
            <form
              className="ClusterAutoSuspendEdit__form"
              onSubmit={handleSubmit}
            >
              <div>
                <label className="ClusterAutoSuspendEdit__form-label">
                  Set the maximum period of inactivity
                </label>
                <div>
                  <Tooltip
                    title={
                      isTrial
                        ? 'Only available for Premium users. Chat with us to upgrade.'
                        : ''
                    }
                  >
                    <Select
                      loading={!data}
                      style={{ width: 180 }}
                      value={idleTime}
                      onChange={value => setiIdleTime(value)}
                      disabled={isTrial}
                    >
                      {suspendData?.map(({ key, display }) => {
                        return (
                          <Select.Option key={key} value={key}>
                            {display}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Tooltip>
                </div>
              </div>

              <div>
                <Tooltip
                  title={
                    status !== 'running' ? (
                      'Your cluster must be connected to apply this change'
                    ) : !isAutoSuspendSupported ? (
                      <FormattedMessage
                        id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                        values={{
                          action: intl.formatMessage({
                            id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.autoSuspend',
                          }),
                        }}
                      />
                    ) : (
                      ''
                    )
                  }
                >
                  <Button
                    loading={isLoading}
                    disabled={!canSave || !isAutoSuspendSupported}
                    type="primary"
                    htmlType={'submit'}
                  >
                    Save
                  </Button>
                </Tooltip>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClusterAutoSuspendEdit;
