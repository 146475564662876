import { useAuth0 } from '@auth0/auth0-react';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import cookie from 'js-cookie';
import { SessionContextType, UserData, UserExtensions } from 'types/user';
import { useGetUserData } from 'hooks/user';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { queryClient } from 'index';

export const SessionContext = React.createContext<SessionContextType>(
  {} as SessionContextType,
);

export function SessionProvider({
  children,
  ...props
}: PropsWithChildren<any>) {
  function reloadUser() {
    queryClient.invalidateQueries(QueryServerKeys.USER.GET_USER_DATA); // TODO: call invalidate queries on every useQuery that calls reloadUser instead of making it here
  }

  const {
    user,
    isAuthenticated,
    isLoading,
    error,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
  } = useAuth0();

  useEffect(() => {
    // shift-user up to App component for qualified chat functionality
    if (user?.email) {
      props.setUser(user);
    }
    // eslint-disable-next-line
  }, [user?.email]);

  const namespace = process.env.REACT_APP_AUTH_NAMESPACE;

  let shallowNesting = {};
  if (user) {
    shallowNesting = {
      uuid: user[`${namespace}/app_metadata`]?.uuid,
      redirectTo: user[`${namespace}/redirect_to`],
      sub: user.sub,
    };
    // Set SSO cookie and redirect url
    const cloudSSO = user[`${namespace}/sso`];
    if (cloudSSO && !cookie.get('cloudSSO')) {
      cookie.set('cloudSSO', cloudSSO, {
        domain: process.env.REACT_APP_DOMAIN,
        expires: 1000 * 60 * 60 * 24 * 365,
        secure: true,
        sameSite: 'strict',
      });
    }
  }

  let [extensions, setExtensions] = useState<UserExtensions>({
    configurations: null,
    platformActions: null,
    toggleFeatures: [],
    features: [],
    instancesCount: 0,
    role: null,
    userDataIsSet: false,
  });

  const extendedUser = {
    ...(shallowNesting as UserData),
    ...extensions,
  } as UserData;

  const {
    data: result,
    isLoading: isGetUserPending,
    error: getUserErrorStatusCode,
  } = useGetUserData({
    userId: (shallowNesting as UserData)?.uuid,
    isAuthenticated,
  });

  useEffect(() => {
    if (result) {
      const configurations = result.data.configurations;
      const platformActions = result.data.platformActions;
      const updatedUserData = result?.data?.user;

      setExtensions({
        configurations,
        platformActions,
        ...updatedUserData,
        userDataIsSet: true,
      });
    }
  }, [result]);

  const initialState = {
    user: extendedUser,
    isAuthenticated,
    isLoading: isLoading || isGetUserPending,
    extensions,
    error,
    getUserErrorStatusCode,
    getAccessTokenSilently,
    loginWithRedirect,
    logout,
    reloadUser,
  };

  return (
    <SessionContext.Provider value={{ ...initialState }} {...props}>
      {children}
    </SessionContext.Provider>
  );
}
