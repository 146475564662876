import React, { useState, useContext } from 'react';
import './AzureSynapse.less';
import { Button, Modal, Tooltip, notification } from 'antd';
import { useIntl } from 'react-intl';
import AzureSynapseModal from '../AzureSynapseModal/AzureSynapseModal';
import { ReactComponent as AzureSynapseIcon } from 'images/azure-synapse-analytics.svg';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { useEnableAzureSynapse } from 'hooks/cluster';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';

interface AzureSynapseProps {
  instance: Instance;
  services: Service[];
}
function AzureSynapse({ instance, services }: AzureSynapseProps) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  const clusterStatus = getClusterStatus(instance, services[0]);
  const { user } = useContext(SessionContext);
  const { isToggleFeatureEnabled: synapseIsEnabled } = useToggleFeature({
    user,
    featureKey: TOGGLE_FEATURE_KEYS.microsoftSynapse,
  });

  const {
    mutateAsync: mutateAzureSynapse,
    isLoading,
    isError,
    error,
  } = useEnableAzureSynapse({
    instanceName: instance.name,
    instanceId: instance.id,
    userID: user.uuid,
  });

  async function setSynapse({
    tenant,
    username,
    password,
  }: {
    tenant: string;
    username: string;
    password: string;
  }) {
    const { data, status } = await mutateAzureSynapse({
      tenant,
      username,
      password,
      enableMicrosoftSynapse: true, // TODO: handle this when business want to make the disable works
    });
    if (status === 200) {
      notification.success({
        message: intl.formatMessage({
          id: 'clusterConfiguration.azureSynapse.successMessage',
        }),
        description: data.message,
      });
    } else {
      if (isError) {
        console.error(error);
      }
    }
  }

  function handleSwitchingAzureSynapse() {
    setShowModal(prev => !prev);
  }

  function getButtonText() {
    if (isLoading) {
      return intl.formatMessage({
        id: 'clusterConfiguration.azureSynapse.button.installingButton',
      });
    } else {
      if (instance.enableMicrosoftSynapse) {
        return intl.formatMessage({
          id: 'clusterConfiguration.azureSynapse.button.editButton',
        });
      } else {
        return intl.formatMessage({
          id: 'clusterConfiguration.azureSynapse.button.enableButton',
        });
      }
    }
  }

  return (
    <div className="ClusterEdit azure-synapse">
      <div className="ClusterEdit__icon">
        <AzureSynapseIcon style={{ width: '16px' }} />
      </div>
      <div className="ClusterEdit__content azure-synapse__content">
        <div className="azure-synapse__main">
          <h3 className="ClusterEdit__title">
            {intl.formatMessage({
              id: 'clusterConfiguration.azureSynapse.header',
            })}
          </h3>
          <div className="azure-synapse__body">
            <p className="azure-synapse__hint">
              {intl.formatMessage({
                id: 'clusterConfiguration.azureSynapse.description',
              })}
            </p>
          </div>
        </div>
        <div className="azure-synapse__switch-button">
          <Tooltip
            title={
              !synapseIsEnabled
                ? 'Microsoft Synapse has been temporarily disabled.'
                : clusterStatus !== 'running'
                ? intl.formatMessage({
                    id: 'clusterConfiguration.general.clusterMustBeConnectedToChange',
                  })
                : null
            }
          >
            <Button
              loading={isLoading}
              disabled={
                // clusterStatus !== 'running' || !synapseIsEnabled || isLoading // TODO: uncomment this when business want to make the disable works
                clusterStatus !== 'running' || isLoading
              }
              type="primary"
              htmlType={'submit'}
              onClick={handleSwitchingAzureSynapse}
            >
              {getButtonText()}
            </Button>
          </Tooltip>
        </div>
      </div>
      <Modal
        visible={showModal}
        footer={null}
        onCancel={() => setShowModal(false)}
        title={intl.formatMessage({
          id: 'clusterConfiguration.azureSynapseModal.title',
        })}
        destroyOnClose
        centered
        maskClosable={false}
        closable={false}
      >
        <AzureSynapseModal
          closeModal={() => setShowModal(false)}
          setSynapse={setSynapse}
        />
      </Modal>
    </div>
  );
}

export default AzureSynapse;
