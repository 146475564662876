import './NotFound.less';

import React, { useContext } from 'react';
import { Typography } from 'antd';
import { ReactComponent as NotFoundIcon } from './icons/NotFound.svg';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

function NotFound() {
  return (
    <div className="not-found">
      <NotFoundIcon />

      <Title level={2} className="not-found__title">
        THIS PAGE IS NOT FOUND
      </Title>
    </div>
  );
}

function NotFoundWrapper() {
  const { isAuthenticated } = useContext(SessionContext);
  if (isAuthenticated) {
    return (
      <IncortaLayoutContainer>
        <div className="not-found-wrapper">
          <NotFound />
        </div>
      </IncortaLayoutContainer>
    );
  }

  return (
    <div className="not-found-wrapper not-found-wrapper--fullscreen">
      <NotFound />
    </div>
  );
}

export default NotFoundWrapper;
