import { notification } from 'antd';
import {
  getErrorsOfInput,
  hideErrorBelowField,
  resetErrors,
} from './fieldsValidation';

export function getErrorMessage(error: any): string {
  let errorMessage: string = 'Something went wrong!';
  try {
    if (typeof error?.response?.data === 'string') {
      errorMessage = error?.response?.data;
    } else if (error?.response?.data?.errors) {
      const { errors } = error.response.data;
      if (Array.isArray(errors)) {
        errorMessage = '';
        getFieldErrorMessage(errors);
      } else {
        [errorMessage] = Object.values(errors);
      }
    } else if (error?.response?.data?.message) {
      errorMessage = error.response.data.message;
    }
  } catch {}
  return errorMessage.toString();
}

export function showErrorMessage(errorMessage: string) {
  if (errorMessage) {
    notification.error({
      message: 'Error',
      description: errorMessage,
    });
  }
}

function getFieldErrorMessage(errors: any) {
  resetErrors();
  try {
    errors.forEach((error: any) => {
      hideErrorBelowField(error.fieldName);
      getErrorsOfInput(
        error.fieldName,
        () => true,
        error.message,
        error.notActivated,
      );
    });
  } catch {}
}

export function getFormErrors(errors: { [key: string]: string[] | undefined }) {
  return Object.values(errors).filter(
    (error: string[] | undefined): error is string[] => {
      return Boolean(error);
    },
  );
}

export function getErrors(formErrors: any) {
  let hasError: boolean = false;
  if (formErrors.filter(({ errors }: any) => errors.length).length) {
    hasError = true;
  }
  return hasError;
}

export function showActivationError(error: any) {
  const { errors } = error?.response?.data;
  let emailError: any = [0];
  if (Array.isArray(errors)) {
    emailError = errors.filter((error: any) => {
      return error.fieldName === 'email';
    });
  }
  return emailError;
}
