import '../../ClusterCard.less';
import './AzureCreationClusterCard.less';

import React, { useRef, useState } from 'react';
import { Typography, Tooltip, Modal } from 'antd';
import { useIntl } from 'react-intl';
import {
  AzureClusterType,
  clusterCreationStatus,
  hasPipelineReachedLastStep,
} from '../azureClusterCreationHelper';
import AzureCreationClusterErrorModal from '../AzureCreationClusterErrorModal/AzureCreationClusterErrorModal';
import AzureCreationClusterSubCard from '../AzureCreationClusterSubCard/AzureCreationClusterSubCard';
import AzureCreationClusterSteps from '../AzureCreationClusterSteps/AzureCreationClusterSteps';
import AzureCreationClusterTimer, {
  TimerRefType,
} from '../AzureCreationClusterTimer/AzureCreationClusterTimer';
import AzureCreationClusterIcon from '../AzureCreationClusterIcon/AzureCreationClusterIcon';
import { ReactComponent as AzureLogo } from 'images/azure-a-logo.svg';
import { AzureClusterPipelineData } from 'types/cluster';
import useAzurePipeline from 'hooks/useAzurePipeline';

const { Title } = Typography;

interface AzureCreationClusterCardProps {
  azureClusterPipelineData: AzureClusterPipelineData;
}
function AzureCreationClusterCard({
  azureClusterPipelineData,
}: AzureCreationClusterCardProps) {
  const [showErrorDetailsModal, setShowErrorDetailsModal] = useState(false);
  const intl = useIntl();
  const timerRef = useRef<TimerRefType>(null);

  const { shouldTimerPause, isAzurePipelineDone } = useAzurePipeline({
    azureClusterPipelineData,
  });

  const isLastStep = hasPipelineReachedLastStep({
    currentStep: azureClusterPipelineData.pipelineStatus.stageName,
    states: azureClusterPipelineData.states,
  });

  if (isAzurePipelineDone) {
    return null;
  }

  return (
    <div className="cluster-card__wrapper">
      <div className={'azure-creation-cluster-card cluster-card'}>
        <div
          className={`cluster-card__bar ${azureClusterPipelineData.pipelineStatus.state}`}
        />
        <div className={'cluster-card__body'}>
          <div className="cluster-card__header">
            <div className="cluster-card__title">
              <Title level={4} className="cluster-card__title-text truncate">
                <span className="cluster-card__title-icon ">
                  <AzureCreationClusterIcon
                    status={
                      azureClusterPipelineData.pipelineStatus
                        .state as clusterCreationStatus
                    }
                  />
                </span>
                {intl.formatMessage({
                  id: 'clustersPage.azureCreationClusterCard.header',
                })}
              </Title>
              <div className="cluster-card__menu">
                <Tooltip
                  title={intl.formatMessage({
                    id: 'clustersPage.clusterTypes.azureCluster',
                  })}
                  placement="top"
                >
                  <AzureLogo className="platform-logo" />
                </Tooltip>
              </div>
            </div>
            <AzureCreationClusterSteps
              currentStep={
                azureClusterPipelineData.pipelineStatus.stageName ||
                azureClusterPipelineData.states[0]
              }
              states={azureClusterPipelineData.states}
            />
          </div>

          <div className="cluster-card__info">
            <AzureCreationClusterSubCard
              azureClusterType={AzureClusterType.prod}
              configurationName={
                azureClusterPipelineData.pipelineStatus.configurationName
              }
              currentVersion={
                azureClusterPipelineData.pipelineStatus.incortaImage
              }
              size={azureClusterPipelineData.pipelineStatus.plan}
              region={azureClusterPipelineData.pipelineStatus.region}
              autoSuspend="1 Hour"
            />
            <AzureCreationClusterSubCard
              azureClusterType={AzureClusterType.dev}
              configurationName={
                azureClusterPipelineData.pipelineStatus.configurationName
              }
              currentVersion={
                azureClusterPipelineData.pipelineStatus.incortaImage
              }
              size={azureClusterPipelineData.pipelineStatus.plan}
              region={azureClusterPipelineData.pipelineStatus.region}
              autoSuspend="1 Hour"
            />
          </div>
        </div>

        <div className="cluster-card__footer">
          <div className="cluster-card__footer-wrapper">
            <div className="cluster-card__loading-footer">
              <div className="cluster-card__status">
                <AzureCreationClusterIcon
                  status={
                    azureClusterPipelineData.pipelineStatus
                      .state as clusterCreationStatus
                  }
                  forFooter={true}
                />
                {azureClusterPipelineData.pipelineStatus.state ===
                  clusterCreationStatus.INPROGRESS && (
                  <>
                    {intl.formatMessage({
                      id: 'clustersPage.azureCreationClusterCard.footer.status',
                    })}
                    <label className="cluster-card__status-value">
                      {intl.formatMessage({
                        id: 'clustersPage.azureCreationClusterCard.footer.preparing',
                      })}
                    </label>
                  </>
                )}
                {[
                  clusterCreationStatus.COMPLETED,
                  clusterCreationStatus.RUNNING,
                  clusterCreationStatus.WAITING,
                ].includes(
                  azureClusterPipelineData.pipelineStatus
                    .state as clusterCreationStatus,
                ) &&
                  !isLastStep && (
                    <>
                      {intl.formatMessage({
                        id: 'clustersPage.azureCreationClusterCard.footer.status',
                      })}
                      <label className="cluster-card__status-value">
                        {intl.formatMessage({
                          id: 'clustersPage.azureCreationClusterCard.footer.working',
                        })}
                      </label>
                    </>
                  )}
                {(azureClusterPipelineData.pipelineStatus.state ===
                  clusterCreationStatus.FAILED ||
                  azureClusterPipelineData.pipelineStatus.state ===
                    clusterCreationStatus.CANCELED) && (
                  <>
                    {intl.formatMessage({
                      id: 'clustersPage.azureCreationClusterCard.footer.status',
                    })}
                    <label className="cluster-card__status-value">
                      {azureClusterPipelineData.pipelineStatus.state ===
                        clusterCreationStatus.FAILED &&
                        intl.formatMessage({
                          id: 'clustersPage.azureCreationClusterCard.footer.failed',
                        })}
                      {azureClusterPipelineData.pipelineStatus.state ===
                        clusterCreationStatus.CANCELED &&
                        intl.formatMessage({
                          id: 'clustersPage.azureCreationClusterCard.footer.canceled',
                        })}
                    </label>{' '}
                    {azureClusterPipelineData.pipelineStatus.state ===
                      clusterCreationStatus.FAILED && (
                      <p
                        className="cluster-card__display-details"
                        onClick={() => setShowErrorDetailsModal(true)}
                      >
                        {intl.formatMessage({
                          id: 'clustersPage.azureCreationClusterCard.footer.viewDetails',
                        })}
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <AzureCreationClusterTimer
              startTime={azureClusterPipelineData.pipelineStatus.createdAt}
              state={azureClusterPipelineData.pipelineStatus.state}
              isLastStep={isLastStep}
              updatedAt={azureClusterPipelineData.pipelineStatus.updatedAt}
              shouldTimerPause={shouldTimerPause}
              ref={timerRef}
            />
          </div>
        </div>
      </div>
      <Modal
        visible={showErrorDetailsModal}
        footer={null}
        onCancel={() => setShowErrorDetailsModal(false)}
        title={intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.errorModal.title',
        })}
        destroyOnClose
        centered
      >
        <AzureCreationClusterErrorModal
          error={azureClusterPipelineData.pipelineStatus.error || ''}
          createdAt={azureClusterPipelineData.pipelineStatus.createdAt}
          closeModal={() => setShowErrorDetailsModal(false)}
        />
      </Modal>
    </div>
  );
}

export default AzureCreationClusterCard;
