import React from 'react';
import { useLocation } from 'react-router-dom';
import { parse } from 'query-string';
import AcceptingOwnership from './AcceptingOwnership/AcceptingOwnership';
import NotFound from 'components/NotFound/NotFound';

function UserAcceptInvitation() {
  const { search } = useLocation();
  const { userID, instanceID } = parse(search);

  if (!userID || typeof userID !== 'string') {
    return <NotFound />;
  }

  if (!instanceID || typeof instanceID !== 'string') {
    return <NotFound />;
  }

  return <AcceptingOwnership userID={userID} instanceID={instanceID} />;
}

export default UserAcceptInvitation;
