import { useEffect, useState } from 'react';
import _ from 'lodash';
import { PlatformActions } from 'types/user';

export enum PLATFORM_ACTIONS_KEYS {
  clone = 'clone',
  whitelisting = 'whitelisting',
  clusterSuspend = 'cluster_suspend',
  clusterScaling = 'cluster_scaling',
  clusterResize = 'cluster_resize',
  packagesInstallion = 'packages_installion',
  clusterUpgrade = 'cluster_upgrade',
  subcluster = 'subcluster',
}

interface usePlatformActionsSupportProps {
  platformKey: PLATFORM_ACTIONS_KEYS;
  userPlatformActions: PlatformActions[] | null;
  currentPlatform: string;
}
function usePlatformActionsSupport({
  platformKey,
  userPlatformActions = [],
  currentPlatform,
}: usePlatformActionsSupportProps) {
  const [currentPlatformAction, setCurrentPlatformAction] = useState<
    PlatformActions[] | null
  >([]);
  const [isPlatformActionSupported, setIsPlatformActionSupported] = useState<
    PlatformActions | undefined
  >();

  useEffect(() => {
    if (!_.isEqual(currentPlatformAction, userPlatformActions)) {
      setCurrentPlatformAction(userPlatformActions);

      setIsPlatformActionSupported(() => {
        return userPlatformActions?.find(
          ({ name, platforms }) =>
            name === platformKey &&
            platforms.split(',').filter(Boolean).includes(currentPlatform),
        );
      });
    }
  }, [
    platformKey,
    userPlatformActions,
    currentPlatform,
    currentPlatformAction,
  ]);

  return {
    isPlatformActionSupported,
    PLATFORM_ACTIONS_KEYS,
  };
}

export default usePlatformActionsSupport;
