import './AboutModal.less';

import React from 'react';
import Icon, {
  CustomerServiceOutlined,
  FileTextOutlined,
  GlobalOutlined,
  ReadOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { Divider, Space } from 'antd';
import { ReactComponent as IncortaLogoDark } from 'images/incorta-logo-dark.svg';
import { ReactComponent as CommunityIcon } from 'images/community.svg';

function AboutModal() {
  return (
    <div className="AboutModal">
      <IncortaLogoDark className="AboutModal__incorta-logo" />

      <div className="AboutModal__links">
        <div>
          <a
            className="AboutModal__link"
            href="https://incorta.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GlobalOutlined /> Incorta.com
          </a>
          <a
            className="AboutModal__link"
            href="https://learn.incorta.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <ReadOutlined /> Learn
          </a>
          <a
            className="AboutModal__link"
            href="https://support.incorta.com/hc/en-us"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomerServiceOutlined /> Support
          </a>
        </div>

        <div>
          <a
            className="AboutModal__link"
            href="https://docs.incorta.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FileTextOutlined /> Documentation
          </a>
          <a
            className="AboutModal__link"
            href="https://community.incorta.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon component={CommunityIcon} /> Community
          </a>
        </div>
      </div>

      <div className="AboutModal__footer">
        <Space size="small">
          <a
            href="https://incorta.com/privacy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
          <Divider style={{ margin: 0 }} type="vertical" />
          <a
            href="TermsandConditions.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions.
          </a>
        </Space>

        <div>
          &copy; {format(new Date(), 'yyyy')} Incorta, Inc. All Rights Reserved.
        </div>
      </div>
    </div>
  );
}

export default AboutModal;
