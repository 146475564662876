import axios, { AxiosResponse } from 'axios';
import { saveAs as saveToClient } from 'file-saver';
import { API_URL, getContentDispositionFilename } from 'utils/http';

import {
  InstancesData,
  ClusterData,
  Subscription,
  DeleteClusterData,
  ImagesResult,
  TenantsResult,
  SizesResult,
  ChangeSqliStateResult,
  ClusterUpgradeResult,
  BlueprintsResult,
  ImportBlueprintArg,
  ExportBlueprintArg,
  ImportState,
  ConsumptionInfo,
  SupportToken,
  ValidBlueprintClusters,
  SubClusterCreateData,
  SubClusterUpdateData,
  SuspendTimeData,
  AuthUserData,
  WhiteListData,
  Regions,
  DataSource,
  AdvancedConfig,
  AdvancedConfigsResponse,
  UpdateAdvancedConfigsRequest,
  AzureClusterPipelineData,
  ServiceToRestart,
  ClusterAction,
  ClusterServiceConsumption,
  ServicesStatusResponse,
  AutoScaleHistoryRecord,
  UpdateScheduledEventEndpointBody,
  CreateScheduledEventEndpointBody,
  ScheduledEventsType,
  SelfManagedClusterCreateData,
  SelfManagedClusterUpdateData,
} from 'types/cluster';
import { UserData } from 'types/user';
import { security } from 'utils/security';
import { mapAdvancedConfigsResponseToAdvancedConfigsModel } from 'components/ClusterDetails/ClusterDetailsBody/AdvancedConfigurations/utils/utils';
import { ClusterSecrets } from 'components/ClusterDetails/ClusterSecretsPopover/ClusterSecretsPopover';

declare module 'axios' {
  export interface AxiosRequestConfig {
    scope?: string[];
  }
}

export async function getRegions() {
  let res = await axios.get<Regions>(`${API_URL}/operators`);
  return res;
}

export async function getAllClusters(userId: string) {
  let res = await axios.get<InstancesData>(
    `${API_URL}/users/${userId}/instances`,
  );
  return res.data;
}

export async function getDatasources() {
  let res = await axios.get<DataSource>(`${API_URL}/blueprints/data_sources`);
  return res.data;
}

export async function getAllClustersForPulling(userId: string) {
  let res = await axios.get<InstancesData>(
    `${API_URL}/users/${userId}/instances/services`,
  );
  return res.data;
}

export async function deleteCluster({
  userId,
  instanceName,
}: DeleteClusterData) {
  await axios.delete(`${API_URL}/users/${userId}/instances/${instanceName}`);
}

export function getDefaultImage() {
  return axios.get(`${API_URL}/images/defaultIncortaimage`);
}

export function getKubernetesVersion() {
  return axios.get(`${API_URL}/images/getKubernetesVersion`);
}

export async function getImages() {
  const result = await axios.get<ImagesResult>(`${API_URL}/images`);
  return result?.data;
}

export function getAzureClusterPipelineState({
  email,
  userID,
}: {
  email: string;
  userID: string;
}) {
  return axios.post<AzureClusterPipelineData>(
    `${API_URL}/azurePipeline/azurepipelinestatus`,
    {
      email,
      userID,
    },
  );
}

export function getAzurePlan(email: any, tenant: any) {
  return axios.post(`${API_URL}/azurePipeline/getAzurePlan`, { email, tenant });
}

export function storeAzurePlan(body: any, subscription: Subscription) {
  let fullbody = { ...body, ...subscription };
  return axios.post(`${API_URL}/azurePipeline`, fullbody);
}

export function getTenants() {
  return axios.get<TenantsResult>(`${API_URL}/tenants`);
}

export function getImageSizes() {
  return axios.get<SizesResult>(`${API_URL}/images/sizes`);
}

export async function createSubscription({
  user,
  subscription,
}: {
  user: UserData;
  subscription: Subscription;
}) {
  const token = await security.getAccessTokenSilently()({
    audience: process.env.REACT_APP_AUTH_AUDIENCE,
    // ignoreCache: true
  });

  const obj = JSON.parse(subscription.servicePrincipalCredentials);
  const plan = await getAzurePlan(user.email, obj.tenant);
  const defaultImage = await getDefaultImage();
  const k8sVersion = await getKubernetesVersion();
  const incortaImageUpdated = defaultImage.data.incortaImage;
  subscription.plan = plan.data.body[0].tackle_plan;
  subscription.appID = obj.appId;
  subscription.password = obj.password;
  subscription.tenant = obj.tenant;
  subscription.token = token;
  subscription.userId = user.uuid;
  subscription.email = user.email;
  subscription.incortaImage = incortaImageUpdated;
  subscription.k8sVersion = k8sVersion.data.k8sVersion;

  return await axios.post(
    `${process.env.REACT_APP_CONSENT_FLOW_BE}`,
    subscription,
  );
}

export async function createCluster({
  userId,
  cluster,
}: {
  userId: string;
  cluster: ClusterData;
}) {
  return await axios.post(`${API_URL}/users/${userId}/instances`, cluster);
}

export async function updateCluster({
  userId,
  clusterName,
  ...rest
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.put(`${API_URL}/users/${userId}/instances/${clusterName}`, {
    ...rest,
  });
}

export async function disconnectCluster({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/disconnect`,
  );
}
export async function connectCluster({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/connect`,
  );
}

export async function changeSqliState({
  userId,
  clusterName,
  sqliEnabled,
}: {
  userId: string;
  clusterName: string;
  sqliEnabled: boolean;
}) {
  return axios.put<ChangeSqliStateResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/sqli`,
    { sqliEnabled },
  );
}

export async function changeIncortaXState({
  userId,
  clusterName,
  incortaXEnabled,
}: {
  userId: string;
  clusterName: string;
  incortaXEnabled: boolean;
}) {
  return axios.put<ChangeSqliStateResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/incortax`,
    { incortaXEnabled },
  );
}

export async function upgradeVersion({
  userId,
  clusterName,
  version,
  byBuildNo,
}: {
  userId: string;
  clusterName: string;
  version: string;
  byBuildNo: string;
}) {
  return axios.put<ClusterUpgradeResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/upgrade`,
    { version, byBuildNo },
  );
}

export async function getClusterUpdates({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.get<ClusterUpgradeResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/pending`,
  );
}

export async function upgradeDisconnectedCluster({
  userId,
  clusterName,
  version,
  byBuildNo,
}: {
  userId: string;
  clusterName: string;
  version: string;
  byBuildNo: string;
}) {
  return axios.post<ClusterUpgradeResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/pending`,
    {
      kind: 'UPGRADE_VERSION',
      change: {
        version: version,
        byBuildNo,
      },
    },
  );
}

export async function cancelUpgrade({
  userId,
  clusterName,
  updateId,
}: {
  userId: string;
  clusterName: string;
  updateId: string;
}) {
  return axios.delete<ClusterUpgradeResult>(
    `${API_URL}/users/${userId}/instances/${clusterName}/pending/${updateId}`,
  );
}

export async function cloneCluster({
  userId,
  clusterName,
  name,
}: {
  userId: string;
  clusterName: string;
  name: string;
}) {
  await axios.post(`${API_URL}/users/${userId}/instances/clone`, {
    name,
    clone: clusterName,
  });
}

export function getBlueprints({ fields }: { fields?: string[] } = {}) {
  return axios.get<BlueprintsResult>(`${API_URL}/blueprints`, {
    params: {
      fields: fields?.join(','),
    },
  });
}

export function exportBlueprints(body: ExportBlueprintArg) {
  return axios.put(`${API_URL}/blueprints/export`, body);
}

export function importBlueprints(body: ImportBlueprintArg) {
  return axios.put(`${API_URL}/blueprints/import`, body);
}

export async function downloadExportedBlueprint(exportId: number) {
  const { data, headers } = await axios.get(
    `${API_URL}/blueprints/exports/${exportId}/download`,
    { responseType: 'blob' },
  );
  const filename = getContentDispositionFilename(
    headers['content-disposition'],
  );
  saveToClient(data, filename);
}

export function getSupportedVersions(
  slug: string,
  instanceId: string | undefined,
) {
  return axios.get(`${API_URL}/blueprints/${slug}/${instanceId}/isSupported`);
}

export function getClusterTenants({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.get(`${API_URL}/tenants/${userId}/instances/${clusterName}`);
}

export async function checkClusterImportStatus(clusterId: string) {
  let res = await axios.get<ImportState>(
    `${API_URL}/blueprints/${clusterId}/status`,
  );
  return res;
}

export function getConsumptionInfoTODO({
  userId,
  startDate,
  endDate,
}: {
  userId: string;
  startDate: string;
  endDate: string;
}) {
  return axios.get<ConsumptionInfo>(`${API_URL}/users/${userId}/consumption`, {
    params: {
      startDate,
      endDate,
    },
  });
}

export function generateSupportToken({
  userId,
  instanceName,
}: {
  userId: String;
  instanceName: string;
}) {
  return axios.post<SupportToken>(
    `${API_URL}/cli/${userId}/instances/${instanceName}/token`,
  );
}

export function getSupportToken({
  userId,
  instanceName,
}: {
  userId: String;
  instanceName: string;
}) {
  return axios.get<SupportToken>(
    `${API_URL}/cli/${userId}/instances/${instanceName}/token`,
  );
}

export function deleteSupportToken({
  userId,
  instanceName,
}: {
  userId: String;
  instanceName: string;
}) {
  return axios.delete(
    `${API_URL}/cli/${userId}/instances/${instanceName}/token`,
  );
}

export function getValidBlueprintClusters({ userId }: { userId: string }) {
  return axios.get<ValidBlueprintClusters>(
    `${API_URL}/users/${userId}/valid_bp_clusters`,
  );
}

export function changeInstanceScale({
  userId,
  clusterName,
  data,
}: {
  userId: string;
  clusterName: string;
  data: { loaderReplicas?: number; analyticsReplicas?: number };
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/scale`,
    data,
  );
}

export function createSubCluster({
  userId,
  clusterName,
  data,
}: {
  userId: string;
  clusterName: string;
  data: SubClusterCreateData;
}) {
  return axios.post(
    `${API_URL}/users/${userId}/instances/${clusterName}/subcluster`,
    data,
  );
}

export function updateSubCluster({
  userId,
  clusterName,
  data,
}: {
  userId: string;
  clusterName: string;
  data: SubClusterUpdateData;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/updatesubcluster`,
    data,
  );
}

export function createSelfManagedCluster({
  userId,
  clusterName,
  data,
}: {
  userId: string;
  clusterName: string;
  data: SelfManagedClusterCreateData;
}) {
  return axios.post(
    `${API_URL}/users/${userId}/instances/${clusterName}/subcluster`,
    data,
  );
}

export function updateSelfManagedCluster({
  userId,
  clusterName,
  data,
}: {
  userId: string;
  clusterName: string;
  data: SelfManagedClusterUpdateData;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/updatesubcluster`,
    data,
  );
}

export function deleteSubCluster({
  userId,
  clusterName,
  subClusterName,
}: {
  userId: string;
  clusterName: string;
  subClusterName: string;
}) {
  return axios.delete(
    `${API_URL}/users/${userId}/instances/${clusterName}/subcluster/${subClusterName}`,
  );
}

export function connectSubCluster({
  userId,
  clusterName,
  subClusterName,
}: {
  userId: string;
  clusterName: string;
  subClusterName: string;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/connectsubcluster`,
    { subCluster: subClusterName },
  );
}

export function disconnectSubCluster({
  userId,
  clusterName,
  subClusterName,
}: {
  userId: string;
  clusterName: string;
  subClusterName: string;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/disconnectsubcluster`,
    { subCluster: subClusterName },
  );
}

export function getSuspendTimes() {
  return axios.get<SuspendTimeData>(
    `${API_URL}/users/instances/availableidletime`,
  );
}

export function getAuthUsers({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.get<AuthUserData>(
    `${API_URL}/users/${userId}/instances/${clusterName}/autherizedusers`,
  );
}

export function addUserRole({
  userId,
  clusterName,
  user,
  bypassValidationFlag = false,
}: {
  userId: string;
  clusterName: string;
  user: {
    userEmail: string;
    roleName: string;
  };
  bypassValidationFlag?: boolean;
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/adduserrole`,
    {
      ...user,
      bypassValidationFlag,
    },
  );
}

export function updateClusterUserRole({
  userId,
  clusterName,
  user,
}: {
  userId: string;
  clusterName: string;
  user: {
    userEmail: string;
    roleName: string;
  };
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/updateuserrole`,
    user,
  );
}

export function removeClusterUser({
  userId,
  clusterName,
  user,
}: {
  userId: string;
  clusterName: string;
  user: {
    id: string;
  };
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/removeuserrole`,
    { userID: user.id },
  );
}

export function withdrawClusterUserInvitation({
  userId,
  clusterName,
  user,
}: {
  userId: string;
  clusterName: string;
  user: {
    id: string;
  };
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/withdrawinvitation`,
    { userID: user.id },
  );
}

export function getClusterWhiteList({
  userId,
  clusterName,
}: {
  userId: string;
  clusterName: string;
}) {
  return axios.get<WhiteListData>(
    `${API_URL}/users/${userId}/instances/${clusterName}/whitelists`,
  );
}

export function addIpWhiteList({
  userId,
  clusterName,
  whitelist,
}: {
  userId: string;
  clusterName: string;
  whitelist: {
    range: string;
    description: string;
  };
}) {
  return axios.post(
    `${API_URL}/users/${userId}/instances/${clusterName}/whitelists`,
    whitelist,
  );
}

export function removeClusterIpWhitelist({
  userId,
  clusterName,
  whitelistId,
}: {
  userId: string;
  clusterName: string;
  whitelistId: number;
}) {
  return axios.delete(
    `${API_URL}/users/${userId}/instances/${clusterName}/whitelists/${whitelistId}`,
  );
}

export function updateClusterIpWhitelist({
  userId,
  clusterName,
  whitelistId,
  whitelist,
}: {
  userId: string;
  clusterName: string;
  whitelistId: number;
  whitelist: {
    active?: boolean;
    range?: string;
    description?: string;
  };
}) {
  return axios.put(
    `${API_URL}/users/${userId}/instances/${clusterName}/whitelists/${whitelistId}`,
    whitelist,
  );
}

export function markMessageAsRead(
  userId: string,
  notificationId: string | undefined,
) {
  return axios.post<SupportToken>(
    `${API_URL}/notification/${userId}/readNotifications`,
    { notificationsIDs: notificationId ? [notificationId] : [] },
  );
}

export function downloadDataAgent(instanceid: string) {
  return axios.get(
    `${API_URL}/users/instances/downloadagent?instanceid=${instanceid}`,
  );
}

export async function switchAzureSynapse({
  userID,
  instanceName,
  instanceId,
  tenant,
  username,
  password,
  enableMicrosoftSynapse,
}: {
  userID: string;
  instanceName: string;
  instanceId: string;
  tenant: string;
  username: string;
  password: string;
  enableMicrosoftSynapse: boolean;
}) {
  return axios.put(
    `${API_URL}/users/${userID}/instances/${instanceName}/azuresynapse`,
    {
      tenant: tenant,
      instanceId: instanceId,
      username: username,
      password: password,
      enableMicrosoftSynapse,
    },
  );
}

export async function getClusterSecrets(
  instance: string,
  userid: string,
): Promise<AxiosResponse<ClusterSecrets>> {
  return await axios.get(
    `${API_URL}/users/${userid}/instances/${instance}/secrets`,
  );
}

export async function getAdvancedConfigurations(
  instanceId: string,
): Promise<AdvancedConfig[]> {
  const { data: configs } = await axios.get<AdvancedConfigsResponse>(
    `${API_URL}/instances/${instanceId}/advanced-configs`,
  );
  return mapAdvancedConfigsResponseToAdvancedConfigsModel(configs);
}

export async function updateAdvancedConfigurations(
  instanceId: string,
  changes: UpdateAdvancedConfigsRequest,
) {
  return axios.patch(
    `${API_URL}/instances/${instanceId}/advanced-configs`,
    changes,
  );
}

export async function stopOrRestartService({
  instanceId,
  action,
  service,
  nodeName,
  all,
}: {
  instanceId: string;
  action: ClusterAction;
  service: ServiceToRestart;
  nodeName?: string;
  all?: boolean;
}) {
  return axios.put(`${API_URL}/instances/${instanceId}/services/execute`, {
    action, // 'start' or 'stop' or 'restart',
    service, // 'loader', 'analytics', 'sqli', or 'notebook',
    ...(!all && { nodeName }),
    ...(all && { node: 'all' }), // 'all' or undefined if we don't want to apply the action for all replicas
  });
}

export async function getServicesStatus(
  instanceId: string,
): Promise<{ data: ServicesStatusResponse }> {
  return axios.get(`${API_URL}/instances/${instanceId}/services/status`);
}

export async function getClusterServicesConsumption(
  userId: string,
  instanceName: string,
): Promise<AxiosResponse<{ nodes: ClusterServiceConsumption[] }>> {
  return axios.get(
    `${API_URL}/users/${userId}/instances/${instanceName}/nodes`,
  );
}

export async function getSystemConfigurations({
  configKey,
  configType,
  defaultValue,
}: {
  configKey: string;
  configType: string;
  defaultValue: string;
}): Promise<{ data: { sysConfig: string } }> {
  return axios.post(`${API_URL}/systemconfigurations`, {
    configKey,
    configType,
    defaultValue,
  });
}

export async function getAutoScaleHistory({
  instanceId,
  hours,
}: {
  instanceId: string;
  hours?: number;
}): Promise<
  AxiosResponse<
    Record<'analytics' | 'loader', Record<string, AutoScaleHistoryRecord[]>>
  >
> {
  return axios.get(
    `${API_URL}/users/instances/${instanceId}/getautoscalehistory/${
      hours ?? 24
    }`,
  );
}

export async function changeCopilotState({
  userId,
  clusterID,
  openAIEnabled,
}: {
  userId: string;
  clusterID: string;
  openAIEnabled: boolean;
}) {
  return axios.post<ChangeSqliStateResult>(
    `${API_URL}/users/${userId}/instances/${clusterID}/copilot`,
    { openAIEnabled },
  );
}

export async function syncPythonPackages({
  instanceID,
}: {
  instanceID: string;
}) {
  return axios.post(`${API_URL}/users/syncpythonpackages`, { instanceID });
}

export async function getScheduledEvent({
  instanceID,
}: {
  instanceID: string;
}) {
  return axios.get<ScheduledEventsType>(
    `${API_URL}/users/instances/${instanceID}/scheduleevent`,
  );
}

export async function deleteScheduledEvent({
  instanceID,
  eventID,
}: {
  instanceID: string;
  eventID: number;
}) {
  return await axios.delete(
    `${API_URL}/users/instances/${instanceID}/${eventID}/deletescheduledevent`,
  );
}

export async function updateScheduledEvent({
  instanceID,
  updatedEvent,
}: {
  instanceID: string;
  updatedEvent: UpdateScheduledEventEndpointBody;
}) {
  return await axios.post(
    `${API_URL}/users/instances/${instanceID}/scheduleevent`,
    updatedEvent,
  );
}

export async function createScheduledEvent({
  instanceID,
  eventToCreate,
}: {
  instanceID: string;
  eventToCreate: CreateScheduledEventEndpointBody;
}) {
  return await axios.post(
    `${API_URL}/users/instances/${instanceID}/scheduleevent`,
    eventToCreate,
  );
}

export async function changeMLflowState({
  userID,
  instanceID,
  isEnabled,
}: {
  userID: string;
  instanceID: string;
  isEnabled: boolean;
}) {
  return axios.post<ChangeSqliStateResult>(
    `${API_URL}/users/instances/mlflow`,
    { isEnabled, userID, instanceID },
  );
}
