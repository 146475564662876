import './ClusterTypeField.less';

import React, { useMemo } from 'react';
import { InfoCircleFilled } from '@ant-design/icons';
import { Form, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { CSize } from 'types/cluster';
import { getClusterTypeValue } from 'utils/cluster';
import { InstancePlatform } from 'utils/platforms';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
import { useClusterSizeTypes } from 'hooks/cluster';
import ClusterTypeFieldSlider from 'components/ClusterCreate/ClusterTypeField/ClusterTypeFieldSlider/ClusterTypeFieldSlider';

interface ClusterTypeFieldProps {
  cluster: { csize?: CSize };
  currentPlatform: InstancePlatform;
  azureClusterSize?: string;
}

function ClusterTypeField({
  cluster,
  currentPlatform,
  azureClusterSize = 'medium',
}: ClusterTypeFieldProps) {
  let { data: result } = useClusterSizeTypes();
  const sizes = useMemo(() => result?.data.sizes ?? [], [result]);
  const editableSizes = useMemo(() => result?.data.allSizes ?? [], [result]);

  const currentsize = sizes.filter(size => {
    return cluster.csize === size.type;
  });
  function getClusterSize() {
    if (currentPlatform !== InstancePlatform.AZURE) {
      return sizes?.length === 0
        ? null
        : currentsize.length > 0
        ? currentsize[0].type
        : sizes[0].type;
    }
    return getClusterTypeValue(azureClusterSize);
  }

  const size = getClusterSize();

  if (sizes.length === 0 || !size) {
    return (
      <div className="size-fields__skeleton-wrapper">
        <Spin>
          <div className="size-fields__skeleton" />
        </Spin>
      </div>
    );
  }
  return (
    <Form.Item
      name={clusterFormFieldNames.csize}
      initialValue={size}
      rules={[
        {
          required: true,
          message: <FormattedMessage id="clusterForm.clusterSizeRequired" />,
        },
      ]}
      className="size-fields cluster-input-with-hint"
      label={<FormattedMessage id="clusterForm.clusterSizeLabel" />}
      extra={
        <>
          <InfoCircleFilled />{' '}
          <FormattedMessage
            id="clusterForm.clusterSizeExtra"
            values={{ supportLink: <Link to="/support">Contact Support</Link> }}
          />
        </>
      }
    >
      <ClusterTypeFieldSlider
        sizes={sizes}
        currentSize={size}
        editableSizes={editableSizes}
        currentPlatform={currentPlatform}
      />
    </Form.Item>
  );
}

export default ClusterTypeField;
