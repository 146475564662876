import React, { useContext, useMemo, useState } from 'react';
import './ScheduledEventScalingInfoStep.less';
import { Form, InputNumber } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoCircleFilled, InfoCircleOutlined } from '@ant-design/icons';
import { scheduledEventFormFieldNames } from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/ScheduledEventModal';
import { ScheduledEventEnum, isEventExistingType } from 'types/cluster';
import { maxServiceNodesCount } from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterServicesControl/ClusterServicesControlHelper/useClusterServicesControlHelper';
import { SessionContext } from 'auth/SessionProvider';

type ScheduledEventScalingInfoStepProps = {
  currentFormValues: Record<any, any>;
  instanceId: string;
  isEventExisting: isEventExistingType;
};
function ScheduledEventScalingInfoStep({
  currentFormValues,
  instanceId,
  isEventExisting,
}: ScheduledEventScalingInfoStepProps) {
  const intl = useIntl();
  const { user } = useContext(SessionContext);
  const instanceServices = user.instanceServices?.find(
    srvc => srvc.instanceId === instanceId,
  );
  const currentAnalyticsReplicas = instanceServices?.analyticsNodes || 0;
  const currentLoaderReplicas = instanceServices?.loaderNodes || 0;
  const isViewOnly = isEventExisting === 'View';

  const { event: eventValue } = currentFormValues;
  const isScaleUp = eventValue === ScheduledEventEnum.SCALE_UP;

  const rangeAnalytics = {
    from: isScaleUp ? currentAnalyticsReplicas : 1,
    to: isScaleUp ? maxServiceNodesCount.analytics : currentAnalyticsReplicas,
  };
  const analyticsInputMin = 0;
  const analyticsInputMax = useMemo(
    () => Math.abs(rangeAnalytics.to - rangeAnalytics.from),
    [rangeAnalytics.to, rangeAnalytics.from],
  );

  const rangeLoader = {
    from: isScaleUp ? currentLoaderReplicas : 1,
    to: isScaleUp ? maxServiceNodesCount.loader : currentLoaderReplicas,
  };
  const loaderInputMin = 0;
  const loaderInputMax = useMemo(
    () => Math.abs(rangeLoader.to - rangeLoader.from),
    [rangeLoader.to, rangeLoader.from],
  );

  const [analyticsInputValue, setAnalyticsInputValue] = useState(0);
  const [loaderInputValue, setLoaderInputValue] = useState(0);
  function handleAnalyticsInputChange(val: string | number | null | undefined) {
    if (typeof val === 'number') {
      setAnalyticsInputValue(val);
    }
  }
  function handleLoaderInputChange(val: string | number | null | undefined) {
    if (typeof val === 'number') {
      setLoaderInputValue(val);
    }
  }
  const analyticsAfterEvent = useMemo(
    () =>
      isScaleUp
        ? currentAnalyticsReplicas + analyticsInputValue
        : currentAnalyticsReplicas - analyticsInputValue,
    [analyticsInputValue, currentAnalyticsReplicas, isScaleUp],
  );
  const loaderAfterEvent = useMemo(
    () =>
      isScaleUp
        ? currentLoaderReplicas + loaderInputValue
        : currentLoaderReplicas - loaderInputValue,
    [loaderInputValue, currentLoaderReplicas, isScaleUp],
  );

  return (
    <>
      <section className="scheduled-event-scaling-step__hint">
        <InfoCircleOutlined />
        <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleStepHint" />
      </section>
      <Form.Item
        name={scheduledEventFormFieldNames.eventInfoAnalyticsReplicas}
        label={
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleAnalyticsReplicas" />
        }
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="scheduled-event-scaling-step__form-item-analytics-replicas"
        extra={
          <section className="scheduled-event-scaling-step__count-hint">
            <InfoCircleFilled />
            <FormattedMessage
              id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleReplicasCounts.current"
              values={{
                currentReplicas: currentAnalyticsReplicas,
              }}
            />
            {!isViewOnly && (
              <FormattedMessage
                id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleReplicasCounts.after"
                values={{
                  replicasAfterEvent: analyticsAfterEvent,
                }}
              />
            )}
          </section>
        }
      >
        <InputNumber
          min={analyticsInputMin}
          max={analyticsInputMax}
          placeholder={intl.formatMessage({
            id: 'clusterConfiguration.schedulingTab.scheduledEventForm.scaleAnalyticsReplicasPlaceholder',
          })}
          onChange={handleAnalyticsInputChange}
          disabled={isViewOnly}
        />
      </Form.Item>
      <Form.Item
        name={scheduledEventFormFieldNames.eventInfoLoaderReplicas}
        label={
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleLoaderReplicas" />
        }
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="scheduled-event-scaling-step__form-item-loader-replicas"
        extra={
          <section className="scheduled-event-scaling-step__count-hint">
            <InfoCircleFilled />
            <FormattedMessage
              id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleReplicasCounts.current"
              values={{
                currentReplicas: currentLoaderReplicas,
              }}
            />
            {!isViewOnly && (
              <FormattedMessage
                id="clusterConfiguration.schedulingTab.scheduledEventForm.scaleReplicasCounts.after"
                values={{
                  replicasAfterEvent: loaderAfterEvent,
                }}
              />
            )}
          </section>
        }
      >
        <InputNumber
          min={loaderInputMin}
          max={loaderInputMax}
          placeholder={intl.formatMessage({
            id: 'clusterConfiguration.schedulingTab.scheduledEventForm.scaleLoaderReplicasPlaceholder',
          })}
          onChange={handleLoaderInputChange}
          disabled={isViewOnly}
        />
      </Form.Item>
    </>
  );
}

export default ScheduledEventScalingInfoStep;
