import './ClusterConnectBiToolsEdit.less';

import React from 'react';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Modal, notification, Switch, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useMutateSqliState } from 'hooks/cluster';
import { ReactComponent as BiTools } from 'images/bi-tools-icon.svg';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import CopyableText from 'components/CopyableText/CopyableText';
import { getClusterStatus } from 'utils/cluster';
import { UserData } from 'types/user';

interface ClusterConnectBiToolsEditProps {
  instance: Instance;
  serverConfigurations: ServerConfiguration[];
  services: Service[];
  user: UserData;
}

function ClusterConnectBiToolsEdit({
  instance,
  serverConfigurations,
  services,
  user,
}: ClusterConnectBiToolsEditProps) {
  const intl = useIntl();
  const { mutateAsync: mutateSqliState, isLoading } = useMutateSqliState({
    clusterName: instance.name,
  });
  async function handleChange(enable: boolean) {
    if (enable) {
      const { data } = await mutateSqliState(true);
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } else {
      Modal.confirm({
        title: 'Disconnect External BI Tool!',
        content:
          'Disconnecting will lead loss of any current or upcoming query request.',
        okText: 'Disconnect',
        centered: true,
        width: 620,
        async onOk() {
          const { data } = await mutateSqliState(false);
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    }
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  const status = getClusterStatus(instance, services[0]);

  const enable = status === 'running';
  const platformTest = user?.platformActions?.find(
    ({ name, platforms }) =>
      name === 'sqli' && platforms.includes(instance.platform),
  );

  return (
    <div className="ClusterEdit ClusterConnectBiToolsEdit">
      <div className="ClusterEdit__icon">
        <Icon component={BiTools} />
      </div>
      <div className="ClusterEdit__content ClusterConnectBiToolsEdit__content">
        <div className="ClusterConnectBiToolsEdit__main">
          <h3 className="ClusterEdit__title">Connect External BI Tools</h3>
          <div className="ClusterConnectBiToolsEdit__body">
            <p className="ClusterConnectBiToolsEdit__hint">
              {instance.sqliEnabled ? (
                <>
                  Connect to External BI tools by copying the following strings
                  to the BI tools!
                </>
              ) : (
                <>
                  Enabling connection allow you connect Tableau, Power BI or SQL
                  client to Incorta using PostgreSQL connector.
                </>
              )}
            </p>

            {instance.sqliEnabled ? (
              <div className="ClusterConnectBiToolsEdit__info-wrapper">
                {serverConfigurations.map(config => {
                  return (
                    <div
                      key={config.name}
                      className="ClusterConnectBiToolsEdit__info-box"
                    >
                      <div className="ClusterConnectBiToolsEdit__info-box-label">
                        {config.name}{' '}
                        <Tooltip
                          placement="top"
                          title={config.description}
                          overlayClassName="full-width-tooltip"
                        >
                          <InfoCircleOutlined />
                        </Tooltip>
                      </div>
                      <div className="ClusterConnectBiToolsEdit__info-box-content">
                        <CopyableText text={config.text} />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
        <div className="ClusterConnectBiToolsEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                status !== 'running' ? (
                  'Your cluster must be connected to apply this change'
                ) : !platformTest ? (
                  <FormattedMessage
                    id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                    values={{
                      action: intl.formatMessage({
                        id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.sqli',
                      }),
                    }}
                  />
                ) : (
                  ''
                )
              }
            >
              <Switch
                onChange={handleChange}
                loading={isLoading}
                checked={instance.sqliEnabled}
                disabled={!enable || !platformTest}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default ClusterConnectBiToolsEdit;
