import React, { useState } from 'react';
import { Input, Modal } from 'antd';
import './EditPythonPackageModal.less';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { pythonVersionRegex } from '../../../../../../utils/constants';
import { useMutateCluster } from 'hooks/cluster';

type EditPythonPackageModalProps = {
  clusterName: string;
  wholePackageName: string;
  visible: boolean;
  handleCloseEdit: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  handleEditPackages: Function;
};
function EditPythonPackageModal({
  clusterName,
  wholePackageName,
  visible,
  handleCloseEdit,
  handleEditPackages,
}: EditPythonPackageModalProps) {
  const [packageName, initialPackageVersion] = wholePackageName?.split('==');
  const [inputValue, setInputValue] = useState(initialPackageVersion);
  const [isValid, setIsValid] = useState(true);

  const { isLoading: isMutateClusterLoading } = useMutateCluster({
    clusterName,
  });

  function handleChangeVersion(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setInputValue(value);

    const isValidInput = value === '' || pythonVersionRegex.test(value);
    setIsValid(isValidInput);
  }

  async function handleInstallPackage(
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) {
    await handleEditPackages(
      `${packageName}${inputValue ? `==${inputValue}` : ''}`,
    );
    handleCloseEdit(e);
  }

  return (
    <Modal
      title={
        <section className="edit-python-package__header">
          <label>
            <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.edit" />{' '}
            <label className="edit-python-package__package-name">
              {packageName}
            </label>
          </label>
        </section>
      }
      visible={visible}
      onOk={handleInstallPackage}
      onCancel={handleCloseEdit}
      okText={'Install'}
      cancelText={'Cancel'}
      okButtonProps={{ loading: isMutateClusterLoading, disabled: !isValid }}
    >
      <section className="edit-python-package__content">
        <label className="edit-python-package__version-label">
          <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.title" />
        </label>
        <Input
          value={inputValue}
          onChange={handleChangeVersion}
          placeholder="Latest"
        />
        <InfoCircleOutlined />{' '}
        <label className="edit-python-package__version-hint">
          <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.hint" />
        </label>
        {!isValid && (
          <>
            <br />
            <label className="edit-python-package__error-label">
              <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.errorLabel" />
            </label>
          </>
        )}
      </section>
    </Modal>
  );
}

export default EditPythonPackageModal;
