export const platformMap = new Map<string, string>([
  ['gc', 'Google Cloud'],
  ['az', 'Azure'],
  ['az-saas', 'Azure'],
]);

export enum InstancePlatform {
  GC = 'gc',
  AZURE = 'az',
  AZURE_SAAS = 'az-saas',
}
