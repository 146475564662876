import {
  AdvancedConfig,
  AdvancedConfigsResponse,
  ConfigCategoryItemConfig,
  AdvancedConfigCategory,
  ConfigCategoryType,
  NormalizedAdvancedConfig,
  UpdateAdvancedConfigsRequest,
  AdvancedConfigTabDisplayName,
} from 'types/cluster';

export const getDependencies = (
  changes: AdvancedConfigurationStateChanges | undefined,
  categoryConfigs: ConfigCategoryItemConfig[],
  advancedConfig: string,
) => {
  const curDependencies: Record<
    string,
    Record<string, any>
  > = getConfigsDependenciesMap(categoryConfigs, advancedConfig);

  fillDependenciesWithConfigValue(
    categoryConfigs,
    advancedConfig,
    curDependencies,
  );

  updateDependenciesWithChanges(advancedConfig, curDependencies, changes);

  return curDependencies[advancedConfig] || {};
};

/**
 * @example categoryConfigs: [
 *  { "key":"SERVICE_MAIL_USERNAME",
 *    "dependencies":[{"key":"SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT","value":true}],
 *    ....
 *  },
 *  {
 *    "key":"WARMUP_MEMORY_LIMIT",
 *   "dependencies":[{"key":"WARMUP_MODE_ANALYTICS","value":"Most Used Dashboards Columns"}]
 *  }
 * ]
 *
 * mapped to -> {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: '',
 *  },
 *  'Tenant configurations (default): {
 *    WARMUP_MODE_ANALYTICS: ''
 *  }
 * }
 * @param categoryConfigs
 * @param advancedConfigName
 * @returns
 */
function getConfigsDependenciesMap(
  categoryConfigs: ConfigCategoryItemConfig[],
  advancedConfigName: string,
): Record<string, Record<string, ''>> {
  const curDependencies: { [key: string]: any } = {};
  categoryConfigs.forEach((item: any) => {
    if (item.dependencies.length !== 0) {
      item.dependencies.forEach((dependency: any) => {
        if (!curDependencies[advancedConfigName]) {
          curDependencies[advancedConfigName] = {
            [dependency.key]: '',
          };
        } else if (!curDependencies[advancedConfigName][dependency.key]) {
          curDependencies[advancedConfigName][dependency.key] = '';
        }
      });
    }
  });
  return curDependencies;
}

/**
 * @example categoryConfigs: [
 *  { "key":"SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT",
 *    "value": true
 *    ....
 *  },
 *  {
 *    "key":"WARMUP_MODE_ANALYTICS",
 *    "value":"Most Used Dashboards Columns",
 *    ....
 *  }
 * ]
 *
 * dependencies -> {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: '',
 *  },
 *  'Tenant configurations (default): {
 *    WARMUP_MODE_ANALYTICS: ''
 *  }
 * }
 *
 * will map dependencies -> {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: true,
 *  },
 *  'Tenant configurations (default): {
 *    WARMUP_MODE_ANALYTICS: 'Most Used Dashboards Columns'
 *  }
 * }
 * @param categoryConfigs
 * @param advancedConfigName
 * @returns
 */
function fillDependenciesWithConfigValue(
  categoryConfigs: ConfigCategoryItemConfig[],
  advanceConfigName: string,
  dependencies: Record<string, Record<string, any>>,
) {
  categoryConfigs.forEach((item: any) => {
    if (
      dependencies[advanceConfigName] &&
      item.key in dependencies[advanceConfigName] &&
      dependencies[advanceConfigName][item.key] === ''
    )
      dependencies[advanceConfigName][item.key] = item.value;
  });
}

/**
 * @example  dependencies: {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: true,
 *  },
 *  'Tenant configurations (default): {
 *    WARMUP_MODE_ANALYTICS: 'Most Used Dashboards Columns'
 *  }
 * }
 *
 * changes: {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: false,
 *  },
 * }
 *
 * will map dependencies to -> {
 *  'Server configurations': {
 *    SERVICE_MAIL_USE_SENDER_USERNAME_BY_DEFAULT: false, // updated
 *  },
 *  'Tenant configurations (default): {
 *    WARMUP_MODE_ANALYTICS: 'Most Used Dashboards Columns'
 *  }
 * }
 */
function updateDependenciesWithChanges(
  advanceConfigName: string,
  dependencies: Record<string, Record<string, any>>,
  changes: AdvancedConfigurationStateChanges | undefined,
) {
  if (!changes) return;
  for (let category in changes[advanceConfigName]) {
    for (let itemKey in changes[advanceConfigName][category]) {
      if (
        dependencies[advanceConfigName] &&
        itemKey in dependencies[advanceConfigName]
      )
        dependencies[advanceConfigName][itemKey] =
          changes[advanceConfigName][category][itemKey];
    }
  }
}

export function normalizeAdvancedConfigurations(
  configs: AdvancedConfig[],
): NormalizedAdvancedConfig {
  return configs.reduce((acc, val: AdvancedConfig) => {
    acc[val.displayName] = {
      ...val,
      categoriesList: [...val.categories],
      categories: normalizeCategoryConfigurations(val.categories),
    };

    return acc;
  }, {} as NormalizedAdvancedConfig);
}

function normalizeCategoryConfigurations(
  catgories: AdvancedConfigCategory[],
): Record<ConfigCategoryType, ConfigCategoryItemConfig[]> {
  return catgories.reduce((acc, val: AdvancedConfigCategory) => {
    acc[val.category] = val.config;
    return acc;
  }, {} as Record<ConfigCategoryType, ConfigCategoryItemConfig[]>);
}

export function getAdvancedConfigCategoryGuildUrl(
  advancedConfigId: 'system' | string,
  category: ConfigCategoryType,
) {
  const baseUrl = 'https://docs.incorta.com/cloud';
  const guidePathUrl =
    advancedConfigId === 'system'
      ? 'guides-configure-server'
      : 'guides-configure-tenants';
  const categoryGuideAnchor = category.toLowerCase().split(' ').join('-');
  return `${baseUrl}/${guidePathUrl}/#${categoryGuideAnchor}`;
}
/**
 * @example mapping changes: {
 *  'Server Configurations': {
 *    'SQL Interface': {
 *      'SQLI_SSL_ENABLED': true,
 *      'SQLI_SSL_ENFORCED': true
 *      id: 'system'
 *    }
 *  },
 *  'Tenant Configurations (default)': {
 *    'Security': {
 *      INVITE_USERS_ENABLED: true,
 *      AUTHENTICATION_TYPE: 'LDAP'
 *    }
 *  }
 * }
 *
 * to -> AdvancedConfigurationsRequest:
 * [
 *  {
 *    name: 'system',
 *    data: [
 *       {
 *          configKey: 'SQLI_SSL_ENABLED',
 *          configValue: true
 *       }
 *       {
 *          configKey: 'SQLI_SSL_ENFORCED',
 *          configValue: true
 *       }
 *    ]
 *  }
 *  {
 *    name: 'default',
 *    data: [
 *       {
 *          configKey: 'INVITE_USERS_ENABLED',
 *          configValue: true
 *       }
 *       {
 *          configKey: 'AUTHENTICATION_TYPE',
 *          configValue: 'LDAP'
 *       }
 *    ]
 *  }
 * ]
 * @param changes
 * @returns UpdateAdvancedConfigsRequest
 */
export const mapChangesToUpdateAdvancedConfigurationsRequest = (
  changes: AdvancedConfigurationStateChanges,
): UpdateAdvancedConfigsRequest => {
  const updates: {
    [key: string]: {
      configKey: string;
      configValue: any;
    }[];
  } = {};
  for (let tab in changes) {
    for (let category in changes[tab]) {
      Object.entries(changes[tab][category]).forEach(([key, value]) => {
        if (key === 'id') return;
        updates[changes[tab][category].id] = [
          ...(updates[changes[tab][category].id]
            ? updates[changes[tab][category].id]
            : []),
          {
            configKey: key,
            configValue: value,
          },
        ];
      });
    }
  }

  return Object.keys(updates).map(updateKey => ({
    name: updateKey,
    data: updates[updateKey],
  }));
};

export function mapAdvancedConfigsResponseToAdvancedConfigsModel(
  configs: AdvancedConfigsResponse,
): AdvancedConfig[] {
  return configs.map(config => {
    const isServer = config.tab === 'Server';

    const displayName = isServer
      ? 'Server Configurations'
      : `Tenant Configurations (${config.name})`;

    /**
     * Upon sending the update request to the BE to save the updated configs change
     * The BE requires a unique identifier for the updated entity (Server or Tenant and if tenant which tenant)
     * So in case the updated entity was "Server" the ID will be -> system
     * However if the updated entity was "Tenant" then the ID will be -> tenant name
     */
    const id = isServer ? 'system' : config.name!;

    return {
      ...config,
      displayName,
      id,
    };
  });
}

export type AdvancedConfigurationReducerAction = {
  type: AdvancedConfigurationReducerActionType;
  payload?: AdvancedConfigurationReducerActionPayload;
};

export type AdvanceConfigurationState = {
  changes?: AdvancedConfigurationStateChanges;
  advancedConfigs: AdvancedConfig[];
  normalizedAdvancedConfigs?: NormalizedAdvancedConfig;
  isSaveDisabled: boolean;
  requiresRestart: boolean;
  selectedConfigs?: Record<AdvancedConfigTabDisplayName, ConfigCategoryType>;
  currentSelectedTab?: AdvancedConfigTabDisplayName;
  helpUrl: string;
  servicesRequireRestart: {
    name: 'loader' | 'analytics' | 'all' | 'SQLi';
    color: 'green' | 'gold' | 'purple' | 'blue';
  }[];
  isAdvancedConfigsEditable: boolean;
};

export type AdvancedConfigurationStateChanges = {
  [key: string]: {
    [key: string]: {
      id: string;
      [key: string]: string | number | boolean;
    };
  };
};

type AdvancedConfigurationReducerActionType =
  | 'CONIFGURATIONS_FETCHED_SUCCEEDED'
  | 'SAVING_CONFIGURATION_CHANGES_SUCCEEDED'
  | 'CONFIGURATION_CATEGORY_SELECTION_CHANGED'
  | 'CONFIGURATION_TAB_SELECTION_CHANGED'
  | 'CATEGORY_CONFIG_UPDATED';

type AdvancedConfigurationReducerActionPayload = {
  categorySelection?: ConfigCategoryType;
  tabSelection?: AdvancedConfigTabDisplayName;
  CategoryConfigItemKey?: string;
  CategoryConfigItemValue?: string | number | boolean;
  restartRequired?: boolean;
  advancedConfigs?: AdvancedConfig[];
  isSqliMigrated?: boolean;
  isSqliSeparated?: boolean;
  isAdvancedConfigsEditable?: boolean;
};
