import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY as string);

interface StripeElementsWrapperProps {}

function StripeElementsWrapper({
  children,
}: React.PropsWithChildren<StripeElementsWrapperProps>) {
  return <Elements stripe={stripePromise}>{children}</Elements>;
}

export default StripeElementsWrapper;
