import './ConsentForm.less';

import React, { useContext, useEffect } from 'react';
import { Typography, notification, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import ConsentFlowDetails from '../ConsentFlowDetails/ConsentFlowDetails';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import { Subscription } from 'types/cluster';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';
import {
  useAzureClusterPipelineState,
  useCreateSubscription,
} from 'hooks/cluster';

const { Title } = Typography;

function ConsentForm() {
  const history = useHistory();
  const intl = useIntl();
  const { user, reloadUser, isLoading } = useContext(SessionContext);
  // const { search } = useLocation<any>();
  // console.log(search.split('=')[1]);

  if (!isLoading && user?.showConsentFlow === false) {
    history.push('/clusters');
  }

  const { refetch: refetchAzureClusterPipeline } = useAzureClusterPipelineState(
    {
      email: user.email,
      userID: user.uuid,
      enabled: !!(user.pipelineStatus && user.uuid && user.email),
    },
  );

  const {
    mutateAsync: mutateCreateSubscription,
    isLoading: isPending,
    data: result,
    isError: isRejected,
    error: errorMessage,
  } = useCreateSubscription();

  async function handleCreateSubscription(formData: Subscription) {
    await mutateCreateSubscription({ user, subscription: { ...formData } });
    displaySuccessMessage(
      intl.formatMessage({
        id: 'consentFlow.submissionSuccess',
      }),
    );
    await refetchAzureClusterPipeline();
    reloadUser();
    history.push('/clusters');
  }

  const defaultSubscription = {
    configurationName: '',
    servicePrincipalCredentials: '',
    subscriptionID: '',
    resourceGroup: '',
    appID: '',
    password: '',
    tenant: '',
    network: false,
    vnName: '',
    subnet1: '',
    subnet2: '',
    subnet3: '',
    subnet4: '',
    subnet5: '',
    region: '',
    token: '',
    userId: '',
    email: '',
    plan: '',
    incortaImage: '',
    k8sVersion: '',
  };

  useEffect(() => {
    if (isRejected) {
      notification.error({
        message: intl.formatMessage({
          id: 'consentFlow.submissionError',
        }),
      });
    }
  }, [intl, isRejected]);
  return (
    <IncortaLayoutContainer>
      {isLoading && user?.showConsentFlow === undefined ? (
        <Spin className="consent-flow__spinner" />
      ) : (
        <div className="consent-flow">
          <Title level={2} className="consent-flow__title">
            {intl.formatMessage({ id: 'consentFlow.formTitle' })}
          </Title>
          <Title level={3} className="consent-flow__sub-title">
            {intl.formatMessage({ id: 'consentFlow.formSubtitle' })}
          </Title>

          <ConsentFlowDetails
            onSubmit={handleCreateSubscription}
            loading={isPending}
            subscription={defaultSubscription}
            result={result}
            isRejected={isRejected}
            errorMessage={errorMessage}
          />
        </div>
      )}
    </IncortaLayoutContainer>
  );
}

export default ConsentForm;
