import './CreateNewSubClusterModal.less';

import { Form, Button, Col, Input, Row, Select } from 'antd';
import React, { useState, useContext, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Rule } from 'antd/lib/form';
import {
  clusterNameRules,
  getClusterTypeName,
  getClusterTypeIcons,
} from 'utils/cluster';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import {
  useClusterSizeTypes,
  useRequestCreateSubCluster,
  useUpdateSubCluster,
} from 'hooks/cluster';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';
// import { ReactComponent as ChidoriLogo } from 'images/chidori-icon.svg';

export const createNewSubClusterModalFieldNames = {
  name: 'name',
  size: 'size',
  type: 'type',
} as const;

interface CreateNewSubClusterModalProps {
  onCancel(): void;
  instance: Instance;
  subCluster?: SubCluster;
}

function CreateNewSubClusterModal({
  instance,
  subCluster,
  onCancel,
}: CreateNewSubClusterModalProps) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();
  const [form] = Form.useForm();
  const selfManagedClusters = instance.subClusters;
  const isSelfManagedClustersHaveSqlx =
    selfManagedClusters?.findIndex(
      smc => smc.type === SelfManagedClusterTypes.sqlx,
    ) >= 0
      ? true
      : false;
  const isSelfManagedClustersHaveChidori =
    selfManagedClusters?.findIndex(
      smc => smc.type === SelfManagedClusterTypes.chidori,
    ) >= 0
      ? true
      : false;

  const { validateFields } = form;

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  const {
    mutateAsync: requestCreateSubCluster,
    isLoading: createSubClusterLoading,
  } = useRequestCreateSubCluster({ refetchClusterList: true });
  const {
    mutateAsync: requestUpdateSubCluster,
    isLoading: updateSubClusterLoading,
  } = useUpdateSubCluster({ refetchClusterList: true });

  const { data: imagesData, isLoading: isClusterSizesResultLoading } =
    useClusterSizeTypes();
  const sizes = useMemo(() => imagesData?.data.sizes ?? [], [imagesData]);
  const size = sizes?.length === 0 ? null : subCluster?.size ?? sizes[0].type;

  const types = [
    {
      id: 0,
      label: 'Analytics',
      value: SelfManagedClusterTypes.analytics,
      icon: (
        <IncortaLogo className="CreateNewSubClusterModal__type-select-item" />
      ),
    },
    {
      id: 1,
      label: 'Advanced SQL Warehouse',
      value: SelfManagedClusterTypes.sqlx,
      icon: (
        <i className="CreateNewSubClusterModal__type-select-item sqlx">SQLX</i>
      ),
    },
    // {
    //   id: 2,
    //   label: 'Chidori',
    //   value: SelfManagedClusterTypes.chidori,
    //   icon: (
    //     <ChidoriLogo className="CreateNewSubClusterModal__type-select-item" />
    //   ),
    // },
  ];

  async function handleSubmit(values: any) {
    try {
      await validateFields();
      if (subCluster) {
        // update sub Cluster
        await requestUpdateSubCluster({
          userId: user.uuid,
          clusterName: instance.name,
          data: {
            subCluster: subCluster.name,
            size: values.size,
          },
        });
      } else {
        // create new one
        await requestCreateSubCluster({
          userId: user.uuid,
          clusterName: instance.name,
          data: {
            type: values.type,
            size: values.size,
            name: values.name,
            nodes: 1,
          },
        });
      }
      onCancel();
    } catch {}
  }

  const loading = createSubClusterLoading || updateSubClusterLoading;

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { name, type, size } = form.getFieldsValue();
    if (
      (subCluster && (!type || !size)) ||
      (!subCluster && (!name || !type || !size))
    ) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  return (
    <div className="CreateNewSubClusterModal modal-body">
      <p className="CreateNewSubClusterModal__desc">
        <FormattedMessage id={'createNewSubClusterModal.description'} />
      </p>

      <Form
        form={form}
        layout="vertical"
        id="ChangeLoadersModal"
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
        initialValues={{
          [createNewSubClusterModalFieldNames.name]: subCluster?.name,
          [createNewSubClusterModalFieldNames.size]: subCluster?.size,
          [createNewSubClusterModalFieldNames.type]: subCluster?.type,
        }}
      >
        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={createNewSubClusterModalFieldNames.name}
              label={
                <FormattedMessage
                  id={'createNewSubClusterModal.nameFieldLabel'}
                />
              }
              rules={subCluster ? [] : (clusterNameRules as Rule[])}
            >
              {subCluster ? (
                <Input defaultValue={subCluster.name} disabled />
              ) : (
                <Input
                  placeholder={intl.formatMessage({
                    id: 'createNewSubClusterModal.nameFieldPlaceholder',
                  })}
                />
              )}
            </Form.Item>
          </Col>

          <Col xs={24} sm={12}>
            <Form.Item
              name={createNewSubClusterModalFieldNames.size}
              label={
                <FormattedMessage id="createNewSubClusterModal.sizeFieldLabel" />
              }
              initialValue={size}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="clusterForm.clusterSizeRequired" />
                  ),
                },
              ]}
            >
              <Select
                showSearch
                loading={sizes?.length === 0}
                placeholder={intl.formatMessage({
                  id: 'createNewSubClusterModal.sizeFieldPlaceholder',
                })}
                disabled={isClusterSizesResultLoading}
              >
                {sizes?.map(size => (
                  <Select.Option key={size.type} value={size.type}>
                    {React.createElement(
                      getClusterTypeIcons(size.type, 'fill'),
                      {
                        className: 'fill-icon',
                        style: { fontSize: 14, color: '#99A6B0' },
                      },
                    )}{' '}
                    {getClusterTypeName(size.type)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col xs={24} sm={12}>
            <Form.Item
              name={createNewSubClusterModalFieldNames.type}
              label={
                <FormattedMessage id="createNewSubClusterModal.typeFieldLabel" />
              }
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="clusterForm.typeFieldError" />,
                },
              ]}
            >
              <Select
                showSearch
                loading={sizes?.length === 0}
                placeholder={intl.formatMessage({
                  id: 'createNewSubClusterModal.typeFieldPlaceholder',
                })}
                className="CreateNewSubClusterModal__type-select"
                dropdownClassName="CreateNewSubClusterModal__type-select-dropdown"
                disabled={!!subCluster}
              >
                {types?.map(type => {
                  let isDisabled = false;
                  if (type.value === SelfManagedClusterTypes.sqlx) {
                    isDisabled = isSelfManagedClustersHaveSqlx;
                  } else if (type.value === SelfManagedClusterTypes.chidori) {
                    isDisabled = isSelfManagedClustersHaveChidori;
                  }

                  return (
                    <Select.Option
                      key={type.value}
                      value={type.value}
                      disabled={isDisabled}
                    >
                      {type.icon}
                      {type.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="modal-body-footer">
          <Button
            htmlType="submit"
            type="primary"
            disabled={
              isSubmitButtonDisabled ||
              form.getFieldsError().some(err => err.errors.length)
            }
            loading={loading}
          >
            {subCluster ? (
              <FormattedMessage id={'createNewSubClusterModal.saveButton'} />
            ) : (
              <FormattedMessage id={'createNewSubClusterModal.createButton'} />
            )}
          </Button>
          <Button onClick={onCancel}>
            <FormattedMessage id={'createNewSubClusterModal.cancelButton'} />
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default CreateNewSubClusterModal;
