import './InviteAuthUserModal.less';

import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import { Button, Form, Input, notification, Select, Modal } from 'antd';
import {
  showErrorMessage,
  getErrorMessage,
  showActivationError,
} from '../../../../../utils/errors';
import { Instance } from 'types/cluster';
import { mapRuleToName } from 'utils/cluster';
import {
  getErrorsOfInput,
  isValidEmail,
  hideErrorBelowField,
  resetErrors,
} from 'utils/fieldsValidation';
import { addUserRole } from 'services/cluster';
import { SessionContext } from 'auth/SessionProvider';

interface InviteAuthUserModalProps {
  instance: Instance;
  onChancel(): void;
  onDone: Function;
}
function InviteAuthUserModal({
  instance,
  onChancel,
  onDone,
}: InviteAuthUserModalProps) {
  const [email, setEmail] = useState('');
  const [role, setRole] = useState<undefined | string>(undefined);
  const [emailNotProceeded, setEmailNotProceeded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();
  const { user: me } = useContext(SessionContext);

  async function handleFinish(bypassValidationFlag: boolean = false) {
    resetErrors();
    setEmailNotProceeded(false);

    async function submitInvitation() {
      if (!role) {
        setRoleError();
      }
      if (email === '' || !isValidEmail(email)) {
        setEmailError();
      }

      if (email !== '' && isValidEmail(email) && role) {
        setIsLoading(true);
        addUserRole({
          userId: me!.uuid,
          clusterName: instance.name,
          user: { userEmail: email, roleName: role },
          bypassValidationFlag: bypassValidationFlag,
        })
          .then(res => {
            onDone(res.data.authorizedUserRoles);
            setIsLoading(false);

            notification.success({
              message: 'Invitation sent',
              description: `An invitation email was sent to ${email}.`,
            });
            onChancel();
          })
          .catch(error => {
            setIsLoading(false);

            let emailErrors = showActivationError(error);
            emailErrors.length > 0 &&
              emailErrors[0].isDomainValidationError !== undefined &&
              setEmailNotProceeded(emailErrors[0].isDomainValidationError);
            !emailErrors[0].isDomainValidationError &&
              showErrorMessage(getErrorMessage(error));
          });
      }
    }

    if (role === 'owner') {
      Modal.confirm({
        title: `Transfer ${instance.name} ownership to ${email}?`,
        content: `Once the user accepts the invitation sent via email, cluster ownership can't be revoked or rolled back.`,
        okText: 'Transfer',
        cancelText: 'Cancel',
        centered: true,
        width: 620,
        async onOk() {
          await submitInvitation();
        },
      });
    } else {
      await submitInvitation();
    }
  }

  const setEmailError = (e?: any, error?: string) => {
    getErrorsOfInput(
      'email',
      e ? () => !isValidEmail(e.target.value) : () => true,
      error ? error : " This doesn't seem to be a valid email. Try again.",
    );
  };

  const EmailValidation = useCallback(
    _.debounce((e?: any) => {
      setEmailError(e);
    }, 1500),
    [],
  );

  const setRoleError = (e?: any, error?: string) => {
    getErrorsOfInput(
      'role',
      () => true,
      error ? error : 'please input your role',
    );
  };

  return (
    <div className="InviteAuthUserModal modal-body">
      <Form
        form={form}
        layout="vertical"
        id="InviteAuthUserModal"
        onFinish={() => handleFinish()}
      >
        <div className="InviteAuthUserModal__input">
          <Form.Item label="Invite someone to help administer your Incorta cloud cluster">
            <div className="InviteAuthUserModal__input-group">
              <div className="input-wrapper input-field-container">
                <Form.Item noStyle name="email">
                  <Input
                    placeholder="Email"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    onKeyUp={e => {
                      if (e.code !== 'Enter') {
                        hideErrorBelowField('email');
                        EmailValidation(e);
                      }
                    }}
                  />
                </Form.Item>
                {emailNotProceeded && (
                  <span className="activate-error-message">
                    This doesn’t seem to be a valid email. Try again or{' '}
                    <Button
                      type="link"
                      disabled={isLoading}
                      onClick={e => handleFinish(true)}
                    >
                      continue with this email.
                    </Button>
                  </span>
                )}
              </div>
              <div className="input-wrapper input-field-container">
                <Form.Item noStyle name="role">
                  <Select
                    placeholder="Select Role"
                    value={role}
                    onChange={role => {
                      hideErrorBelowField('role');
                      setRole(role);
                    }}
                  >
                    {instance.clusterPolicy.authorizedRoles.map(rule => {
                      return (
                        <Select.Option key={rule} value={rule}>
                          {mapRuleToName(rule)}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </div>
          </Form.Item>
        </div>

        <div className="modal-body-footer">
          <Button onClick={onChancel}>Cancel</Button>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            Send Invite
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default InviteAuthUserModal;
