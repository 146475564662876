import './ImportedBlueprints.less';

import React from 'react';
import { Collapse } from 'antd';
import { InfoCircleFilled, CaretRightOutlined } from '@ant-design/icons';
import parse from 'html-react-parser';
import { ImportedBlueprintItem } from '../BlueprintItem/BlueprintItem';
import EmptyBlueprint from '../EmptyBlueprint/EmptyBlueprint';
import BlueprintLoading from '../BlueprintLoading/BlueprintLoading';
import { ImportState, Service } from 'types/cluster';
import { PlatformActions } from 'types/user';

const { Panel } = Collapse;

interface ImportedBlueprintsProps {
  importState: ImportState;
  service: Service;
  wasLoading: boolean;
  instanceName: string;
  disableMessage: string | undefined;
  showCreate: boolean;
  platformTest: PlatformActions | undefined;
  instancePlatform: string | undefined;
}

function ImportedBlueprints({
  importState,
  service,
  wasLoading,
  instanceName,
  showCreate,
  disableMessage,
  platformTest,
  instancePlatform,
}: ImportedBlueprintsProps) {
  let setupUrl = `${service.url.split('#/')[0]}#/data-sources/connectors`;
  let isEmpty = Object.keys(importState.importedBlueprints).length === 0;
  let isLoading = importState.state === 'loading';
  let showWarning = wasLoading && importState.state === 'success';
  let importedBlueprintItems = Object.keys(importState.importedBlueprints);

  return (
    <div className="ImportedBlueprints">
      {isLoading && <BlueprintLoading message={importState.message} />}

      {showWarning && (
        <>
          <div className="ImportedBlueprints__desc">
            <InfoCircleFilled />{' '}
            {parse(
              importState.datasourceMessage?.replace(
                '{DataTab}',
                `<a  href=${setupUrl} target="_blank" rel="noopener noreferrer">Data Tab</a>`,
              ) || '',
            )}{' '}
          </div>
        </>
      )}

      {isEmpty && !isLoading && (
        <EmptyBlueprint
          instanceName={instanceName}
          showCreate={showCreate}
          disableMessage={disableMessage}
          platformTest={platformTest}
          instancePlatform={instancePlatform}
        />
      )}

      {!isEmpty && (
        <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIcon={({ isActive }) => (
            <CaretRightOutlined rotate={isActive ? 90 : 0} />
          )}
          className="site-collapse-custom-collapse"
        >
          {importedBlueprintItems.map(
            (blueprintItem: string, index: number) => (
              <Panel
                header={`${blueprintItem} (${importState.importedBlueprints[blueprintItem].length})`}
                key={index + 1}
                className="site-collapse-custom-panel"
              >
                <div className="ImportedBlueprints__body">
                  {importState.importedBlueprints[blueprintItem].map(
                    (blueprint: any) => (
                      <ImportedBlueprintItem
                        key={blueprint.id}
                        blueprintItem={blueprint}
                      />
                    ),
                  )}
                </div>
              </Panel>
            ),
          )}
        </Collapse>
      )}
    </div>
  );
}

export default ImportedBlueprints;
