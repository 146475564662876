import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Modal, Form, Button, Input } from 'antd';
import { Rule } from 'antd/lib/form';
import { FormattedMessage, useIntl } from 'react-intl';
import { Instance } from 'types/cluster';
import { displaySuccessMessage } from 'utils';
import { clusterNameMax, clusterNameRules } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { useCloneCluster } from 'hooks/cluster';

export const cloneModalFieldNames = { name: 'name' } as const;

interface CloneModalProps {
  instance: Instance;
  show: boolean;
  setShow: (value: boolean) => void;
  refresh?: () => Promise<any>;
  onSuccess?: () => void;
}

function CloneModal({
  show,
  setShow,
  instance,
  refresh = async () => {},
  onSuccess = () => {},
}: CloneModalProps) {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { validateFields } = form;
  const { user } = useContext(SessionContext);
  const mounted = useRef(false);
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);

  const { mutateAsync: mutateCloneCluster, isLoading: isPending } =
    useCloneCluster({ refetchClusterList: true });

  async function handleSubmit(values: any) {
    try {
      await validateFields();
      await mutateCloneCluster({
        userId: user?.uuid,
        clusterName: instance.name,
        name: values.name,
      });
      displaySuccessMessage(
        intl.formatMessage({
          id: 'cloneModal.successMessage',
        }),
      );
      onSuccess?.();
      setShow(false);
    } catch {}
  }

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { name } = form.getFieldsValue();
    if (!name || name.length > clusterNameMax) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  const fieldsErrors = useCallback(() => {
    if (show) {
      return form.getFieldsError().some(err => err.errors.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  function handleCancel() {
    setShow(false);
  }

  useEffect(() => {
    if (show) {
      if (mounted?.current) {
        form.validateFields();
      } else {
        mounted.current = true;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted?.current, show]);

  if (!show) {
    return null;
  }

  return (
    <Modal
      forceRender={show}
      destroyOnClose
      title="Clone Cluster"
      visible={show}
      centered
      onCancel={handleCancel}
      footer={
        <div>
          <Button onClick={handleCancel}>
            <FormattedMessage id="cloneModal.cancelButton" />
          </Button>

          <Button
            form="CloneModalProps"
            htmlType="submit"
            type="primary"
            loading={isPending}
            style={{ marginLeft: 8 }}
            disabled={isSubmitButtonDisabled || fieldsErrors()}
          >
            <FormattedMessage id="cloneModal.submitButton" />
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        id="CloneModalProps"
        onFinish={handleSubmit}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name={cloneModalFieldNames.name}
          initialValue={
            `${instance.name}-2`.length > clusterNameMax
              ? ''
              : `${instance.name}-2`
          }
          rules={clusterNameRules as Rule[]}
          label={
            <FormattedMessage id="cloneModal.clonedClusterNameFieldLabel" />
          }
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CloneModal;
