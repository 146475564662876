import React from 'react';
import EmailSentPage from 'components/EmailSentPage/EmailSentPage';
import BlockedUserErrorPage from 'components/ErrorPages/BlockedUser/BlockedUserErrorPage';
import TrialExpiredErrorPage from 'components/ErrorPages/TrialExpired/TrialExpiredErrorPage';
import PersonalEmailErrorPage from 'components/ErrorPages/PersonalEmail/PersonalEmailErrorPage';
import MFARequired from 'components/ErrorPages/MFARequired/MFARequired';

export function ErrorHandler({ message }: any) {
  if (message.toLocaleLowerCase().includes('blocked'))
    return <BlockedUserErrorPage />;
  else if (message.toLocaleLowerCase().includes('expired'))
    return <TrialExpiredErrorPage />;
  else if (message.toLocaleLowerCase().includes('verify'))
    return <EmailSentPage />;
  else if (message.toLocaleLowerCase().includes('personal email'))
    return <PersonalEmailErrorPage />;
  else if (
    message.toLocaleLowerCase().includes('multifactor authentication required')
  ) {
    return <MFARequired />;
  } else if (message.toLocaleLowerCase().includes('state'))
    return <>Invalid State</>;
  else return message;
}
