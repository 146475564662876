import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import ClusterCreatePage from './ClusterCreatePage/ClusterCreatePage';
import { canUserCreateCluster } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';

function ClusterCreate() {
  const { user } = useContext(SessionContext);

  if (
    user.configurations &&
    !canUserCreateCluster({
      user,
      configurations: user?.configurations,
      platformActions: user?.platformActions,
    })
  ) {
    return <Redirect to="/clusters" />;
  }

  return <ClusterCreatePage />;
}

export default ClusterCreate;
