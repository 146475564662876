import React, { ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, Radio } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { OptimizationLevel } from 'types/cluster';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';

const OPTIMIZATION_LEVELS: OptimizationLevel[] =
  Object.values(OptimizationLevel);

const options: { [key in OptimizationLevel]: ReactNode } = {
  [OptimizationLevel.Balanced]: (
    <FormattedMessage id="clusterForm.clusterOptimizationHint.balanced" />
  ),
  [OptimizationLevel.Analytics]: (
    <FormattedMessage id="clusterForm.clusterOptimizationHint.analytics" />
  ),
  [OptimizationLevel.Loader]: (
    <FormattedMessage id="clusterForm.clusterOptimizationHint.loader" />
  ),
};
function ClusterOptimization() {
  const [selectedOption, setSelectedOption] = useState<OptimizationLevel>(
    OPTIMIZATION_LEVELS[1],
  );
  return (
    <Form.Item
      name={clusterFormFieldNames.optimizationLevel}
      className="cluster-input-optimization"
      label={<FormattedMessage id="clusterForm.clusterSizeOptimizationLabel" />}
      extra={
        <>
          <InfoCircleFilled /> {options[selectedOption]}
        </>
      }
      initialValue={OPTIMIZATION_LEVELS[1]}
    >
      <Radio.Group onChange={event => setSelectedOption(event.target.value)}>
        {OPTIMIZATION_LEVELS.map(level => (
          <Radio.Button key={level} value={level}>
            {level}
          </Radio.Button>
        ))}
      </Radio.Group>
    </Form.Item>
  );
}

export default ClusterOptimization;
