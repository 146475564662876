import React, {
  Ref,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './AddPythonPackage.less';
import { Button, Select, Tooltip } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { Instance, Service } from 'types/cluster';
import { UserData } from 'types/user';
import { getClusterStatus } from 'utils/cluster';
import { useMutateCluster } from 'hooks/cluster';

export type AddPythonPackageRefType = {
  resetInput: () => void;
};

type AddPythonPackageProps = {
  instance: Instance;
  services: Service[];
  user: UserData;
  handleAddingNewPackages: Function;
};
function AddPythonPackage(
  { instance, services, user, handleAddingNewPackages }: AddPythonPackageProps,
  ref: Ref<AddPythonPackageRefType>,
) {
  const [tags, setTags] = useState([]);
  const intl = useIntl();
  const installBtnRef = useRef<HTMLButtonElement>(null);

  const { isLoading: isMutateClusterLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  const { isPlatformActionSupported: isPythonSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.packagesInstallion,
    });

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  const status = getClusterStatus(instance, services[0]);

  const isAddButtonDisabled =
    !isPythonSupported || status !== 'running' || !tags.length;

  const handleTagChange = (tag: any) => {
    setTags(tag);
  };

  function handleSubmit(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    handleAddingNewPackages(tags.join(','));
  }

  function handlePressEnter(e: React.KeyboardEvent<HTMLElement>) {
    if (e.key === 'Enter') {
      installBtnRef.current?.focus();
      handleAddingNewPackages(tags.join(','));
    }
  }

  useImperativeHandle(ref, () => ({
    resetInput: () => {
      setTags([]);
    },
  }));

  return (
    <section
      className="add-python-package__wrapper"
      onKeyPress={handlePressEnter}
    >
      <Tooltip
        title={
          status !== 'running' ? (
            <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
          ) : !isPythonSupported ? (
            <FormattedMessage
              id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
              values={{
                action: intl.formatMessage({
                  id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.pythonPackages',
                }),
              }}
            />
          ) : (
            ''
          )
        }
      >
        <Select
          mode="tags"
          tokenSeparators={[' ', '\t']}
          placeholder={
            <FormattedMessage id="clusterConfiguration.pythonPackages.addPackages.placeholder" />
          }
          value={tags}
          onChange={handleTagChange}
          open={false}
          className="add-python-package__select"
          disabled={!isPythonSupported || status !== 'running'}
        />
      </Tooltip>
      {canUpdate && (
        <Tooltip
          title={
            status !== 'running' ? (
              <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
            ) : !isPythonSupported ? (
              <FormattedMessage
                id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                values={{
                  action: intl.formatMessage({
                    id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.pythonPackages',
                  }),
                }}
              />
            ) : (
              ''
            )
          }
        >
          <Button
            ref={installBtnRef}
            type="primary"
            icon={<DownloadOutlined />}
            loading={isMutateClusterLoading}
            disabled={isAddButtonDisabled}
            onClick={handleSubmit}
          >
            <FormattedMessage id="clusterConfiguration.pythonPackages.addPackages.install" />
          </Button>
        </Tooltip>
      )}
    </section>
  );
}

export default forwardRef(AddPythonPackage);
