import './IncortaXCard.less';

import React from 'react';
import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useHistory } from 'react-router';
import { FormattedMessage } from 'react-intl';
import MainCard from '../MainCard/MainCard';
import { ReactComponent as BIToolsIcon } from 'images/bi-tools-icon.svg';
import { Instance, ServerConfiguration } from 'types/cluster';
import CopyableText from 'components/CopyableText/CopyableText';

interface IncortaXCardProps {
  instance: Instance;
  serverConfigurations: ServerConfiguration[];
}

function IncortaXCard({ instance, serverConfigurations }: IncortaXCardProps) {
  let history = useHistory();

  let canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  if (instance.incortaXEnabled) {
    return (
      <MainCard
        icon={<Icon component={BIToolsIcon} />}
        title={'Advanced SQL Interface'}
        subtitle={'Connection Enabled'}
        hideFooter={!canUpdate}
        footerText={'Disable Advanced SQL Interface'}
        onFooterClick={() => {
          history.push(
            `/clusters/${instance.name}?tab=configurations#cluster-incorta-x`,
          );
        }}
        content={
          <div className="incorta-x-card">
            <p className="incorta-x-card__hint">
              <FormattedMessage id="clusterConfiguration.incortaX.mainHint" />
              <label className="incorta-x-card__hint-more">
                <FormattedMessage id="clusterConfiguration.incortaX.moreHint" />
              </label>
            </p>

            <div className="incorta-x-card__server-configuration-wrapper">
              {serverConfigurations.map(config => {
                return (
                  <div
                    key={config.name}
                    className="incorta-x-card__config-section"
                  >
                    <div className="incorta-x-card__config-section-name">
                      {config.name}
                      <Tooltip
                        placement="top"
                        title={config.description}
                        overlayClassName="full-width-tooltip"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <CopyableText text={config.text} />
                  </div>
                );
              })}
            </div>
          </div>
        }
      />
    );
  }

  return (
    <MainCard
      icon={<Icon component={BIToolsIcon} />}
      title={'Advanced SQL Interface'}
      subtitle={'Connection Disabled'}
      hideFooter={!canUpdate}
      footerText={'Enable Advanced SQL Interface'}
      onFooterClick={() => {
        history.push(
          `/clusters/${instance.name}?tab=configurations#cluster-incorta-x`,
        );
      }}
      content={
        <div className="incorta-x-card">
          <p className="incorta-x-card__hint" style={{ marginBottom: 0 }}>
            <FormattedMessage id="clusterConfiguration.incortaX.mainHint" />
            <label className="incorta-x-card__hint-more">
              <FormattedMessage id="clusterConfiguration.incortaX.moreHint" />
            </label>
          </p>
        </div>
      }
    />
  );
}

export default IncortaXCard;
