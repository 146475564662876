import React, { useState } from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Empty, Modal, notification, Space, Table, Switch } from 'antd';
import WhiteListModal from '../WhiteListModal/WhiteListModal';
import { Instance, Whitelist } from 'types/cluster';
import InputTags from 'components/InputTags/InputTags';
import {
  useRemoveIpWhitelist,
  useUpdateClusterIpWhitelist,
} from 'hooks/cluster';

interface WhiteListListProps {
  instance: Instance;
  isLoading: boolean;
  whitelists?: Whitelist[];
  addButtonUi: any;
  setWhitelists: Function;
}
function WhiteListList({
  instance,
  isLoading,
  whitelists,
  addButtonUi,
  setWhitelists,
}: WhiteListListProps) {
  let canDelete = instance.clusterPolicy.whitelistPolicy.includes('delete');
  let canUpdate = instance.clusterPolicy.whitelistPolicy.includes('update');

  let { mutateAsync: deleteIpWhitelist } = useRemoveIpWhitelist({
    clusterName: instance.name,
  });

  let columns = [
    {
      title: 'IP Addresses/Range',
      dataIndex: 'range',
      key: 'range',
      render(range: string) {
        return <InputTags viewOnly value={range} />;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render(_: any, ipWhitelistItem: Whitelist) {
        return (
          <ActiveAction
            instance={instance}
            ipWhitelistItem={ipWhitelistItem}
            whitelists={whitelists}
            setWhitelists={setWhitelists}
          />
        );
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render(_: any, ipWhitelistItem: Whitelist) {
        return (
          <IpWhitelistActions
            canDelete={canDelete}
            canUpdate={canUpdate}
            deleteIpWhitelist={deleteIpWhitelist}
            ipWhitelistItem={ipWhitelistItem}
            instance={instance}
            whitelists={whitelists}
            setWhitelists={setWhitelists}
          />
        );
      },
    },
  ];

  return (
    <div className="AccessSecurityList">
      <Table
        loading={isLoading}
        size="small"
        className="AccessSecurityList__table"
        pagination={false}
        columns={columns}
        dataSource={whitelists}
        rowKey="id"
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No restrictions defined yet."
            >
              {addButtonUi}
            </Empty>
          ),
        }}
      />
    </div>
  );
}

interface IpWhitelistActions {
  canUpdate: boolean;
  canDelete: boolean;
  deleteIpWhitelist: Function;
  ipWhitelistItem: Whitelist;
  instance: Instance;
  whitelists?: Whitelist[];
  setWhitelists: Function;
}
function IpWhitelistActions({
  canUpdate,
  canDelete,
  deleteIpWhitelist,
  ipWhitelistItem,
  instance,
  whitelists,
  setWhitelists,
}: IpWhitelistActions) {
  let [showUpdateModal, setShowUpdateModal] = useState(false);

  return (
    <div style={{ minWidth: 60 }}>
      <Space>
        {canUpdate && (
          <button
            className="icon-btn"
            onClick={() => {
              setShowUpdateModal(true);
            }}
          >
            <EditOutlined />
          </button>
        )}

        {canDelete && (
          <button
            className="icon-btn"
            onClick={() => {
              Modal.confirm({
                title: `Are you sure you want to delete IP Addresses/Range?`,
                okText: 'Delete',
                cancelText: 'Cancel',
                centered: true,
                width: 620,
                async onOk() {
                  await deleteIpWhitelist(ipWhitelistItem.id);
                  let index = whitelists?.indexOf(ipWhitelistItem);
                  if (index !== undefined && index !== -1) {
                    whitelists?.splice(index, 1);
                  }

                  setWhitelists(whitelists);
                  notification.success({
                    message: 'IP Addresses/Range Deleted',
                    description: `IP Addresses/Range deleted successfully.`,
                  });
                },
              });
            }}
          >
            <DeleteOutlined />
          </button>
        )}
      </Space>

      <Modal
        title="Add IP Addresses/Range"
        visible={showUpdateModal}
        onCancel={() => setShowUpdateModal(false)}
        footer={null}
        width={645}
        destroyOnClose
        centered
      >
        <WhiteListModal
          ipWhitelistItem={ipWhitelistItem}
          onChancel={() => setShowUpdateModal(false)}
          instance={instance}
          whitelists={whitelists}
          setWhitelists={setWhitelists}
        />
      </Modal>
    </div>
  );
}

interface ActiveActionProps {
  ipWhitelistItem: Whitelist;
  instance: Instance;
  whitelists?: Whitelist[];
  setWhitelists: Function;
}

function ActiveAction({
  ipWhitelistItem,
  instance,
  whitelists,
  setWhitelists,
}: ActiveActionProps) {
  let { mutateAsync: updateClusterIpWhiteList, isLoading } =
    useUpdateClusterIpWhitelist({
      clusterName: instance.name,
    });

  let canUpdate = instance.clusterPolicy.whitelistPolicy.includes('update');

  return (
    <Switch
      size="small"
      checked={ipWhitelistItem.active}
      loading={isLoading}
      disabled={!canUpdate}
      onChange={() => {
        updateClusterIpWhiteList({
          whitelistId: ipWhitelistItem.id,
          whitelist: { active: !ipWhitelistItem.active },
        });
        let index = whitelists?.indexOf(ipWhitelistItem);
        if (index !== undefined && index !== -1) {
          whitelists![index].active = !ipWhitelistItem.active;
        }
        setWhitelists(whitelists);
      }}
    ></Switch>
  );
}

export default WhiteListList;
