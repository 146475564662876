import React from 'react';
import './AzureCreationClusterSubCard.less';
import { useIntl } from 'react-intl';
import { AzureClusterType } from '../azureClusterCreationHelper';
import AzureCreationClusterSubCardOneLineInfo from './AzureCreationClusterSubCardOneLineInfo';

type AzureCreationClusterSubCardProps = {
  azureClusterType: string;
  configurationName: string;
  currentVersion: string;
  size: string;
  region: string;
  autoSuspend: string;
};
function AzureCreationClusterSubCard({
  azureClusterType,
  configurationName,
  currentVersion,
  size,
  region,
  autoSuspend,
}: AzureCreationClusterSubCardProps) {
  const intl = useIntl();

  return (
    <div className="azure-creation-cluster-sub-card__wrapper">
      <p className="bolder-700">{`${configurationName}-${
        azureClusterType === AzureClusterType.prod ? 'prod' : 'dev'
      }`}</p>
      <AzureCreationClusterSubCardOneLineInfo
        label={intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.info.currentVersion',
        })}
        info={currentVersion}
      />
      <AzureCreationClusterSubCardOneLineInfo
        label={intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.info.size',
        })}
        info={size}
      />
      <AzureCreationClusterSubCardOneLineInfo
        label={intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.info.region',
        })}
        info={region}
      />
      <AzureCreationClusterSubCardOneLineInfo
        label={intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.info.autoSuspend',
        })}
        info={autoSuspend}
      />
    </div>
  );
}

export default AzureCreationClusterSubCard;
