import React, { useState } from 'react';

import { useQuery } from 'react-query';
import { FormattedMessage } from 'react-intl';
import {
  DatabaseOutlined,
  EyeOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { Popover, Space } from 'antd';
import CopyableText from 'components/CopyableText/CopyableText';
import { getClusterSecrets } from 'services/cluster';
import { getErrorMessage, showErrorMessage } from 'utils/errors';
import './ClusterSecretsPopover.less';

export interface ClusterSecrets {
  DB_PW: string;
  DB_USER: string;
  DB_CONNECTION: string;
}

const CLUSTERS_SECRETS_KEY = 'clusters-secrets';

function SecretsContent({
  secrets,
  isLoading,
}: {
  secrets?: ClusterSecrets;
  isLoading: boolean;
}) {
  return (
    <div className="secerts-popover">
      <h5>
        <b>
          <FormattedMessage id="clusterPage.secrets.dbConnection" />
        </b>
        <span>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 14 }} spin />
          ) : secrets?.DB_CONNECTION ? (
            <CopyableText text={secrets.DB_CONNECTION} />
          ) : (
            '-'
          )}
        </span>
      </h5>
      <h5>
        <b>
          <FormattedMessage id="clusterPage.secrets.dbUsername" />
        </b>
        <span>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 14 }} spin />
          ) : secrets?.DB_USER ? (
            <CopyableText text={secrets.DB_USER} />
          ) : (
            '-'
          )}
        </span>
      </h5>
      <h5 className="password">
        <b>
          <FormattedMessage id="clusterPage.secrets.dbPassword" />
        </b>{' '}
        <span>
          {isLoading ? (
            <LoadingOutlined style={{ fontSize: 14 }} spin />
          ) : secrets?.DB_PW ? (
            <CopyableText text={secrets.DB_PW} />
          ) : (
            '-'
          )}
        </span>
      </h5>
    </div>
  );
}

function ClusterSecretsPopover({
  clusterName,
  userId,
}: {
  clusterName: string;
  userId: string;
}) {
  const [getSecrets, setGetSecrets] = useState(false);

  const { data: secrets, isLoading } = useQuery(
    [CLUSTERS_SECRETS_KEY, clusterName, userId],
    () => getClusterSecrets(clusterName, userId),
    {
      enabled: getSecrets,
      onError(error) {
        showErrorMessage(error ? getErrorMessage(error) : '');
        setGetSecrets(false);
      },
      retry: 0,
      staleTime: 4 * 1000 * 60,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    },
  );

  return (
    <>
      <Popover
        trigger={'click'}
        placement="bottomRight"
        title={
          <div className="title">
            <Space>
              <DatabaseOutlined />
              <FormattedMessage id="clusterPage.secrets.titlle" />
            </Space>
            <></>
          </div>
        }
        content={
          <SecretsContent secrets={secrets?.data} isLoading={isLoading} />
        }
        overlayClassName="secrets-container"
      >
        <button
          onClick={() => setGetSecrets(true)}
          className="icon-btn"
          title="display cluster secrets"
        >
          <EyeOutlined />
        </button>
      </Popover>
    </>
  );
}

export default ClusterSecretsPopover;
