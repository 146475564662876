import './IncBackgroundContainer.less';

import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface IncBackgroundContainerProps {
  children?: ReactNode;
  className?: String;
}

function IncBackgroundContainer({
  className,
  children,
}: IncBackgroundContainerProps) {
  return (
    <div className={clsx('inc-background-container', className)}>
      <div className="inc-background-container__body">
        <div className="inc-background-container__body-main">{children}</div>
      </div>
    </div>
  );
}

export default IncBackgroundContainer;
