import './AppNav.less';

import React, { useState, useEffect, useMemo, useContext } from 'react';
import Icon, {
  CaretDownOutlined,
  QuestionCircleOutlined,
  BellFilled,
  InfoCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  CheckCircleFilled,
} from '@ant-design/icons';
import { Layout, Menu, Dropdown, Modal, Popover, Spin, Typography } from 'antd';
import { Link, NavLink } from 'react-router-dom';
import ClickOutside from '../ClickOutside/ClickOutside.js';
import AboutModal from './AboutModal/AboutModal';
import { ReactComponent as ConsumptionIcon } from 'images/consumed-icon.svg';
import incortaLogo from 'images/incorta-logo.svg';
import { parseDateFromUTCToString } from 'utils/date';
import { ReactComponent as MyAccountIcon } from 'images/my-account.svg';
import { ReactComponent as AboutIcon } from 'images/about.svg';
import { useClusterList, useMarkNotificationAsRead } from 'hooks/cluster';
import { Notification, NotificationInfo } from 'types/cluster';
import LogoutButton from 'components/SignIn/Logout';
import { SessionContext } from 'auth/SessionProvider';

const { Header } = Layout;

function AppNav() {
  let [showAboutModal, setShowAboutModal] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [showNotification, setShowNotification] = useState(false);
  let [notificationsList, setNotificationsList] = useState<
    NotificationInfo | undefined
  >(undefined);
  const { user } = useContext(SessionContext);

  let { data } = useClusterList();
  const markNotificationsAsRead = useMarkNotificationAsRead();
  const notifications = data?.notifications;

  useMemo(() => {
    if (notifications) {
      setNotificationsList(notifications);
    }
  }, [notifications]);

  const markAsRead = (notificationId?: string | undefined) => {
    markNotificationsAsRead({
      notificationId: notificationId,
      userId: user.uuid,
    });
  };

  useEffect(() => {
    setIsLoading(false);
  }, [data]);

  const unreadNotifications = notificationsList?.notifications?.filter(
    notification => !notification.read,
  );

  return (
    <Header className="header">
      <div className="header__logo">
        <Link to="/clusters">
          <img src={incortaLogo} alt="Incorta Logo" width="75" />
        </Link>
      </div>

      <nav className="header__nav">
        <ul>
          <li>
            <NavLink to={'/clusters'}>Clusters</NavLink>
          </li>

          {user?.enableMarketplace && (
            <li>
              <NavLink to={'/data-apps'}>Data Apps</NavLink>
            </li>
          )}
        </ul>
      </nav>

      {user && (
        <div className="header__right">
          <div className="header__menu-section">
            <ClickOutside
              handleClickOutside={() => {
                setShowNotification(false);
                if (showNotification) {
                  markAsRead();
                }
              }}
            >
              <div className="header__menu-section__notification">
                <div
                  className="notification-icon-wrapper"
                  id="notification-icon"
                  onClick={() => setShowNotification(!showNotification)}
                >
                  <BellFilled className="notification-icon" />
                  {notificationsList?.hasUnRead && !showNotification && (
                    <div className="unread-messages-number">
                      {(unreadNotifications?.length ?? 0) > 9
                        ? '+9'
                        : unreadNotifications?.length}
                    </div>
                  )}
                </div>
                {showNotification && (
                  <div className="header__menu-section__notification-box">
                    <Spin
                      indicator={
                        <LoadingOutlined style={{ fontSize: 24 }} spin />
                      }
                      spinning={isLoading}
                    >
                      {notificationsList?.notifications &&
                      notificationsList?.notifications.length > 0 ? (
                        <>
                          <header>
                            <h2>Notifications</h2>
                          </header>
                          <section className="header__menu-section__notification-box_notification-messages">
                            {notificationsList &&
                              notificationsList.notifications.map(
                                (notification: Notification) => (
                                  <Popover
                                    content={notification.message}
                                    title={notification.event}
                                    trigger="click"
                                    key={notification?.id}
                                  >
                                    <article
                                      onClick={() =>
                                        markAsRead(notification.id)
                                      }
                                    >
                                      {notification.eventType === 'info' && (
                                        <InfoCircleOutlined />
                                      )}
                                      {notification.eventType === 'success' && (
                                        <CheckCircleFilled className="success-notification" />
                                      )}
                                      {notification.eventType === 'failure' && (
                                        <CloseCircleOutlined className="error-notification" />
                                      )}
                                      <div className="notification-description">
                                        <h3>{notification.event}</h3>
                                        <p>{notification.message}</p>
                                        <span>
                                          {parseDateFromUTCToString(
                                            notification.createdAt,
                                          )}
                                        </span>
                                      </div>
                                      {!notification.read && (
                                        <button>
                                          <div className="unread-sign" />
                                        </button>
                                      )}
                                    </article>
                                  </Popover>
                                ),
                              )}
                          </section>
                        </>
                      ) : (
                        !isLoading && (
                          <div className="no-notification-message">
                            <span> No Notifications </span>
                          </div>
                        )
                      )}
                    </Spin>
                  </div>
                )}
              </div>
            </ClickOutside>
            {showNotification && <div className="overlay-notification" />}

            <div className="header__menu">
              <Dropdown
                placement="bottomRight"
                overlayClassName="header__dropdown-overlay"
                overlay={
                  <Menu>
                    <Menu.Item key="My Account">
                      <Link to="/my-account">
                        <Icon component={MyAccountIcon} /> My Account
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="Incorta Units Consumption">
                      <Link to="/consumption">
                        <Icon component={ConsumptionIcon} /> Incorta Units
                        Consumption
                      </Link>
                    </Menu.Item>
                    <Menu.Divider />
                    <Menu.Item key="Support">
                      <Link to="/support">
                        <QuestionCircleOutlined /> Help & Support
                      </Link>
                    </Menu.Item>
                    <Menu.Item
                      key="About"
                      onClick={() => {
                        setShowAboutModal(true);
                      }}
                    >
                      <Icon component={AboutIcon} /> About
                    </Menu.Item>
                    <Menu.Divider />

                    <div style={{ margin: '10px 12px' }}>
                      <LogoutButton />
                    </div>
                  </Menu>
                }
                trigger={['click']}
              >
                <div className="header__sub-menu-title">
                  <div className="header__sub-menu-info">
                    <span className="header__sub-menu-info-name">
                      {`${user.fullName || user.email}`}{' '}
                      {user.role.name === 'associatePartner' ? (
                        <Typography.Text
                          strong
                          style={{ fontWeight: 'bold', color: 'white' }}
                        >
                          (Incorta Partner)
                        </Typography.Text>
                      ) : (
                        ''
                      )}
                    </span>
                  </div>

                  <CaretDownOutlined className="header__sub-menu-down-icons" />
                </div>
              </Dropdown>
            </div>
          </div>

          <Modal
            className="header__about-modal"
            visible={showAboutModal}
            onCancel={() => {
              setShowAboutModal(false);
            }}
            width={520}
            footer={null}
            centered
          >
            <AboutModal />
          </Modal>
        </div>
      )}
    </Header>
  );
}

export default AppNav;
