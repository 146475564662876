import './CopyableText.less';

import React, { useEffect, useState } from 'react';
import { CheckCircleFilled, CopyOutlined } from '@ant-design/icons';
import copy from 'clipboard-copy';

interface CopyableTextProps {
  text: string;
}
function CopyableText({ text }: CopyableTextProps) {
  let [copping, setCopping] = useState(false);

  useEffect(() => {
    if (copping) {
      let id = setTimeout(() => setCopping(false), 1000);
      return () => clearTimeout(id);
    }
  }, [copping]);

  return (
    <div
      role="button"
      className="CopyableText"
      onClick={() => {
        copy(text);
        setCopping(true);
      }}
    >
      <div className="CopyableText__text">
        {text}
        <div className="CopyableText__copy-icon">
          {copping ? (
            <CheckCircleFilled style={{ color: '#469650' }} />
          ) : (
            <CopyOutlined />
          )}
        </div>
      </div>
    </div>
  );
}

export default CopyableText;
