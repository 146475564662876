export const azureCloudShellUrl = 'https://portal.azure.com/#home';

export const regionsDefaults = [
  'East US',
  'West US',
  'UK South',
  'France Central',
  'Sweden Central	',
  'Canada Central',
  'UAE North',
  'Australia East',
];

export const configKeys = {
  azureRegions: 'azureRegions',
};
