import './BlueprintSelect.less';

import React from 'react';
import { Spin } from 'antd';
import ImportedBlueprints from './ImportedBlueprints/ImportedBlueprints';
import { Service, ImportState } from 'types/cluster';
import { PlatformActions } from 'types/user';

interface BlueprintSelectProps {
  service: Service;
  importState: ImportState | undefined;
  wasLoading: boolean;
  blueprintLoading: boolean;
  instanceName: any;
  showCreate: boolean;
  disableMessage: string | undefined;
  platformTest: PlatformActions | undefined;
  instancePlatform: string | undefined;
}

function BlueprintSelect({
  service,
  importState,
  instanceName,
  wasLoading,
  blueprintLoading,
  showCreate,
  disableMessage,
  platformTest,
  instancePlatform,
}: BlueprintSelectProps) {
  if (blueprintLoading) {
    return (
      <div className="flex-center" style={{ height: 150, width: '100%' }}>
        <Spin />
      </div>
    );
  }

  if (importState) {
    return (
      <div className="BlueprintSelect">
        <ImportedBlueprints
          wasLoading={wasLoading}
          importState={importState}
          service={service}
          instanceName={instanceName}
          showCreate={showCreate}
          disableMessage={disableMessage}
          platformTest={platformTest}
          instancePlatform={instancePlatform}
        />
      </div>
    );
  }

  return null;
}

export default BlueprintSelect;
