import React, {
  createContext,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

type LocationContextProps = {
  lastLocation: null | string;
  currentLocation: null | string;
};
export const LocationContext = createContext<LocationContextProps>({
  currentLocation: null,
  lastLocation: null,
});

function LastLocation({ children }: PropsWithChildren<any>) {
  const { pathname } = useLocation();
  const [lastLocation, setLastLocation] = useState<null | string>(null);
  const [currentLocation, setCurrentLocation] = useState<null | string>(
    pathname,
  );

  useEffect(() => {
    if (currentLocation !== null && pathname !== currentLocation) {
      setLastLocation(prev => currentLocation);
      setCurrentLocation(prev => pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return (
    <LocationContext.Provider value={{ lastLocation, currentLocation }}>
      {children}
    </LocationContext.Provider>
  );
}

export default LastLocation;
