import React from 'react';
import './PythonPackageTagInfo.less';
import { ExclamationCircleFilled, LoadingOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import PythonPackageInstallationStatus from 'components/PythonPackageInstallationStatus/PythonPackageInstallationStatus';
import {
  InstalledPythonPackage,
  pythonInstallationStateEnum,
} from 'types/cluster';

type PythonPackageTagInfoProps = {
  pythonPackage: InstalledPythonPackage;
  reinstallPythonPackage: (packageName: string) => void;
  isClusterRunning: boolean;
};
function PythonPackageTagInfo({
  pythonPackage,
  reinstallPythonPackage,
  isClusterRunning,
}: PythonPackageTagInfoProps) {
  const isAnalyticsAndLoaderSucceeded =
    pythonPackage.analyticsState === pythonInstallationStateEnum.succeeded &&
    pythonPackage.loaderState === pythonInstallationStateEnum.succeeded;
  const isAnalyticsOrLoaderLoading =
    pythonPackage.analyticsState === pythonInstallationStateEnum.installing ||
    pythonPackage.loaderState === pythonInstallationStateEnum.installing ||
    pythonPackage.analyticsState === pythonInstallationStateEnum.uninstalling ||
    pythonPackage.loaderState === pythonInstallationStateEnum.uninstalling;
  const hasAnalyticsOrLoaderFailed =
    pythonPackage.analyticsState === pythonInstallationStateEnum.failed ||
    pythonPackage.loaderState === pythonInstallationStateEnum.failed;

  const [packageName, packageVersion] = pythonPackage.name.split('==');

  return (
    <div className="python-package-tag-info__wrapper">
      <label>{packageName}</label>
      {packageVersion && (
        <label className="python-package-tag-info__version">
          {packageVersion}
        </label>
      )}
      {!isAnalyticsAndLoaderSucceeded && (
        <Tooltip
          title={
            <PythonPackageInstallationStatus
              pythonPackage={pythonPackage}
              reinstallPythonPackage={reinstallPythonPackage}
              isClusterRunning={isClusterRunning}
            />
          }
          overlayClassName="python-package-tag-info__overlay"
        >
          {hasAnalyticsOrLoaderFailed ? (
            <ExclamationCircleFilled className="python-package-tag-info__failed-icon" />
          ) : (
            isAnalyticsOrLoaderLoading && (
              <LoadingOutlined className="python-package-tag-info__installing-icon" />
            )
          )}
        </Tooltip>
      )}
    </div>
  );
}

export default PythonPackageTagInfo;
