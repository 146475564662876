import './PersonalInformation.less';

import React, { useContext, useState } from 'react';
import { Form, Col, Row, Card, Input, Button } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { PersonalInformationEdit, User } from 'types/user';
import { displaySuccessMessage } from 'utils';
import { SessionContext } from 'auth/SessionProvider';
import { useUpdatePersonalInformation } from 'hooks/user';

export const PersonalInformationFieldNames = {
  firstName: 'firstName',
  lastName: 'lastName',
  jobTitle: 'jobTitle',
  company: 'company',
  phone: 'phone',
} as const;

function PersonalInformation() {
  const [form] = Form.useForm();
  const { getFieldsValue, resetFields, getFieldsError } = form;
  let intl = useIntl();

  const [submitBtnDisabled, setSubmitBtnDisabled] = useState(true);

  const { user } = useContext(SessionContext);

  const {
    mutateAsync: mutateUpdatePersonalInformation,
    isLoading: isUpdatePersonalInformationLoading,
  } = useUpdatePersonalInformation();

  const isDirty = isPersonalInformationDirty(user!, getFieldsValue());

  const [firstName, setFirstName] = useState(user?.firstName);
  const [lastName, setLastName] = useState(user?.lastName);
  const [jobTitle, setJobTitle] = useState(user?.jobTitle);
  const [company, setCompany] = useState(user?.company);
  const [phone, setPhone] = useState(user!.phone);

  function handleChange(e: any) {
    switch (e?.target?.id) {
      case PersonalInformationFieldNames.firstName:
        setFirstName(e?.target?.value);
        break;
      case PersonalInformationFieldNames.lastName:
        setLastName(e?.target?.value);
        break;
      case PersonalInformationFieldNames.jobTitle:
        setJobTitle(e?.target?.value);
        break;
      case PersonalInformationFieldNames.company:
        setCompany(e?.target?.value);
        break;
      case PersonalInformationFieldNames.phone:
        setPhone(e?.target?.value);
        break;
      default:
        break;
    }
  }

  function hasErrors() {
    const fieldsErrors = getFieldsError();
    return fieldsErrors.some(field => field.errors.length);
  }

  const onValuesChange = (changedValues: any, allValues: any) => {
    if (!hasErrors()) {
      setSubmitBtnDisabled(false);
    } else {
      setSubmitBtnDisabled(true);
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Personal Information Form Failed:', errorInfo);
  };

  async function handleSubmit(
    personalInformationEdit: PersonalInformationEdit,
  ) {
    try {
      const isValid = !hasErrors();
      if (!isValid) return;
      const res = await mutateUpdatePersonalInformation({
        userId: user!.uuid,
        personalInformationEdit,
      });
      displaySuccessMessage(res.data.message);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Form
      form={form}
      layout="vertical"
      className="personal-information-form"
      onFinish={handleSubmit}
      onFinishFailed={onFinishFailed}
      onValuesChange={onValuesChange}
    >
      <Row justify="center" gutter={[32, 32]}>
        <Col xl={16} span={24}>
          <Card
            title="Profile Info"
            className="personal-information-form__card"
          >
            <Row gutter={40}>
              <Col lg={12} span={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="myAccount.personalInformation.firstNameFieldLabel" />
                  }
                  name={PersonalInformationFieldNames.firstName}
                  initialValue={user?.firstName}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myAccount.personalInformation.firstNameFieldErrorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'myAccount.personalInformation.firstNameFieldPlaceholder',
                    })}
                    value={firstName}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="myAccount.personalInformation.lastNameFieldLabel" />
                  }
                  name={PersonalInformationFieldNames.lastName}
                  initialValue={user?.lastName}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myAccount.personalInformation.lastNameErrorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'myAccount.personalInformation.lastNameFieldPlaceholder',
                    })}
                    value={lastName}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="myAccount.personalInformation.jobTitleFieldLabel" />
                  }
                  name={PersonalInformationFieldNames.jobTitle}
                  initialValue={user?.jobTitle}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myAccount.personalInformation.jobTitleFieldErrorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'myAccount.personalInformation.jobTitleFieldPlaceholder',
                    })}
                    value={jobTitle}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} span={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="myAccount.personalInformation.companyFieldLabel" />
                  }
                  name={PersonalInformationFieldNames.company}
                  initialValue={user?.company}
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="myAccount.personalInformation.companyFieldErrorMessage" />
                      ),
                    },
                  ]}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'myAccount.personalInformation.companyFieldPlaceholder',
                    })}
                    value={company}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>

        <Col xl={16} span={24}>
          <Card
            title="Contact Info"
            className="personal-information-form__card"
          >
            <Row gutter={40}>
              <Col lg={12} span={24}>
                <div className="personal-information-form__card-field">
                  <div className="personal-information-form__card-field-label">
                    Email
                  </div>
                  <div className="personal-information-form__card-field-text">
                    {user!.email}
                  </div>
                </div>
              </Col>
              <Col lg={12} span={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="myAccount.personalInformation.phoneFieldLabel" />
                  }
                  name={PersonalInformationFieldNames.phone}
                  initialValue={user!.phone}
                >
                  <Input
                    placeholder={intl.formatMessage({
                      id: 'myAccount.personalInformation.phoneFieldPlaceholder',
                    })}
                    value={phone}
                    onChange={handleChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row justify="center" gutter={32}>
        <Col xl={16} span={24} className="personal-information-form__actions">
          <Button
            disabled={!isDirty}
            onClick={() => {
              resetFields();
            }}
          >
            Discard Changes
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            disabled={submitBtnDisabled || !isDirty}
            loading={isUpdatePersonalInformationLoading}
          >
            Change
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

function isPersonalInformationDirty(
  user: User,
  personalInformationEdit: PersonalInformationEdit,
) {
  if (Object.keys(personalInformationEdit).length === 0) {
    return false;
  }
  return Object.entries(personalInformationEdit).some(
    // @ts-ignore
    ([key, value]) => (value ? value !== user[key] : user[key]),
  );
}

export default PersonalInformation;
