import axios, { AxiosError } from 'axios';
import { format } from 'date-fns';
import { API_URL } from '../utils/http';
import {
  MeResult,
  AssignCluster,
  ActivationResult,
  SupportData,
  SupportResult,
  GetIndustriesResult,
  PersonalInformationEdit,
  PersonalInformationEditResult,
  PaymentSetupResult,
  UserPaymentMethodResponse,
  CanDowngradeResponse,
  GetStates,
  GetSatesForADate,
} from 'types/user';

export async function me(userid: string) {
  let meRes;
  try {
    meRes = await axios.get<MeResult>(`${API_URL}/users/${userid}/me`);
    return meRes;
  } catch (error) {
    const err = error as AxiosError;
    throw new Error(err.response?.status?.toString());
  }
}

export async function assignClusterToUser(userid: string) {
  try {
    const res = await axios.post<AssignCluster>(
      `${API_URL}/users/${userid}/instances/assign_cluster`,
      {
        userID: userid,
      },
    );
    return res;
  } catch (error) {
    const err = error as AxiosError;
    throw new Error(err.response?.status?.toString());
  }
}

export async function acceptUserCluster(userID: string, instanceID: string) {
  await axios.post<ActivationResult>(`${API_URL}/users/acceptclusterinv`, {
    userID: userID,
    instanceID: instanceID,
  });
}

export async function acceptUserOwnership(userID: string, instanceID: string) {
  await axios.post<ActivationResult>(
    `${API_URL}/users/acceptownershiptransfer`,
    {
      userID: userID,
      instanceID: instanceID,
    },
  );
}

// export async function signIn(user: SignInUserData) {
// }

export function sendSupport(data: SupportData) {
  return axios.post<SupportResult>(`${API_URL}/support`, data);
}

export function getIndustries() {
  return axios.get<GetIndustriesResult>(`${API_URL}/industries`);
}

export async function updatePersonalInformation({
  userId,
  personalInformationEdit,
}: {
  userId: string;
  personalInformationEdit: PersonalInformationEdit;
}) {
  return axios.put<PersonalInformationEditResult>(
    `${API_URL}/users/${userId}/update`,
    personalInformationEdit,
  );
}

export function paymentSetup({ userId }: { userId: string }) {
  return axios.get<PaymentSetupResult>(`${API_URL}/payment/${userId}/setup`);
}

export function addPaymentMethod({
  userId,
  data,
}: {
  userId: string;
  data: { pmid: string; customer: string };
}) {
  return axios.post(`${API_URL}/payment/${userId}`, data);
}

export function getUserPaymentMethod({ userId }: { userId: string }) {
  return axios.get<UserPaymentMethodResponse>(`${API_URL}/payment/${userId}`);
}

export function deleteUserPaymentMethod({ userId }: { userId: string }) {
  return axios.delete(`${API_URL}/payment/${userId}`);
}

export async function changePlan({
  userId,
  plan,
}: {
  userId: string;
  plan: 'free' | 'premium';
}) {
  return await axios.post(`${API_URL}/plan/${userId}`, { plan });
}

export function canDowngrade({ userId }: { userId: string }) {
  return axios.get<CanDowngradeResponse>(`${API_URL}/plan/${userId}/downgrade`);
}

export function getStats({ userId }: { userId: string }) {
  return axios.get<GetStates>(`${API_URL}/users/${userId}/statshistorytotal`);
}

export function getStatsForADate({
  userId,
  date,
}: {
  userId: string;
  date: string;
}) {
  date = format(new Date(date), 'MM/yyyy');
  return axios.get<GetSatesForADate>(
    `${API_URL}/users/${userId}/statshistory`,
    { params: { date } },
  );
}

export async function updateProvisionUserPassword({
  userID,
  isSkipped,
  password,
}: {
  userID: string;
  isSkipped: boolean;
  password?: string;
}) {
  try {
    return axios.put(`${API_URL}/users/${userID}/provision/updatepassword`, {
      isSkipped,
      password,
    });
  } catch (error) {
    const err = error as AxiosError;
    throw new Error(err.response?.status?.toString());
  }
}

export function updateAutoScaling({
  userId,
  instanceID,
  isEnabled,
}: {
  userId: string;
  instanceID: string;
  isEnabled: boolean;
}) {
  return axios.post<{ message: string; status: number }>(
    `${API_URL}/users/${userId}/instances/updateautoscaling`,
    {
      instanceID,
      isEnabled,
    },
  );
}
