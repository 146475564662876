import React, { useCallback, useContext } from 'react';
import { Divider, Spin, Table } from 'antd';
import useRequest from 'hooks/useRequest';
import { getStatsForADate } from 'services/user';
import { Stat } from 'types/user';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';
import { SessionContext } from 'auth/SessionProvider';

interface StatHistoryDetailsProps {
  stat: Stat;
}

function StatHistoryDetails({ stat }: StatHistoryDetailsProps) {
  const { user } = useContext(SessionContext);

  let { result, isPending, isRejected } = useRequest(
    useCallback(
      () => getStatsForADate({ userId: user!.uuid, date: stat.date }),
      [stat.date, user],
    ),
    { resolve: true },
  );

  if (isPending) {
    return (
      <div className="spinner-container">
        <Spin />
      </div>
    );
  }

  if (isRejected) {
    return (
      <div className="flex-center">
        <UnderMaintenance />
      </div>
    );
  }

  let instances = result?.data.instances;

  return (
    <div className="StatHistoryDetails">
      {instances?.map(instance => {
        return (
          <div key={instance.id}>
            <h2>{instance.name}</h2>
            <Table
              size="small"
              dataSource={instance.stats}
              pagination={false}
              rowKey="id"
              columns={[
                {
                  title: 'Date',
                  key: 'dateFormatted',
                  dataIndex: 'dateFormatted',
                },
                {
                  title: 'Units',
                  key: 'units',
                  dataIndex: 'units',
                  align: 'right',
                  render: cost => `${cost} IPU`,
                },
                {
                  title: 'Units cost',
                  key: 'unitsCost',
                  dataIndex: 'unitsCost',
                  align: 'right',
                  render: cost => `${cost} ICC`,
                },
                {
                  title: 'Users',
                  key: 'users',
                  dataIndex: 'users',
                  align: 'right',
                },
                {
                  title: 'Users cost',
                  key: 'usersCost',
                  dataIndex: 'usersCost',
                  align: 'right',
                  render: cost => `${cost} ICC`,
                },
                {
                  title: 'Usage',
                  key: 'usage',
                  dataIndex: 'usage',
                  align: 'right',
                  render: mb => `${mb} GB`,
                },
                {
                  title: 'Usage cost',
                  key: 'usageCost',
                  dataIndex: 'usageCost',
                  align: 'right',
                  render: cost => `${cost} ICC`,
                },
                {
                  title: 'Total',
                  key: 'totalCost',
                  dataIndex: 'totalCost',
                  align: 'right',
                  render: cost => `${cost} ICC`,
                },
              ]}
            />
            <Divider />
          </div>
        );
      })}
    </div>
  );
}

export default StatHistoryDetails;
