import React, { useCallback, useMemo } from 'react';
import Icon, { BarChartOutlined } from '@ant-design/icons';
import { Popover, Space, Progress, Tooltip } from 'antd';
import clsx from 'clsx';
import { ClusterServiceConsumption } from 'types/cluster';
import { ReactComponent as ArrowRightIcon } from 'images/arrow-right.svg';

import './NodesConsumptionPopover.less';

export interface ClusterSecrets {
  DB_PW: string;
  DB_USER: string;
  DB_CONNECTION: string;
}

type Nodes = {
  loaderNodes: ClusterServiceConsumption[];
  analyticsNodes: ClusterServiceConsumption[];
  cmcNodes: ClusterServiceConsumption[];
  subNodes: ClusterServiceConsumption[];
};

const ConsumptionProgressColor = {
  LOW: { from: '#b4d1e8', to: '#678ead' },
  MEDIUM: { from: '#e89f88', to: '#d85328' },
  HIGH: { from: '#bb6e71', to: '#B43237' },
};

const THRESHOLD = {
  LOW: 40,
  MEDIUM: 70,
};

function ConsumptionContent({ nodes }: { nodes: ClusterServiceConsumption[] }) {
  const { analyticsNodes, cmcNodes, loaderNodes, subNodes } = useMemo(
    () =>
      nodes?.reduce<Nodes>(
        (prev, curr) => {
          if (curr.type === 'loader') {
            prev.loaderNodes.push(curr);
          }
          if (curr.type === 'analytics') {
            prev.analyticsNodes.push(curr);
          }
          if (curr.type === 'cmc') {
            prev.cmcNodes.push(curr);
          }
          if (curr.type === 'subcluster') {
            prev.subNodes.push(curr);
          }

          return prev;
        },
        {
          loaderNodes: [],
          analyticsNodes: [],
          cmcNodes: [],
          subNodes: [],
        },
      ),
    [nodes],
  );

  const groupedSubNodes = subNodes.reduce((groupedSubNodes: any, item: any) => {
    const group = groupedSubNodes[item.subcluster] || [];
    group.push(item);
    groupedSubNodes[item.subcluster] = group;
    return groupedSubNodes;
  }, {});

  const parsedGroupedSubNodes: any = [];
  // eslint-disable-next-line
  Object.entries(groupedSubNodes).map(([, value]: any) => {
    parsedGroupedSubNodes.push(value);
  });

  const getProgressColor = useCallback((progress: number) => {
    if (progress <= THRESHOLD.LOW) {
      return ConsumptionProgressColor.LOW;
    } else if (progress <= THRESHOLD.MEDIUM) {
      return ConsumptionProgressColor.MEDIUM;
    }
    return ConsumptionProgressColor.HIGH;
  }, []);

  const getConsumptionPercentage = useCallback((diskSize, availableSize) => {
    return Math.floor(((diskSize - availableSize) / diskSize) * 100);
  }, []);

  return (
    <div className="NodesConsumption__breakdown">
      {loaderNodes.length > 0 && (
        <div>
          <div>Loader Node{loaderNodes.length > 1 && 's'}</div>
          <ol
            className={clsx({
              'remove-list-style': loaderNodes.length === 1,
            })}
          >
            {loaderNodes.map(node => (
              <li>
                <Tooltip
                  title={`Consumed ${
                    node.diskSize - node.availabeSize
                  } GB out of ${node.diskSize} GB`}
                >
                  <div>
                    <Progress
                      status={'normal'}
                      format={(percent: any) => `${percent}%`}
                      strokeColor={getProgressColor(
                        getConsumptionPercentage(
                          node.diskSize,
                          node.availabeSize,
                        ),
                      )}
                      percent={getConsumptionPercentage(
                        node.diskSize,
                        node.availabeSize,
                      )}
                      size="small"
                    />
                    <div className="consumption-details">
                      {node.diskSize - node.availabeSize} / {node.diskSize} GB
                    </div>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ol>
        </div>
      )}

      {analyticsNodes.length > 0 && (
        <div>
          <span>Analytics Node{analyticsNodes.length > 1 && 's'}</span>
          <ol
            className={clsx({
              'remove-list-style': analyticsNodes.length === 1,
            })}
          >
            {analyticsNodes.map(node => (
              <li>
                <Tooltip
                  title={`Consumed ${
                    node.diskSize - node.availabeSize
                  } GB out of ${node.diskSize} GB`}
                >
                  <div>
                    <Progress
                      status={'normal'}
                      format={(percent: any) => `${percent}%`}
                      strokeColor={getProgressColor(
                        getConsumptionPercentage(
                          node.diskSize,
                          node.availabeSize,
                        ),
                      )}
                      percent={getConsumptionPercentage(
                        node.diskSize,
                        node.availabeSize,
                      )}
                      size="small"
                    />
                    <div className="consumption-details">
                      {node.diskSize - node.availabeSize} / {node.diskSize} GB
                    </div>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ol>
        </div>
      )}

      {cmcNodes.length > 0 && (
        <div>
          <span>CMC Node</span>
          <ol
            className={clsx({
              'remove-list-style': cmcNodes.length === 1,
            })}
          >
            {cmcNodes.map(node => (
              <li>
                <Tooltip
                  title={`Consumed ${
                    node.diskSize - node.availabeSize
                  } GB out of ${node.diskSize} GB`}
                >
                  <div>
                    <Progress
                      status={'normal'}
                      format={(percent: any) => `${percent}%`}
                      strokeColor={getProgressColor(
                        getConsumptionPercentage(
                          node.diskSize,
                          node.availabeSize,
                        ),
                      )}
                      percent={getConsumptionPercentage(
                        node.diskSize,
                        node.availabeSize,
                      )}
                      size="small"
                    />
                    <div className="consumption-details">
                      {node.diskSize - node.availabeSize} / {node.diskSize} GB
                    </div>
                  </div>
                </Tooltip>
              </li>
            ))}
          </ol>
        </div>
      )}

      {parsedGroupedSubNodes.length > 0 && (
        <div>
          <div>Subcluster Node{subNodes.length > 1 && 's'}</div>

          {parsedGroupedSubNodes.map((subNode: ClusterServiceConsumption[]) => (
            <ol>
              <div>Subcluster {subNode[0].subcluster}</div>
              {subNode.map(node => (
                <li>
                  <Tooltip
                    title={`Consumed ${
                      node.diskSize - node.availabeSize
                    } GB out of ${node.diskSize} GB`}
                  >
                    <div>
                      <Progress
                        status={'normal'}
                        format={(percent: any) => `${percent}%`}
                        strokeColor={getProgressColor(
                          getConsumptionPercentage(
                            node.diskSize,
                            node.availabeSize,
                          ),
                        )}
                        percent={getConsumptionPercentage(
                          node.diskSize,
                          node.availabeSize,
                        )}
                        size="small"
                      />
                      <div className="consumption-details">
                        {node.diskSize - node.availabeSize} / {node.diskSize} GB
                      </div>
                    </div>
                  </Tooltip>
                </li>
              ))}
            </ol>
          ))}
        </div>
      )}
    </div>
  );
}

function NodesConsumptionPopover({
  nodes,
}: {
  nodes: ClusterServiceConsumption[];
}) {
  return (
    <Popover
      trigger={'click'}
      overlayClassName="nodes-consumption-overlay"
      placement="bottomRight"
      title={
        <div className="title">
          <Space>
            <BarChartOutlined />
            Services Disk Usage Breakdown
          </Space>
          <></>
        </div>
      }
      content={<ConsumptionContent nodes={nodes} />}
    >
      <div className="services-consumption-title">
        <Icon component={ArrowRightIcon} />
        <a href="_">View Disk Usage</a>
      </div>
    </Popover>
  );
}

export default NodesConsumptionPopover;
