import React from 'react';
import { ReactComponent as LoadingIcon } from 'images/loading.svg';
import { ReactComponent as ClusterCheckIcon } from 'images/cluster-check.svg';
import { ReactComponent as SleepIcon } from 'images/sleep.svg';
import { SubClusterStates } from 'types/cluster';

interface SubClusterStateProps {
  state: SubClusterStates;
}

function SubClusterState({ state }: SubClusterStateProps) {
  if (state === 'running') {
    return (
      <span style={{ color: '#469650' }}>
        <ClusterCheckIcon className="text-icon" /> Connected
      </span>
    );
  }

  if (state === 'loading') {
    return (
      <span>
        <LoadingIcon className="text-icon" style={{ color: '#FFA900' }} />{' '}
        Connecting...
      </span>
    );
  }

  if (state === 'disconnected') {
    return (
      <span style={{ color: '#B43237' }}>
        <SleepIcon className="text-icon" /> Disconnected...
      </span>
    );
  }

  return null;
}

export default SubClusterState;
