import React from 'react';
import { notification, Switch, Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';
import { useMutateCopilotState } from 'hooks/cluster';
import { ReactComponent as CopilotLogo } from 'images/copilot-filled.svg';
interface ClusterConnectCopilot {
  instance: Instance;
  services: Service[];
  title: string;
}

function Copilot({ instance, services, title }: ClusterConnectCopilot) {
  const {
    mutateAsync: mutateCopilotState,
    isLoading: isCopilotSettingLoading,
  } = useMutateCopilotState({
    clusterID: instance.id,
  });

  async function handleChange(enable: boolean) {
    let { data } = await mutateCopilotState({ enable });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');
  const status = getClusterStatus(instance, services[0]);

  const isClusterRunning = status === 'running';

  return (
    <div className="ClusterEdit ClusterConnectBiToolsEdit">
      <div className="ClusterEdit__icon">
        <span>
          <CopilotLogo style={{ width: '16px', height: '16px' }} />
        </span>
      </div>
      <div className="ClusterEdit__content ClusterConnectBiToolsEdit__content">
        <div className="ClusterConnectBiToolsEdit__main">
          <h3 className="ClusterEdit__title">{title}</h3>
          <div className="ClusterConnectBiToolsEdit__body">
            <p className="ClusterConnectBiToolsEdit__hint">
              <FormattedMessage id="clusterConfiguration.copilot.desription" />
            </p>
          </div>
        </div>
        <div className="ClusterConnectBiToolsEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                status !== 'running' ? (
                  <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
                ) : (
                  ''
                )
              }
            >
              <Switch
                checked={instance.enableOpenAI}
                onChange={handleChange}
                loading={isCopilotSettingLoading}
                disabled={!isClusterRunning}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
}

export default Copilot;
