import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import UnderMaintenance from 'components/SomethingWentWrong/SomethingWentWrong';

interface ErrorWrapperProps {}

function ErrorWrapper({
  children,
}: React.PropsWithChildren<ErrorWrapperProps>) {
  function handleError(error: Error, info: { componentStack: string }) {
    let errorBody = {
      message: error.message,
      stack: info.componentStack,
    };
    // TODO: send errors to server
    console.log(errorBody);
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorComponent} onError={handleError}>
      {children}
    </ErrorBoundary>
  );
}

function ErrorComponent() {
  return (
    <div className="flex-center" style={{ height: '100vh' }}>
      <UnderMaintenance />
    </div>
  );
}

export default ErrorWrapper;
