import { AzureClusterPipelineData, CSize } from 'types/cluster';

export interface SignUpUserData {
  fullName?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  password?: string;
  company?: string;
  jobTitle?: string;
  phone?: string;
  acceptTerms?: boolean;
  acceptPrivacy?: boolean;
  optInLocation?: string;
  rm_landing_page_url?: string;
  rm_referring_url?: string;
  rm_destination_url?: string;
  rm_person_id?: string;
  bypassValidationFlag?: boolean;
}

interface UserRule {
  createdAt: string;
  description: string;
  id: number;
  name: string;
  updatedAt: string;
  displayName: string;
}

export interface Feature {
  createdAt: string;
  id: number;
  key: string;
  name: string;
  published: boolean;
  toggle: boolean;
  updatedAt: string;
}

export interface User {
  email: string;
  fullname: string;
  firstName: string;
  lastName: string;
  phone: string;
  company: string;
  jobTitle: string;
  isPartner: boolean;
  enableMarketplace: boolean;
  internalUser: boolean;
  instancesCount: number;
  trialExpired: boolean;
  createdAt: string;
  role: UserRule;
  features: Feature[];
  toggleFeatures: Feature[];
  pipelineStatus: AzureClusterPipelineData | null;
}

export interface UserConfigurations {
  allowedInstances: { count: number };
  pythonLibs: { value: string };
  showDiskSize: {
    enabled: boolean;
    value: number;
    min: number;
    max: number;
  };
  showAlwaysAlive: { enabled: boolean; value: boolean };
  showLatestRelease: { enabled: boolean; value: string };
  showInstanceSize: { enabled: boolean; value: CSize };
  showTenants: { enabled: boolean; value: number };
  showInstallation: { enabled: boolean; value: string };
  showClusterOptimization: { enabled: boolean };
}

export type SessionContextType = {
  user: UserData;
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: Error;
  getUserErrorStatusCode?: Error;
  getAccessTokenSilently: Function;
  loginWithRedirect: Function;
  logout: Function;
  reloadUser: Function;
};

export enum UserConsent {
  ACCEPT = 'accept',
  REJECT = 'reject',
}

type InstanceServices = {
  instanceServices: {
    instanceId: string;
    name: string;
    analyticsNodes: number;
    loaderNodes: number;
    sqli: number;
    notebook: number;
  }[];
};

export type UserData = User &
  UserExtensions & {
    uuid: string;
    isProvision: boolean;
    isDummyPassword: boolean;
    last_password_reset: string;
    email: string;
    fullName: string;
    trialExpired: boolean;
    redirectTo: string | '';
    sub: string;
    showConsentFlow: boolean;
    confirmed: boolean;
    email_verified: boolean;
    newUser: boolean | false;
    userConsent?: UserConsent | false;
    assignedCluster: boolean | false;
  } & InstanceServices;

export type UserExtensions = {
  configurations: UserConfigurations | null;
  platformActions: PlatformActions[] | null;
  toggleFeatures: Feature[];
  features: Feature[];
  instancesCount: number;
  role: UserRule | null;
  userDataIsSet?: boolean;
};

export interface PlatformActions {
  name: string;
  platforms: string;
  createdAt: string;
  displayName: string;
  id: string;
  updatedAt: string;
}

export interface SessionData {
  user: User | null;
  configurations: UserConfigurations | null;
  platformActions: PlatformActions[] | null;
}

export interface SignUpResult {
  user: User;
}

export interface ValidEmailResult {
  valid: boolean;
}

export interface SignInUserData {
  email: string;
  password: string;
}

export interface MeResult {
  platformActions: PlatformActions[] | null;
  user: User;
  configurations: UserConfigurations;
  toggleFeatures: any[];
  features: any[];
  instancesCount: number;
  redirectTo: string;
  location: string;
}

export interface SignInResult {
  user: User;
  configurations: UserConfigurations;
  redirectTo: string;
  location: string;
}

export interface ActivationResult {
  user: User;
  token: string;
  redirectTo: string;
}

export interface AssignCluster {
  status: boolean;
  user: User;
  token: string;
  redirectTo: string;
  crypted: string;
  message: string;
}

export interface SupportData {
  email?: string;
  message: string;
}

export interface SupportResult {
  message: string;
}

interface Industrie {
  id: number;
  name: String;
  enabled: boolean;
  createdAt: String;
  updatedAt: String;
}

export interface GetIndustriesResult {
  industries: Industrie[];
}

export interface PersonalInformationEdit {
  firstName?: string;
  lastName?: string;
  jobTitle?: string;
  company?: string;
  phone?: string;
  userConsent?: UserConsent | false;
}

export interface PersonalInformationEditResult {
  message: string;
}

export interface PasswordData {
  currentPassword: string;
  newPassword: string;
}

export interface PaymentSetupResult {
  secret: string;
  customer: string;
}

export interface UserPaymentMethodResponse {
  data: {
    brand: string;
    createdAt: string;
    customerID: string;
    deletedAt?: string | null;
    expiration: string;
    id: string;
    last4: string;
    pmID: string;
    updatedAt: string;
    userID: string;
  } | null;
}

export interface CanDowngradeResponse {
  can: boolean;
}

export interface Stat {
  date: string;
  totalCost: number;
  units: number;
  unitsCost: number;
  usage: number;
  usageCost: number;
  users: number;
  usersCost: number;
}

export interface GetStates {
  stats: Stat[];
}

interface InstanceStat {
  date: string;
  dateFormatted: string;
  id: number;
  totalCost: number;
  unitCostPerDay: number;
  units: number;
  unitsCost: number;
  usage: number;
  usageCost: number;
  userCostPerDay: number;
  users: number;
  usersCost: number;
}

interface StatInstance {
  id: string;
  name: string;
  stats: InstanceStat[];
}

export interface GetSatesForADate {
  instances: StatInstance[];
}
