import React from 'react';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  InstalledPythonPackage,
  pythonInstallationStateEnum,
} from 'types/cluster';

type PythonPackageMenuProps = {
  pythonPackage: InstalledPythonPackage;
  reinstallPythonPackage: (packageName: string) => void;
  deletePackage: (packageName: string) => void;
  handleOpenEdit: ({
    pythonPackage,
  }: {
    pythonPackage: InstalledPythonPackage;
  }) => void;
  isClusterRunning: boolean;
};
function PythonPackageMenu({
  pythonPackage,
  reinstallPythonPackage,
  deletePackage,
  handleOpenEdit,
  isClusterRunning,
}: PythonPackageMenuProps) {
  const hasAnalyticsOrLoaderFailed =
    pythonPackage.analyticsState === pythonInstallationStateEnum.failed ||
    pythonPackage.loaderState === pythonInstallationStateEnum.failed;

  return (
    <Menu>
      {hasAnalyticsOrLoaderFailed && (
        <Menu.Item
          key="reinstall"
          onClick={() => reinstallPythonPackage(pythonPackage.name)}
          disabled={!isClusterRunning}
          title={
            !isClusterRunning ? (
              <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
            ) : (
              ''
            )
          }
        >
          <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.reinstall" />
        </Menu.Item>
      )}
      <Menu.Item
        key="edit"
        onClick={() => handleOpenEdit({ pythonPackage })}
        disabled={!isClusterRunning}
        title={
          !isClusterRunning ? (
            <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
          ) : (
            ''
          )
        }
      >
        <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.editVersion" />
      </Menu.Item>
      <Menu.Item
        key="delete"
        onClick={e => deletePackage(pythonPackage.name)}
        disabled={!isClusterRunning}
        title={
          !isClusterRunning ? (
            <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
          ) : (
            ''
          )
        }
      >
        <FormattedMessage id="clusterConfiguration.pythonPackages.editModal.delete" />
      </Menu.Item>
    </Menu>
  );
}

export default PythonPackageMenu;
