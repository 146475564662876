import React from 'react';
import './ScheduledEventActionStep.less';
import { Select, Form, DatePicker } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { ScheduledEventEnum } from 'types/cluster';
import { scheduledEventFormFieldNames } from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/ScheduledEventModal';
import useClusterSchedulingHelpers from 'components/ClusterDetails/ClusterDetailsBody/ClusterScheduling/ScheduledEventModal/useClusterSchedulingHelpers';

type ScheduledEventActionStepProps = {
  setIsScaling: React.Dispatch<React.SetStateAction<boolean>>;
  isViewOnly: boolean;
};
function ScheduledEventActionStep({
  setIsScaling,
  isViewOnly,
}: ScheduledEventActionStepProps) {
  const { availableSchedulingActions } = useClusterSchedulingHelpers();

  function disabledDate(current: moment.Moment) {
    // Disable dates before the current date
    return current && current < moment().startOf('day');
  }

  function handleActionChange(newEvent: ScheduledEventEnum) {
    if (
      [ScheduledEventEnum.SCALE_UP, ScheduledEventEnum.SCALE_DOWN].includes(
        newEvent,
      )
    ) {
      setIsScaling(true);
      return;
    }
    setIsScaling(false);
  }

  return (
    <>
      <Form.Item
        name={scheduledEventFormFieldNames.event}
        label={
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.event" />
        }
        required
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="scheduled-event-action-step__form-item"
      >
        <Select
          onChange={handleActionChange}
          className="scheduled-event-action-step__select"
          disabled={isViewOnly}
        >
          {Object.entries(availableSchedulingActions)?.map(action => {
            const [actionValue, actionObject] = action;
            const { label: actionLabel, icon } = actionObject;
            return (
              <Select.Option
                key={actionValue}
                value={actionValue}
                className="scheduled-event-action-step__option"
              >
                {icon}
                {actionLabel}
              </Select.Option>
            );
          })}
        </Select>
      </Form.Item>
      <Form.Item
        name={scheduledEventFormFieldNames.startTime}
        label={
          <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.startTime" />
        }
        required
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="scheduled-event-action-step__form-item"
        rules={[
          {
            validator(_rule, value) {
              const now = moment();
              if (now >= value) {
                return Promise.reject(
                  <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.startTimeErrorText" />,
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          className="scheduled-event-action-step__start-time-date-picker"
          showTime
          showNow={false}
          disabledDate={disabledDate}
          disabled={isViewOnly}
        />
      </Form.Item>
    </>
  );
}

export default ScheduledEventActionStep;
