import './ClusterSizeEdit.less';

import React, { useMemo, useState } from 'react';
import { Button, notification, Spin, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useClusterSizeTypes, useMutateCluster } from 'hooks/cluster';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { CSize, Instance, Service } from 'types/cluster';
import {
  getClusterStatus,
  getClusterTypeIcons,
  getClusterSize,
  extraClusterTypeName,
} from 'utils/cluster';
import ClusterTypeFieldSlider from 'components/ClusterCreate/ClusterTypeField/ClusterTypeFieldSlider/ClusterTypeFieldSlider';
import { UserData } from 'types/user';

interface ClusterSizeEditProps {
  instance: Instance;
  services: Service[];
  user: UserData;
}
function ClusterSizeEdit({ instance, services, user }: ClusterSizeEditProps) {
  const intl = useIntl();
  const [size, setSize] = useState(instance.csize);
  const { data } = useClusterSizeTypes();
  const editableSizes = data?.data.sizes;
  const allSizes = data?.data.allSizes;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const allAvailableSizes = useMemo(() => getAllAvailableSizes(), [allSizes]);

  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  function getAllAvailableSizes() {
    if (!data?.data?.allSizes) {
      return [];
    }

    const analyticsSize = instance.csize;
    const loaderSize = instance.csize2;
    const extraClusterTypeNameKeys = Object.keys(extraClusterTypeName);

    const analyticsSizeIsLarge =
      extraClusterTypeNameKeys.includes(analyticsSize);
    const loaderSizeIsLarge = extraClusterTypeNameKeys.includes(loaderSize);

    let allAvailableSizes;
    if (!analyticsSizeIsLarge && !loaderSizeIsLarge) {
      allAvailableSizes = data?.data?.allSizes?.filter(
        size => !extraClusterTypeNameKeys.includes(size.type),
      );
    } else {
      let maxSize: CSize;
      let maxSizeIndexInAllSizes: number;
      if (!analyticsSizeIsLarge || !loaderSizeIsLarge) {
        maxSize = analyticsSizeIsLarge ? analyticsSize : loaderSize;
        maxSizeIndexInAllSizes = data?.data?.allSizes?.findIndex(
          size => size.type === maxSize,
        ) as number;
      } else {
        maxSize =
          analyticsSize.slice(-1) > loaderSize.slice(-1)
            ? analyticsSize
            : loaderSize;
        maxSizeIndexInAllSizes = data?.data?.allSizes?.findIndex(
          size => size.type === maxSize,
        ) as number;
      }
      allAvailableSizes = data?.data?.allSizes?.filter(
        (_, i) => i <= maxSizeIndexInAllSizes,
      );
    }

    return allAvailableSizes || [];
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  async function handleSubmit() {
    const { data } = await mutateCluster({ csize: size });
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }

  const status = getClusterStatus(instance, services[0]);
  const canSave = status === 'running' && instance.csize !== size;
  const { isPlatformActionSupported: isResizeSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clusterResize,
    });

  return (
    <div className="ClusterEdit ClusterSizeEdit">
      <div className="ClusterEdit__icon">
        {React.createElement(getClusterTypeIcons(instance.csize, 'fill'))}
      </div>
      <div className="ClusterEdit__content">
        <h3 className="ClusterEdit__title">Cluster Size</h3>
        <p className="ClusterEdit__subtitle">{`${getClusterSize(instance)}`}</p>
        {canUpdate && (
          <div className="ClusterSizeEdit__body">
            <div className="ClusterSizeEdit__body-side-wrapper">
              {allAvailableSizes ? (
                <ClusterTypeFieldSlider
                  sizes={allAvailableSizes}
                  editableSizes={editableSizes!}
                  currentSize={size}
                  onChange={newSize => {
                    if (newSize !== size) {
                      setSize(newSize!);
                    }
                  }}
                  hideMainIcon
                  isEditCluster
                />
              ) : (
                <Spin>
                  <div style={{ height: 82 }} />
                </Spin>
              )}
            </div>
            <div className="ClusterSizeEdit__submit">
              <Tooltip
                title={
                  status !== 'running' ? (
                    'Your cluster must be connected to apply this change'
                  ) : !isResizeSupported ? (
                    <FormattedMessage
                      id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                      values={{
                        action: intl.formatMessage({
                          id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.clusterResize',
                        }),
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <Button
                  loading={isLoading}
                  disabled={!canSave || !isResizeSupported}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ClusterSizeEdit;
