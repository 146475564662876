import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Tooltip } from 'antd';
import React, { useContext, useState } from 'react';
import CreateNewSubClusterModal from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/CreateNewSubClusterModal/CreateNewSubClusterModal';
import { Instance, SelfManagedClusterTypes, SubCluster } from 'types/cluster';
import { SessionContext } from 'auth/SessionProvider';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import {
  useConnectSubCluster,
  useDeleteSubCluster,
  useDisconnectSubCluster,
} from 'hooks/cluster';
import useSubCluster from 'components/ClusterDetails/ClusterDetailsBody/SubCluster/SubClusterList/useSubCluster';

type SelfManagedClustersListActionsProps = {
  subCluster: SubCluster;
  instance: Instance;
};
function SelfManagedClustersListActions({
  subCluster,
  instance,
}: SelfManagedClustersListActionsProps) {
  const { getSubClusterState } = useSubCluster();

  const isClusterRunning = instance.status === 'running';
  const { user } = useContext(SessionContext);
  const { isPlatformActionSupported: isSubClusterSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.subcluster,
    });

  const [showModal, setShowModal] = useState(false);

  const showEdit = instance.clusterPolicy.subClusterPolicy.includes('update');
  const showDelete = instance.clusterPolicy.subClusterPolicy.includes('delete');

  const { mutateAsync: mutateDeleteSubCluster } = useDeleteSubCluster();
  const {
    mutateAsync: mutateConnectSubCluster,
    isLoading: isConnectSubClusterLoading,
  } = useConnectSubCluster();
  const {
    mutateAsync: mutateDisconnectSubCluster,
    isLoading: isDisconnectSubClusterLoading,
  } = useDisconnectSubCluster();

  function handleDelete() {
    Modal.confirm({
      title: `Delete ${subCluster.name} Self-Managed Cluster`,
      content: 'Are you sure delete this Self-Managed Cluster?',
      okText: 'Delete',
      cancelText: 'Cancel',
      centered: true,
      width: 620,
      async onOk() {
        await mutateDeleteSubCluster({
          userId: user.uuid,
          clusterName: instance.name,
          subClusterName: subCluster.name,
        });
        notification.success({
          message: 'Self-Managed Cluster Deleted',
          description: `Successfully deleted ${subCluster.name} Self-Managed Cluster.`,
        });
      },
    });
  }

  const state = getSubClusterState(subCluster);

  const loading =
    isDisconnectSubClusterLoading ||
    isConnectSubClusterLoading ||
    state === 'loading';

  const isSubClusterConnected = state === 'running';
  const isSubClusterDisconnected = state === 'disconnected';

  function handleConnectionClick() {
    if (isSubClusterConnected) {
      mutateDisconnectSubCluster({
        userId: user.uuid,
        clusterName: instance.name,
        subClusterName: subCluster.name,
      });
    } else if (isSubClusterDisconnected) {
      mutateConnectSubCluster({
        userId: user.uuid,
        clusterName: instance.name,
        subClusterName: subCluster.name,
      });
    }
  }

  const isConnectionButtonDisabled =
    !isClusterRunning || !isSubClusterSupported;

  const additionalTooltip = !isClusterRunning
    ? ' : Cluster Must be running'
    : !isSubClusterSupported
    ? ' : You can’t make actions on Self-Managed Cluster'
    : '';

  const connectionButtonLabel = isSubClusterConnected
    ? 'Disconnect'
    : isSubClusterDisconnected
    ? 'Connect'
    : '';

  const showConnectionButton =
    subCluster.type === SelfManagedClusterTypes.analytics;

  return (
    <div className="SubClusterActions">
      {showEdit && (
        <Tooltip title={'Edit' + additionalTooltip}>
          <Button
            type="text"
            size="small"
            icon={<EditOutlined />}
            disabled={!isSubClusterSupported || !isSubClusterConnected}
            onClick={() => setShowModal(true)}
          />
        </Tooltip>
      )}

      {showDelete && (
        <Tooltip title={'Remove Self-Managed Cluster' + additionalTooltip}>
          <Button
            type="text"
            size="small"
            icon={<DeleteOutlined />}
            onClick={handleDelete}
            disabled={!isClusterRunning || !isSubClusterSupported}
          />
        </Tooltip>
      )}

      {showConnectionButton ? (
        <Tooltip
          title={
            isSubClusterConnected || isSubClusterDisconnected
              ? connectionButtonLabel + additionalTooltip
              : ''
          }
        >
          <Button
            size="small"
            style={{ width: 100 }}
            loading={loading}
            onClick={handleConnectionClick}
            disabled={isConnectionButtonDisabled}
          >
            {connectionButtonLabel}
          </Button>
        </Tooltip>
      ) : null}

      <Modal
        title="Edit Self-Managed Cluster"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={645}
        centered
      >
        <CreateNewSubClusterModal
          onCancel={() => setShowModal(false)}
          instance={instance}
          subCluster={subCluster}
        />
      </Modal>
    </div>
  );
}

export default SelfManagedClustersListActions;
