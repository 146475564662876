import './UserSecurity.less';

import React, { useState, useCallback, useContext } from 'react';
import _ from 'lodash';
import {
  Col,
  Row,
  Card,
  Input,
  Button,
  Tooltip,
  Form,
  notification,
  Popover,
} from 'antd';
import { CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';
import { useIntl } from 'react-intl';
import { SessionContext } from 'auth/SessionProvider';
import { useUpdateProvisionUserPassword } from 'hooks/user';
import {
  isValidPassword,
  getErrorsOfInput,
  hideErrorBelowField,
} from 'utils/fieldsValidation';
import PasswordCriteriaPopover from 'components/ProvisionUserModal/PasswordCriteriaPopover';

function UserSecurity() {
  const [form] = Form.useForm();
  const intl = useIntl();
  const { innerWidth } = window;

  //eslint-disable-next-line
  const [passwordHasError, setPasswordHasError] = useState<any>(undefined);
  const [newPasswordHasError, setNewPasswordHasError] =
    useState<any>(undefined);
  const [conPasswordValue, setConfPassword] = useState('');
  const [password, setPassword] = useState<string>('');
  const [confPasswordHasError, setConPasswordHasError] =
    useState<any>(undefined);

  const { user } = useContext(SessionContext);
  const fieldNames = {
    newPasswordFieldName: 'newPassword',
    confirmPasswordFieldName: 'confirmPassword',
  } as const;
  const {
    mutateAsync: mutateUpdateProvisionUserPassword,
    isLoading,
    isError,
    error,
  } = useUpdateProvisionUserPassword();

  const onChangePassword = async () => {
    if (!user.sub.includes('auth0'))
      notification.error({
        message: 'You cannot change password with this connection.',
      });
    else {
      const { data, status } = await mutateUpdateProvisionUserPassword({
        userID: user.uuid,
        isSkipped: false,
        password: form.getFieldValue(fieldNames.newPasswordFieldName),
      });
      if (status === 200) {
        notification.success({
          message: intl.formatMessage({
            id: 'provisionUserModal.changeProvisionUserPassword.successMessage',
          }),
          description: data.message,
        });
      } else {
        if (isError) {
          notification.error({
            message: error,
          });
        }
      }
    }
  };

  const checkPasswordHasError = (
    value: string,
    isPassword: boolean,
    passwordValue: string,
  ) => {
    if (isPassword) {
      setPasswordHasError(!isValidPassword(value));
    } else {
      checkConfirmPassHasError(value, passwordValue);
    }
  };

  const handlePasswordChange = (value: string, isPassword: boolean) => {
    if (!isPassword) {
      setConfPassword(value);
    }
  };

  function checkConfirmPassHasError(value: string, passwordValue: string) {
    let hasError = false;
    if (value !== passwordValue) {
      hasError = true;
    }
    setConPasswordHasError(hasError);
    return hasError;
  }

  const confirmPasswordValidation = useCallback(
    _.debounce((value: any, isPassword, passwordValue) => {
      checkPasswordHasError(value, isPassword, passwordValue);
      getErrorsOfInput(
        'confirmPassword-input',
        () => passwordValue !== value,
        "The passwords don't match",
      );
    }, 1500),
    [],
  );

  const newPasswordValidation = useCallback(
    _.debounce((value: any) => {
      setNewPasswordHasError(!isValidPassword(value));
      getErrorsOfInput(
        'newPassword-input',
        () => (value ? !isValidPassword(value) : {}),
        ' Password does not meet criteria.',
      );
    }, 1500),
    [],
  );

  return (
    <Form form={form} layout="vertical" className="user-security-form">
      <Row justify="center" gutter={[32, 32]}>
        <Col xl={8} lg={12} span={24}>
          <Card title="Change Password" className="user-security-form__card">
            <div className="input-wrapper">
              <Popover
                placement={innerWidth > 575 ? 'right' : 'top'}
                content={<PasswordCriteriaPopover password={password} />}
                trigger="focus"
              >
                <Form.Item
                  label="New Password"
                  name={fieldNames.newPasswordFieldName}
                  className="create-password__form__password"
                >
                  <div>
                    <Tooltip
                      placement="left"
                      title={`Your Incorta account password is for both your Incorta Cluster and Incorta Cloud Portal. The password must have:
                    - At least 12 characters
                    - At Most 32 characters
                    - A lowercase letter
                    - An uppercase letter
                    - No Spaces
                    - A number
                    - A special character
                    `}
                    >
                      <div className="input-password">
                        <Input.Password
                          id="newPassword-input"
                          value={password}
                          onChange={e => setPassword(e.target.value)}
                          onKeyUp={(e: any) => {
                            if (e.code !== 'Enter') {
                              hideErrorBelowField('newPassword-input');
                              setNewPasswordHasError(undefined);
                              newPasswordValidation(e.target.value);
                              if (conPasswordValue !== '') {
                                hideErrorBelowField('confirmPassword-input');
                                confirmPasswordValidation(
                                  conPasswordValue,
                                  false,
                                  password,
                                );
                              }
                            }
                          }}
                        />
                        <CloseCircleFilled />
                        <CheckCircleFilled />
                      </div>
                    </Tooltip>
                  </div>
                </Form.Item>
              </Popover>
            </div>
            <div className="input-wrapper">
              <Form.Item
                label="Confirm Password"
                name={fieldNames.confirmPasswordFieldName}
                className="create-password__form__confirm-password"
                dependencies={[fieldNames.newPasswordFieldName]}
              >
                <div>
                  <Tooltip
                    placement="left"
                    title={'Must match the new password entered previously.'}
                  >
                    <div className="input-password">
                      <Input.Password
                        id="confirmPassword-input"
                        onChange={e =>
                          handlePasswordChange(e.target.value, false)
                        }
                        onKeyUp={(e: any) => {
                          if (e.code !== 'Enter') {
                            hideErrorBelowField('confirmPassword-input');
                            setConPasswordHasError(undefined);
                            confirmPasswordValidation(
                              e.target.value,
                              false,
                              password,
                            );
                          }
                        }}
                      />
                      <CloseCircleFilled />
                      <CheckCircleFilled />
                    </div>
                  </Tooltip>
                </div>
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>

      <Row justify="center" gutter={32}>
        <Col xl={8} lg={12} span={24} className="user-security-form__actions">
          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading}
            onClick={onChangePassword}
            disabled={
              confPasswordHasError ||
              newPasswordHasError ||
              newPasswordHasError === undefined ||
              confPasswordHasError === undefined
            }
          >
            Update Password
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
export default UserSecurity;
