import React, { useEffect, useState } from 'react';
import Icon, { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Input,
  Modal,
  Space,
  Typography,
  Tooltip,
} from 'antd';
import RolesAndPermissionsModal from '../RolesAndPermissionsModal/RolesAndPermissionsModal';
import AccessSecurityList from '../AccessSecurityList/AccessSecurityList';
import InviteAuthUserModal from '../InviteAuthUserModal/InviteAuthUserModal';
import { ReactComponent as AccessAndSecurityIcon } from 'images/access-and-security.svg';
import { Instance } from 'types/cluster';
import { useAuthUsers } from 'hooks/cluster';
import { search } from 'utils';

interface AuthUserSectionProps {
  instance: Instance;
}
function AuthUserSection({ instance }: AuthUserSectionProps) {
  let [showModal, setShowModal] = useState(false);
  let [authUsersList, setAuthUsersList] = useState<any>([]);
  let [showRolesAndPermissions, setShowRolesAndPermissions] = useState(false);
  let [searchKeyword, setSearchKeyword] = useState('');

  let { isLoading, data } = useAuthUsers({
    clusterName: instance.name,
  });

  let authUsers = data?.data?.authorizedUserRoles;

  let canInviteUser = instance.clusterPolicy.userPolicy.includes('create');

  useEffect(() => {
    addAuthUsers(authUsers);
  }, [authUsers]);

  useEffect(() => {
    let authUsersFiltered = searchKeyword.trim()
      ? authUsers?.filter(user => {
          return (
            search(user.user.fullname, searchKeyword) ||
            search(user.user.email, searchKeyword)
          );
        })
      : authUsers;
    addAuthUsers(authUsersFiltered);
    //eslint-disable-next-line
  }, [searchKeyword]);

  const addAuthUsers = (users: any) => {
    setAuthUsersList(users);
  };

  return (
    <div className="AccessSecurity">
      <div className="AccessSecurity__icon-wrapper">
        <Icon component={AccessAndSecurityIcon} />
      </div>

      <div className="AccessSecurity__content-wrapper">
        <div className="AccessSecurity__content-title-wrapper">
          <div className="AccessSecurity__content-title">
            <h2>Cluster Administration Users</h2>

            <p>
              Restrict the cluster access based on the roles and permissions of
              individual users within your enterprise{' '}
              <button
                className="link"
                onClick={() => setShowRolesAndPermissions(true)}
              >
                View Roles & Permissions
              </button>
            </p>

            <Input
              size="small"
              placeholder="Search Users"
              prefix={<SearchOutlined />}
              value={searchKeyword}
              onChange={e => setSearchKeyword(e.target.value)}
              allowClear
            />
          </div>

          <div className="AccessSecurity__content-action">
            {authUsers && (
              <Space>
                {canInviteUser && (
                  <>
                    <Tooltip title="In order to grant a user access to a cluster, add a new user from within the cluster.">
                      <Button
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        Invite User
                      </Button>
                    </Tooltip>
                    <Divider type="vertical" />
                  </>
                )}
                <Typography.Text className="AccessSecurity__items-count">
                  {authUsers.length} Users
                </Typography.Text>
              </Space>
            )}
          </div>
        </div>

        <AccessSecurityList
          instance={instance}
          authUsers={authUsersList}
          isLoading={isLoading}
        />
      </div>

      <Modal
        title="Cluster Access & Security"
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={null}
        width={645}
        destroyOnClose
        centered
      >
        <InviteAuthUserModal
          onChancel={() => setShowModal(false)}
          instance={instance}
          onDone={addAuthUsers}
        />
      </Modal>

      <Modal
        title={'Roles & Permissions'}
        visible={showRolesAndPermissions}
        onCancel={() => setShowRolesAndPermissions(false)}
        footer={null}
        width={1170}
        centered
      >
        <RolesAndPermissionsModal />
      </Modal>
    </div>
  );
}

export default AuthUserSection;
