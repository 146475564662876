import React, {
  PropsWithChildren,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { SessionContext } from 'auth/SessionProvider';
import { security } from 'utils/security';

interface AppStateContextProps {
  showUpgradeToPremiumBanner: boolean;
  setShowUpgradeToPremiumBanner: React.Dispatch<React.SetStateAction<boolean>>;
  setChatIsShown: Function;
}

const AppStateContext = React.createContext({} as AppStateContextProps);

type AppStateProviderProps = PropsWithChildren<{
  setChatIsShown: Function;
}>;
export function AppStateProvider({
  children,
  setChatIsShown,
}: AppStateProviderProps) {
  let { pathname } = useLocation();

  const { user, getAccessTokenSilently } = useContext(SessionContext);
  security.setAccessTokenSilently(getAccessTokenSilently);

  let [showUpgradeToPremiumBanner, setShowUpgradeToPremiumBanner] =
    useState(false);

  useLayoutEffect(() => {
    if (user?.role) {
      const isFreeUser = user?.role?.id === 1;
      setShowUpgradeToPremiumBanner(isFreeUser);
    }
  }, [user]);

  useLayoutEffect(() => {
    if ('/plans' === pathname) {
      setShowUpgradeToPremiumBanner(false);
    }
  }, [pathname]);

  let value = useMemo(
    () => ({
      showUpgradeToPremiumBanner,
      setShowUpgradeToPremiumBanner,
      setChatIsShown,
    }),
    [showUpgradeToPremiumBanner, setChatIsShown],
  );

  return (
    <AppStateContext.Provider value={value}>
      {children}
    </AppStateContext.Provider>
  );
}

export function useAppState() {
  return useContext(AppStateContext);
}
