import { SubCluster, SubClusterStates } from 'types/cluster';

function useSubCluster() {
  function getSubClusterState(subCluster: SubCluster): SubClusterStates {
    if (subCluster.state === 'disconnected') {
      return 'disconnected';
    }
    return subCluster.status as any;
  }

  return { getSubClusterState };
}

export default useSubCluster;
