import React, { ReactElement, useContext } from 'react';
import './ErrorPage.less';
import { FormattedMessage } from 'react-intl';
import { Button } from 'antd';
import { SessionContext } from 'auth/SessionProvider';
import { ReactComponent as ErrorPageLogo } from 'images/error-page-logo.svg';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';

type Props = {
  headerContent: ReactElement;
  subheaderContent: ReactElement;
};
function ErrorPage(props: Props) {
  const { logout } = useContext(SessionContext);

  function handleReturnToLoginPage() {
    logout({ returnTo: window.location.origin });
  }

  return (
    <div className="error-page__wrapper">
      <section className="error-page__info">
        <IncortaLogo className="error-page__incorta-logo" />
        <h2 className="error-page__header">{props.headerContent}</h2>
        <label className="error-page__sub-header">
          {props.subheaderContent}
        </label>
        <Button
          className="error-page__home-page-button"
          size="large"
          onClick={handleReturnToLoginPage}
          type="primary"
        >
          <FormattedMessage id="auth0.errorPages.general.returnToLoginPageButton" />
        </Button>
      </section>
      <section className="error-page__logo-wrapper">
        <ErrorPageLogo className="error-page__logo" />
      </section>
    </div>
  );
}

export default ErrorPage;
