import React from 'react';
import { FormattedMessage } from 'react-intl';
import HomePage from 'components/ClusterDetails/ClusterDetailsBody/Configurations/HomePage/HomePage';
import OnBoard from 'components/ClusterDetails/ClusterDetailsBody/Configurations/OnBoard/OnBoard';
import ClusterServiceNodeEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterServiceNodeEdit/ClusterServiceNodeEdit';
import ClusterSizeEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterSizeEdit/ClusterSizeEdit';
import ClusterAutoSuspendEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterAutoSuspendEdit/ClusterAutoSuspendEdit';
import ClusterConnectBiToolsEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterConnectBiToolsEdit/ClusterConnectBiToolsEdit';
import AzureSynapse from 'components/ClusterDetails/ClusterDetailsBody/Configurations/AzureSynapse/AzureSynapse';
import ClusterDataAgentEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterDataAgentEdit/ClusterDataAgentEdit';
import PythonPackagesEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/PythonPackagesEdit/PythonPackagesEdit';
import Copilot from 'components/ClusterDetails/ClusterDetailsBody/Configurations/Copilot/Copilot';
import Mlflow from 'components/ClusterDetails/ClusterDetailsBody/Configurations/Mlflow/Mlflow';
import { getIU } from 'utils/cluster';
import {
  Instance,
  ServerConfiguration,
  Service,
  ServiceToRestart,
  clusterConfigurationId,
} from 'types/cluster';
import { UserData } from 'types/user';
import ClusterServicesControl from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterServicesControl/ClusterServicesControl';
import ClusterEnableIncortaXEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/ClusterEnableIncortaXEdit/ClusterEnableIncortaXEdit';
import DeltaSharingEdit from 'components/ClusterDetails/ClusterDetailsBody/Configurations/DeltaSharingEdit/DeltaSharingEdit';

export type clusterConfigurationsType = {
  conditionToShow: boolean;
  id: string;
  title: JSX.Element | string;
  component: (props: any) => JSX.Element; // props depends on the component's nned
};

type useClusterConfigurationProps = {
  showClusterSize: boolean;
  instance: Instance;
  services: Service[];
  user: UserData;
  isTrialUser: boolean;
  serverConfiguration: ServerConfiguration[];
  incortaXServerConfiguration: ServerConfiguration[];
  mlflowServerConfiguration: ServerConfiguration[];
  showDeltaSharing: boolean;
  showMicrosoftSynapse: boolean;
  showIncortaX: boolean;
  isCopilotEnabled: boolean;
  isMlflowEnabled: boolean;
};
function useClusterConfiguration({
  showClusterSize,
  instance,
  services,
  user,
  isTrialUser,
  serverConfiguration,
  incortaXServerConfiguration,
  mlflowServerConfiguration,
  showDeltaSharing,
  showMicrosoftSynapse,
  showIncortaX,
  isCopilotEnabled,
  isMlflowEnabled,
}: useClusterConfigurationProps) {
  const clusterConfigurations: clusterConfigurationsType[] = [
    {
      conditionToShow: showClusterSize,
      id: clusterConfigurationId.CLUSTER_SIZE,
      title: <FormattedMessage id="clusterConfiguration.titles.clusterSize" />,
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterSizeEdit instance={instance} services={services} user={user} />
      ),
    },
    {
      conditionToShow: !!user,
      id: clusterConfigurationId.CLUSTER_SERVICES_CONTROL,
      title: (
        <FormattedMessage id="clusterConfiguration.titles.clusterServicesControl" />
      ),
      component: ({
        instance,
        user,
      }: {
        instance: Instance;
        user: UserData;
      }) => <ClusterServicesControl instance={instance} user={user} />,
    },
    {
      conditionToShow: false, // !!user,
      id: clusterConfigurationId.ANALYTICS_NODE,
      title: (
        <FormattedMessage id="clusterConfiguration.titles.analyticsNode" />
      ),
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterServiceNodeEdit
          instance={instance}
          services={services}
          isTrial={isTrialUser}
          user={user}
          serviceNode={ServiceToRestart.ANALYTICS}
          serviceNodesCount={instance.analyticsNodes}
          hint="Creating multiple analytics improves the query throughput for high
                  concurrency workloads."
          subTitle={
            <>
              {instance.analyticsNodes} Analytics Service -{' '}
              {getIU(instance, instance.analyticsNodes, 'analytics')}
            </>
          }
        />
      ),
    },
    {
      conditionToShow: false, // !!user,
      id: clusterConfigurationId.LOADER_NODE,
      title: <FormattedMessage id="clusterConfiguration.titles.loaderNode" />,
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterServiceNodeEdit
          instance={instance}
          services={services}
          isTrial={isTrialUser}
          user={user}
          serviceNode={ServiceToRestart.LOADER}
          serviceNodesCount={instance.loaderNodes}
          hint="Creating multiple loaders improve running and loading multiple
                  parallel workloads."
          subTitle={
            <>
              {instance.loaderNodes}{' '}
              {instance.loaderNodes === 1 ? 'Loader' : 'Loaders'} Service -{' '}
              {getIU(instance, instance.loaderNodes, 'loader')}
            </>
          }
        />
      ),
    },
    {
      conditionToShow: !!user,
      id: clusterConfigurationId.AUTO_SUSPEND,
      title: <FormattedMessage id="clusterConfiguration.titles.autoSuspend" />,
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterAutoSuspendEdit
          instance={instance}
          services={services}
          isTrial={false}
          user={user}
        />
      ),
    },
    {
      conditionToShow: !!user,
      id: clusterConfigurationId.HOME_PAGE,
      title: <FormattedMessage id="clusterConfiguration.titles.homePage" />,
      component: ({
        instance,
        services,
      }: {
        instance: Instance;
        services: Service[];
      }) => (
        <HomePage
          instance={instance}
          services={services}
          isTrial={isTrialUser}
        />
      ),
    },
    {
      conditionToShow: !!user,
      id: clusterConfigurationId.ON_BOARD,
      title: <FormattedMessage id="clusterConfiguration.titles.onBoard" />,
      component: ({
        instance,
        services,
      }: {
        instance: Instance;
        services: Service[];
      }) => (
        <OnBoard
          instance={instance}
          services={services}
          isTrial={isTrialUser}
        />
      ),
    },

    {
      conditionToShow: isMlflowEnabled,
      id: clusterConfigurationId.MLFLOW,
      title: <FormattedMessage id="clusterConfiguration.titles.mlFlow" />,
      component: ({
        instance,
        services,
      }: {
        instance: Instance;
        services: Service[];
      }) => (
        <Mlflow
          instance={instance}
          services={services}
          serverConfigurations={mlflowServerConfiguration}
        />
      ),
    },
    {
      conditionToShow: showDeltaSharing,
      id: clusterConfigurationId.DELTA_SHARING,
      title: <FormattedMessage id="clusterConfiguration.titles.deltaSharing" />,
      component: ({
        instance,
        services,
        title,
      }: {
        instance: Instance;
        services: Service[];
        title: string;
      }) => (
        <DeltaSharingEdit
          instance={instance}
          services={services}
          title={title}
        />
      ),
    },
    {
      conditionToShow: isCopilotEnabled,
      id: clusterConfigurationId.COPILOT,
      title: <FormattedMessage id="clusterConfiguration.titles.copilot" />,
      component: ({
        instance,
        services,
        title,
      }: {
        instance: Instance;
        services: Service[];
        title: string;
      }) => <Copilot instance={instance} services={services} title={title} />,
    },
    {
      conditionToShow: true,
      id: clusterConfigurationId.EXTERNAL_BI_TOOLS,
      title: (
        <FormattedMessage id="clusterConfiguration.titles.externalBITools" />
      ),
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterConnectBiToolsEdit
          instance={instance}
          serverConfigurations={serverConfiguration}
          services={services}
          user={user}
        />
      ),
    },
    {
      conditionToShow: showIncortaX,
      id: clusterConfigurationId.INCORTAX,
      title: <FormattedMessage id="clusterConfiguration.titles.incortaX" />,
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <ClusterEnableIncortaXEdit
          instance={instance}
          incortaXServerConfiguration={incortaXServerConfiguration}
          services={services}
          user={user}
        />
      ),
    },
    {
      conditionToShow: false, // Will be shipped by default in Incorta backend
      id: clusterConfigurationId.MICROSOFT_SYNAPSE,
      title: (
        <FormattedMessage id="clusterConfiguration.titles.microsoftSynapse" />
      ),
      component: ({
        instance,
        services,
      }: {
        instance: Instance;
        services: Service[];
      }) => <AzureSynapse instance={instance} services={services} />,
    },
    {
      conditionToShow: true,
      id: clusterConfigurationId.DATA_AGENT,
      title: <FormattedMessage id="clusterConfiguration.titles.dataAgent" />,
      component: ({
        instance,
        services,
      }: {
        instance: Instance;
        services: Service[];
      }) => (
        <ClusterDataAgentEdit
          instance={instance}
          services={services}
          serverConfigurations={serverConfiguration}
        />
      ),
    },
    {
      conditionToShow: true,
      id: clusterConfigurationId.PYTHON_PACKAGES,
      title: (
        <FormattedMessage id="clusterConfiguration.titles.pythonPackages" />
      ),
      component: ({
        instance,
        services,
        user,
      }: {
        instance: Instance;
        services: Service[];
        user: UserData;
      }) => (
        <PythonPackagesEdit
          instance={instance}
          services={services}
          user={user}
        />
      ),
    },
  ];

  return { clusterConfigurations };
}

export default useClusterConfiguration;
