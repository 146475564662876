import './ClusterDataAgentEdit.less';

import React, { useContext, useState } from 'react';
import Icon, { DownloadOutlined } from '@ant-design/icons';
import { Modal, notification, Switch, Tooltip } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReactComponent as ShieldIcon } from 'images/shield.svg';
import { Instance, ServerConfiguration, Service } from 'types/cluster';
import { useMutateCluster } from 'hooks/cluster';
import { getClusterStatus } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';
import { API_URL } from 'utils/http';
import useToggleFeature, { TOGGLE_FEATURE_KEYS } from 'hooks/useToggleFeature';

interface ClusterDataAgentEditProps {
  instance: Instance;
  services: Service[];
  serverConfigurations: ServerConfiguration[];
}

function ClusterDataAgentEdit({
  instance,
  services,
}: ClusterDataAgentEditProps) {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);
  const { user } = useContext(SessionContext);
  const downloadLink = useDownloadLink({ instance });

  const { mutateAsync: mutateCluster, isLoading } = useMutateCluster({
    clusterName: instance.name,
  });

  async function enableDataAgent() {
    const { data } = await mutateCluster({ enableDataAgent: true });
    setShowModal(true);
    notification.success({
      message: 'Success',
      description: data.message,
    });
  }
  async function handleChange(enable: boolean) {
    if (enable) {
      Modal.confirm({
        title: 'Enable Data Agent',
        content:
          'Are you sure you want to proceed with enabling the Data Agent?',
        okText: 'Proceed',
        centered: true,
        width: 620,
        async onOk() {
          enableDataAgent();
        },
      });
    } else {
      Modal.confirm({
        title: 'Are you sure you want to disable Data Agent?',
        content:
          'Disabling the data agent will block incorta from connecting to any data source using the data agent.',
        okText: 'Disable',
        centered: true,
        width: 620,
        async onOk() {
          const { data } = await mutateCluster({ enableDataAgent: false });
          notification.success({
            message: 'Success',
            description: data.message,
          });
        },
      });
    }
  }

  const canUpdate = instance.clusterPolicy.clusterPolicy.includes('update');

  const status = getClusterStatus(instance, services[0]);

  const enable = status === 'running';
  const { isToggleFeatureEnabled: isDataAgentToggleFeatureEnabled } =
    useToggleFeature({
      user,
      featureKey: TOGGLE_FEATURE_KEYS.dataagent,
    });

  const showDataAgent = user?.platformActions?.find(
    ({ name, platforms }) =>
      name === 'da' && platforms.includes(instance.platform),
  );

  return (
    <div className="ClusterEdit ClusterDataAgentEdit">
      <div className="ClusterEdit__icon">
        <Icon component={ShieldIcon} />
      </div>
      <div className="ClusterEdit__content ClusterDataAgentEdit__content">
        <div className="ClusterDataAgentEdit__main">
          <h3 className="ClusterEdit__title">Enable Data Agent</h3>
          <div className="ClusterDataAgentEdit__body">
            {instance.enableDataAgent ? (
              <div className="ClusterDataAgentEdit__body-enabled">
                <p className="ClusterDataAgentEdit__hint">
                  Data agent allow Incorta to connect to on-premise data
                  sources, which may be protected behind a firewall that blocks
                  Incorta from connecting to the data source directly.
                </p>
                <p className="ClusterDataAgentEdit__hint">
                  Follow the 4 steps{' '}
                  <button className="link" onClick={() => setShowModal(true)}>
                    here
                  </button>{' '}
                  to create, authorize, connect, and map data agent.
                  {/* For more
                  information, visit the documentation on Data Agent. */}
                </p>

                <a download href={downloadLink} className="download-link link">
                  <DownloadOutlined /> Download Data Agent
                </a>
              </div>
            ) : (
              <div>
                <p className="ClusterDataAgentEdit__hint">
                  Enabling data agent allows Incorta to connect to on-premise
                  data sources, which may be protected behind a firewall that
                  blocks Incorta from connecting to the data source directly.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="ClusterDataAgentEdit__switch-button">
          {canUpdate && (
            <Tooltip
              title={
                !isDataAgentToggleFeatureEnabled ? (
                  'Data Agent has been temporarily disabled.'
                ) : !enable ? (
                  'Your cluster must be connected to apply this change'
                ) : !showDataAgent ? (
                  <FormattedMessage
                    id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                    values={{
                      action: intl.formatMessage({
                        id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.dataAgent',
                      }),
                    }}
                  />
                ) : (
                  ''
                )
              }
            >
              <Switch
                disabled={
                  !enable || !isDataAgentToggleFeatureEnabled || !showDataAgent
                }
                onChange={handleChange}
                loading={isLoading}
                checked={instance.enableDataAgent}
              />
            </Tooltip>
          )}
        </div>
      </div>

      <Modal
        visible={showModal}
        title={'Enable Data Agent'}
        onCancel={() => setShowModal(false)}
        width={800}
        centered
        footer={null}
      >
        <DataAgentContent instance={instance} />
      </Modal>
    </div>
  );
}

interface DataAgentContentProps {
  instance: Instance;
}

function DataAgentContent({ instance }: DataAgentContentProps) {
  let downloadLink = useDownloadLink({ instance });

  return (
    <div className="DataAgentContent">
      <p>
        Follow the step below to set up a data agent. For more information,
        visit the{' '}
        <a
          href="https://docs.incorta.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          documentation
        </a>{' '}
        on Data Agent.
      </p>

      <ol>
        <li>
          <h2>Download Data Agent</h2>
          <p>
            Download Data Agent runnable JAR file. The data agent is a zip file
            that must be unzipped to the local directory where your on-premise
            data sources are located. * The local network must not block
            outgoing connections.
          </p>
          <a download href={downloadLink} className="download-link link">
            <DownloadOutlined /> Download Data Agent
          </a>{' '}
        </li>
        <li>
          <h2>Define & Authorize Data Agent</h2>
          <p>
            {`Create a new Data Agent from Incorta application by going to Data > Data Agents and select New > Data Agent. After creating a data agent you will be prompted to generate an authentication file. Once .auth file generated and downloaded to your machine, place the file in the conf directory inside the data agent.`}
          </p>
        </li>
        <li>
          <h2>Start Data Agent Service</h2>
          <p>
            {`Start the data agent by running “./agent.sh start” on Unix/Linux, or “agent.bat start” on Windows from inside the data agent directory. Once the data agent is started, it should connect automatically to Incorta. You can check the data agent status in Incorta on Data Agents tab under data. Data > Data Agent.`}
          </p>
        </li>
        <li>
          <h2>Connect & Map Data Sources</h2>
          <p>
            {`Once a data agent is created, authorized, and connected, you can use it to create data sources. The connection between Incorta and the data agent is encrypted via SSL, and both sides are authenticated via SSL certificates.`}
          </p>
        </li>
      </ol>
    </div>
  );
}

interface UseDownloadLinkArg {
  instance: Instance;
}
function useDownloadLink({ instance }: UseDownloadLinkArg) {
  let instanceid = instance.id;
  let link = `${API_URL}/users/instances/downloadagent?instanceid=${instanceid}`;

  return link;
}

export default ClusterDataAgentEdit;
