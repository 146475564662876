import './SupportForm.less';

import React, { useContext, useState } from 'react';
import { Row, Col, Input, Button, Form } from 'antd';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { SupportData } from 'types/user';
import { SessionContext } from 'auth/SessionProvider';
import { LocationContext } from 'contexts/LastLocation';

export const supportFormFieldNames = {
  email: 'email',
  message: 'message',
} as const;
interface SupportFormProps {
  onSubmit: (values: SupportData) => void;
  loading: boolean;
}
function SupportForm({ onSubmit, loading }: SupportFormProps) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();
  const [form] = Form.useForm();

  let history = useHistory();
  const { lastLocation } = useContext(LocationContext);

  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);

  type SupportFormValues = {
    message: string;
  };
  async function handleSubmit(values: SupportFormValues) {
    try {
      await form.validateFields();
      onSubmit(values);
    } catch {}
  }

  const onValuesChange = async (changedValues: any, allValues: any) => {
    const { message } = form.getFieldsValue();
    if (!message) {
      setIsSubmitButtonDisabled(true);
    } else {
      setIsSubmitButtonDisabled(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      className="support-form"
      onFinish={handleSubmit}
      onValuesChange={onValuesChange}
    >
      <Row justify="center">
        <Col xs={24} sm={12}>
          {!user && (
            <Form.Item
              name={supportFormFieldNames.email}
              label={<FormattedMessage id={'supportForm.emailFieldLabel'} />}
              rules={[
                {
                  type: 'email',
                  message: (
                    <FormattedMessage
                      id={'supportForm.emailFieldNotValidError'}
                    />
                  ),
                },
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id={'supportForm.emailFieldRequiredError'}
                    />
                  ),
                },
              ]}
            >
              <Input
                placeholder={intl.formatMessage({
                  id: 'supportForm.emailFieldPlaceholder',
                })}
              />
            </Form.Item>
          )}

          <Form.Item
            name={supportFormFieldNames.message}
            label={<FormattedMessage id={'supportForm.messageFieldLabel'} />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id={'supportForm.messageFieldError'} />
                ),
              },
            ]}
          >
            <Input.TextArea
              placeholder={intl.formatMessage({
                id: 'supportForm.messageFieldPlaceholder',
              })}
              autoSize={{ minRows: 6, maxRows: 20 }}
            />
          </Form.Item>

          <Form.Item className="support-form__button-item">
            <Button
              block
              type="primary"
              htmlType="submit"
              disabled={
                isSubmitButtonDisabled ||
                form.getFieldsError().some(err => err.errors.length)
              }
              loading={loading}
            >
              <FormattedMessage id={'supportForm.sendButton'} />
            </Button>
          </Form.Item>

          <Form.Item className="support-form__button-item-cancel">
            <Button
              block
              onClick={() => {
                if (lastLocation) {
                  history.push(lastLocation);
                } else {
                  history.push('/clusters');
                }
              }}
            >
              <FormattedMessage id={'supportForm.cancelButton'} />
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default SupportForm;
