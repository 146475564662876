import React from 'react';
import Blueprint from 'components/ClusterDetails/Blueprint/Blueprint';
import { Instance, Service } from 'types/cluster';
import { getClusterStatus } from 'utils/cluster';

interface ClusterBlueprintsProps {
  instance: Instance;
  services: Service[];
}
function ClusterBlueprints({ instance, services }: ClusterBlueprintsProps) {
  return (
    <div>
      {instance && services && (
        <Blueprint
          instance={instance}
          service={services[0]}
          state={getClusterStatus(instance, services[0])}
        />
      )}
    </div>
  );
}

export default ClusterBlueprints;
