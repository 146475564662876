import { Tooltip } from 'antd';
import React from 'react';
import { useIntl } from 'react-intl';
import { azureCreationPipelineStatusMap } from '../azureClusterCreationHelper';

type AzureCreationClusterCurrentStepProps = {
  currentStep: string;
  stepOrder: number;
  stepsCount: number;
};
function AzureCreationClusterCurrentStep({
  currentStep,
  stepOrder,
  stepsCount,
}: AzureCreationClusterCurrentStepProps) {
  const intl = useIntl();

  const currentStepName = azureCreationPipelineStatusMap.has(currentStep)
    ? azureCreationPipelineStatusMap.get(currentStep)
    : currentStep;

  return (
    <div className="azure-creation-cluster-step current-step">
      <p className="azure-creation-cluster-step__title">
        {intl.formatMessage({
          id: 'clustersPage.azureCreationClusterCard.step',
        })}
        {` `}
        {stepOrder}/{stepsCount}
      </p>
      <Tooltip title={currentStepName}>
        <p className="azure-creation-cluster-step__name">{currentStepName}</p>
      </Tooltip>
    </div>
  );
}

export default AzureCreationClusterCurrentStep;
