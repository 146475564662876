import { useState } from 'react';
import { notification } from 'antd';
import { useMutation } from 'react-query';
import { QueryServerKeys } from 'constants/QueryServerKeys';
import { queryClient } from 'index';
import { stopOrRestartService as stopOrRestartServiceApi } from 'services/cluster';
import { ClusterAction, ServiceToRestart } from 'types/cluster';
import { getErrorMessage, showErrorMessage } from 'utils/errors';

function useServiceAction({
  instanceId,
  serviceNode,
}: {
  instanceId: string;
  serviceNode: ServiceToRestart;
}) {
  const [triggeredAction, setTriggeredAction] = useState<ClusterAction>();

  const {
    mutateAsync: stopOrRestartService,
    isLoading: isChangingServiceState,
    data: stopOrRestartServiceApiData,
    isSuccess: isStopOrRestartServiceApiSuccess,
  } = useMutation(stopOrRestartServiceApi);

  async function handleServiceRestartorStop({
    action,
    all,
    nodeName,
  }: {
    action: ClusterAction;
    all?: boolean;
    nodeName?: string;
  }) {
    setTriggeredAction(action);
    try {
      const data = await stopOrRestartService({
        action,
        instanceId,
        service: serviceNode,
        ...(!all && { nodeName }),
        ...(all && { all: true }),
      });
      await queryClient.invalidateQueries([
        QueryServerKeys.CLUSTER.GET_SERVICES_STATUS,
        instanceId,
        serviceNode,
      ]);
      notification.success({
        message: 'Success',
        description: data?.data?.message,
      });
    } catch (error) {
      let errorMessage = (error && getErrorMessage(error)) as string;
      if (errorMessage) {
        showErrorMessage(errorMessage);
      }
    } finally {
      // loading false
    }
  }

  return {
    triggeredAction,
    handleServiceRestartorStop,
    isChangingServiceState,
    stopOrRestartServiceApiData,
    isStopOrRestartServiceApiSuccess,
  };
}

export default useServiceAction;
