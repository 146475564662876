import './SignUp.less';

import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import FullPageLoader from 'components/FullPageLoader/FullPageLoader';
import { SessionContext } from 'auth/SessionProvider';

function SignUp({ isSSO = false, isSignUp = false }: any) {
  const { isLoading: loading, loginWithRedirect } = useContext(SessionContext);
  const history = useHistory();

  if (loading) {
    return <FullPageLoader />;
  }

  const queryParams = new URLSearchParams(history.location.search);
  if (isSSO) {
    localStorage.removeItem('firstLogin'); //when session expires and auth0 redirects User to login Page
    const clusterURL = queryParams.get('redirect_to');
    let additionalOptions = {};
    if (clusterURL) {
      additionalOptions = {
        appState: {
          returnToClusterURL: clusterURL,
        },
      };
    }
    loginWithRedirect({
      redirectUri: `${process.env.REACT_APP_URL}`,
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      screen_hint: 'login',
      ...additionalOptions,
    });
  } else if (isSignUp) {
    const utmParamNames = [
      'utm_medium',
      'utm_source',
      'utm_campaign',
      'utm_term',
      'utm_content',
    ];
    const utm = Object.fromEntries(
      utmParamNames.map(paramName => [paramName, queryParams.get(paramName)]),
    );
    loginWithRedirect({
      redirectUri: `${process.env.REACT_APP_URL}`,
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      screen_hint: 'signup',
      ...(utm && { utm: JSON.stringify(utm) }),
    });
  } else {
    loginWithRedirect({
      redirectUri: `${process.env.REACT_APP_URL}/clusters`,
      audience: process.env.REACT_APP_AUTH_AUDIENCE,
      screen_hint: 'login',
    });
  }

  return <FullPageLoader />;
}

export default SignUp;
