import './Accepting.less';

import React, { useContext, useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { displaySuccessMessage } from 'utils';
import { showErrorMessage } from 'utils/errors';
import { SessionContext } from 'auth/SessionProvider';
import { useAcceptUserCluster } from 'hooks/cluster';

interface AcceptingProps {
  userID: string;
  instanceID: string;
}

function Accepting({ userID, instanceID }: AcceptingProps) {
  const history = useHistory();
  const { user } = useContext(SessionContext);

  const [isEnabled, setIsEnabled] = useState(false);
  const { isSuccess, isError } = useAcceptUserCluster({
    userID,
    instanceID,
    isEnabled,
  });

  useEffect(() => {
    if (user.uuid !== userID) {
      showErrorMessage(
        'Invalid invitation for this account. Please logout and try again',
      );
      history.push('/clusters');
    } else {
      setIsEnabled(() => true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.uuid, history, userID, instanceID]);

  useEffect(() => {
    if (isSuccess) {
      displaySuccessMessage('Cluster added to cluster list');
      history.push('/clusters');
    } else if (!isSuccess && isError) {
      history.push('/signin');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError, isSuccess]);

  return (
    <div className="accepting">
      <Spin tip="Accepting Cluster Invitaion" />
    </div>
  );
}

export default Accepting;
