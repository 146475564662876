import { useState, useEffect } from 'react';
import _ from 'lodash';
import { Feature, UserData } from 'types/user';

export enum TOGGLE_FEATURE_KEYS {
  'payment' = 'payment',
  'azureSupport' = 'azureSupport',
  'whitelist' = 'whitelist',
  'subcluster' = 'subcluster',
  'microsoftSynapse' = 'microsoftSynapse',
  'dataagent' = 'dataagent',
  'azureSaaSSupport' = 'azureSaaSSupport',
  'incortaX' = 'incortaX',
  'autoScale' = 'autoScale',
  'copilot' = 'copilot',
  'schedular' = 'schedular',
  'deltashare' = 'deltashare',
  'mlflow' = 'mlflow',
}

interface useToggleFeatureProps {
  user: UserData;
  featureKey: TOGGLE_FEATURE_KEYS;
}
function useToggleFeature({ user, featureKey }: useToggleFeatureProps) {
  const [currentToggleFeatures, setCurrentToggleFeatures] = useState(
    [] as Feature[],
  );
  const [currentFeatures, setCurrentFeatures] = useState([] as Feature[]);

  const [isToggleFeatureEnabled, setIsToggleFeatureEnabled] = useState(false);

  useEffect(() => {
    if (
      user &&
      (!_.isEqual(user.toggleFeatures, currentToggleFeatures) ||
        !_.isEqual(user.features, currentFeatures))
    ) {
      setCurrentToggleFeatures(user.toggleFeatures);
      setCurrentFeatures(user.features);

      const isFeaturePublished =
        user.toggleFeatures?.find(({ key }) => key === featureKey)?.published ||
        false;
      const isFeatureToggled =
        !!user.features?.find(({ key }) => key === featureKey)?.toggle || false;

      setIsToggleFeatureEnabled(
        isFeaturePublished || isFeatureToggled || false,
      );
    }
  }, [
    currentFeatures,
    currentToggleFeatures,
    featureKey,
    user,
    user.features,
    user.toggleFeatures,
  ]);

  return { isToggleFeatureEnabled, TOGGLE_FEATURE_KEYS };
}

export default useToggleFeature;
