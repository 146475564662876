import './TabConfiguration.less';
import React, { useMemo } from 'react';
import { RetweetOutlined } from '@ant-design/icons';
import { Form, Input, InputNumber, Select, Switch, Table, Tag } from 'antd';
import { Rule } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import ReactHtmlParser from 'html-react-parser';
import { useForm } from 'antd/lib/form/Form';
import {
  AdvancedConfigurationStateChanges,
  getDependencies,
} from '../utils/utils';
import {
  AdvancedConfigTabDisplayName,
  ConfigCategoryItemConfig,
  ConfigCategoryType,
  RangeValidator,
} from 'types/cluster';

const columns: { dataIndex: string; width?: string }[] = [
  { dataIndex: 'description' },
  { dataIndex: 'formItem' },
];

export const TabConfiguration = React.memo(
  ({
    changes,
    categoryConfigs,
    tab,
    selectedCategory,
    handleConfigurationValueChange,
    isAdvancedConfigsEditable,
    loading,
  }: TabConfigurationArgs) => {
    const [form] = useForm();
    const tabDependencies = useMemo(
      () => getDependencies(changes, categoryConfigs, tab),
      [changes, categoryConfigs, tab],
    );
    const renderSettings = (settings: ConfigCategoryItemConfig[]) => {
      const data: any[] = [];

      settings.forEach((item: ConfigCategoryItemConfig) => {
        let formItem = renderItem(item);
        if (formItem !== undefined) {
          data.push({
            key: item.key,
            description: createDescription(item),
            formItem,
          });
        }
      });
      return data;
    };

    const renderItem = (item: ConfigCategoryItemConfig) => {
      if (item.dependencies.length !== 0 && !isActivelyDependent(item)) {
        return;
      } else {
        // lowering case for type value as BE was returning the lowercase types in old CMC release
        const itemType = item.type.toLowerCase();
        switch (itemType) {
          case 'enum':
            return renderSelect(item);
          case 'string':
            return renderInput(item);
          case 'password':
            return renderInput(item, true);
          case 'text':
            return renderTextArea(item);
          case 'boolean':
            return renderSwitch(item);
          case 'number':
            return renderInputNumber(item);
        }
      }
    };

    const isActivelyDependent = (curItem: ConfigCategoryItemConfig) => {
      const activeDependencies = curItem.dependencies.filter(
        (itemDeps: any) => itemDeps.key in tabDependencies,
      );

      if (!!curItem.dependenciesAndingEvaluation) {
        for (let dependency of activeDependencies) {
          if (tabDependencies[dependency.key] !== dependency.value)
            return false;
        }
        return true;
      } else {
        for (let dependency of activeDependencies) {
          if (tabDependencies[dependency.key] === dependency.value) return true;
        }
        return false;
      }
    };

    const createDescription = (item: ConfigCategoryItemConfig) => {
      const colors: { [key: string]: string } = {
        all: 'purple',
        loader: 'green',
        analytics: 'gold',
        SQLi: 'blue',
      };
      return (
        <>
          <b>{item.label}</b>
          {item.requiresRestart && (
            <sup className="server-restart">
              <Tag id="antd-tag" color={colors[item.serviceToRestart]}>
                <RetweetOutlined /> {item.serviceToRestart}
              </Tag>
            </sup>
          )}
          <br />
          <span className="text-description">
            {ReactHtmlParser(item.description)}
          </span>
        </>
      );
    };

    const renderInput = (
      item: ConfigCategoryItemConfig,
      isPassword: boolean = false,
    ) => {
      const InputSelected = isPassword ? Input.Password : Input;
      return (
        <InputSelected
          data-testid={item.key}
          value={getItemValue(item.key, item.value)}
          disabled={!isAdvancedConfigsEditable}
          onChange={(event: any) =>
            handleConfigurationValueChange(
              item.key,
              event.target.value,
              item.requiresRestart,
            )
          }
        />
      );
    };

    const renderTextArea = (item: ConfigCategoryItemConfig) => {
      return (
        <TextArea
          rows={4}
          data-testid={item.key}
          value={getItemValue(item.key, item.value)}
          disabled={!isAdvancedConfigsEditable}
          onChange={(event: any) =>
            handleConfigurationValueChange(
              item.key,
              event.target.value,
              item.requiresRestart,
            )
          }
        />
      );
    };

    const renderInputNumber = (item: ConfigCategoryItemConfig) => {
      const rules: Rule[] = [
        ...(item.validators.length > 0
          ? ([
              { type: 'integer', message: 'Input must be an integer.' },
              {
                message: `Value cannot be less than ${(
                  item.validators[0] as RangeValidator
                ).min!} or greater than ${
                  (item.validators[0] as RangeValidator).max
                }`,
                validator: (rule: any, value: any) =>
                  value < (item.validators[0] as RangeValidator) ||
                  value > (item.validators[0] as RangeValidator)
                    ? Promise.reject()
                    : Promise.resolve(),
              },
            ] as Rule[])
          : ([
              { type: 'integer', message: 'Input must be an integer' },
            ] as Rule[])),
      ];

      return (
        <Form.Item
          name={item.key}
          key={item.key}
          className="input-number"
          initialValue={getItemValue(item.key, item.value)}
          rules={rules}
        >
          <InputNumber
            data-testid={item.key}
            key={item.key}
            disabled={!isAdvancedConfigsEditable}
            onChange={(value: any) =>
              handleConfigurationValueChange(
                item.key,
                value,
                item.requiresRestart,
              )
            }
          />
        </Form.Item>
      );
    };

    const renderSwitch = (item: ConfigCategoryItemConfig) => {
      return (
        <Form.Item name={item.key} key={item.key}>
          <Switch
            data-testid={item.key}
            checked={getItemValue(item.key, item.value)}
            disabled={!isAdvancedConfigsEditable}
            onChange={(value: boolean) =>
              handleConfigurationValueChange(
                item.key,
                value,
                item.requiresRestart,
              )
            }
          />
        </Form.Item>
      );
    };

    const renderSelect = (item: ConfigCategoryItemConfig) => {
      return (
        <Select
          data-testid={item.key}
          value={getItemValue(item.key, item.value)}
          disabled={!isAdvancedConfigsEditable}
          onChange={(value: string | number) =>
            handleConfigurationValueChange(
              item.key,
              value,
              item.requiresRestart,
            )
          }
        >
          {(item.possibleValues as any[]).map((itemValue, index: number) => (
            <Select.Option key={index} value={itemValue}>
              {itemValue}
            </Select.Option>
          ))}
        </Select>
      );
    };

    const getItemValue = (key: any, value: any) => {
      if (
        changes &&
        changes[tab] &&
        changes[tab][selectedCategory] &&
        changes[tab][selectedCategory][key] !== undefined
      )
        return changes[tab][selectedCategory][key];
      return value;
    };

    columns[0].width = [
      'Diagnostics',
      'Integration',
      'Incorta Labs',
      'Export',
    ].includes(selectedCategory)
      ? '40%'
      : '75%';

    return (
      <>
        {categoryConfigs.length > 0 && (
          <Form form={form} key={tab}>
            <Table
              showSorterTooltip={false}
              loading={loading}
              columns={columns}
              dataSource={renderSettings(categoryConfigs)}
              showHeader={false}
              pagination={false}
            />
          </Form>
        )}
      </>
    );
  },
);

type TabConfigurationArgs = {
  changes: AdvancedConfigurationStateChanges;
  categoryConfigs: ConfigCategoryItemConfig[];
  tab: AdvancedConfigTabDisplayName;
  selectedCategory: ConfigCategoryType;
  loading: boolean;
  handleConfigurationValueChange: (
    key: string,
    value: string | number | boolean,
    restartRequired: boolean,
  ) => void;
  isAdvancedConfigsEditable: boolean;
};
