import React, { useContext, useState } from 'react';
import { Button, Input, Form, Popover, notification } from 'antd';
import { useIntl } from 'react-intl';
import { SessionContext } from 'auth/SessionProvider';
import PasswordCriteriaPopover from 'components/ProvisionUserModal/PasswordCriteriaPopover';
import { validatePasswordField } from 'components/ProvisionUserModal/helper';
import { useUpdateProvisionUserPassword } from 'hooks/user';

type ProvisionUserModalProps = {
  closeModal: () => void;
};
function ProvisionUserModal({ closeModal }: ProvisionUserModalProps) {
  const intl = useIntl();
  const [form] = Form.useForm();
  const { user } = useContext(SessionContext);
  const { innerWidth } = window;

  const [password, setPassword] = useState('');
  const [reEnterPassword, setReEnterPassword] = useState('');
  const [btnClicked, setBtnClicked] = useState<string | null>(null);

  const fieldNames = {
    newPasswordFieldName: 'new-password',
    confirmPasswordFieldName: 'confirm-password',
  } as const;
  const btns = {
    skip: 'skip',
    update: 'update',
  } as const;

  const {
    mutateAsync: mutateUpdateProvisionUserPassword,
    isLoading,
    isError,
    error,
  } = useUpdateProvisionUserPassword();

  const onChangePassword = async () => {
    setBtnClicked(btns.skip);
    const { data, status } = await mutateUpdateProvisionUserPassword({
      userID: user.uuid,
      isSkipped: false,
      password: form.getFieldValue(fieldNames.newPasswordFieldName),
    });
    if (status === 200) {
      user.isDummyPassword = false;
      notification.success({
        message: intl.formatMessage({
          id: 'provisionUserModal.changeProvisionUserPassword.successMessage',
        }),
        description: data.message,
      });
    } else {
      if (isError) {
        notification.error({
          message: error,
        });
        console.error(error);
      }
    }
    closeModal();
  };

  const onSkip = async () => {
    setBtnClicked(btns.skip);
    const { status } = await mutateUpdateProvisionUserPassword({
      userID: user.uuid,
      isSkipped: true,
      password: form.getFieldValue(fieldNames.newPasswordFieldName),
    });
    if (status === 200) {
      user.isDummyPassword = false;
    } else {
      if (isError) {
        console.error(error);
      }
    }
    closeModal();
  };

  return (
    <Form form={form} layout="vertical" className="provision-user-modal__form">
      <Popover
        placement={innerWidth > 575 ? 'right' : 'top'}
        content={<PasswordCriteriaPopover password={password} />}
        trigger="focus"
      >
        <Form.Item
          label={intl.formatMessage({
            id: 'provisionUserModal.newPasswordFieldLabel',
          })}
          name={fieldNames.newPasswordFieldName}
          rules={[
            {
              required: true,
              message: intl.formatMessage({
                id: 'provisionUserModal.fillInPasswordErrorMessage',
              }),
            },
            {
              validator: (_, value) => {
                const { isPasswordValid } = validatePasswordField(value);
                if (isPasswordValid) {
                  return Promise.resolve();
                } else {
                  return Promise.reject(
                    intl.formatMessage({
                      id: 'provisionUserModal.validatePasswordCriteriaErrorMessage',
                    }),
                  );
                }
              },
            },
          ]}
        >
          <Input.Password
            placeholder={intl.formatMessage({
              id: 'provisionUserModal.newPasswordFieldLabel',
            })}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Item>
      </Popover>

      <Form.Item
        label={intl.formatMessage({
          id: 'provisionUserModal.confirmPasswordFieldLabel',
        })}
        name={fieldNames.confirmPasswordFieldName}
        dependencies={[fieldNames.newPasswordFieldName]}
        rules={[
          {
            required: true,
            message: intl.formatMessage({
              id: 'provisionUserModal.confirmPasswordErrorMessage',
            }),
          },
          {
            validator: (_, value) => {
              const newPasswordFieldValue = form.getFieldValue(
                fieldNames.newPasswordFieldName,
              ) as string;
              if (newPasswordFieldValue === (value as string)) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  intl.formatMessage({
                    id: 'provisionUserModal.passwordsDontMatchErrorMessage',
                  }),
                );
              }
            },
          },
        ]}
      >
        <Input.Password
          placeholder={intl.formatMessage({
            id: 'provisionUserModal.confirmPasswordFieldLabel',
          })}
          value={reEnterPassword}
          onChange={e => setReEnterPassword(e.target.value)}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <div className="azure-synapse-modal__footer-bottom">
          <Button
            type="primary"
            htmlType="button"
            onClick={onSkip}
            disabled={isLoading}
            loading={isLoading && btnClicked === btns.skip}
          >
            {intl.formatMessage({
              id: 'provisionUserModal.skipBtn',
            })}
          </Button>
          <Button
            type="primary"
            htmlType="button"
            onClick={onChangePassword}
            disabled={isLoading}
            loading={isLoading && btnClicked === btns.update}
          >
            {intl.formatMessage({
              id: 'provisionUserModal.updateBtn',
            })}
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
}

export default ProvisionUserModal;
