import React, { useContext } from 'react';
import './AutoScalingControl.less';
import { Switch, Tooltip, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { AutoScaleItem } from 'types/cluster';
import { useUpdateAutoScaling } from 'hooks/user';
import { SessionContext } from 'auth/SessionProvider';

const { Title } = Typography;

function AutoScalingControl({
  instanceID,
  isInstanceRunning,
  autoScaleStatus,
}: {
  instanceID: string;
  isInstanceRunning: boolean;
  autoScaleStatus: AutoScaleItem | null;
}) {
  const { user } = useContext(SessionContext);

  const {
    mutateAsync: mutateUpdateAutoScaling,
    isLoading: isUpdateAutoScalingLoading,
  } = useUpdateAutoScaling();

  async function handleChangeScaling(isEnabled: boolean) {
    try {
      await mutateUpdateAutoScaling({
        userId: user.uuid,
        instanceID,
        isEnabled,
      });
    } catch (error) {
      //
    }
  }

  return (
    <div className="auto-scaling-control__wrapper">
      <section className="auto-scaling-control__switch-wrapper">
        <Title level={3}>
          <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScalingSelect" />
        </Title>
        <Tooltip
          title={
            !isInstanceRunning ? (
              <FormattedMessage id="clusterConfiguration.general.clusterMustBeConnectedToChange" />
            ) : (
              ''
            )
          }
        >
          <Switch
            loading={isUpdateAutoScalingLoading}
            disabled={!isInstanceRunning}
            checked={autoScaleStatus?.enabled ?? false}
            onChange={handleChangeScaling}
          />
        </Tooltip>
      </section>
      {autoScaleStatus?.enabled && (
        <article className="auto-scaling-control__parameters">
          <Title level={5} className="auto-scaling-control__parameters-title">
            <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.title" />
          </Title>
          <ul className="auto-scaling-control__parameters-items">
            <li>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.requestDelay"
                tagName={'label'}
              />
              {autoScaleStatus.requestDelay}
              <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.units.requestDelay" />
            </li>
            <li>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.coolDelay"
                tagName={'label'}
              />
              {+autoScaleStatus.coolDelay / 60}
              <FormattedMessage id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.units.coolDelay" />
            </li>
            <li>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.rejectedQueriesUp"
                tagName={'label'}
              />
              {autoScaleStatus.rejectedQueriesUp}
            </li>
            <li>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.rejectedQueriesDown"
                tagName={'label'}
              />
              {autoScaleStatus.rejectedQueriesDown}
            </li>
            <li>
              <FormattedMessage
                id="clusterConfiguration.configurationsTab.scaling.autoScaling.parameters.consecutiveQueries"
                tagName={'label'}
              />
              {autoScaleStatus.consecutiveQueries}
            </li>
          </ul>
        </article>
      )}
    </div>
  );
}

export default AutoScalingControl;
