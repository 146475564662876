import './BlueprintItem.less';

import React from 'react';
import { ImportedBlueprint } from 'types/cluster';
import { getFunctionColorMap } from 'utils/blueprint';

interface ImportedBlueprintItemProps {
  blueprintItem: ImportedBlueprint;
}
export function ImportedBlueprintItem({
  blueprintItem,
}: ImportedBlueprintItemProps) {
  return (
    <div className="BlueprintItem">
      <div
        className="BlueprintItem__color-bar"
        style={{
          background: getFunctionColorMap(
            blueprintItem.blueprint.businessFunction,
          ),
        }}
      />

      <div className="BlueprintItem__body">
        <div>
          <div className="BlueprintItem__business-function">
            {blueprintItem.blueprint.businessFunction}
          </div>
          <div className="BlueprintItem__text">
            {blueprintItem.blueprint.name}
          </div>
        </div>
        <div className="BlueprintList__item-footer">
          <div className="BlueprintList__item-images">
            <img
              src={blueprintItem.blueprint.blueprintCategory.image}
              alt={blueprintItem.blueprint.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
