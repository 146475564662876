import React, { useContext, useMemo } from 'react';
import { Form, Select } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import semver from 'semver';
import { searchFilterOption } from 'utils';
import { SessionContext } from 'auth/SessionProvider';
import { clusterFormFieldNames } from 'components/ClusterCreate/ClusterForm/ClusterForm';
import { useGetBlueprints } from 'hooks/cluster';

function DataAppFormField({ currentVersion }: { currentVersion: string }) {
  const { user } = useContext(SessionContext);
  const { data: result } = useGetBlueprints({
    fields: ['name', 'id', 'trial', 'minimal_version', 'installable'],
  });

  const dataApps = useMemo(() => {
    return currentVersion
      ? result?.blueprints
          ?.filter(dataApp => {
            const versionMatched =
              semver.valid(dataApp.minimal_version) &&
              semver.valid(currentVersion)
                ? semver.gte(currentVersion, dataApp.minimal_version as string)
                : true;
            const inculdeEnterprise = user?.role?.name === 'enterprise';

            return (
              dataApp.installable &&
              versionMatched &&
              (dataApp.trial || inculdeEnterprise)
            );
          })
          .map(e => ({
            label: e?.name,
            value: e?.id,
          }))
      : [];

    // eslint-disable-next-line
  }, [result, currentVersion]);

  return (
    <Form.Item
      name={clusterFormFieldNames.blueprintId}
      label={<FormattedMessage id="clusterForm.clusterDataAppLabel" />}
      className="cluster-input-with-hint"
      extra={
        <>
          <InfoCircleFilled />{' '}
          <FormattedMessage id="clusterForm.clusterDataAppHint" />
        </>
      }
    >
      <Select
        showSearch
        placeholder={
          <FormattedMessage id="clusterForm.clusterDataAppPlaceholder" />
        }
        defaultActiveFirstOption={true}
        notFoundContent={null}
        filterOption={searchFilterOption}
        allowClear
      >
        {dataApps?.map(app => (
          <Select.Option key={app.value} value={app.value}>
            {app.label}
          </Select.Option>
        ))}
      </Select>{' '}
    </Form.Item>
  );
}

export default DataAppFormField;
