import React from 'react';

type AzureCreationClusterSubCardOneLineInfoProps = {
  label: string;
  info: string;
};
function AzureCreationClusterSubCardOneLineInfo({
  label,
  info,
}: AzureCreationClusterSubCardOneLineInfoProps) {
  return (
    <section className="one-line">
      <label>
        {label}
        <p className="bolder-700"> {info}</p>
      </label>
    </section>
  );
}
export default AzureCreationClusterSubCardOneLineInfo;
