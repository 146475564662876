import React, { useContext } from 'react';
import { Dropdown, Menu, Modal, notification, Select, Table, Tag } from 'antd';
import moment from 'moment';
import { MoreOutlined } from '@ant-design/icons';
import { AuthorizedUserRole, Instance, Role } from 'types/cluster';
import {
  useRemoveClusterUser,
  useUpdateClusterUserRole,
  useWithdrawClusterUserInvitation,
} from 'hooks/cluster';
import { mapRuleToName } from 'utils/cluster';
import { SessionContext } from 'auth/SessionProvider';

interface AccessSecurityListProps {
  instance: Instance;
  authUsers: AuthorizedUserRole[] | undefined;
  isLoading: boolean;
  // requestCluster: () => Promise<any>;
}
function AccessSecurityList({
  instance,
  authUsers,
  isLoading,
}: AccessSecurityListProps) {
  const { user } = useContext(SessionContext);
  let { mutateAsync: deleteUser } = useRemoveClusterUser({
    clusterName: instance.name,
  });

  let { mutateAsync: withdrawClusterUserInvitation } =
    useWithdrawClusterUserInvitation({
      clusterName: instance.name,
    });

  let authUsersFormatted = authUsers?.map(authUser => {
    return {
      ...authUser.user,
      status: authUser.status,
      roles: authUser.authorizedRoles,
      lastLogin: null,
    };
  });

  let columns = [
    {
      title: 'Name',
      dataIndex: 'fullname',
      key: 'fullname',
      render(_: any, authUser: any) {
        return (
          <div>
            {authUser.fullname}{' '}
            {authUser.id === user!.uuid && (
              <span style={{ color: '#9aa6b0' }}>(You)</span>
            )}
          </div>
        );
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render(_: any, authUser: any) {
        let canUpdate =
          instance.clusterPolicy.userPolicy.includes('update') &&
          authUser.id !== user!.uuid &&
          authUser.roles.some(
            (user: any) =>
              user.role === 'developer' ||
              user.role === 'accountAdmin' ||
              user.role === 'accountManager',
          );

        if (canUpdate) {
          let [firstRule] = authUser.roles;
          return (
            <ChangeRoleSelectBox
              instance={instance}
              authUser={authUser}
              firstRule={firstRule}
            />
          );
        }

        return (
          <div>
            {authUser.roles.map((role: Role, i: number) => {
              switch (role.role) {
                case 'owner': {
                  return (
                    <Tag
                      key={i}
                      color="rgba(191, 130, 176, 0.2)"
                      style={{ color: '#4D1D41' }}
                    >
                      Owner
                    </Tag>
                  );
                }
                case 'accountAdmin': {
                  return (
                    <Tag
                      key={i}
                      color="rgba(112, 160, 179, 0.2)"
                      style={{ color: '#37517F' }}
                    >
                      Manager
                    </Tag>
                  );
                }
                case 'accountManager': {
                  return (
                    <Tag
                      key={i}
                      color="rgba(191, 190, 178, 0.2)"
                      style={{ color: '#595939' }}
                    >
                      Billing User
                    </Tag>
                  );
                }
                case 'developer': {
                  return (
                    <Tag
                      key={i}
                      color="rgba(112, 160, 179, 0.25)"
                      style={{ color: '#365966' }}
                    >
                      User
                    </Tag>
                  );
                }
                default: {
                  return (
                    <Tag key={i} color="red">
                      NOT KNOWN
                    </Tag>
                  );
                }
              }
            })}
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render(status: string) {
        return <div style={{ textTransform: 'capitalize' }}>{status}</div>;
      },
    },
    {
      title: 'Last Signed In',
      dataIndex: 'lastLoginAt',
      key: 'lastLoginAt',
      render(lastLoginAt: string) {
        return lastLoginAt ? moment(lastLoginAt).fromNow() : '';
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render(_: any, authUser: any) {
        let [firstRole] = authUser.roles;
        let meIsAccountManager = instance.authorizedRoles.some(
          role => role === 'accountManager',
        );

        let hasWithdraw =
          meIsAccountManager &&
          firstRole.role === 'owner' &&
          authUser.status === 'pending';

        let canDelete =
          instance.clusterPolicy.userPolicy.includes('delete') &&
          authUser.id !== user!.uuid &&
          (hasWithdraw ||
            authUser.roles.some(
              (user: any) =>
                user.role === 'developer' ||
                user.role === 'accountAdmin' ||
                user.role === 'accountManager',
            ));

        if (!canDelete && !hasWithdraw) {
          return null;
        }

        return (
          <Dropdown
            trigger={['click']}
            placement="bottomRight"
            overlay={
              <Menu>
                {hasWithdraw && (
                  <Menu.Item
                    key="2"
                    onClick={() => {
                      Modal.confirm({
                        title: `Are you sure you want to withdraw the ownership Invitation from ${authUser.fullname}?`,
                        content: `Withdrawing the ownership will turn the user to the previous role.`,
                        okText: 'Yes',
                        cancelText: 'Cancel',
                        centered: true,
                        width: 620,
                        onOk() {
                          return withdrawClusterUserInvitation({
                            id: authUser.id,
                          });
                        },
                      });
                    }}
                  >
                    Withdraw Ownership Invitation
                  </Menu.Item>
                )}

                {canDelete && (
                  <Menu.Item
                    key="1"
                    onClick={() => {
                      Modal.confirm({
                        title: `Are you sure you want to delete ${authUser.fullname}?`,
                        content: `${authUser.email} will no longer have access to this cluster.`,
                        okText: 'Delete',
                        cancelText: 'Cancel',
                        centered: true,
                        width: 620,
                        async onOk() {
                          await deleteUser({ id: authUser.id });
                          notification.success({
                            message: 'User Deleted',
                            description: `Successfully deleted ${authUser.email} user.`,
                          });
                        },
                      });
                    }}
                  >
                    Delete User
                  </Menu.Item>
                )}
              </Menu>
            }
          >
            <button className="icon-btn" onClick={() => {}}>
              <MoreOutlined />
            </button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div className="AccessSecurityList">
      <Table
        loading={isLoading}
        size="small"
        className="AccessSecurityList__table"
        pagination={false}
        columns={columns}
        dataSource={authUsersFormatted}
        rowKey="id"
      />
    </div>
  );
}

interface ChangeRoleSelectBoxProps {
  instance: Instance;
  firstRule: any;
  authUser: any;
}
function ChangeRoleSelectBox({
  instance,
  firstRule,
  authUser,
}: ChangeRoleSelectBoxProps) {
  let { mutateAsync: updateClusterUserRole, isLoading } =
    useUpdateClusterUserRole({
      clusterName: instance.name,
    });

  return (
    <Select
      size="small"
      style={{ width: '160px' }}
      value={firstRule.role}
      disabled={authUser.status === 'pending'}
      loading={isLoading}
      onChange={role => {
        if (role === 'owner') {
          Modal.confirm({
            title: `Transfer ${instance.name} ownership to ${authUser.fullname}?`,
            content: `Once the user accepts the invitation sent via email, cluster ownership can't be revoked or rolled back.`,
            okText: 'Transfer',
            cancelText: 'Cancel',
            centered: true,
            width: 620,
            async onOk() {
              await updateClusterUserRole({
                userEmail: authUser.email,
                roleName: role,
              });
            },
          });
        } else {
          Modal.confirm({
            title: `Are you sure you want to change ${
              authUser.fullname
            } role to ${mapRuleToName(role)}?`,
            content: ``,
            okText: 'Change',
            cancelText: 'Cancel',
            centered: true,
            width: 620,
            async onOk() {
              await updateClusterUserRole({
                userEmail: authUser.email,
                roleName: role,
              });
            },
          });
        }
      }}
    >
      {instance.clusterPolicy.authorizedRoles.map(rule => {
        return (
          <Select.Option key={rule} value={rule}>
            {mapRuleToName(rule)}
          </Select.Option>
        );
      })}
    </Select>
  );
}

export default AccessSecurityList;
