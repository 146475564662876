import React from 'react';
import './SelfManagedClusterType.less';
import { ReactComponent as IncortaLogo } from 'images/incorta-blue-logo.svg';
import { ReactComponent as ChidoriLogo } from 'images/chidori-icon.svg';
import { SelfManagedClusterTypes } from 'types/cluster';

type SelfManagedClusterTypeProps = {
  type: SelfManagedClusterTypes;
};
function SelfManagedClusterType({ type }: SelfManagedClusterTypeProps) {
  const TypeIcon = () => {
    if (type === SelfManagedClusterTypes.sqlx) {
      return <i>SQLX</i>;
    } else if (type === SelfManagedClusterTypes.chidori) {
      return <ChidoriLogo />;
    } else {
      return <IncortaLogo />;
    }
  };
  return (
    <div className="self-managed-cluster-type">
      <TypeIcon />
      {type}
    </div>
  );
}

export default SelfManagedClusterType;
