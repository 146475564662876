import React, { useRef, useState } from 'react';
import './PythonPackagesEdit.less';
import Icon, { InfoCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { Modal, notification } from 'antd';
import { InstalledPythonPackage, Instance, Service } from 'types/cluster';
import { ReactComponent as BoxIcon } from 'images/box.svg';
import { useMutateCluster, useSyncPythonPackages } from 'hooks/cluster';
import { UserData } from 'types/user';
import AddPythonPackage, {
  AddPythonPackageRefType,
} from 'components/ClusterDetails/ClusterDetailsBody/Configurations/AddPythonPackage/AddPythonPackage';
import OptionedTag from 'components/OptionedTag/OptionedTag';
import PythonPackageTagInfo from 'components/ClusterDetails/ClusterDetailsBody/Configurations/PythonPackagesEdit/PythonPackageTagInfo/PythonPackageTagInfo';
import PythonPackageMenu from 'components/ClusterDetails/ClusterDetailsBody/Configurations/PythonPackagesEdit/PythonPackageMenu/PythonPackageMenu';
import EditPythonPackageModal from 'components/ClusterDetails/ClusterDetailsBody/Configurations/PythonPackagesEdit/EditPythonPackageModal/EditPythonPackageModal';

interface PythonPackagesEditProps {
  instance: Instance;
  services: Service[];
  user: UserData;
}

function PythonPackagesEdit({
  instance,
  services,
  user,
}: PythonPackagesEditProps) {
  const installedPythonPackages = instance?.pythonPackages;
  const [isEditPythonPackageModalOpen, setIsEditPythonPackageModalOpen] =
    useState(false);
  const [selectedPythonPackage, setSelectedPythonPackage] =
    useState<InstalledPythonPackage | null>(null);

  const addPythonPackageRef = useRef<AddPythonPackageRefType>(null);

  const isClusterRunning = instance.status === 'running';

  function handleOpenEdit({
    pythonPackage,
  }: {
    pythonPackage: InstalledPythonPackage;
  }) {
    setSelectedPythonPackage(pythonPackage);
    setIsEditPythonPackageModalOpen(true);
  }
  const handleCloseEdit = () => {
    setIsEditPythonPackageModalOpen(false);
    setSelectedPythonPackage(null);
  };

  const { mutateAsync: mutateCluster } = useMutateCluster({
    clusterName: instance.name,
  });

  const isAnyPythonPackageHasNoVersion =
    installedPythonPackages.findIndex(
      currentPackage => !currentPackage.name.includes('=='),
    ) !== -1
      ? true
      : false;

  const {
    mutateAsync: mutateRefreshPythonPackages,
    isLoading: isRefreshPythonPackagesLoading,
  } = useSyncPythonPackages({ instanceID: instance.id });

  async function reinstallPythonPackage(packageToBeReinstalled: string) {
    try {
      notification.info({
        message: '',
        description: 'Package will be reinstalled',
      });
      const { data } = await mutateCluster({
        packagesToBeInstalled: packageToBeReinstalled,
        packagesToBeUnInstalled: '',
      });
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } catch (error) {}
  }

  async function deletePackage(packageToBeDeleted: string) {
    try {
      const { data } = await mutateCluster({
        packagesToBeInstalled: '',
        packagesToBeUnInstalled: packageToBeDeleted,
      });
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } catch (error) {}
  }

  async function handleEditPackages(packagesToBeEdited: string) {
    try {
      const { data } = await mutateCluster({
        packagesToBeInstalled: packagesToBeEdited,
        packagesToBeUnInstalled: '',
      });
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } catch (error) {}
  }

  async function installPackages({
    packagesToBeInstalled,
  }: {
    packagesToBeInstalled: string;
  }) {
    try {
      const { data } = await mutateCluster({
        packagesToBeInstalled,
        packagesToBeUnInstalled: '',
      });
      notification.success({
        message: 'Success',
        description: data.message,
      });
    } catch (error) {}
  }

  async function refreshPythonPackages() {
    try {
      const status = await mutateRefreshPythonPackages();
      if (status === 200) {
        notification.success({
          message: 'Success',
          description: 'Python Packages Versions Syncronised',
        });
      }
    } catch (error) {}
  }

  async function handleAddingNewPackages(newPackages: string) {
    const alreadyInstalledPackagesNames = installedPythonPackages
      ?.filter(pythonPackage =>
        newPackages.includes(pythonPackage.name.split('==')[0]),
      )
      ?.map(pythonPackage => pythonPackage.name.split('==')[0]);
    if (alreadyInstalledPackagesNames.length) {
      Modal.confirm({
        title: 'Installed Packages Detected',
        // title: (
        //   <FormattedMessage id="clusterConfiguration.pythonPackages.addPackages.confirmModal.title" />
        // ),
        content: `Please note that
        ${alreadyInstalledPackagesNames.join(', ')}
        ${
          alreadyInstalledPackagesNames.length < 2 ? 'is' : 'are'
        }  already installed, do you want to overwrite ${
          alreadyInstalledPackagesNames.length < 2 ? 'it' : 'them'
        } ?`,
        // content: intl.formatMessage(
        //   {
        //     id: 'clusterConfiguration.pythonPackages.addPackages.confirmModal.content',
        //   },
        //   {
        //     packages: alreadyInstalledPackagesNames.join(', '),
        //     plural1:
        //       alreadyInstalledPackagesNames.length < 2
        //         ? intl.formatMessage({
        //             id: 'clusterConfiguration.pythonPackages.addPackages.confirmModal.singular1',
        //           })
        //         : intl.formatMessage({
        //             id: 'clusterConfiguration.pythonPackages.addPackages.confirmModal.plural1',
        //           }),
        //     plural2:
        //       alreadyInstalledPackagesNames.length < 2
        //         ? intl.formatMessage({
        //             id: 'clusterConfiguration.pythonPackages.addPackages.confirmModal.singular2',
        //           })
        //         : intl.formatMessage({
        //             id: 'clusterConfiguration.pythonPackages.addPackages.confirmModal.plural2',
        //           }),
        //   },
        // ),
        centered: true,
        width: 620,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk() {
          proceedToInstall(newPackages);
        },
      });
    } else {
      proceedToInstall(newPackages);
    }
  }

  async function proceedToInstall(newPackages: string) {
    addPythonPackageRef.current?.resetInput();
    await installPackages({
      packagesToBeInstalled: newPackages,
    });
  }

  return (
    <>
      <div className="ClusterEdit PythonPackagesEdit">
        <div className="ClusterEdit__icon">
          <Icon component={BoxIcon} />
        </div>
        <div className="ClusterEdit__content">
          <h3 className="ClusterEdit__title">
            <FormattedMessage id="clusterConfiguration.titles.pythonPackages" />
          </h3>
          <div className="PythonPackagesEdit__body">
            <p className="PythonPackagesEdit__hint">
              <FormattedMessage id="clusterConfiguration.pythonPackages.subHeader" />
            </p>
            <div className="PythonPackagesEdit__form">
              <section>
                <label className="PythonPackagesEdit__form-label">
                  <FormattedMessage id="clusterConfiguration.pythonPackages.addPackages.title" />
                </label>
                <AddPythonPackage
                  instance={instance}
                  services={services}
                  user={user}
                  handleAddingNewPackages={handleAddingNewPackages}
                  ref={addPythonPackageRef}
                />
                <p className="ClusterEdit__icon-hint">
                  <InfoCircleOutlined />{' '}
                  <FormattedMessage id="clusterConfiguration.pythonPackages.addPackages.hint" />
                </p>
              </section>

              <section>
                <label className="PythonPackagesEdit__form-label">
                  <FormattedMessage
                    id="clusterConfiguration.pythonPackages.installedPackages.title"
                    tagName={'span'}
                  />
                  {isAnyPythonPackageHasNoVersion && (
                    <button
                      className="link"
                      onClick={refreshPythonPackages}
                      disabled={
                        isRefreshPythonPackagesLoading || !isClusterRunning
                      }
                    >
                      <SyncOutlined style={{ marginRight: 8 }} />
                      <FormattedMessage id="clusterConfiguration.pythonPackages.installedPackages.displayVersions" />
                    </button>
                  )}
                </label>
                <ul
                  className="PythonPackagesEdit__list"
                  aria-label="Installed Python Packages List"
                >
                  {installedPythonPackages?.map(
                    (pythonPackage: InstalledPythonPackage) => {
                      return (
                        <OptionedTag
                          key={pythonPackage.name}
                          TagContent={PythonPackageTagInfo}
                          tagContentProps={{
                            pythonPackage,
                            reinstallPythonPackage,
                            isClusterRunning,
                          }}
                          DropdownMenu={PythonPackageMenu}
                          dropdownProps={{
                            show: isClusterRunning,
                            pythonPackage,
                            reinstallPythonPackage,
                            deletePackage,
                            handleOpenEdit,
                            isClusterRunning,
                          }}
                        />
                      );
                    },
                  )}
                </ul>
              </section>
            </div>
          </div>
        </div>
      </div>
      {isEditPythonPackageModalOpen && (
        <EditPythonPackageModal
          clusterName={instance.name}
          wholePackageName={selectedPythonPackage?.name as string}
          visible={isEditPythonPackageModalOpen}
          handleCloseEdit={handleCloseEdit}
          handleEditPackages={handleEditPackages}
        />
      )}
    </>
  );
}

export default PythonPackagesEdit;
