import { FS, Heap, TrackingEventsEnum } from 'types/tracking';

declare global {
  interface Window {
    heap?: Heap;
    FS?: FS;
  }
}

/**
 * set user identity
 * @param identity
 */
export const identifyHeapUser = (
  identity: string,
  userVars?: { displayName?: string; email?: string },
) => {
  window.heap?.identify(identity);
  window.heap?.addUserProperties({ Name: userVars?.displayName });
};

/**
 * adding track methods for user interactions events
 * @param event
 * @param props
 */
export const trackEvent = (event: TrackingEventsEnum, props?: Object) => {
  window.heap?.track(event, props);
};

/**
 * adding add user properties
 * @param props
 */

export const addProperties = (props: Object) => {
  window.heap?.addUserProperties(props);
};

/**
 * adding add user properties
 */
export const anonymizeUserForHeap = () => {
  window.heap?.resetIdentity();
};

/**
 * set user identity for FS
 * @param identity
 */
export const identifyUserForFullStory = (
  identity: string,
  userVars?: { displayName: string; email: string },
) => {
  window.FS?.identify(identity, userVars);
};

export const anonymizeUserForFullStory = () => {
  window.FS?.anonymize();
};

export const identifyUserForQualified = (user: any) => {
  document.dispatchEvent(
    new CustomEvent('SESSION_LOGGED_IN', {
      detail: { user },
    }),
  );
};

export const anonymizeUserForQualified = () => {
  document.dispatchEvent(new CustomEvent('SESSION_LOGGED_OUT'));
};

export const clearUserIdentityForAllTrackingTools = () => {
  anonymizeUserForFullStory();
  anonymizeUserForHeap();
  anonymizeUserForQualified();
};
