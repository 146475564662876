import React, { useRef, useState, useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag } from 'antd';
import { splitComaString } from 'utils/cluster';

interface InputTagsProps {
  newButtonText?: string;
  onNewButtonClick?: () => void;
  value?: string;
  onChange?: (value: string) => void;
  viewOnly?: boolean;
}

const InputTags = React.forwardRef(
  (
    {
      newButtonText = 'New Tag',
      onNewButtonClick = () => {},
      value = '',
      onChange = () => {},
      viewOnly = false,
    }: InputTagsProps,
    _ref,
  ) => {
    const [showInput, setShowInput] = useState(false);
    const inputRef = useRef<Input | null>(null);

    const tags = splitComaString(value);

    useEffect(() => {
      if (showInput && inputRef.current) {
        inputRef.current.input.focus();
      }
    }, [showInput]);

    function handleAddTag(
      e:
        | React.FocusEvent<HTMLInputElement>
        | React.KeyboardEvent<HTMLInputElement>,
    ) {
      e.preventDefault();
      const newTag = e.currentTarget.value;
      if (newTag && !tags.includes(newTag)) {
        const newValue = [...tags, newTag].join(',');
        onChange(newValue);
      }
      setShowInput(false);
    }

    function handleCloseTag(targetTag: string) {
      const newValue = tags.filter(tag => targetTag !== tag).join(',');
      onChange(newValue);
    }

    if (viewOnly) {
      return (
        <div className="input-tag-wrapper">
          {tags.map(tag => (
            <Tag key={tag} className="tag">
              {tag}
            </Tag>
          ))}
        </div>
      );
    }

    return (
      <div className="input-tag-wrapper">
        {tags.map(tag => (
          <Tag
            key={tag}
            closable={true}
            onClose={() => {
              handleCloseTag(tag);
            }}
            className="tag"
          >
            {tag}
          </Tag>
        ))}
        {showInput ? (
          <Input
            ref={inputRef}
            type="text"
            size="small"
            style={{ width: 116 }}
            onBlur={handleAddTag}
            onPressEnter={handleAddTag}
            className="tag-input"
          />
        ) : (
          <Tag
            onClick={() => {
              setShowInput(true);
              onNewButtonClick();
            }}
            className="tag tag-add"
          >
            <PlusOutlined /> {newButtonText}
          </Tag>
        )}
      </div>
    );
  },
);

export default InputTags;
