import './Consumption.less';

import { format } from 'util';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import { useLocation } from 'react-router';
import { Typography } from 'antd';
import ConsumptionBody from './ConsumptionBody/ConsumptionBody';
import IncortaLayoutContainer from 'components/IncortaLayoutContainer/IncortaLayoutContainer';
import PageSideWrapper from 'components/Sider/PageSideWrapper/PageSideWrapper';
import { useConsumptionInfo } from 'hooks/cluster';
import {
  getClustersConsumption,
  getTimeRangeDates,
  TimeRange,
} from 'utils/cluster';

const { Title } = Typography;

export interface ConsumptionFiltersOptions {
  timeRange: TimeRange;
  selectedClusters: { value: string; label: string }[];
  timeRangeChanged?: boolean;
}

function Consumption() {
  let { state } = useLocation<any>();
  let { clusterId, clusterName } = state ?? {};
  let clusterIdRef = useRef(clusterId);

  let [filters, setFilters] = useState<ConsumptionFiltersOptions>({
    timeRange: { type: 'month' },
    selectedClusters: [],
  });

  let { start, end } = useMemo(
    () => getTimeRangeDates(filters.timeRange),
    [filters.timeRange],
  );
  let startEndRef = useRef<any>();
  startEndRef.current = {
    start: format(start, 'd MMM yyyy'),
    end: format(end, 'd MMM yyyy'),
  };

  let { data, isLoading, isError } = useConsumptionInfo({ start, end });

  let clusters = data?.data.data.clusters;
  let clustersFormatted = useMemo(
    () =>
      clusters ? getClustersConsumption(clusters, { start, end }) : undefined,
    [clusters, end, start],
  );

  // when range changes set selected clusters to new range clusters
  let oldStartEndRef = useRef<any>();
  useLayoutEffect(() => {
    if (clustersFormatted) {
      if (!_.isEqual(oldStartEndRef.current, startEndRef.current)) {
        setFilters(f => {
          let res = {
            ...f,
            selectedClusters: clusterIdRef.current
              ? [{ label: clusterName, value: clusterId }]
              : f.timeRangeChanged
              ? f.selectedClusters
              : clustersFormatted?.map((cluster: any) => ({
                  label: cluster.name,
                  value: cluster.id,
                })) || [],
          };
          clusterIdRef.current = null;
          return res;
        });
        oldStartEndRef.current = startEndRef.current;
      }
    }
    // eslint-disable-next-line
  }, [clustersFormatted]);

  return (
    <IncortaLayoutContainer>
      <PageSideWrapper>
        <div className="Consumption">
          <div className="Consumption__page-header">
            <div className="clusters-page__page-header-info">
              <Title level={4}>Incorta Units Consumption</Title>
            </div>
          </div>

          <ConsumptionBody
            filters={filters}
            setFilters={setFilters}
            clustersFormatted={clustersFormatted}
            isLoading={isLoading}
            isError={isError}
            start={start}
            end={end}
          />
        </div>
      </PageSideWrapper>
    </IncortaLayoutContainer>
  );
}

export default Consumption;
