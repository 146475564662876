import { Form, Switch, Select } from 'antd';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { blueprintInstallModalFormFieldNames } from 'components/BlueprintsPage/BlueprintInstallModal/BlueprintInstallModal';
import { getDatasources } from 'services/cluster';

const GET_DATASOURCES = 'GET_DATASOURCES';
export default function CopyParquetToggleField() {
  const [copyParquet, setCopyParquet] = useState<boolean>(false);

  const { data: datasources } = useQuery(
    GET_DATASOURCES,
    () => getDatasources(),
    {
      enabled: !!copyParquet,
      select: data => data?.datasources,
    },
  );

  return (
    <>
      <Form.Item
        name={blueprintInstallModalFormFieldNames.includeParquet}
        label="Copy Parquet Files"
        className="BlueprintInstallModal__switch-field"
        initialValue={false}
      >
        <Switch onChange={setCopyParquet} />
      </Form.Item>

      {copyParquet && (
        <Form.Item
          name={blueprintInstallModalFormFieldNames.datasource}
          label="Data source"
          initialValue={datasources?.[0]}
          rules={[
            {
              required: true,
              message: 'Please select the Data source',
            },
          ]}
        >
          <Select showSearch placeholder={'Select Datasource'}>
            {datasources?.map(datasource => (
              <Select.Option key={datasource} value={datasource}>
                {datasource}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      )}
    </>
  );
}
