import React from 'react';
import './ScheduledEventEndTimeStep.less';
import { InfoCircleFilled } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import { scheduledEventFormFieldNames } from '../../ScheduledEventModal';
import { scheduledEventDateFormat } from '../../../../../../../utils/constants';

type ScheduledEventEndTimeStepProps = {
  currentFormValues: Record<any, any>;
  isViewOnly: boolean;
};
function ScheduledEventEndTimeStep({
  currentFormValues,
  isViewOnly,
}: ScheduledEventEndTimeStepProps) {
  function disabledDate(current: moment.Moment) {
    // Disable dates before the current date
    return current && current < moment().startOf('day');
  }

  return (
    <>
      <Form.Item
        name={scheduledEventFormFieldNames.endTime}
        label={
          <>
            <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.endTime" />
            <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.endTimeStepOptional" />
          </>
        }
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        className="scheduled-event-end-time-step__form-item"
        extra={
          <section className="scheduled-event-end-time-step__hint">
            <InfoCircleFilled />
            <FormattedMessage id="clusterConfiguration.schedulingTab.scheduledEventForm.endTimeStepHint" />
          </section>
        }
        rules={[
          {
            validator(_rule, value) {
              const startTime = moment(currentFormValues.startTime);
              if (startTime >= value && value !== null) {
                return Promise.reject(
                  `Please select a date and time after the start time (${moment(
                    startTime,
                  ).format(scheduledEventDateFormat)})`,
                );
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <DatePicker
          className="scheduled-event-end-time-step__start-time-date-picker"
          showTime
          showNow={false}
          disabledDate={disabledDate}
          disabled={isViewOnly}
        />
      </Form.Item>
    </>
  );
}

export default ScheduledEventEndTimeStep;
