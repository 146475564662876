import './BlueprintLoading.less';

import React, { useMemo, useState } from 'react';
import { Spin } from 'antd';

function BlueprintLoading({ message }: any) {
  const [messageDetails, seMessageDetails] = useState<any>();
  useMemo(() => {
    message && seMessageDetails(message);
  }, [message]);
  return messageDetails ? (
    <div className="BlueprintLoading">
      <div className="BlueprintLoading__header">
        <Spin className="BlueprintLoading__header-loading" />
        {messageDetails.title}
      </div>
      <div className="BlueprintLoading__text">{messageDetails.body}</div>
    </div>
  ) : null;
}

export default BlueprintLoading;
