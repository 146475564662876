import './ClusterCard.less';

import React, { useCallback, useContext, useState } from 'react';
import Icon, {
  CopyOutlined,
  DeleteOutlined,
  MoreOutlined,
} from '@ant-design/icons';
import _ from 'lodash';
import {
  Typography,
  Dropdown,
  Menu,
  Tooltip,
  Spin,
  Button,
  Divider,
} from 'antd';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { FormattedMessage, useIntl } from 'react-intl';
import cookie from 'js-cookie';
import CloneModal from './CloneModal/CloneModal';
import { ReactComponent as DisconnectedIcon } from 'images/disconnected.svg';
import { ReactComponent as LoadingIcon } from 'images/loading-icon.svg';
import { ReactComponent as CheckIcon } from 'images/check.svg';
import { ReactComponent as IncortaVIcon } from 'images/incorta-v-icon.svg';
import { ReactComponent as RegionIcon } from 'images/regionClusterDetails.svg';
import { ReactComponent as IconPauseIcon } from 'images/icon-pause.svg';
import { ReactComponent as OpenIcon } from 'images/open-icon.svg';
import { ReactComponent as IncortaNewLogo } from 'images/incorta-new-logo.svg';
import { ReactComponent as AzureLogo } from 'images/azure-a-logo.svg';
import { InstanceInfo } from 'types/cluster';
import {
  getClusterStatus,
  getClusterUrl,
  getClusterTypeIcons,
  getClusterTypeName,
  canUserCreateCluster,
  deleteModalConfirm,
} from 'utils/cluster';
import { updateClusterListQuery, useClusterListRefetch } from 'hooks/cluster';
import useRequest from 'hooks/useRequest';
import usePlatformActionsSupport, {
  PLATFORM_ACTIONS_KEYS,
} from 'hooks/usePlatformActionsSupport';
import { connectCluster, disconnectCluster } from 'services/cluster';
import { getErrorsOfInput } from 'utils/fieldsValidation';
import { SessionContext } from 'auth/SessionProvider';
import { InstancePlatform } from 'utils/platforms';

const { Title } = Typography;

interface ClusterCardProps {
  cluster: InstanceInfo;
  handleDelete: (instanceName: string) => Promise<any>;
}

function ClusterCard({ cluster, handleDelete }: ClusterCardProps) {
  const { user } = useContext(SessionContext);
  const intl = useIntl();

  let [showCloneModal, setCloneModal] = useState(false);

  let instance = cluster.instance;
  let incortaService = cluster.services[0];
  let clusterStatus = getClusterStatus(instance, incortaService);

  let isLoading =
    clusterStatus === 'creating' || clusterStatus === 'starting-up';

  const cloudSSO = cookie.get('cloudSSO') || '';
  let clusterUrl = getClusterUrl(cloudSSO, {
    instance,
    service: incortaService,
  });

  let requestCluster = useClusterListRefetch();

  const { makeRequest: makeConnectCluster, isPending: isConnecting } =
    useRequest(
      useCallback(async () => {
        await connectCluster({
          userId: user!.uuid,
          clusterName: instance.name,
        });
        await requestCluster();
      }, [instance.name, requestCluster, user]),
      {
        handleError: true,
      },
    );

  async function handleConnect() {
    await makeConnectCluster();
  }

  const { makeRequest: makeDisconnectCluster } = useRequest(disconnectCluster, {
    handleError: true,
  });

  async function handleDisconnect() {
    // Optimistic update for disconnect
    await updateClusterListQuery(user?.uuid!, instanceData => {
      let instances =
        instanceData?.instances.map(instance => {
          if (instance.instance.name === cluster.instance.name) {
            try {
              instance.services[0].status = 'disconnected';
            } catch {}
          }
          return instance;
        }) ?? [];
      return { instances };
    });
    try {
      await makeDisconnectCluster({
        userId: user!.uuid,
        clusterName: instance.name,
      });
    } catch {}
  }

  const clusterNameError = (value?: string, clusterName?: string) => {
    getErrorsOfInput(
      'cluster-name',
      value ? () => value === '' || clusterName !== value : true,
      'The input is not valid Name!',
    );
  };

  const clusterNameValidation = useCallback(
    _.debounce((e: any, clusterName) => {
      // clusterNameError(e.target.value, clusterName);
    }, 1500),
    [],
  );

  let canClone = instance.clusterPolicy?.managementPolicy.includes('create');
  let canDelete = instance.clusterPolicy?.clusterPolicy.includes('delete');
  let canDoConnection =
    instance.clusterPolicy?.connectionPolicy.includes('create');
  const { isPlatformActionSupported: isCloneSupported } =
    usePlatformActionsSupport({
      currentPlatform: instance.platform,
      userPlatformActions: user?.platformActions,
      platformKey: PLATFORM_ACTIONS_KEYS.clone,
    });

  let statusColor = isLoading
    ? '#FFA900'
    : instance.corrupted
    ? '#393E41'
    : clusterStatus === 'running'
    ? '#469650'
    : clusterStatus === 'sleeping'
    ? '#B43237'
    : '#393E41';

  let statusIcon = isLoading ? (
    <LoadingIcon />
  ) : clusterStatus === 'running' ? (
    <Tooltip title="Connected">
      <CheckIcon />
    </Tooltip>
  ) : clusterStatus === 'sleeping' ? (
    <Tooltip title="Disconnected">
      <DisconnectedIcon color="#B43237" />
    </Tooltip>
  ) : instance.corrupted ? (
    <Tooltip title="Unavailable">
      <DisconnectedIcon color="#393E41" />
    </Tooltip>
  ) : null;

  let footerContent = isLoading ? (
    <div className="cluster-card__loading-footer">
      <Spin size="small" />
      {clusterStatus === 'creating'
        ? 'Creating Incorta cluster may take 5-10 minutes'
        : 'Starting up Incorta cluster may take up to 5 mins'}
    </div>
  ) : clusterStatus === 'running' ? (
    <div className="cluster-card__footer-wrapper">
      <div className="cluster-card__footer-info truncate">
        <span>
          <span className="cluster-card__light-text">Created On</span>{' '}
          {format(new Date(instance.createdAt), 'dd MMM yyyy')}
        </span>
        <span style={{ marginLeft: 16 }}>
          <span className="cluster-card__light-text">Owner</span>{' '}
          {instance.owner.fullName}
        </span>
      </div>
      <object>
        <a
          className="cluster-card__footer-incorta-link"
          onClick={e => e.stopPropagation()}
          href={clusterUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          <OpenIcon /> Incorta
        </a>
      </object>
    </div>
  ) : clusterStatus === 'sleeping' ? (
    <div
      className="cluster-card__footer-wrapper"
      onClick={e => e.preventDefault()}
    >
      <div className="cluster-card__footer-info truncate">
        <span>
          <span className="cluster-card__light-text">Created On</span>{' '}
          {format(new Date(instance.createdAt), 'dd MMM yyyy')}
        </span>
        <span style={{ marginLeft: 16 }}>
          <span className="cluster-card__light-text">Owner</span>{' '}
          {instance.owner.fullName}
        </span>
      </div>
      {canDoConnection && (
        <Button
          className="cluster-card__button"
          onClick={handleConnect}
          loading={isConnecting}
        >
          Connect
        </Button>
      )}
    </div>
  ) : null;

  let canDisconnect =
    clusterStatus === 'starting-up' || clusterStatus === 'running';

  let uiCanDisconnect = canDoConnection && canDisconnect;
  let uiCanClone =
    canClone &&
    canUserCreateCluster({
      user,
      configurations: user?.configurations,
      platformActions: user?.platformActions,
    }) &&
    instance.cloneable;
  let uiCanDelete = canDelete;

  return (
    <div className="cluster-card__wrapper">
      <Link to={`/clusters/${instance.name}`}>
        <div className={'cluster-card'}>
          <div
            className="cluster-card__bar"
            style={{
              // @ts-ignore
              '--color': statusColor,
            }}
          />
          <div className={'cluster-card__body'}>
            <div className="cluster-card__title">
              <Title level={4} className="cluster-card__title-text truncate">
                <span className="cluster-card__title-icon ">{statusIcon}</span>
                {instance.hostName || instance.name}
              </Title>
              <div
                className="cluster-card__menu"
                onClick={e => e.stopPropagation()}
              >
                <Tooltip
                  title={
                    instance.platform === InstancePlatform.AZURE
                      ? intl.formatMessage({
                          id: 'clustersPage.clusterTypes.azureCluster',
                        })
                      : intl.formatMessage({
                          id: 'clustersPage.clusterTypes.incortaCluster',
                        })
                  }
                  placement="top"
                >
                  {instance.platform === InstancePlatform.AZURE ? (
                    <AzureLogo className="platform-logo" />
                  ) : (
                    <IncortaNewLogo className="platform-logo" />
                  )}
                </Tooltip>
                {(uiCanDisconnect || uiCanClone || uiCanDelete) && (
                  <Dropdown
                    trigger={['click']}
                    overlayClassName="cluster-card__menu-overlay"
                    placement="bottomRight"
                    overlay={
                      <Menu>
                        {uiCanDisconnect && (
                          <Menu.Item
                            key="2"
                            onClick={() => {
                              handleDisconnect();
                            }}
                          >
                            <Icon component={DisconnectedIcon}></Icon>
                            Disconnect
                          </Menu.Item>
                        )}

                        {uiCanClone && (
                          <Menu.Item
                            key="0"
                            disabled={
                              clusterStatus !== 'running' || !isCloneSupported
                            }
                            onClick={() => {
                              setCloneModal(true);
                            }}
                          >
                            <Tooltip
                              placement="left"
                              title={
                                !isCloneSupported && (
                                  <FormattedMessage
                                    id="clusterConfiguration.general.actionNotSupportedInCurrentPlan.message"
                                    values={{
                                      action: intl.formatMessage({
                                        id: 'clusterConfiguration.general.actionNotSupportedInCurrentPlan.clone',
                                      }),
                                    }}
                                  />
                                )
                              }
                            >
                              <CopyOutlined />
                              Clone
                            </Tooltip>
                          </Menu.Item>
                        )}
                        <Divider />
                        {uiCanDelete && (
                          <Menu.Item
                            key="1"
                            onClick={() => {
                              deleteModalConfirm({
                                clusterName: instance.name,
                                handleDelete,
                                clusterNameValidation,
                                clusterNameError,
                              });
                            }}
                          >
                            <DeleteOutlined />
                            Delete
                          </Menu.Item>
                        )}
                      </Menu>
                    }
                  >
                    <MoreOutlined />
                  </Dropdown>
                )}
                <CloneModal
                  instance={instance}
                  show={showCloneModal}
                  setShow={setCloneModal}
                  refresh={requestCluster}
                />
              </div>
            </div>

            <div className="cluster-card__info">
              <div className="cluster-card__info-text truncate">
                <span className="cluster-card__info-icon">
                  <Icon component={IncortaVIcon} />
                </span>
                <span className="cluster-card__info-label">
                  Current Version
                </span>{' '}
                <strong className="cluster-card__info-value">
                  {instance.customBuild
                    ? `${instance.customBuild}`
                    : `${instance.image}`}
                </strong>
              </div>

              <div className="cluster-card__info-text truncate">
                <span className="cluster-card__info-icon">
                  {React.createElement(
                    getClusterTypeIcons(instance.csize, 'gray'),
                    {
                      style: {
                        fontSize: 14,
                        color: '#99A6B0',
                        fill: '#99A6B0',
                      },
                    },
                  )}
                </span>
                <span className="cluster-card__info-label">Size</span>{' '}
                <strong className="cluster-card__info-value">
                  {getClusterTypeName(instance.csize)}
                </strong>
              </div>

              {user && (
                <div className="cluster-card__info-text truncate">
                  <span className="cluster-card__info-icon">
                    <RegionIcon />
                  </span>
                  <span className="cluster-card__info-label">Region</span>{' '}
                  <strong className="cluster-card__info-value">
                    {instance.regionAlias}
                  </strong>
                </div>
              )}

              <div className="cluster-card__info-text truncate">
                <span className="cluster-card__info-icon">
                  <Icon component={IconPauseIcon} />
                </span>
                <span className="cluster-card__info-label">Auto Suspend</span>{' '}
                <strong className="cluster-card__info-value">
                  {instance.displayIdleTime}
                </strong>
              </div>
            </div>
          </div>

          <div className="cluster-card__footer">{footerContent}</div>
        </div>
      </Link>
    </div>
  );
}

export default ClusterCard;
