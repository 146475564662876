import Icon from '@ant-design/icons';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { endOfMonth, startOfMonth } from 'date-fns/esm';
import MainCard from '../MainCard/MainCard';
import { Instance } from 'types/cluster';
import { ReactComponent as ConsumedIcon } from 'images/consumed-icon.svg';
import { getClustersConsumption, getConsumptionTableDate } from 'utils/cluster';
import { sum } from 'utils';
import UnitsChart from 'components/UnitsChart/UnitsChart';

interface ClusterSizeCardProps {
  instance: Instance;
}

function ConsumedUnitsCard({ instance }: ClusterSizeCardProps) {
  let history = useHistory();

  let tableDate = useMemo(
    () =>
      getConsumptionTableDate(instance.consumptionAgg, new Date().toString()),
    [instance],
  );
  let mothUnits = sum(tableDate, d => d.total);

  let consumption = getClustersConsumption(
    [
      {
        id: instance.id,
        name: instance.name,
        consumptionAgg: instance.consumptionAgg,
      },
    ],
    {
      start: startOfMonth(new Date()),
      end: endOfMonth(new Date()),
    },
  );

  return (
    <MainCard
      icon={<Icon component={ConsumedIcon} />}
      title={
        <>
          Consumed Incorta Power Units{' '}
          <span style={{ color: '#393E41' }}>(Current Month)</span>
        </>
      }
      subtitle={`${mothUnits} IPU`}
      footerText={'Consumption Details'}
      onFooterClick={() => {
        history.push({
          pathname: '/consumption',
          state: { clusterId: instance.id, clusterName: instance.name },
        });
      }}
    >
      <UnitsChart
        chartType="line"
        filteredClusters={consumption}
        height={200}
        grid={undefined}
      />
    </MainCard>
  );
}

export default ConsumedUnitsCard;
