import './SupportTokenButton.less';

import { QuestionCircleOutlined } from '@ant-design/icons';
import { Divider, Popconfirm, Popover, Skeleton, Space, Spin } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { useSupportToken } from 'hooks/cluster';
import { Instance } from 'types/cluster';
import CopyableText from 'components/CopyableText/CopyableText';

interface SupportTokenButtonProps {
  instance: Instance;
}
function SupportTokenButton({ instance }: SupportTokenButtonProps) {
  return (
    <Popover
      trigger={'click'}
      placement="bottomRight"
      title={'Help & Support'}
      content={<SupportTokenPopover instance={instance} />}
    >
      <button className="icon-btn">
        <QuestionCircleOutlined />
      </button>
    </Popover>
  );
}

interface SupportTokenPopoverProps {
  instance: Instance;
}
function SupportTokenPopover({ instance }: SupportTokenPopoverProps) {
  let {
    data,
    requestNewToken,
    requestRevokeToken,
    isGenerating,
    isTokenLoading,
  } = useSupportToken({
    clusterName: instance.name,
  });

  let canCreate = instance.clusterPolicy.cliPolicy.includes('create');
  let canDelete = instance.clusterPolicy.cliPolicy.includes('delete');
  let canUpdate = instance.clusterPolicy.cliPolicy.includes('update');

  let generateUi =
    canCreate &&
    (isGenerating ? (
      <div className="SupportTokenPopover__loading">
        <Spin size="small" />
        <span className="SupportTokenPopover__loading-hint">
          Generating an Access Token
        </span>
      </div>
    ) : (
      <button className="link" onClick={() => requestNewToken({})}>
        Generate Access Token
      </button>
    ));

  let tokenUi = data && (
    <div>
      <CopyableText text={data.token} />
      <div className="SupportTokenPopover__token-actions">
        <div>Expire on {format(new Date(data.expiresAt), 'dd/MM/yyyy')}</div>
        <div>
          <Space size="small">
            {canDelete && (
              <Popconfirm
                placement="bottomLeft"
                title="Are you sure you want revoke access token?"
                okText="Revoke"
                onConfirm={() => requestRevokeToken()}
              >
                <button className="link">Revoke</button>
              </Popconfirm>
            )}

            {canDelete && canUpdate && <Divider type="vertical" />}

            {canUpdate && (
              <Popconfirm
                placement="bottomLeft"
                title="Are you sure you want renew access token?"
                okText="Renew"
                onConfirm={() => requestNewToken({ revokeFirst: true })}
              >
                <button className="link">Renew</button>
              </Popconfirm>
            )}
          </Space>
        </div>
      </div>
    </div>
  );

  return (
    <div className="SupportTokenPopover">
      <p className="SupportTokenPopover__hint">
        Need help? <Link to={'/support'}>Contact Support</Link>
      </p>
      <p className="SupportTokenPopover__hint">
        Give our support team this temporarily access to your cluster by copying
        the token code and send it to our support team.
      </p>

      {isTokenLoading ? (
        <Skeleton paragraph={{ rows: 0 }} active />
      ) : !isGenerating && data ? (
        tokenUi
      ) : (
        generateUi
      )}
    </div>
  );
}

export default SupportTokenButton;
