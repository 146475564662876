import {
  PasswordStrengthCondition,
  PasswordStrengthConditions,
} from './constants';
import { showErrorMessage } from './errors';

let emailTime: any, passwordTime: any;

export function handleWriting(isEmail: boolean) {
  clearTimeout(isEmail ? emailTime : passwordTime);
}

export function clearTimeWhenWriting(time: any) {
  clearTimeout(emailTime);
}

export function getErrorsOfInput(
  fieldId: string,
  inputHasError: any,
  message: string,
  notActivated?: boolean,
  skip: boolean = false,
) {
  let elm: HTMLElement | null =
    document.querySelector(`input#${fieldId}`) ||
    document.querySelector(`input#${fieldId}-input`);
  if (!skip) {
    if (elm) {
      if (inputHasError()) {
        showErrorBelowField(elm, message, notActivated);
      } else {
        addSuccesClass(elm);
      }
    } else {
      showErrorMessage(message);
    }
  }
}

export function isValidEmail(email: string) {
  let isValid = false;
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (re.test(email)) {
    isValid = true;
  }
  return isValid;
}

export function clearAll(windowObject: any) {
  var id = Math.max(
    windowObject.setInterval(noop, 1000),
    windowObject.setTimeout(noop, 1000),
  );

  while (id--) {
    windowObject.clearTimeout(id);
    windowObject.clearInterval(id);
  }

  function noop() {}
}

export function resetErrors() {
  let elements = document.querySelectorAll<HTMLElement>('.error-message')!;
  let parentElms = document.querySelectorAll<HTMLElement>('.item-has-error')!;
  elements.forEach(element => {
    element.remove();
  });
  parentElms.forEach(element => {
    element && element.classList.remove('item-has-error');
    element && element.classList.remove('item-has-success');
  });
}

export function getPasswordStrengthStatus(value: string): {
  conditions: PasswordStrengthCondition[];
  isValid: boolean;
} {
  const conditions = PasswordStrengthConditions.map(
    (condition: PasswordStrengthCondition) => ({
      ...condition,
      valid: !!value.match(condition.regex),
    }),
  );
  return {
    conditions,
    isValid:
      PasswordStrengthConditions.length ===
      conditions.filter(e => e.valid).length,
  };
}

export function isValidPassword(value: string) {
  let isValid;
  if (
    value === '' ||
    value.match(
      /((?=.*\d)(?=^\S*$)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{12,32}$)/g,
    ) === null
  ) {
    isValid = false;
  } else {
    isValid = true;
  }
  return isValid;
}

export function showErrorBelowField(
  elm: HTMLElement,
  message: string,
  notActivated?: boolean,
) {
  let messageElement = document.createElement('p');
  messageElement.innerHTML = message;
  if (!notActivated) {
    messageElement.className = 'error-message';
    elm.closest('.input-wrapper')!.appendChild(messageElement);
  }

  elm.closest('.input-wrapper')!.classList.remove('item-has-success');
  elm.closest('.input-wrapper')!.classList.add('item-has-error');
}

export function addSuccesClass(elm: HTMLElement) {
  elm.closest('.input-wrapper')!.classList.remove('item-has-error');
  elm.closest('.input-wrapper')!.classList.add('item-has-success');
  const hasError = elm
    .closest('.input-wrapper')
    ?.lastElementChild?.classList.contains('error-message');
  if (hasError) {
    elm.closest('.input-wrapper')?.lastElementChild?.remove();
  }
}

export function hideErrorBelowField(fieldId: string) {
  let elm: HTMLElement | null =
    document.querySelector(`input#${fieldId}`) ||
    document.querySelector(`input#${fieldId}-input`);
  if (elm) {
    elm.closest('.input-wrapper')!.classList.remove('item-has-error');
    elm.closest('.input-wrapper')!.classList.remove('item-has-success');

    const hasError = elm
      .closest('.input-wrapper')
      ?.lastElementChild?.classList.contains('error-message');

    if (hasError) {
      elm.closest('.input-wrapper')?.lastElementChild?.remove();
    }
  }
}
