import { Button, Form, Input, notification, Select } from 'antd';
import React from 'react';
import {
  useAddClusterWhitelist,
  useUpdateClusterIpWhitelist,
} from 'hooks/cluster';
import { Instance, Whitelist } from 'types/cluster';

const IpRegex =
  /\b(?:(?:2(?:[0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9])\.){3}(?:(?:2([0-4][0-9]|5[0-5])|[0-1]?[0-9]?[0-9]))(?:(\/[0-9]{1,3}))?\b/;

interface WhiteListModalProps {
  instance: Instance;
  onChancel(): void;
  ipWhitelistItem?: Whitelist;
  whitelists?: Whitelist[];
  setWhitelists: Function;
}
function WhiteListModal({
  instance,
  onChancel,
  ipWhitelistItem,
  whitelists,
  setWhitelists,
}: WhiteListModalProps) {
  let { isLoading: addClusterLoading, mutateAsync } = useAddClusterWhitelist({
    clusterName: instance.name,
  });

  let {
    isLoading: updateClusterLoading,
    mutateAsync: updateClusterIpWhiteList,
  } = useUpdateClusterIpWhitelist({
    clusterName: instance.name,
  });

  let isLoading = addClusterLoading || updateClusterLoading;

  async function handleFinish({
    range,
    description,
  }: {
    range: string[];
    description: string;
  }) {
    let whiteList = { range: range.join(','), description };
    if (ipWhitelistItem) {
      // use Update
      let { data } = await updateClusterIpWhiteList({
        whitelistId: ipWhitelistItem.id,
        whitelist: {
          range: whiteList.range,
          description: whiteList.description,
        },
      });
      notification.success({
        message: 'Success',
        description: data.message,
      });

      let index = whitelists?.indexOf(ipWhitelistItem);
      if (index !== undefined && index !== -1) {
        whitelists![index] = data.whitelist;
      }
    } else {
      // create new
      let { data } = await mutateAsync(whiteList);
      notification.success({
        message: 'Success',
        description: data.message,
      });
      whitelists?.push(data.whitelist);
    }
    onChancel();
    setWhitelists(whitelists);
  }

  return (
    <div className="WhiteListModal modal-body">
      <Form
        layout="vertical"
        id="WhiteListModal"
        onFinish={handleFinish}
        initialValues={{
          range: ipWhitelistItem?.range.split(',').map(s => s.trim()),
          description: ipWhitelistItem?.description,
        }}
      >
        <Form.Item
          label="Allowed IP Adresses/Range"
          name="range"
          rules={[
            { required: true },
            {
              async validator(_, value) {
                value?.forEach((ip: string) => {
                  if (!IpRegex.test(ip)) {
                    throw Error(`Invalid Ip item formatting!`);
                  }
                });
              },
            },
          ]}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder="Eg. 192.168.1.0/24 OR 192.168.1.4, 192.168.1.7"
            open={false}
            onKeyDown={e => {
              // Stop Enter from submitting the form
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
          ></Select>
        </Form.Item>

        <Form.Item label="Description" name="description">
          <Input.TextArea
            placeholder="Eg. Location or Place Name..."
            autoSize={{ minRows: 4, maxRows: 10 }}
          />
        </Form.Item>

        <div className="modal-body-footer">
          <Button onClick={onChancel}>Cancel</Button>
          <Button htmlType="submit" type="primary" loading={isLoading}>
            {ipWhitelistItem ? 'Update' : 'Add'}
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default WhiteListModal;
