import React from 'react';
import './ConfigurationNavigationBar.less';
import { Anchor } from 'antd';
import { clusterConfigurationsType } from 'hooks/useClusterConfiguration';

const { Link } = Anchor;

type ConfigurationNavigationBar = {
  clusterConfigurations: clusterConfigurationsType[];
};
function ConfigurationNavigationBar({
  clusterConfigurations,
}: ConfigurationNavigationBar) {
  return (
    <Anchor style={{ marginTop: 24 }} className="configuration-navigation">
      {clusterConfigurations.map(
        (clusterConfig: clusterConfigurationsType) =>
          clusterConfig.conditionToShow && (
            <Link
              key={clusterConfig.id}
              href={`#${clusterConfig.id}`}
              title={clusterConfig.title}
            />
          ),
      )}
    </Anchor>
  );
}

export default ConfigurationNavigationBar;
